import clsx from 'clsx';
import { useMemo } from 'react';

import Chip from '@/components/Chip';
import formatSubCategory from '@/utils/formatSubCategory';

import styles from './SubCategoriesChips.module.scss';

const SubCategoriesChips = ({ subCategories, onDelete }) => {
  const subCategoriesArray = useMemo(() => Object.entries(subCategories), [subCategories]);

  return (
    <div className={styles.chips}>
      {subCategoriesArray.map(([subCategory, value]) => (
        <Chip
          key={subCategory}
          className={clsx(styles.chip, value === 'exclude' && styles.exclude)}
          onDelete={onDelete(subCategory)}
        >{formatSubCategory(subCategory)}
        </Chip>
      ))}
    </div>
  );
};

export default SubCategoriesChips;
