import { loadSpace } from '@usersnap/browser';
import React, { useContext, useEffect, useState } from 'react';

import useIsScreenshotting from '@/hooks/useIsScreenshotting';

import userInformation from './userInformation';

export const UsersnapContext = React.createContext(null);

export const UsersnapProvider = ({ children }) => {
  const [usersnapApi, setUsersnapApi] = useState(null);
  const isScreenshotting = useIsScreenshotting();

  useEffect(() => {
    if (!isScreenshotting) {
      loadSpace('b7f2468a-6291-4dc3-88b1-7ebdd9e5190e').then((api) => {
        const userInfo = userInformation();
        const email = userInfo?.email;
        const userId = userInfo?.userId;

        api.init({ user: { userId, email } });
        setUsersnapApi(api);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UsersnapContext.Provider value={usersnapApi}>
      {children}
    </UsersnapContext.Provider>
  );
};

export const useUsersnapApiContext = () => useContext(UsersnapContext);
