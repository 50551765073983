import clsx from 'clsx';
import React from 'react';

import Skeleton from '@/components/Skeleton';

import styles from './PanelParamsModifiers.module.scss';

const PanelParamsModifiersSkeleton = ({ disabled }) => (
  <div className={clsx([styles.container])}>
    <Skeleton disabled={disabled} width={320} height={39} />
  </div>
);

export default PanelParamsModifiersSkeleton;
