import clsx from 'clsx';
import React from 'react';

import formatTagType from '@/screens/Dashboard/DashboardPanel/PanelBodyExplore/formatTagType';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';

import styles from './CategorizedTags.module.scss';
import CategorizedTagsItems from './CategorizedTagsItems';

const CategorizedTags = ({
  category, label, tags, limit,
}) => {
  const renderedLabel = label || category;
  return (
    <div
      className={clsx({
        [styles.categoryDataTagsItem]: true,
      })}
      style={{
        '--dynamic-category-color':
                `var(--category-${formatCategoryUrnToCssVar(category)})`,
      }}
    >
      {renderedLabel ? <span className={styles.categoryDataTagsItemLabel}>{formatTagType(renderedLabel)}</span> : null}
      <CategorizedTagsItems tags={tags} limit={limit} />
    </div>
  );
};

export default CategorizedTags;
