import { ListItemText } from '@mui/material';
import React from 'react';

import Tooltip from '@/components/Tooltip';

import styles from './CreativesModal.module.scss';
import {
  colorByTemplateStatus,
  statuses,
  TEMPLATE_STATUSES,
} from './statuses';
import templatesByKey from './templates';

// TODO: move it to top file, so we could reuse it in other places
interface Template {
  name: string;
  description?: string;
  credit?: string;
}

interface ListItemProps {
  templateKey: string;
  isQlooUser: boolean;
}

const getTemplateTitle = (item: Template) => {
  if (!item.credit) return item.name;
  return `${item.name} by ${item.credit}`;
};

const CreativesModalStepOneListItem = ({ templateKey, isQlooUser }: ListItemProps) => {
  const templateItem = templatesByKey[templateKey] as Template;
  const status = statuses[templateKey] || TEMPLATE_STATUSES.EXPERIMENTAL;
  const color = colorByTemplateStatus[status];
  const secondary = templateItem?.description || templateItem?.credit;

  return (
    <ListItemText
      primary={(
        <div className={styles.selectItemPrimary}>
          {isQlooUser && (
            <div className={styles.selectDotContainer}>
              {/* @ts-ignore */}
              <Tooltip title={status}>
                <div
                  className={styles.selectDot}
                  style={{ backgroundColor: color }}
                />
              </Tooltip>
            </div>
          )}
          <span>{getTemplateTitle(templateItem)}</span>
        </div>
      )}
      secondary={
        secondary && (
          <div style={{ marginLeft: isQlooUser ? '26px' : '0' }}>
            {secondary}
          </div>
        )
      }
    />
  );
};

export default CreativesModalStepOneListItem;
