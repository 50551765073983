import {
  Box,
  Button, Typography,
} from '@mui/material';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useCallback, useMemo, useState } from 'react';

import permissionTags, {
  inheritedPermissionTags,
  permissionTagLabels,
} from '@/constants/permissionTags';
import { useDashboardIdentitySoftDeleteMutation, useUpdateDashboardSharingMutation } from '@/store/apis/dashboardsApi';
import getHighestRole from '@/utils/getHighestRole';

import RoleSelector from './RoleSelector';

const UndoChip = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      color="accent"
      shape="rounded"
      size="small"
      disableElevation
      onClick={onClick}
    >
      <Box display="flex" gap={1} alignItems="center">
        <span>Permission changed</span>
        <Typography color="primary" variant="caption">Undo</Typography>
      </Box>
    </Button>
  );
};

const IdentityRoleSelector = ({ identityLink, dashboardId, viewOnly }) => {
  const [previousRole, setPreviousRole] = useState(null);
  const [showUndo, setShowUndo] = useState(false);
  const popupState = usePopupState({ variant: 'popover', popupId: 'roleSelector' });
  const [updateDashboardSharing, { isLoading: isUpdating, error }] = useUpdateDashboardSharingMutation();
  const [dashboardIdentitySoftDelete, { isLoading: isDeleting }] = useDashboardIdentitySoftDeleteMutation();

  const highestRole = useMemo(
    () => getHighestRole(identityLink.calculatedPermissionTagIds),
    [identityLink.calculatedPermissionTagIds],
  );

  const handleRoleChange = useCallback(
    (role) => {
      if (!previousRole) {
        setPreviousRole(highestRole);
      }
      popupState.close();

      if (!role) {
        dashboardIdentitySoftDelete({
          dashboardId,
          identityId: identityLink.identityId,
        });
        return;
      }

      updateDashboardSharing({
        dashboardId,
        identityId: identityLink.identityId,
        grantedPermissionTagIds: `{${inheritedPermissionTags[role].join(', ')}}`,
      });
    },
    [dashboardId, dashboardIdentitySoftDelete, highestRole, identityLink, popupState, previousRole, updateDashboardSharing],
  );

  const handleRoleClick = useCallback((role) => {
    if (highestRole === role) {
      return;
    }

    setShowUndo(true);
    handleRoleChange(role);
  }, [highestRole, handleRoleChange]);

  const handleUndo = () => {
    setShowUndo(false);
    handleRoleChange(previousRole);
  };

  if (viewOnly || highestRole === permissionTags.roleOwner) {
    return (
      <Typography variant="body2" mr="32px">{permissionTagLabels[highestRole]}</Typography>
    );
  }

  return (
    <Box display="flex" gap={1} alignItems="center">
      {showUndo && !error && !isUpdating && !isDeleting && <UndoChip onClick={handleUndo} />}
      <RoleSelector
        selectedRole={highestRole}
        viewOnly={viewOnly}
        isLoading={isUpdating || isDeleting}
        onRoleChange={handleRoleClick}
        onDelete={handleRoleChange}
      />
    </Box>
  );
};

export default IdentityRoleSelector;
