import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Input from '@mui/material/TextField';
import clsx from 'clsx';
import {
  memo, useCallback, useMemo, useState,
} from 'react';

import useDebounce from '@/hooks/useDebounce';
import useDescribeLocation from '@/hooks/useDescribeLocation';
import { useGetSearchQuery } from '@/store/apis/searchApi';
import { geoJsonAreaSize, WktToGeoJson } from '@/utils/geoutils';
import tooltips from '@/utils/tooltips';

import GeoIconView from '../GeoIconView';
import TooltipInfo from '../Tooltip/TooltipInfo';
import styles from './MapDrawShape.module.scss';

const useLocationSearch = (search) => {
  const debouncedSearch = useDebounce(search, 250);

  const {
    data: searchData,
    isLoading: searchIsLoading,
    isFetching: searchIsFetching,
  } = useGetSearchQuery({
    query: debouncedSearch,
    types: ['urn:entity:place', 'urn:entity:locality'],
    'options.fuzziness.name': 'auto',
  }, { skip: !debouncedSearch });

  const searchResults = useMemo(() => searchData?.results.map((r) => {
    const geojson = WktToGeoJson(r.properties.boundaries);

    const geometry = geojson || {
      type: 'Point',
      coordinates: [r.location.lon, r.location.lat],
    };

    const displayName = r.disambiguation.startsWith(r.name) ? r.disambiguation : `${r.name} - ${r.disambiguation}`;

    return {
      type: 'Feature',
      geometry,
      displayName,
      name: r.name,
      entityId: r.entity_id,
    };
  }) || [], [searchData]);

  return {
    isLoading: search !== debouncedSearch || searchIsLoading || searchIsFetching,
    results: [...searchResults],
  };
};

const LocationOption = ({ option, params }) => (
  <div {...params}>
    <Box display="flex" alignItems="start" gap="16px">
      <div className={styles.geoIcon}>
        <GeoIconView geojson={option?.geometry} />
      </div>
      <Box display="flex" flexDirection="column">
        <h3>{ option?.displayName }</h3>
        <p>
          { option?.geometry?.type } { option?.geometry?.type !== 'Point' && geoJsonAreaSize(option, { unit: 'meters' }) }
        </p>
      </Box>
    </Box>
  </div>
);

const LocationSelector = ({
  location,
  className,
  color,
  size,
  placeholder = 'Find a country, city, or neighborhood',
  filterResults = (r) => r,
  onSelected = (r) => r,
}) => {
  const [search, setSearch] = useState();
  const { results, isLoading } = useLocationSearch(search);

  const describedLocation = useDescribeLocation(location);

  const handleChange = useCallback((e, result) => {
    if (!result) {
      // Case: Clear the input
      return;
    }

    onSelected(result);
  }, [onSelected]);

  return (
    <>
      <TooltipInfo title={tooltips.searchLocation}>
        <Box padding="12px" display="flex"><SearchIcon fontSize={size} /></Box>
      </TooltipInfo>
      <Autocomplete
        id="mapbox-place-finder"
        className={className}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option?.properties?.display_name)}
        filterOptions={filterResults}
        options={Array.isArray(results) && !isLoading ? results : []}
        loading={isLoading}
        loadingText="Searching..."
        noOptionsText={!search ? 'Start typing to search' : 'No results found'}
        clearOnEscape
        blurOnSelect
        onChange={handleChange}
        value={location.value ? describedLocation?.displayLabel : ''}
        renderInput={(params) => (
          <Input
            {...params}
            placeholder={placeholder}
            variant="standard"
            fullWidth
            onChange={(e) => setSearch(e.target.value)}
            className={clsx({
              [styles.locationInput]: true,
              [styles.colorBlue]: !!color && color === 'blue',
              [styles.colorPurple]: !!color && color === 'purple',
              [styles.locationSelected]: !!color && !!location.value,
              [styles.smallInput]: !!size && size === 'small',
            })}
            InputProps={{
              ...params.InputProps,
              endAdornment: null, // Remove endAdornment
              disableUnderline: true,
            }}
          />
        )}
        renderOption={(params, option) => (
          <LocationOption option={option} params={params} />
        )}
        componentsProps={{
          paper: {
            sx: {
              borderRadius: 'var(--border-radius)',
            },
          },
          popper: {
            className: styles.locationPopper,
            placement: 'bottom-start',
          },
        }}
      />
    </>
  );
};

export default memo(LocationSelector, (prev, next) => {
  const prevLocation = prev.location;
  const nextLocation = next.location;

  if (!!nextLocation.entityId && prevLocation?.entityId === nextLocation?.entityId) {
    return true;
  }

  if (!!nextLocation.geoJson && prevLocation?.geoJson === nextLocation?.geoJson) {
    return true;
  }

  return false;
});
