const getLayoutPanels = (layout, panels) => {
  if (!layout || !layout.length) {
    return [];
  }

  const itemsWithPanel = layout.filter(
    (item) => panels[item.i] && panels[item.i].params,
  );

  // TODO Re-add panel item after behavior is fixed

  return [
    ...itemsWithPanel,
  ];
};

export default getLayoutPanels;
