import { urnsToDisplayName } from '@qloo/categories';

import getParamsLabel from '@/utils/getParamsLabel';

// TODO this needs to be smarter and generalized
export const getBaseQueryLabel = (baseParams) => {
  const paramsLabel = getParamsLabel(baseParams);
  return `${paramsLabel} ${baseParams['signal.location'] ? ` and live in ${baseParams['signal.location'].label}` : ''}`;
};

// { [Brands]: Foo, Bar, Baz }
export const groupEntitiesByCategory = (entities) => entities?.reduce((acc, entity) => {
  const categoryName = urnsToDisplayName(entity.subtype) || 'Other';
  if (!acc[categoryName]) {
    acc[categoryName] = [];
  }
  acc[categoryName].push(entity.name);
  return acc;
}, {});

export const readableEntitiesByCategory = (entities) => (
  Object.entries(groupEntitiesByCategory(entities))
    .map(([category, es]) => `${category}: ${es.join(', ')}`)
    .join('\n')
);

export const groupTagsByCategory = (tags) => tags?.reduce((acc, tag) => {
  const categoryName = urnsToDisplayName(tag.subtype) || 'Other';
  if (!acc[categoryName]) {
    acc[categoryName] = [];
  }
  acc[categoryName].push(tag.name);
  return acc;
}, {});

// This doesn't really work, the output should be like the summary overview:
// Brands:
//    Aesthetic properties: Minimalist, Scandi, etc.
//    Other: Foo, Bar, Baz
export const readableTagsByCategory = (tags) => (
  Object.entries(groupTagsByCategory(tags))
    .map(([category, ts]) => `${category}: ${ts.join(', ')}`)
    .join('\n')
);

export const allReadableInsights = ({ entities, tags }) => (
  [readableEntitiesByCategory(entities), readableTagsByCategory(tags)]
    .filter(Boolean)
    .join('\n')
);
