export default {
  buttons: {
    newInsight: 'new-insight',
    panelMenu: 'panel-menu',
    dashboardMenu: 'dashboard-menu',
    locationParam: 'location-param',
    demographics: 'demographics',
    interests: 'interests',
    modifier: 'modifier',
    tab: 'tab',
    tabHome: 'tab-home',
    tabClose: 'tab-close',
    tabAdd: 'tab-add',
    creativesMenu: 'creativesMenu',
  },
  heatmapControls: {
    toggle: 'heatmap-controls-toggle',
    range: 'heatmap-controls-range',
    opacity: 'heatmap-controls-opacity',
    collapse: 'heatmap-controls-collapse',
  },
};
