const friendlyNames = {
  'filter.type': '"Category"',
  omnisearch: '"Interests"',
  audiences: '"Audience"',
  'filter.location': '"Filter Location"',
  'signal.location': '"Signal Location"',
  'signal.demographics.gender': '"Gender"',
  'signal.demographics.age': '"Age"',
};

const buildMissingParamsErrorMessage = (missingParamsObj) => {
  const messageParts = [];

  if (missingParamsObj.mandatory && missingParamsObj.mandatory.length > 0) {
    const mandatoryNames = missingParamsObj.mandatory.map(
      (field) => friendlyNames[field] || `"${field}"`,
    );
    messageParts.push(
        `The following required field${mandatoryNames.length > 1 ? 's are' : ' is'} missing: ${mandatoryNames.join(' and ')}`,
    );
  }

  if (missingParamsObj.oneOf && missingParamsObj.oneOf.length > 0) {
    const oneOfNames = missingParamsObj.oneOf.map(
      (field) => friendlyNames[field] || `"${field}"`,
    );
    messageParts.push(
        `Please select at least one from: ${oneOfNames.join(', ')}`,
    );
  }

  return messageParts.join(' and ');
};

export default buildMissingParamsErrorMessage;
