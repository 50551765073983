export const requiredParamsByPanelType = {
  table: {
    mandatory: [
      { key: 'filter.type', source: 'both' },
    ],
    oneOf: [
      { key: 'omnisearch', source: 'both' },
      { key: 'audiences', source: 'both' },
      { key: 'signal.location', source: 'both' },
    ],
  },
  heatmap: {
    mandatory: [
      { key: 'filter.location', source: 'both' },
    ],
    oneOf: [
      { key: 'audiences', source: 'both' },
      { key: 'omnisearch', source: 'both' },
      { key: 'signal.location', source: 'both' },
      { key: 'signal.demographics.age', source: 'both' },
      { key: 'signal.demographics.gender', source: 'both' },
    ],
  },
  explore: {
    oneOf: [
      { key: 'audiences', source: 'base' },
      { key: 'omnisearch', source: 'both' },
      { key: 'signal.location', source: 'both' },
      { key: 'signal.demographics.gender', source: 'both' },
      { key: 'signal.demographics.age', source: 'both' },
    ],
  },
  location_explorer: {
    mandatory: [
      { key: 'filter.location', source: 'both' },
    ],
    oneOf: [
      { key: 'audiences', source: 'base' },
      { key: 'omnisearch', source: 'both' },
      { key: 'signal.location', source: 'both' },
      { key: 'signal.demographics.gender', source: 'both' },
      { key: 'signal.demographics.age', source: 'both' },
    ],
  },
};

const getNestedValue = (obj, path) => {
  if (Object.prototype.hasOwnProperty.call(obj, path)) {
    return obj[path];
  }
  return path.split('.').reduce((acc, key) => acc?.[key], obj);
};

// Validates if the value is not null, undefined, an empty string,
// an empty array or an empty object, this ensures to avoid false positives
const isValueValid = (value) => {
  if (value === null || value === undefined) return false;
  if (typeof value === 'string' && value.trim() === '') return false;
  if (Array.isArray(value) && value.length === 0) return false;
  if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) return false;
  return true;
};

const isParamValid = (paramDef, panelParams, baseParams) => {
  const { key, source = 'both' } = paramDef;

  let value;
  if (source === 'panel') {
    value = getNestedValue(panelParams, key);
  } else if (source === 'base') {
    value = getNestedValue(baseParams, key);
  } else {
    value = getNestedValue(panelParams, key) || getNestedValue(baseParams, key);
  }

  return isValueValid(value);
};

export const getMissingPanelParams = (panelType, panelParams, baseParams) => {
  const config = requiredParamsByPanelType[panelType];
  const missingParams = {
    mandatory: [],
    oneOf: [],
  };

  if (!config) return { ...missingParams, isValid: true };

  if (config.mandatory) {
    config.mandatory.forEach((paramDef) => {
      if (!isParamValid(paramDef, panelParams, baseParams)) {
        missingParams.mandatory.push(paramDef.key);
      }
    });
  }

  if (config.oneOf) {
    const oneOfValid = config.oneOf.some((paramDef) => isParamValid(paramDef, panelParams, baseParams));
    if (!oneOfValid) {
      config.oneOf.forEach((paramDef) => {
        if (!isParamValid(paramDef, panelParams, baseParams)) {
          missingParams.oneOf.push(paramDef.key);
        }
      });
    }
  }

  const isValid = missingParams.mandatory.length === 0 && missingParams.oneOf.length === 0;
  return { ...missingParams, isValid };
};
