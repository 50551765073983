const placeBrands = [
  {
    value: '8417D6F9-C8C7-40AD-BE49-0987A4663228',
    label: 'McDonald\'s',
  },
  {
    value: '4FF960CB-CCB8-4723-8657-8E9A5F0A53E2',
    label: 'Subway',
  },
  {
    value: 'B13C02E3-BA3C-4B39-85B4-ACF12FEBC892',
    label: 'Starbucks',
  },
  {
    value: '74D13B62-2C51-4D99-804F-3D338F7B0406',
    label: 'Burger King',
  },
  { value: 'FD829EF9-385E-4BB2-AB6E-67F15A3D1E87', label: 'Domino\'s Pizza' },
  {
    value: '77A843BF-AA9A-4588-A44B-E8AA684A910C',
    label: 'Dollar General',
  },
  {
    value: '29271B4D-CAD2-4DD4-A766-7338AEE5533D',
    label: 'Taco Bell',
  },
  {
    value: '16876F8E-06C7-429D-9F42-713027D90F2C',
    label: 'Pizza Hut',
  },
  {
    value: '6AC63878-1A9C-4D43-98C7-EAF9582BE1FB',
    label: 'Wendy\'s',
  },
  {
    value: '88483819-91A9-45BE-8110-0D6CD0C9B35D',
    label: 'ALDI',
  },
  {
    value: 'D72865CB-DDB5-4202-96A4-D0415C0ACBF3',
    label: 'Walmart',
  },
  {
    value: '9278BB94-37C8-4C8F-908A-5067ECA014E7',
    label: 'Tim Hortons',
  },
  {
    value: 'CE2A2DDD-3F1D-49A4-8DDF-5665365587CE',
    label: 'Arby\'s',
  },
  {
    value: '4CFF9589-5168-48BD-88F0-D0826466F37D',
    label: 'Chipotle',
  },
  {
    value: 'B4606C6E-9133-4FB8-88FD-CF1D37F5AAC7',
    label: 'Chick-fil-A',
  },
  {
    value: '98440E84-530F-4A97-AC5B-3677C78A9720',
    label: 'Jack In The Box',
  },
  {
    value: '0E67ECB0-C65D-4221-A0A2-2E5CD84B9322',
    label: 'Panda Express',
  },
  {
    value: 'CCD54989-DFC4-4443-A378-72D6693B4903',
    label: 'Panera Bread',
  },
  {
    value: '4C7C9E59-5CD7-4EDF-860D-5427FEA4046C',
    label: 'Waffle House',
  },
  {
    value: '1C26370D-CCAB-4014-9D61-F17703B7C25C',
    label: 'Jimmy John\'s',
  },
  {
    value: '7D48963B-E4CF-484F-8760-4EAF7165ECF1',
    label: 'Hardee\'s',
  },
  {
    value: '16C4C92D-D525-419F-9D45-DE87FA5BD757',
    label: 'IHOP',
  },
  {
    value: '230C8922-2B19-4012-9954-150C181CCFAD',
    label: 'Jersey Mike\'s Subs',
  },
  {
    value: 'D0D3D990-E5B5-4B8A-AAC1-8D97E9227B56',
    label: 'Denny\'s',
  },
  {
    value: 'D019C9FF-8F94-4734-B1FC-E9D1F6541645',
    label: 'Petsmart',
  },
  {
    value: 'BFC8D7B1-BDD2-478E-85AB-F4FEE868177A',
    label: 'Target',
  },
  {
    value: '6D548BAE-A999-44A2-8FEB-5E92E9AE158F',
    label: 'Five Guys',
  },
  {
    value: '9D8CE161-6BBD-4FE7-AA0A-DA9FB0C760DF',
    label: 'Chili\'s',
  },
  {
    value: '0D2EF24C-80B5-4841-A2F4-47CBC9471CC1',
    label: 'Wingstop',
  },
  {
    value: 'AED3E369-28F1-4F7C-9692-832544846812',
    label: 'Gamestop',
  },
  {
    value: '108EB684-257B-42D7-84E5-6E9D8BE3E793',
    label: 'H&M',
  },
  {
    value: 'D9E4D872-7E4D-4E5A-B94F-FFD25B7D98C2',
    label: 'Buffalo Wild Wings',
  },
  {
    value: 'C69202E6-9A83-4BD6-A720-ECAB9DB2AE52',
    label: 'Michaels',
  },
  {
    value: '706BC87D-2A45-474B-AD67-210677434F88',
    label: 'Baskin-Robbins',
  },
  {
    value: '2158D476-D2CC-4FAA-BCA2-CD2B373E0CFF',
    label: 'Dairy Queen',
  },
  {
    value: '83777A7D-B59C-429A-AD39-AEE846A1BC18',
    label: 'Greggs',
  },
  {
    value: '0E00B261-EF66-4F74-B645-040E8C6755D3',
    label: 'Kroger',
  },
  {
    value: 'F899A8F6-2780-4971-9426-B98FDFEA0FC9',
    label: 'Decathlon',
  },
  {
    value: 'AD18E464-9E20-425E-80B3-010E665D78C1',
    label: 'Big Lots',
  },
  {
    value: '4B0D04B7-1DE2-4ACA-92FD-0807C3DDA4BA',
    label: 'Harbor Freight Tools',
  },
  {
    value: 'C628D8FF-42F1-4626-9766-274148DBAB27',
    label: 'Church\'s Chicken',
  },
  {
    value: 'C0DBDC49-C4E5-41A2-B53B-28676260A0DE',
    label: 'Olive Garden',
  },
  {
    value: 'A94FC7B6-34D3-4970-9EA5-2D17C49E84B6',
    label: 'T.J. Maxx',
  },
  {
    value: '423EDCAF-1289-474E-9982-0CC39A5BAEFE',
    label: 'Hobby Lobby',
  },
  {
    value: '4EA947E3-F38C-4D92-8125-B3A2E0B310E9',
    label: 'Safeway',
  },
  {
    value: 'A7E4F56C-19A7-4166-A4EA-EC77A59C3DC9',
    label: 'Marshalls',
  },
  {
    value: '74A44B01-5C99-4754-9585-F487D21C543D',
    label: 'Zara',
  },
  {
    value: '48803C8A-4E24-4888-B029-CDDF0D4547CE',
    label: 'Whataburger',
  },
  {
    value: 'FF629FFA-5CDA-446C-A30E-408CC2174035',
    label: 'Marco\'s Pizza',
  },
  {
    value: '54057197-DAD6-4798-846B-4A6EB72C8D9F',
    label: 'Culver\'s',
  },
  {
    value: '64DF8559-AE55-45FC-BD3A-BB88307CE15F',
    label: 'BP',
  },
  {
    value: '1B29B80D-ADE8-470A-85CC-5E02AAF61368',
    label: 'Outback Steakhouse',
  },
  {
    value: 'DC5E59A2-0D57-4959-8400-5B8E85008DF6',
    label: 'Cold Stone Creamery',
  },
  {
    value: 'C3195799-34B7-4AEB-92DB-7C595190B240',
    label: 'Tropical Smoothie Cafe',
  },
  {
    value: '54617D9D-5EC7-45E0-8B7F-360DADB0FE7E',
    label: 'Bath & Body Works',
  },
  {
    value: '82E3141D-43F9-4D3D-A82C-ED4EB281D342',
    label: 'Jollibee',
  },
  {
    value: 'F6A198C3-A7F2-4637-95C0-F89234B788DE',
    label: 'Red Lobster',
  },
  {
    value: '31F7D3DA-7A3B-4579-BF17-DAB3F541A2EB',
    label: 'Cracker Barrel',
  },
  {
    value: '4C35AE62-092D-4110-A6DC-19F6932AE5FD',
    label: 'Texas Roadhouse',
  },
  {
    value: '3D47E593-FAD0-41F6-A289-CEDD54BCD8CC',
    label: 'Wawa',
  },
  {
    value: '51D54EC5-B77F-485C-A3D8-2852EF220205',
    label: 'Qdoba Mexican Eats',
  },
  {
    value: '20E485DE-70A9-4635-AF36-F1305B60827D',
    label: 'Raising Cane\'s',
  },
  {
    value: 'B5EA071B-4ADF-444D-A5B9-46DF53BFC4A5',
    label: 'Smoothie King',
  },
  {
    value: '1D533FB9-E335-4ACA-8095-24E199049EA7',
    label: 'LongHorn Steakhouse',
  },
  {
    value: 'BEFB84C0-693C-41A5-9538-A687A767DE13',
    label: 'Del Taco',
  },
  {
    value: '0BAC80D0-90D5-46A8-A11F-7CC58DE74963',
    label: 'Barnes & Noble',
  },
  {
    value: 'C39E890A-6D19-4A07-99A3-DAD18D1613DE',
    label: 'El Pollo Loco',
  },
  {
    value: 'B03A2DFA-E127-4D0A-9425-9298EA6DEE00',
    label: 'PizzaExpress',
  },
  {
    value: '096B986E-3D63-44F4-BE44-F85D52A2979D',
    label: 'Trader Joe\'s',
  },
  {
    value: '5DE43850-42FE-4A85-BAE9-4BE8BC0AEFFD',
    label: 'Captain D\'s',
  },
  {
    value: '18F140C6-5C05-4E2B-8B54-CED41F570451',
    label: 'Red Robin',
  },
  {
    value: '1E5D90F5-5E0D-4486-B651-D0E7B8A6BE23',
    label: 'Whole Foods Market',
  },
  {
    value: 'B23E595D-0C54-4B41-94F0-23C17B4075D9',
    label: 'MOD Pizza',
  },
  {
    value: 'FCE08E02-CA14-439D-8511-06832D5FA2B5',
    label: 'McAlister\'s Deli',
  },
  {
    value: '1267A1AB-5359-48E3-A1A5-371066D72338',
    label: 'Hungry Howie\'s Pizza',
  },
  {
    value: '1DF5514A-1819-438B-BF91-880A21169FE9',
    label: 'First Watch',
  },
  {
    value: 'CA5AE7AD-7CA2-4C8B-BC17-DC89B09ABD35',
    label: 'Dick\'s Sporting Goods',
  },
  {
    value: 'A409F6A6-1D61-4C4A-AD48-CB8981874F64',
    label: 'Noodles & Company',
  },
  {
    value: '1A8E8332-F0D0-49AF-B4B0-EDE1C9596D65',
    label: 'Freddy\'s Frozen Custard',
  },
  {
    value: 'F6047639-9741-4CA4-A9B8-40B54D63DD3F',
    label: 'Home Bargains',
  },
  {
    value: '2C8106F4-B33F-45DA-80E6-5DCD39826B54',
    label: 'Steak \'n Shake',
  },
  {
    value: 'DE5DD631-BCC4-4EC9-B684-D2C822CA8F33',
    label: 'Einstein Bros. Bagels',
  },
  {
    value: 'DCAFE637-8024-4FC0-A390-6E6E0E584641',
    label: 'Long John Silver\'s',
  },
  {
    value: 'B6DA90E2-E073-4CE1-83CC-678F089C92EC',
    label: 'Café Coffee Day',
  },
  {
    value: 'D20B525B-3FBF-407A-B793-597C63ADDFF6',
    label: 'Edible Arrangements',
  },
  {
    value: 'EF5EE567-CAAD-4907-ACCA-95CEAD1ED92C',
    label: 'In-N-Out Burger',
  },
  {
    value: 'F44FD58A-FCD5-4ED1-B6D1-E7E83A30B26E',
    label: 'Jamba Juice',
  },
  {
    value: 'FCEA7CA1-1D40-4680-B337-E74594D9BCF1',
    label: 'Adidas',
  },
  {
    value: '2C5054E1-8691-4F52-83CD-310D029BA6A3',
    label: 'Sephora',
  },
  {
    value: '0F6BCED5-19B1-4DFD-B55C-42708EE32A64',
    label: 'Caribou Coffee',
  },
  {
    value: '004BC701-E46F-4A91-802F-4200A139C3BF',
    label: 'Primark',
  },
  {
    value: 'A187242D-74D8-464A-A5B4-DFA46B51BCAA',
    label: 'Golden Corral',
  },
  {
    value: '23AC5A4E-9665-4FCF-8377-746DC27BCA47',
    label: 'The Coffee Bean & Tea Leaf',
  },
  {
    value: '861A5E7E-4D43-4786-9D29-08BA9D3A7755',
    label: 'Poundland',
  },
  {
    value: '4D49F3B0-7B4D-42DB-A414-DC71A6979792',
    label: 'White Castle',
  },
  {
    value: '8EBE774A-52F3-487F-B806-0BC618A98435',
    label: 'Jet\'s Pizza',
  },
  {
    value: '76CDFF5F-DB77-463D-AD97-3E83618BE05C',
    label: 'Zaxby\'s',
  },
  {
    value: '7CBE5BEE-64D6-479C-9AAC-F3C68E773A60',
    label: '99 Cents Only Stores',
  },
  {
    value: 'CEFEB1F5-69DB-4A7D-B713-87B8D3FF7B20',
    label: 'Round Table Pizza',
  },
  {
    value: '179E8DBE-E199-4E3A-96DE-175BA7707C70',
    label: 'Pret A Manger',
  },
  {
    value: '3444CFC5-613E-4490-A9A0-4BCD750D2E11',
    label: 'Deichmann',
  },
  {
    value: '6BEA5158-88A1-4073-8BD2-26DB945659D6',
    label: 'Dickey\'s Barbecue Pit',
  },
  {
    value: 'A30D6F1D-AD57-4048-9597-2956EAEE8FFB',
    label: 'Sprouts Farmers Market',
  },
  {
    value: '789923EE-1F0C-482A-A8DA-CE8BA39A5385',
    label: 'Wienerschnitzel',
  },
  {
    value: '9EAF2345-B604-441C-BFF8-5C7A16AD49BD',
    label: 'LensCrafters',
  },
  {
    value: 'E63F41C0-B5D1-41CE-89B4-D2D48EE18072',
    label: 'Hooters',
  },
  {
    value: '6EDAA9D9-D3F1-4F5E-BFBF-450C58EEB5BF',
    label: 'Schlotzsky\'s',
  },
  {
    value: 'FEFD9054-037C-4EF3-8DA8-FBCA73B2DA93',
    label: 'Bata',
  },
  {
    value: '3CD9FCBA-6385-43F0-8071-BD1543665885',
    label: 'The Honey Baked Ham Company',
  },
  {
    value: 'F60AAE8F-A7D7-4BFE-9392-3003AEEBBFB4',
    label: 'TGI Fridays',
  },
  {
    value: '2B26A41C-02F2-406B-B994-992D7F3E0B58',
    label: 'Krystal',
  },
  {
    value: 'F1032969-49C0-4F0D-AC5C-0629C1319D81',
    label: 'Carl\'s Jr.',
  },
  {
    value: '7E764AF0-E0F8-4CB8-82D3-0FF8232D77C6',
    label: 'H-e-b',
  },
  {
    value: 'B31D0D31-0A38-4508-B04A-C93FA93764D9',
    label: 'Perkins Restaurant And Bakery',
  },
  {
    value: '7B4DBC43-60A3-4D65-9C32-3C17B549B802',
    label: 'Checkers Drive-In Restaurants',
  },
  {
    value: '2B3690DD-C6B9-4850-B242-3E6C6012BA66',
    label: 'Huddle House',
  },
  {
    value: '81100467-8F5D-4796-B222-A84983379402',
    label: 'Academy Sports + Outdoors',
  },
  {
    value: '4438FE6A-1D7D-4A8B-9C42-C071A96BA375',
    label: 'The Habit Burger Grill',
  },
  {
    value: 'A165685B-F595-457A-9DD8-E0CC8A6252B6',
    label: 'Papa John\'s Pizza',
  },
  {
    value: '95AC081E-9DA8-4196-B5B6-283EEE33531F',
    label: 'P. F. Chang\'s',
  },
  {
    value: '8803514E-6815-4091-89FA-0D4773415040',
    label: 'Dd\'s Discounts',
  },
  {
    value: '910D90E1-14B4-44B0-BB7A-E8B43CA3D55F',
    label: 'Belk',
  },
  {
    value: 'F23F1F5E-B290-43EE-B827-84540527B79D',
    label: 'Shoe Carnival',
  },
  {
    value: '43E088F9-E1D9-4BB6-A495-A8F04D39D77A',
    label: 'The Cheesecake Factory',
  },
  {
    value: 'B67C1EC4-6CA3-48ED-87A5-B257A46CA5CB',
    label: 'Jack\'s',
  },
  {
    value: '4E357CA4-95B2-47EA-BA5A-8F2F8F4640E0',
    label: 'Ruby Tuesday',
  },
  {
    value: '0E01EDE4-04E3-4801-916D-29171340AD1E',
    label: 'Dillard\'s',
  },
  {
    value: '892DFD9A-E041-49DC-BF10-FD44FDF2C265',
    label: 'BJ\'s Restaurant & Brewery',
  },
  {
    value: '5424B0CD-99F3-4264-A393-C2D5BB865E43',
    label: 'Cinnabon',
  },
  {
    value: '3ADFE43E-4C2A-421A-995B-EE896F73E6FC',
    label: 'Family Dollar',
  },
  {
    value: 'FF45877E-0E1D-4E41-A392-7E7C18A4FE18',
    label: 'Carrabba\'s Italian Grill',
  },
  {
    value: '56F719CF-FA3A-4B95-BAAF-4106D9EE6D90',
    label: 'David\'s Bridal',
  },
  {
    value: 'A7EB849F-73C2-4367-AB4D-467EED8A65D4',
    label: 'Little Caesars',
  },
  {
    value: '7D4FD7A4-1FD4-41D7-BA6A-283493ECF6E8',
    label: 'Fazoli\'s',
  },
  {
    value: '99F67EBA-4BE5-4E64-8491-B0DCD54D882F',
    label: 'Chicken Salad Chick',
  },
  {
    value: '3E125577-9AFB-421D-BF30-779AB82FC0A9',
    label: 'Marble Slab Creamery',
  },
  {
    value: '276A83F6-C4E3-43DD-B42A-6BF9CFAF51CB',
    label: 'Forever 21',
  },
  {
    value: '4C160FEA-FDB6-4DEC-99FC-13899680A0F4',
    label: 'Foot Locker',
  },
  {
    value: '9B3F03CE-3145-4ABC-A42E-C20945BF60B8',
    label: 'Bonefish Grill',
  },
  {
    value: '25D4DDDD-BB86-455F-A270-816468B75651',
    label: 'Insomnia Cookies',
  },
  {
    value: '8C5377D2-BF81-41F4-8CAD-B8C28F4DAC6F',
    label: 'Quiznos',
  },
  {
    value: '9B06986C-2D32-4583-8C95-D2F454B36FDC',
    label: 'Chowking',
  },
  {
    value: '93F6AA68-9168-4F6F-A27E-2508755B1690',
    label: 'Party City',
  },
  {
    value: 'DFD670C3-579A-4561-8233-B41F98A7276B',
    label: 'The Fresh Market',
  },
  {
    value: '45EC5402-69AD-4435-A633-91667BC33D92',
    label: 'Pet Supermarket',
  },
  {
    value: '376A3CA6-56D3-4820-A8C8-5D1DD05475AF',
    label: 'Donatos Pizza',
  },
  {
    value: 'A5332654-C9F6-43B6-9A00-D0AE2BF8B20C',
    label: 'Pollo Campero',
  },
  {
    value: '7A7B3D79-C6F4-41DE-B717-480BF06C0BF7',
    label: 'Freshii',
  },
  {
    value: '1A2C01EA-A7E8-4370-8EE8-3D2BD6071F58',
    label: 'Taco Cabana',
  },
  {
    value: '179A15C4-A443-41AB-8AC5-77E8742FE24E',
    label: 'Pollo Tropical',
  },
  {
    value: '4F94C666-5FFA-4A42-8453-24C2F779EC36',
    label: 'Urban Outfitters',
  },
  {
    value: 'AE59B629-0D66-40D7-9986-2F6A985A40DC',
    label: 'Ruth\'s Chris Steak House',
  },
  {
    value: 'E50919AC-B1FF-410A-9B0D-DFA914286C3C',
    label: 'Puma',
  },
  {
    value: '9218A7AB-8D8C-40F1-9E24-0B74DA03758A',
    label: 'Le Pain Quotidien',
  },
  {
    value: '3870A17E-BE9C-4C6B-BD78-CE6CEB94470E',
    label: 'Skyline Chili',
  },
  {
    value: '3FA960CF-BBBA-445A-995F-C6175B81C374',
    label: 'Johnny Rockets',
  },
  {
    value: '6163A8FB-D669-4DD7-BADE-EB985ED05ABA',
    label: 'Logan\'s Roadhouse',
  },
  {
    value: 'BBF22D8D-166F-44A8-8694-3F6FF4F745AB',
    label: 'Popeyes',
  },
  {
    value: 'D559ECC5-90B9-4FA4-8B7A-B1E111280998',
    label: 'Taco Bueno',
  },
  {
    value: '14D07FA0-1C87-48E7-B19A-0D6401D2D082',
    label: 'Boot Barn',
  },
  {
    value: 'AC8F9047-F23D-4A54-BBAA-A3CBD2991C02',
    label: 'Vans',
  },
  {
    value: 'E515D012-5197-4B01-BFAD-5E5FAC25B525',
    label: 'Sweetgreen',
  },
  {
    value: 'D3310425-7161-41FC-8C2C-5435AF0F0A5B',
    label: 'Victoria\'s Secret',
  },
  {
    value: 'E953DF14-DB79-4EC8-8A41-DDDDBF76A587',
    label: 'On The Border Mexican Grill & Cantina',
  },
  {
    value: '49B85273-116E-4EDB-9AA4-1CC35EE17F38',
    label: 'Big Boy',
  },
  {
    value: '543326EC-B1A8-4C29-BAB9-323526F13D88',
    label: 'Friendly\'s',
  },
  {
    value: '6E0C6E70-48D8-4644-BA75-6DFD1AF48EAF',
    label: 'Chuy\'s',
  },
  {
    value: 'C2924889-C657-41C9-A304-623830E7B4A1',
    label: 'Tijuana Flats',
  },
  {
    value: '84B18312-3C2B-41A9-863B-89651C2F6A2C',
    label: 'Gucci',
  },
  {
    value: '5760BF0A-EBDF-4B9A-88CD-69F4BF08C362',
    label: 'Louis Vuitton',
  },
  {
    value: '6954A080-1421-4498-A809-A2A5455D7AA0',
    label: 'Miller\'s Ale House',
  },
  {
    value: '09A47A3C-75B6-4FC3-80FC-51072EDE78C3',
    label: 'Carvel',
  },
  {
    value: 'EF8890B0-D882-4660-A12D-060B7D71E069',
    label: 'Famous Dave\'s',
  },
  {
    value: '2D00EBC5-9F4C-46E2-86D5-99E55B8D30D9',
    label: 'Bass Pro Shops',
  },
  {
    value: '5C47432C-8438-4B10-A0C0-2D688366582E',
    label: 'The Melting Pot',
  },
  {
    value: '89EF0130-360C-4DA3-A869-7CF14F886C29',
    label: 'Pandora',
  },
  {
    value: '5BE78DDD-DFD2-40FF-8C43-502A68D0759F',
    label: 'Yard House',
  },
  {
    value: '5AB1F089-5965-4720-ABEE-6D59E436B7F6',
    label: 'Hollister',
  },
  {
    value: '01AD5444-D417-481E-8EFD-A74A97F5FD2F',
    label: 'Benihana',
  },
  {
    value: '32E873BD-6A12-47B7-B527-F0F59E9D54B3',
    label: 'Sbarro',
  },
  {
    value: 'A90C80D9-6C80-405B-82FC-C072F67F2103',
    label: 'BurgerFi',
  },
  {
    value: '45F819A8-9E74-467F-AE07-AEC24E5C2B69',
    label: 'WSS',
  },
  {
    value: '16C47403-6171-4F7D-9909-F933587EE767',
    label: 'Fuddruckers',
  },
  {
    value: 'DEFEBCC5-A986-4D7C-8408-7575210872FC',
    label: 'Under Armour',
  },
  {
    value: '07DB31F7-9272-49F9-927F-7A81F584030F',
    label: 'BJ\'s Wholesale Club',
  },
  {
    value: '82946F1C-AB10-4669-B9A1-640A01E2A407',
    label: 'Tommy Hilfiger',
  },
  {
    value: '07E7AEC5-4727-4497-A4CA-459CE70C7172',
    label: 'Baja Fresh',
  },
  {
    value: '563F6227-66B9-4416-9D4C-D242AF7D2739',
    label: 'Earls',
  },
  {
    value: '30D0B894-C3BC-4B3F-A6D4-EDB750FF2D74',
    label: 'Boston Market',
  },
  {
    value: '2D93226D-F8E1-4CDF-92AA-6D1B896CAE7E',
    label: 'The Capital Grille',
  },
  {
    value: '22B1BC87-CDFE-4340-86F8-8E0E6E1E6A7D',
    label: 'Blue Bottle Coffee',
  },
  {
    value: '4BD8CE79-29EF-43B7-963E-4445D5EA193C',
    label: 'Giordano\'s Pizzeria',
  },
  {
    value: 'D34192D7-58D6-4C9E-9957-89D3DD7BB1BB',
    label: 'Buca di Beppo',
  },
  {
    value: '85E0506B-C1D7-4E60-B6E1-4D1A142D3994',
    label: 'Auntie Anne\'s',
  },
  {
    value: '129413B8-9741-4348-994A-3BE01B45EA89',
    label: 'Morton\'s The Steakhouse',
  },
  {
    value: '7995C43F-8B37-4BD0-9E0D-69105EFA5B0B',
    label: 'Planet Smoothie',
  },
  {
    value: '053BFC77-F760-4007-AD3E-FFF2DAE13C31',
    label: 'Pressed Juicery',
  },
  {
    value: 'C46B8B20-BF6E-41DD-8F43-8680A8C5D577',
    label: 'Eat\'n Park',
  },
  {
    value: '746CA89F-A00B-4BC2-9E6A-C87C94892904',
    label: 'Blimpie',
  },
  {
    value: '76AD9E43-C469-4929-AB61-BC4DC28B9A27',
    label: 'Fossil',
  },
  {
    value: '79FDD097-AD6B-4FDE-8691-2F750F154757',
    label: 'Snipes',
  },
  {
    value: 'BF013435-8EE8-4EEF-A1A6-83A9E5EF6A1E',
    label: 'Hering',
  },
  {
    value: '517CB046-9257-446A-AD6E-C3C3D9E67800',
    label: 'Jeni\'s Splendid Ice Creams',
  },
  {
    value: '3962391B-9D4F-4058-9159-28DAAF8395F6',
    label: 'Graeter\'s',
  },
  {
    value: '1AE523DD-3DE5-419F-9FDF-FFF48A863E5D',
    label: 'Philz Coffee',
  },
  {
    value: '897624BB-BB1C-4602-8905-977E5A09C624',
    label: 'Fogo de Chão',
  },
  {
    value: 'BAB46F54-163D-4D76-8CD8-65634160A534',
    label: 'Shane\'s Rib Shack',
  },
  {
    value: 'AB3A329B-CF72-468C-A5EE-9CCB29C17EF8',
    label: 'Shake Shack',
  },
  {
    value: '796DBCE4-2B75-4171-BF32-44C16EDE63D5',
    label: 'Sam\'s Club',
  },
  {
    value: 'CF7D11E7-DA78-47D9-9AE1-030366F4507B',
    label: 'Gap',
  },
  {
    value: 'AC39CF94-AA91-4EE5-ACAA-CC05EEC5B819',
    label: 'Anthropologie',
  },
  {
    value: '2DE8D22F-E4EC-4053-9FA0-267B4F594E99',
    label: 'O2',
  },
  {
    value: '0C43889A-AD6F-4EEB-BEEF-067B68502485',
    label: 'River Island',
  },
  {
    value: 'DA4F4642-9D83-4D04-93BD-F08CF62FFD07',
    label: 'Firehouse Subs',
  },
  {
    value: '06E33165-44C9-4D26-825E-4F4118264A67',
    label: 'Bahama Breeze',
  },
  {
    value: '3E2A5DD4-527E-4C09-AB13-3D35CD4C0147',
    label: 'Red Rooster',
  },
  {
    value: '0B47F4FC-A267-4E07-85F4-F64511835C03',
    label: '7-Eleven',
  },
  {
    value: '42C9C74E-8CA4-4026-8B56-77026ED5B1BF',
    label: 'Romano\'s Macaroni Grill',
  },
  {
    value: '36588026-85C4-48C0-B555-85891F815D99',
    label: 'Kentucky Fried Chicken',
  },
  {
    value: '2CA2569D-223D-42D6-BB2F-F8B5D36B9AEC',
    label: 'COS',
  },
  {
    value: '103877D9-5196-46BB-A727-5A5DCB40BDEF',
    label: 'Hotel Chocolat',
  },
  {
    value: '20CAE7BC-9E6D-48DC-B57A-AFC85DAF5911',
    label: 'Shoney\'s',
  },
  {
    value: 'B44053FB-243D-44C9-B854-6252C267DDC2',
    label: 'Oasis',
  },
  {
    value: '5E978F43-4450-4F41-8EE4-A0421E8EC178',
    label: 'Dunkin\' Donuts',
  },
  {
    value: '4637EDA7-3CFA-4F74-9E36-8244309FEE53',
    label: 'Asics',
  },
  {
    value: 'C757BCFD-4F8A-467E-A31E-CBC3C90ADF8B',
    label: 'Festival Foods',
  },
  {
    value: 'FEC67A23-EC8D-4487-BC28-6C2390C8D7ED',
    label: 'Primanti Brothers',
  },
  {
    value: '245E714D-4E3D-4E47-8286-2E09D3D33E9B',
    label: 'Corner Bakery Cafe',
  },
  {
    value: 'B1088A02-715F-4E5B-8F62-BDC62B023909',
    label: 'Cuidado Con El Perro',
  },
  {
    value: '053BE8F3-24CF-4F73-B9DD-A21FDA529EBE',
    label: 'Fatburger',
  },
  {
    value: 'CD7AD471-32C8-462D-AB8E-1FF13538D359',
    label: 'Superdry',
  },
  {
    value: '3785F06F-3131-47F6-BD53-606BDD08CDAD',
    label: 'Sweet Frog',
  },
  {
    value: 'A00972F7-DFB0-4B1A-86E7-927A0C4F3878',
    label: 'Lou Malnati\'s Pizzeria',
  },
  {
    value: 'A09F35D3-38CB-4657-9562-ED485E715D25',
    label: 'Equinox',
  },
  {
    value: '2A173ED9-EAF9-49EA-9102-582E8C1CD20F',
    label: 'Just Salad',
  },
  {
    value: '8227A628-2D77-4EB3-8F1A-2C4238997412',
    label: 'Applebee\'s',
  },
  {
    value: '2723B38E-C3E2-435F-A77B-A94A92D68D07',
    label: 'Ralph Lauren',
  },
  {
    value: '3EC9E7DF-1497-44C4-8642-E7FA2FF3A99D',
    label: 'Calvin Klein',
  },
  {
    value: '7276B861-335A-4054-B24E-761260B0F2A4',
    label: 'OfficeMax',
  },
  {
    value: '333F635B-ECFD-44FA-A76A-0F1A59DEE6CC',
    label: 'Joe\'s Crab Shack',
  },
  {
    value: '2B7158DB-F568-46A3-86B1-3B8F26259609',
    label: 'Cartier',
  },
  {
    value: 'C5E70C68-D552-486E-B111-24C00B846CD3',
    label: 'Desigual',
  },
  {
    value: '8BF7A623-6DD2-42B3-9082-DCF561BE1443',
    label: 'Rainbow Shops',
  },
  {
    value: '17FC8F74-8103-4947-A584-C10CA29B711B',
    label: 'Radioshack',
  },
  {
    value: 'DB4CE34E-3A63-4947-946F-9D52502C5762',
    label: 'Patagonia',
  },
  {
    value: '59E72ACF-8CFA-4E5B-BBEF-BA3AF8988C55',
    label: 'Christian Dior',
  },
  {
    value: '7E9A2E74-1CB5-43E9-8B74-69CA8283BFBD',
    label: 'IKEA',
  },
  {
    value: '2F3A3724-27EF-45BF-B7D2-B78DFEADACEB',
    label: 'Potbelly',
  },
  {
    value: 'D26C4186-EB2E-49EF-B253-0D878BA7488E',
    label: 'The Body Shop',
  },
  {
    value: '93E338F8-EDCC-4FA5-ACD7-891F5454FCBC',
    label: 'Boxlunch',
  },
  {
    value: 'D46154E9-2B1C-49D3-AC12-8218695C4F04',
    label: 'Bonchon Chicken',
  },
  {
    value: 'FEF3D9FA-9813-4036-B17F-5196C2AD7451',
    label: 'Bareburger',
  },
  {
    value: '9F274D1B-D337-4934-B381-6F160B9B9578',
    label: 'Lacoste',
  },
  {
    value: 'F59D6DB5-5A85-48C6-A2EC-CAAE334D4348',
    label: 'Dr. Martens',
  },
  {
    value: '1F8E146D-3673-4372-85DD-C805E2F6AAD8',
    label: 'See\'s Candies',
  },
  {
    value: 'D630A953-8F2E-42CD-A91A-A2A6C05ECE1D',
    label: 'Burberry',
  },
  {
    value: 'D9D7DC95-87FE-41D0-8F76-1E4CA0F9534E',
    label: 'Red Mango',
  },
  {
    value: 'DD93856E-7D6B-4579-94AD-08A2340FE176',
    label: 'California Tortilla',
  },
  {
    value: 'F6B04BDE-1F26-4B27-9AA6-3057C2E8945B',
    label: 'Rainforest Cafe',
  },
  {
    value: '37DFDE20-1984-47AD-B59B-A0BA17ACF8D9',
    label: 'Closed',
  },
  {
    value: '8FA3AD6A-8210-437B-A3C7-7B6DABA9DCFF',
    label: 'Carluccio\'s',
  },
  {
    value: '5BC5F577-5FC7-4A88-9291-36C481904B63',
    label: 'Jennyfer',
  },
  {
    value: '80AED22C-43F1-44EC-AA95-B2202C7B9807',
    label: 'Saks Fifth Avenue',
  },
  {
    value: 'BA0CE900-E44A-427A-94C6-3B5C53441098',
    label: 'Innvictus',
  },
  {
    value: '01096D2C-FC7E-463B-BF8D-FDFE3C6B0256',
    label: 'Buffalo Exchange',
  },
  {
    value: 'DADC5093-9668-418A-8148-2E994E2EAAC1',
    label: 'Bravo',
  },
  {
    value: 'D9B9B404-7B35-4E4B-A0CE-4CAB8155FE9E',
    label: 'Lululemon',
  },
  {
    value: 'DB37F394-B330-4BFC-A14B-42A53B9CF7D9',
    label: 'Express',
  },
  {
    value: '86AA27A6-074F-4137-8358-FA16DBD7F34E',
    label: 'Au Bon Pain',
  },
  {
    value: '1B6DB173-3054-4001-BA93-8E45650AB5B4',
    label: 'Seattle\'s Best Coffee',
  },
  {
    value: '0CBF81A1-FE3A-4D7B-885C-72BC90AC55B6',
    label: 'Nando\'s',
  },
  {
    value: '853AF71D-3B22-42EC-B4E6-C6EE33B910BA',
    label: 'Juice Press',
  },
  {
    value: '44C35E9C-8FA6-4822-9EC6-D1FF43FF271C',
    label: 'Lavazza',
  },
  {
    value: 'B3C5C0E2-ECC4-483D-AE45-B569255E953C',
    label: 'Dig Inn',
  },
  {
    value: '653F44B6-B788-4A53-B316-3BC8D4D0562A',
    label: 'James Avery Artisan Jewelry',
  },
  {
    value: '292D2358-9A31-43F3-AA2E-C3258478842E',
    label: 'Veggie Grill',
  },
  {
    value: '50C8F474-2B9F-42DE-9F0D-A9115D26E643',
    label: 'Aéropostale',
  },
  {
    value: 'B3973A02-35A1-483D-A954-5CC37E471770',
    label: 'Yogurtland',
  },
  {
    value: '91D3E067-7CEE-4B6E-8C67-E0D63F4687DE',
    label: 'Mango',
  },
  {
    value: '0025C006-CD75-479F-B42E-DFD4D16A6DE5',
    label: 'Petco',
  },
  {
    value: '7FFF01CF-7B0E-405A-A232-14176B165E60',
    label: 'Converse',
  },
  {
    value: '8D93BC04-6687-4CCD-A80D-984114024D28',
    label: 'Dippin\' Dots',
  },
  {
    value: 'ADC35DC3-05D8-41C1-BBB3-176486A0E01E',
    label: 'Etam',
  },
  {
    value: '2D69DB33-B4DF-4879-B3DC-308E3402A1FD',
    label: 'Carhartt',
  },
  {
    value: 'F285A92C-7ED1-4A5E-9D2D-55F18C4F631B',
    label: 'Ashley Stewart',
  },
  {
    value: 'B2A2238B-5FDC-46CD-B6F9-A32DB1474C70',
    label: 'Arc\'teryx',
  },
  {
    value: '2C8BD952-8675-4D15-A609-42941D6CB20F',
    label: 'Oysho',
  },
  {
    value: 'D89FBEF9-E16C-4521-8513-20B180F56FAA',
    label: 'Walgreens',
  },
  {
    value: '0EA2A31C-FD21-4AF3-A994-A5AC493F66D8',
    label: 'Nathan\'s Famous',
  },
  {
    value: 'FDE30AD7-DEBC-4C42-96EE-751EAEB20F40',
    label: 'joy',
  },
  {
    value: '454B31CB-594B-4EF3-AF9D-EDC56E89E25E',
    label: 'Crocs',
  },
  {
    value: 'BD604E0B-25ED-4AA3-8AC9-9AE54C13E1C4',
    label: 'Omega',
  },
  {
    value: 'C09F0CC7-D224-4D8F-9DEF-FB9B4EA39355',
    label: 'Philly Pretzel Factory',
  },
  {
    value: '32EF79CA-9097-4F21-A490-CD89DCCD906E',
    label: 'Godiva Chocolatier',
  },
  {
    value: '024B28D3-5496-417D-B55F-0CC1DB9A318B',
    label: 'Swarovski',
  },
  {
    value: '288C05E1-B165-44FB-B105-70DE812C876B',
    label: 'Rue21',
  },
  {
    value: '9AE69D0B-DBFE-49AC-8951-499883140A89',
    label: 'Chico\'s',
  },
  {
    value: '91929FE3-927D-409A-80D8-180AAA5EBF4E',
    label: 'Wilko',
  },
  {
    value: 'C2DE3A22-2B93-40D1-8BA0-2720E8F39AA3',
    label: 'Papa Murphy\'s',
  },
  {
    value: '26E33BE3-E492-4CA9-8577-88A5E3D31F7A',
    label: 'Akira',
  },
  {
    value: 'D1F590D3-D3D9-4900-9D3D-DC21CBDE2340',
    label: 'Wetzel\'s Pretzels',
  },
  {
    value: 'FED3169D-2591-4C79-8B1F-BD4CE7905BC2',
    label: 'Diesel',
  },
  {
    value: '5D03112C-CD2A-41C3-9ED0-D72EED8BB373',
    label: 'Abercrombie & Fitch',
  },
  {
    value: 'E713B9D4-5033-4A21-A20D-69EED8FFA303',
    label: 'Daiso',
  },
  {
    value: 'DECA63A6-D0C9-47E1-BD15-7F818B8B75E2',
    label: 'Sears',
  },
  {
    value: 'BC9451E4-094A-472A-A742-01E0770F974B',
    label: 'Spirit Halloween',
  },
  {
    value: '65246A9E-5A65-432C-8B3C-45D50702F28A',
    label: 'Harvey Nichols',
  },
  {
    value: '91650DD4-1CCF-4A08-9DBF-AF243BCA6288',
    label: 'Dolce & Gabbana',
  },
  {
    value: 'A16489A3-A51E-4DD6-9966-934C86D80FE9',
    label: 'Krispy Kreme',
  },
  {
    value: '6B18B3B7-3C3A-4511-835D-7323C3AA2F27',
    label: 'Nautica',
  },
  {
    value: 'AFACEB64-FFD7-4466-AFF2-7A8B7E1D92D9',
    label: 'Esprit',
  },
  {
    value: 'B780DCBD-832F-4765-96C2-E0BEAE416402',
    label: 'Boost Juice',
  },
  {
    value: '3A0D8A79-F8AB-4D04-AA5D-C13A0C26B28D',
    label: 'Omaha Steaks',
  },
  {
    value: 'E50A3EE1-1D85-46B8-B539-E37CEB06B2C5',
    label: 'Grimoldi',
  },
  {
    value: '1F28D817-7A9A-4882-B0FC-98FD1D02129A',
    label: 'Colin\'s Jeans',
  },
  {
    value: '8B4368C8-ABC7-4AC4-9B8C-D47495E6EE7D',
    label: 'Dollar Tree',
  },
  {
    value: 'B379ACA5-B37F-4CE9-AFEA-2CFF4D4559A9',
    label: 'Strada',
  },
  {
    value: 'D5C33D5D-A7CE-4183-AF90-8EBB2DFE894C',
    label: 'The Vitamin Shoppe',
  },
  {
    value: 'DBAB2205-5D9D-4F02-A04B-FDD1F5ABE1C0',
    label: 'Ocean Basket',
  },
  {
    value: '76DFBE32-7EB3-4CD4-B413-F0DAA9C8FEC0',
    label: 'Geox',
  },
  {
    value: '64607FA4-B9F0-4BC6-AC81-81ED237D5ABD',
    label: 'Prada',
  },
  {
    value: '860395D4-48E2-4B47-991F-50109D19D6FC',
    label: 'Ben & Jerry\'s',
  },
  {
    value: '997C74C3-3BE7-4AA3-A8FC-346C39B99E4F',
    label: 'Coast',
  },
  {
    value: '089AC2DE-1DE0-4EDB-905F-50C77760509F',
    label: 'Great American Cookies',
  },
  {
    value: '8C3F520D-D32C-482B-BD41-C291108E0297',
    label: 'Häagen-Dazs',
  },
  {
    value: 'B9ACFE3A-542B-4B97-BCC1-456910DE8C06',
    label: 'ShopRite',
  },
  {
    value: 'FF057631-8712-4F76-8136-59AA79317637',
    label: 'Cheeky',
  },
  {
    value: '8D0B9212-0D30-4D8C-9134-06B7F232C642',
    label: 'Sally Beauty',
  },
  {
    value: '907A3C05-4527-45D6-A723-1ED92CA46028',
    label: 'Cotton On',
  },
  {
    value: '4F4B6506-341D-42FE-BC21-E440D03F60CC',
    label: 'Peet\'s Coffee & Tea',
  },
  {
    value: '69F9DCD0-509C-4AD1-95E9-3560C8BE0C13',
    label: 'Oporto',
  },
  {
    value: '24E52EF4-CE85-4C5C-B961-DB3454785F4A',
    label: 'Christian Louboutin',
  },
  {
    value: 'A6B753D8-C777-4D6E-BED3-CBB72428CEA4',
    label: 'ECCO',
  },
  {
    value: 'AB477DE2-998D-4068-A432-19BA32F2CA25',
    label: 'Brandy Melville',
  },
  {
    value: '6D6A3721-D881-4EEC-B95F-6E0330FFA541',
    label: 'Edenrobe',
  },
  {
    value: 'D651069E-C9FB-432F-921E-3E76CE6398BA',
    label: 'Calzedonia',
  },
  {
    value: '5D0DD386-C35C-479B-98F6-407938E29949',
    label: 'Buckle',
  },
  {
    value: 'BD93E7F6-AF19-4741-A06C-A818BAEB0FFB',
    label: 'Clarks',
  },
  {
    value: '24C8C807-9EB4-42C4-9C47-4F315FAE5950',
    label: 'Hugo Boss',
  },
  {
    value: 'C0D02EA8-6BC7-4BE0-AD07-8FC3A5E029A7',
    label: 'Café Rouge',
  },
  {
    value: 'B287C7B0-609F-430D-8F17-D3459EFB8FBA',
    label: 'Aritzia',
  },
  {
    value: 'F71DBAFB-A870-4307-84E5-A4597B752E27',
    label: 'Spring',
  },
  {
    value: '3F6BB599-9622-4D54-AF8A-CA448ADA1B5A',
    label: 'Bimba Y Lola',
  },
  {
    value: '0C3BD729-7828-4B25-A1A5-3748268A1CD7',
    label: 'Zumiez',
  },
  {
    value: '04C8E607-211B-40F6-BA0A-70245E7A99F7',
    label: 'Three',
  },
  {
    value: '74A63948-E4FD-45E9-BFF0-2D5D30380330',
    label: 'Montblanc',
  },
  {
    value: '33A42D87-793C-49F5-B899-EF34D47FB1A2',
    label: 'Fendi',
  },
  {
    value: 'C90E3920-BF05-4537-903F-085BFE2E41AC',
    label: 'Acne Studios',
  },
  {
    value: 'A99A2E2D-CFA4-4A6F-A270-FD768398AD9E',
    label: 'Nespresso',
  },
  {
    value: 'C306B282-6425-46DF-98F0-D976C6A65B1E',
    label: 'Coach',
  },
  {
    value: '5C5E0B4F-E62E-4769-A28F-904EB6828FE2',
    label: 'Bed Bath & Beyond',
  },
  {
    value: 'EC1EFF80-25D5-48C1-A03A-B9C73166CDDD',
    label: 'Hermès',
  },
  {
    value: '1D56E32D-8300-46C9-B976-D18328BEB2E1',
    label: 'Moschino',
  },
  {
    value: '95140632-D135-4C2D-B555-F9F8C7CF0295',
    label: 'Warehouse',
  },
  {
    value: '0371CD12-2B88-4F02-BFEC-C493208D2B99',
    label: 'Francesca\'s',
  },
  {
    value: '6C386104-464E-4FB5-8570-37B88B132875',
    label: 'Kate Spade New York',
  },
  {
    value: 'AC31EFD0-F215-4894-B829-440E3CADA26A',
    label: 'Berluti',
  },
  {
    value: 'D4ED6CEB-9CBF-4413-B560-D8F950A26AF3',
    label: 'Umami Burger',
  },
  {
    value: 'AB8D769A-CCEB-4C2D-A3D8-21641E0EC664',
    label: 'Warby Parker',
  },
  {
    value: 'D9C26D43-BC6E-433C-82A7-D39E1C6862CA',
    label: 'EE',
  },
  {
    value: 'BCF8A88A-C054-4333-AE42-FF6E14C056FD',
    label: 'Swatch',
  },
  {
    value: 'DB03F250-64F3-47AB-8756-BC1C4E13EBFD',
    label: 'The North Face',
  },
  {
    value: 'E43E58D0-7342-496E-BEE3-0022BFEB1AFC',
    label: 'Birkenstock',
  },
  {
    value: 'A99EEA55-9F32-4308-9883-7F70FF4E00BE',
    label: 'Damyller',
  },
  {
    value: 'B433D810-C23D-417C-AA9D-45D031E534E7',
    label: 'Burton Snowboards',
  },
  {
    value: '5D5672A4-D587-4D90-A913-326997914611',
    label: 'Carolina Herrera',
  },
  {
    value: '97C0AFF1-742D-4058-A892-B019CCEDE71A',
    label: 'Agent Provocateur',
  },
  {
    value: '88A1BF05-3049-4A96-9F31-E366CD15FDD6',
    label: 'Diadora',
  },
  {
    value: 'C7C2B636-E5F5-42BE-8068-C8A1F1C463F6',
    label: 'Givenchy',
  },
  {
    value: 'C7368B19-3E0F-44A2-9CB1-3DDD2953EFEC',
    label: 'Scotch & Soda',
  },
  {
    value: '9AAC93F0-FA5C-4225-878D-42666943BFC1',
    label: 'The Perfume Shop',
  },
  {
    value: '76C8BE42-B48C-4FDF-BBEA-A87545F69FA0',
    label: 'Chrome Hearts',
  },
  {
    value: '433AF5FB-C10A-42B6-AEDC-257B43D4EA19',
    label: 'Havaianas',
  },
  {
    value: '6890A77C-8839-477E-8A12-E5F911000575',
    label: 'Timberland',
  },
  {
    value: 'B3D9FE5C-5926-45B4-AA66-C9D7D6DE262C',
    label: 'Steve Madden',
  },
  {
    value: '78A44C09-E691-42DA-A553-5CDE6EE9B18A',
    label: 'Allbirds',
  },
  {
    value: 'A46B30EF-686A-435E-A6B8-DF426226ECE0',
    label: 'Aramis Menswear',
  },
  {
    value: '79EC5CA5-47EF-49BB-83E5-A508370909BC',
    label: 'Dickies',
  },
  {
    value: '5EA79857-0E68-48CC-8345-3A786B231326',
    label: 'Allsaints',
  },
  {
    value: '3481DA6C-849A-4B17-A064-DFEFB0295276',
    label: 'Barbour',
  },
  {
    value: '666C23E9-5959-4412-98A2-B988EEBB7BBF',
    label: 'Billabong',
  },
  {
    value: '72B28356-A243-4E88-9A0D-13C5F936A367',
    label: 'Pei Wei Asian Diner',
  },
  {
    value: 'BB047D19-D3D5-47A1-B9AA-ECCEBE9470AE',
    label: 'Balenciaga',
  },
  {
    value: '662272BA-C987-4064-A4B2-5F23E7714251',
    label: 'Being Human Clothing',
  },
  {
    value: 'A1422E65-D068-45AF-9A94-0EA85AFD26B9',
    label: 'Crate & Barrel',
  },
  {
    value: '5E58D35A-7CCC-4448-93F1-9E97AD5978C4',
    label: 'Global Desi',
  },
  {
    value: 'BEB1FCC8-5BB3-4416-AF9A-93892B179711',
    label: 'Homebase',
  },
  {
    value: 'BEF4E5D5-245C-49D5-8D20-7C8A1B3C80F5',
    label: 'Best Buy',
  },
  {
    value: '59D0539B-CAF5-4AF0-B6CF-7A11B16905A9',
    label: 'Brilliant Earth',
  },
  {
    value: '56053596-C1BD-4668-B2B7-F13730A1C339',
    label: 'Ganni',
  },
  {
    value: '4F93D412-C49A-4151-BD4B-DEAE7F998E7E',
    label: 'Dsquared2',
  },
  {
    value: '489BDF44-F2D1-484D-A09B-5A63A3034D6D',
    label: 'Alexander Mcqueen',
  },
  {
    value: '4615A2F7-2411-4FCF-A6D5-5B1C707FCB1D',
    label: 'French Connection',
  },
  {
    value: '66968622-B220-428F-A7D6-AFFDAF4AB338',
    label: 'Cole Haan',
  },
  {
    value: 'CB1633C5-0075-44B5-997C-428B47E2D000',
    label: 'LIDS',
  },
  {
    value: '42E9863C-8EF8-43AA-8A8B-72C119CAB63F',
    label: 'City Beach',
  },
  {
    value: '3FF24713-4FCE-43B6-845E-4A9C20F17242',
    label: 'Elie Saab',
  },
  {
    value: '33A31B51-CE46-40BB-B2BD-B446231247C0',
    label: 'Fred Perry',
  },
  {
    value: '737B2651-E3DA-417A-B127-0A2A334768C8',
    label: 'Elisabetta Franchi',
  },
  {
    value: '322EE262-531E-4F7B-932E-CBFEBB6E1901',
    label: 'Beams',
  },
  {
    value: '764CFB4B-F81C-4C28-ADCA-B35C7A4A69B2',
    label: 'Almirah',
  },
  {
    value: 'DB068773-2005-4D59-8A68-34FA5244AF41',
    label: 'Ellus',
  },
  {
    value: '7C4570FE-9DC0-44EF-BFD5-809904D02BCB',
    label: 'Rip Curl',
  },
  {
    value: '7F4CD3BE-477A-467D-B4F7-5F245B56B5F4',
    label: 'Claire\'s',
  },
  {
    value: 'E10915E4-EE3A-445B-A3F1-86E3EBAC25BC',
    label: 'Draper James',
  },
  {
    value: '82B230DB-20B2-41CE-B184-0231EF33CEFC',
    label: 'Peacocks',
  },
  {
    value: '9C75E53E-48A5-422F-9918-0A729743B563',
    label: 'Champion',
  },
  {
    value: '2548469B-1B72-4A6A-BC05-76A2ACDFD23D',
    label: 'Harry Winston',
  },
  {
    value: 'EAD61C38-D1CC-491E-B8E6-4996D34BACB3',
    label: 'Tumi',
  },
  {
    value: 'EBF216DA-112D-41CB-A31D-8BEF2F85969E',
    label: 'GNC',
  },
  {
    value: 'EBFF0EE8-72FF-47A6-85B8-BC480D474F68',
    label: 'Audemars Piguet',
  },
  {
    value: '20310A12-2D45-46F9-B983-C45CB140618D',
    label: 'Vineyard Vines',
  },
  {
    value: 'F004D1E8-1B92-446C-A8FA-8C2308AD4ABE',
    label: 'Tag Heuer',
  },
  {
    value: '9AD43046-63D7-4BB7-BBDD-968579724250',
    label: 'Fabletics',
  },
  {
    value: '8FDA0D3F-1B1D-4759-A3FC-288EC0C1BB29',
    label: 'Club Monaco',
  },
  {
    value: '97CC808A-759E-4CBA-B68F-655AC3FC58E8',
    label: 'Pottery Barn',
  },
  {
    value: '0CF39C39-5616-4B7F-A338-362E0F788AC8',
    label: 'L\'Occitane',
  },
  {
    value: 'FB657D2F-F5FF-4FC5-8E42-C89D29FFB4EF',
    label: 'Alpinestars',
  },
  {
    value: '8C45D123-D80E-48B5-B12F-38E7C5A8D074',
    label: 'Chop',
  },
];

export default placeBrands;
