import Popover from '@mui/material/Popover';
import { bindPopover } from 'material-ui-popup-state/hooks';
import React, { useMemo } from 'react';

import Locker from '@/components/Locker';
import { popovers } from '@/constants';
import { paramNames } from '@/constants/paramNames';
import { useAppSelector } from '@/store';
import getIsFieldValueEmpty from '@/utils/getIsFieldValueEmpty';
import getIsQlooUser from '@/utils/getIsQlooUser';

import { demographicsParamNames } from '../constants';
import QueryBuilderPopoverPanel from './QueryBuilderPopoverPanel';

const getDemographicsParamNames = ({ isQlooUser, params }) => {
  if (isQlooUser || !getIsFieldValueEmpty(params[paramNames.audiences])) {
    return demographicsParamNames;
  }

  // hide audience selector unless it's a qloo user OR they have the field populated
  return demographicsParamNames.filter(paramName => paramName !== paramNames.audiences);
};

const getPopoverProps = ({ onClose, isQlooUser, selectedPopover, params }) => {
  switch (selectedPopover) {
    case popovers.demographics:
      return {
        color: 'orange',
        paramNames: getDemographicsParamNames({ isQlooUser, params }),
        title: 'Demographics',
      };
    case popovers.interests:
      return {
        color: 'teal',
        onUpdate: (prevValue, nextValue) => {
          if ((nextValue?.length || 0) > (prevValue?.length || 0)) {
            onClose();
          }
        },
        paramNames: [paramNames.omnisearch],
        title: 'Interests',
      };
    case popovers.locations:
      return {
        // onUpdate: onClose,
        color: 'purple',
        paramNames: [paramNames.signalLocation],
        title: 'Location',
      };
    default:
      return {
        paramNames: [],
        title: '',
      };
  }
};

const QueryBuilderPopover = ({
  lock,
  onChange,
  onClose,
  onReset,
  selectedPopover,
  params,
  popupState,
  releaseLock,
}) => {
  const { identity } = useAppSelector((state) => state.app);

  const isQlooUser = useMemo(
    () => Boolean(getIsQlooUser(identity)),
    [identity],
  );

  const isLocationsPopover = selectedPopover === popovers.locations;

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      anchorPosition={
        popupState.anchorPosition
      }
      anchorReference="anchorPosition"
      onClose={onClose}
      PaperProps={
        isLocationsPopover
          ? { style: { width: '800px' } }
          : { style: { minWidth: '550px' } }
      }
      transformOrigin={{
        vertical: 'top',
        horizontal: isLocationsPopover ? 'center' : 'left',
      }}
    >
      <Locker lock={lock} releaseLock={releaseLock}>
        <QueryBuilderPopoverPanel
          close={onClose}
          onChange={onChange}
          onReset={onReset}
          params={params}
          selectedPopover={selectedPopover}
          {...getPopoverProps({ isQlooUser, onClose, params, selectedPopover })}
        />
      </Locker>
    </Popover>
  );
};

export default QueryBuilderPopover;
