import useGPT from '@v1/lib/useGPT';

const useGPTExplainCategories = ({ categories }) => {
  const prompt = categories.every((category) => category.trim() === '')
    ? ''
    : [
      'Please summarize the following categories in a concise and conversational manner.',
      'Identify the common themes or patterns across these categories and explain what they',
      'have in common in a simple and coherent way.',
      'Keep the explanation under 600 characters and ensure it is clear and easy to understand.',
      `Categories: ${categories.join(', ')}`,
    ]
      .filter((n) => !!n)
      .join('\n');

  const { result: gptResult, error: gptError, isLoading: gptLoading } = useGPT(prompt, {
    key: `gpt-v2-explain-cats-${prompt}`,
  });

  if (!prompt) {
    return { result: '', isLoading: false, error: null };
  }

  return {
    isLoading: gptLoading,
    error: gptError,
    result: gptLoading || gptError ? null : gptResult,
  };
};

export default useGPTExplainCategories;
