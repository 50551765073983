import { normal } from 'color-blend';
import React from 'react';

import styles from './AffinityBar.module.scss';

const greenThreshold = 100;
const blueThreshold = 75;
const purpleThreshold = 50;
const grayThreshold = 25;

const rgbByColor = {
  greenBorder: { r: 102, g: 204, b: 102 },
  greenFill: { r: 238, g: 249, b: 238 },
  blueBorder: { r: 0, g: 204, b: 255 },
  blueFill: { r: 229, g: 250, b: 255 },
  purpleBorder: { r: 102, g: 51, b: 204 },
  purpleFill: { r: 234, g: 225, b: 242 },
  grayBorder: { r: 184, g: 198, b: 209 },
  grayFill: { r: 233, g: 238, b: 242 },
};

const getAlphaValues = ({ valuePercent, topThreshold, bottomThreshold }) => {
  const diffPercentage = (valuePercent - bottomThreshold) / (topThreshold - bottomThreshold);
  return [diffPercentage, 1 - diffPercentage];
};

const getColorsByPrefix = (prefix: string) => ({
  border: { ...rgbByColor[`${prefix}Border`], a: 1 },
  fill: { ...rgbByColor[`${prefix}Fill`], a: 1 },
});

const getBlendedColors = ({
  valuePercent, bottomThreshold, topThreshold, prefixes,
}) => {
  const [firstPrefix, secondPrefix] = prefixes;
  const [firstColorAlphaValue, secondColorAlphaValue] = getAlphaValues({ valuePercent, bottomThreshold, topThreshold });
  const { border: firstColorBorder, fill: firstColorFill } = getColorsByPrefix(firstPrefix);
  const { border: secondColorBorder, fill: secondColorFill } = getColorsByPrefix(secondPrefix);

  const firstColorBorderRgba = { ...firstColorBorder, a: firstColorAlphaValue };
  const firstColorFillRgba = { ...firstColorFill, a: firstColorAlphaValue };
  const secondColorBorderRgba = { ...secondColorBorder, a: secondColorAlphaValue };
  const secondColorFillRgba = { ...secondColorFill, a: secondColorAlphaValue };

  return {
    fill: normal(firstColorFillRgba, secondColorFillRgba),
    border: normal(firstColorBorderRgba, secondColorBorderRgba),
  };
};

const getColors = (valuePercent) => {
  if (valuePercent >= blueThreshold) {
    return getBlendedColors({
      valuePercent, bottomThreshold: blueThreshold, topThreshold: greenThreshold, prefixes: ['green', 'blue'],
    });
  }

  if (valuePercent >= purpleThreshold) {
    return getBlendedColors({
      valuePercent, bottomThreshold: purpleThreshold, topThreshold: blueThreshold, prefixes: ['blue', 'purple'],
    });
  }

  if (valuePercent >= grayThreshold) {
    return getBlendedColors({
      valuePercent, bottomThreshold: grayThreshold, topThreshold: purpleThreshold, prefixes: ['purple', 'gray'],
    });
  }

  return getColorsByPrefix('gray');
};

interface Props {
  value: number
}

const AffinityBar = ({ value }: Props) => {
  const valuePercent = value === 1 ? 100 : (Math.floor(value * 10000) / 100).toFixed(2);
  const { border, fill } = getColors(valuePercent);

  return (
    <div className={styles.container}>
      <div className={styles.value}>
        {valuePercent}
      </div>
      <div className={styles.bar}>
        <div
          className={styles.barMask}
          style={{
            borderColor: `rgba(${border.r}, ${border.g}, ${border.b}, ${border.a})`,
            backgroundColor: `rgba(${fill.r}, ${fill.g}, ${fill.b}, ${fill.a})`,
            width: `${valuePercent}%`,
          }}
        />
      </div>
    </div>
  );
};

export default AffinityBar;
