import { Box, Typography } from '@mui/material';
import useHasPermission from '@v1/hooks/useHasPermission';
import { useEffect, useMemo } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import { useGetDashboardRecentQuery } from '@/store/apis/dashboardsApi';

import AddDashboardCard from './AddDashboardCard';
import DashboardCard from './DashboardCard/DashboardCard';
import { getFilteredDashboards } from './helpers/dashboardHelpers';
import RecentDashboardsSkeleton from './Skeleton/RecentDashboardsSkeleton';

const HomePageRecentDashboards = ({ search }) => {
  const { showBoundary } = useErrorBoundary();
  const hasCreatePermission = useHasPermission('feature.dashboard.create');
  const { data: recentDashboards, isLoading, error } = useGetDashboardRecentQuery({});

  const filteredRecentDashboards = useMemo(
    () => getFilteredDashboards(recentDashboards, search),
    [recentDashboards, search],
  );

  useEffect(() => {
    if (error) {
      showBoundary(error);
    }
  }, [error, showBoundary]);

  if (isLoading) {
    return (
      <RecentDashboardsSkeleton />
    );
  }

  if (!filteredRecentDashboards?.length) {
    return null;
  }

  return (
    <Box p={5} display="flex" flexDirection="column" gap={2}>
      <Typography variant="h6" fontWeight={600}>Recent</Typography>
      <Box display="flex" gap={3} flexWrap="wrap">
        {filteredRecentDashboards.map((dashboard) => (
          <DashboardCard key={dashboard.id} data={dashboard} />
        ))}
        {hasCreatePermission && !search.length && (
          <AddDashboardCard />
        )}
      </Box>
    </Box>
  );
};

export default HomePageRecentDashboards;
