import panelTypes from '@/constants/panelTypes';

const insightPanelsBitmap = {
  [panelTypes.table]: true,
  [panelTypes.heatmap]: true,
};

// This is used to determine if the panel is fetching data from the insight API
const getIsInsightPanel = (panelType: keyof typeof panelTypes) => !!insightPanelsBitmap[panelType];

export default getIsInsightPanel;
