import {
  Box, Button, Stack, Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './NotFound.module.scss';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box className={styles.container}>
      <Stack spacing={2} direction="column">
        <Box className={styles.message}>
          <Typography variant="h5" fontWeight="bold">Oops!</Typography>
          <Box component="pre">Page not found :(</Box>
        </Box>

        <Box className={styles.actions}>
          <Button onClick={() => navigate('/dashboards')} variant="outlined">
            Go to Home Page
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default NotFound;
