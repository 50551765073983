import { Card, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { EntityTypes } from '@/entity/EntityTypes';
import { MAX_VISIBLE_ENTITIES } from '@/screens/Dashboard/constants';

import EntityItem from '../EntityItem';
import styles from './Omnisearch.module.scss';

interface EntitiesListProps {
  entities: EntityTypes.Entity[];
  entitiesStartIndex: number;
  entitiesGridClassName: string;
  getItemProps: (props: {
    index: number;
    item: EntityTypes.Entity;
  }) => Record<string, unknown>;
  resetPadding: boolean;
  showMini: boolean;
  valueBitmap: Record<string, boolean>;
}

const EntitiesList = ({
  entities,
  entitiesStartIndex,
  entitiesGridClassName,
  getItemProps,
  resetPadding,
  showMini,
  valueBitmap,
}: EntitiesListProps) => {
  const theme = useTheme();

  const renderEntityItem = (entity: EntityTypes.Entity, index: number) => (
    <EntityItem
      key={entity.entity_id}
      entity={entity}
      selected={valueBitmap?.[entity.entity_id] ?? false}
      minified={showMini}
      {...getItemProps?.({
        index: entitiesStartIndex + index,
        item: entity,
      })}
    />
  );

  const renderMoreCard = (remainingCount: number) => (
    <Card
      className={styles.moreEntitiesCard}
      style={{ borderColor: theme.palette.grey[400] }}
    >
      <Typography fontWeight="bold" fontSize="small">
        +{remainingCount} more
      </Typography>
    </Card>
  );

  return (
    <div
      className={clsx({
        [styles.entities]: true,
        [styles.entitiesMini]: showMini,
        [styles.resetPadding]: resetPadding,
      })}
    >
      <div className={clsx(styles.entitiesGrid, entitiesGridClassName)}>
        {entities?.length > 0 ? (
          <>
            {entities.slice(0, MAX_VISIBLE_ENTITIES).map(renderEntityItem)}
            {entities.length > MAX_VISIBLE_ENTITIES
              ? renderMoreCard(entities.length - MAX_VISIBLE_ENTITIES)
              : null}
          </>
        ) : (
          <div className={styles.placeholder}>
            <Typography
              component="pre"
              padding="10px"
              borderRadius={0.5}
              maxWidth={400}
              color={theme.palette.text.primary}
              sx={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                backgroundColor: theme.palette.grey['50'],
              }}
            >
              No valid entities to display for this search.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default EntitiesList;
