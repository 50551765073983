import React from 'react';

import RouteAndTitle from '../Layout/RouteAndTitle';

const RequestAccessComponent = ({
  missingAccess, identity, permission,
}) => {
  const permissionMessage = permission ? ' ({permission})' : '';
  return (
    <div>
      <h1>Request Access{permissionMessage}</h1>
      <p>You do not have access to <b>{missingAccess}</b>.</p>
      <p>Please have your company representative contact our support to give you access to this feature.</p>
      <p>Please let them know your login was: <b>{identity.loginEmailAddress}</b></p>
      { permission && <p>The permission you need to request is: ${permission}</p> }
    </div>
  );
};

const RequestAccess = ({
  missingAccess,
  identity,
  permission,
}) => (
  <RouteAndTitle
    Component={RequestAccessComponent}
    title="Request Access"
    missingAccess={missingAccess}
    identity={identity}
    permission={permission}
  />
);

export default RequestAccess;
