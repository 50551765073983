import React from 'react';

import panelTypes from '@/constants/panelTypes';

import { getQueryError } from '../FilterTags/helpers';
import styles from './DashboardPanel.module.scss';
import PanelFooterTable from './PanelFooterTable';

const PanelFooter = ({
  panel,
  panelError,
  hasMorePages,
  hasData,
  hasSelectedCategory,
}) => {
  const {
    panelId, panelType, params: panelParams,
  } = panel;

  const error = getQueryError(panelError);
  const hasError = Boolean(error);
  const isExploreFilterType = [panelTypes.explore, panelTypes.locationExplorer].includes(panelType);
  const isTable = panelType === panelTypes.table;

  if (
    (isTable && hasData && !hasError && (hasMorePages || panelParams?.offset > 0))
    || (isExploreFilterType && hasSelectedCategory && (hasMorePages || panelParams?.offset > 0))
  ) {
    return (
      <PanelFooterTable
        panelId={panelId}
        panelParams={panelParams}
        hasMorePages={hasMorePages}
      />
    );
  }

  return (
    <div className={styles.panelFooter} />
  );
};

export default PanelFooter;
