import React, { useMemo } from 'react';

import { useAppSelector } from '@/store';

const PermissionsValidatorWrapper = ({ component: Component, permission, additionalProps = {} }) => {
  const { identity } = useAppSelector((state) => state.app);

  const userIsAuthorizedByPermission = useMemo(() => {
    return !!(identity && (identity.calculatedPermissionTagIds || []).includes(permission));
  }, [identity, permission]);

  return <Component {...additionalProps} isAuthorized={userIsAuthorizedByPermission} />;
};

export default PermissionsValidatorWrapper;
