const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none">
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M6.571 12.143A5.572 5.572 0 1 0 6.571 1a5.572 5.572 0 0 0 0 11.143ZM10.857 10.857 13 13"
    />
  </svg>
);
export default SearchIcon;
