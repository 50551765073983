const logOperations = {
  rop: 'rop',
  ropIns: 'rop.ins',
  ropUpd: 'rop.upd',
  ropSdel: 'rop.sdel',
  ropOpen: 'rop.open',
};

export const logOperationLabels = {
  [logOperations.rop]: 'operation',
  [logOperations.ropIns]: 'insert',
  [logOperations.ropUpd]: 'update',
  [logOperations.ropSdel]: 'soft delete',
  [logOperations.ropOpen]: 'open',
};

export default logOperations;
