/* eslint-disable max-len */
import ReactMarkdown from 'react-markdown';

import getDemographicsLabel from '@/utils/getDemographicsLabel';
import tryParseJson from '@/utils/tryParseJson';

import getPersonaResultMarkdownRealEstate from '../shared/getPersonaResultMarkdownRealEstate';
import personaInsightsRealEstate from '../shared/personaInsightsRealEstate';
import realEstateForm from '../shared/realEstateForm';

const schema = `{
  "strict": True,
  "required": [
      "who_we_are",
      "what_drives_us",
      "ticks",
      "pain_points",
      "goals_and_needs",
      "day_in_the_life",
      "ideas",
  ],
  "additionalProperties": False,
  "properties": {
      "who_we_are": {
          "type": "string",
          "$comment": "Write a detailed description of the persona's background, including their profession, age, interests, and daily habits. Highlight their passions, key personality traits, and how they interact with their environment. Provide specific brand affinities or lifestyle cues that define them."
      },
      "what_drives_us": {
          "type": "string",
          "$comment": "Describe the core motivations and driving factors for the persona. Explain what inspires them, what they value in their environment, and how they connect with their community. Highlight emotional and functional needs driving their decisions."
      },
      "ticks": {
        "type": "string",
        "$comment": "Describe what makes them tick, unique, their quirks and defining characteristics.",
      },
      "pain_points": {
          "type": "string",
          "$comment": "List the main challenges or frustrations the persona experiences in their environment. These should be specific and tied to their lifestyle, habits, and expectations from the asset type or community space."
      },
      "goals_and_needs": {
          "type": "array",
          "$comment": "Listed descriptions of goals and needs",
          "items": {
              "type": "string",
              "description": "string",
              "$comment": "Outline the persona's primary goals and needs related to their interaction with their aasset type. Include both practical and emotional needs, focusing on what would enhance their overall satisfaction and experience."
          }
      },
      "day_in_the_life": {
        "type": "array", 
        "items":{      
          "type": "string",
          "$comment": "Outline a typical day in the persona's life.Bullet out morning, afternoon and evening and provide commentary. Highlight key moments, habits, and interactions they have with their environment. Use specific examples of activities and brand interactions where applicable."
        }
      },
      "ideas": {
          "type": "array",
          "$comment": "Descriptions of the ideal solutions and enhancements for the persona",
          "items": {
              "type": "string",
              "description": "string",
              "$comment": "Propose three tailored recommendations designed to meet the persona's needs and preferences based on the asset type. Each idea should include a clear motivation explaining how it addresses their lifestyle, work, activities and enhances their experience."
          }
      }
  }
}`;
const mapEntity = (entity) => `Entity: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const mapTag = (entity) => `Tag Genre: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const realEstate = {
  name: 'Real Estate Focused Persona',
  description: 'Real Estate focused personas',
  form: realEstateForm,
  insights: personaInsightsRealEstate,
  render: ({ result }) => {
    const markdown = getPersonaResultMarkdownRealEstate(tryParseJson(result.slice(7, -3) || '[]'));
    return (
      <ReactMarkdown>
        {markdown}
      </ReactMarkdown>
    );
  },
  runner: async ({
    baseParams, entities, fetchGpt, tags, userData,
  }) => {
    const { useCase, assetType, personaCount } = userData;
    const demographicsLabel = getDemographicsLabel(baseParams);
    //   const demographicsText = demographicsLabel && demographicsLabel !== "anyone"
    // ? `- Consider the ${demographicsLabel} demographic, but no additional emphasis is required.`
    // : "- Do not assume any specific demographic characteristics unless explicitly provided.";

    const prompt = `
    As a persona developer, your task is to create ${personaCount} distinct personas for a real estate ${useCase}, specifically for ${assetType} asset. 
    Each persona must:
    1. Be grounded in the provided data, reflecting regional, locational, and cultural nuances.
    2. Do not assume residence.  
    3. Focus on practical traits, motivations, and behaviors related to ${assetType} living.
    4. Be engaging in the tone. 

    **Input Data**:
    - Tags: ${tags.map(mapTag).join(' ')}
    - Entities: ${entities.map(mapEntity).join(' ')}

    **Persona Instructions**:
    - Use the provided demographic (${demographicsLabel}) as a reference to interpret the data but do not make additional assumptions beyond what is explicitly stated.
    - Base each persona entirely on the provided tags, entities, and affinity scores. Directly reference tags and entities and their affinity scores in a way that justifies their characteristics, lifestyle, or preferences.
    - Include affinity scores with every entity mentioned. 
    - Avoid fictional details like names, backstories, or unsupported activities.
    - Justify all interests by linking them to the data (e.g., "This persona connects with [Entity X] (score: 0.95) because of its emphasis on [specific lifestyle or habit].").
    - Highlight key motivations, preferences, challenges, and needs relevant to ${assetType} in the ${useCase} category.

    **Sections**:
    - Include a “day in the life” section for these different personas that is guided by key traits derived from the provided data.\
    - Integrate some meaningful and captivating micro-moments in the as this will help bring the personas to life in this section.\
    1. **Profile Overview**: Summarize key traits and characteristics.
    2. **Behaviors and Preferences**: Describe specific patterns and habits tied to the data.
    3. **Challenges and Goals**: Highlight ${useCase}-related challenges and goals focusing on the ${assetType}. Focus on the audience and lifestyle tags in the input column. This section should speak to perceived concerns that this persona may have if their interests are not met.
    4. **Recommendations**: Provide three actionable ideas for improving the ${assetType} experience, with justifications.
    5. **Community Interaction**: Explain how the persona might interact with others and foster collaboration.
    6. **Partnership Ideas**: Suggest partnerships or incentives that would enhance this ${assetType} for it's users.

    **Output Requirements**:
    - Return all ${personaCount} personas only as a **JSON array** using this schema: ${JSON.stringify(schema)}
    `;

    try {
      const gptResult = await fetchGpt({ prompt });
      return gptResult;
    } catch (e) {
      return 'Error';
    }
  },
};

export default realEstate;
    // - Maintain a somewhat professional tone without adding too many embellishments. 
//Highlight only the dominant location when relevant. If a geographic tag is categorized as a trend reference it as a cultural or stylistic influence rather than a residence.
