import { Autocomplete } from '@mui/material';

import InputV2 from '@/components/Input/InputV2';
import { useGetOrganizationsQuery } from '@/store/apis/identityApi';

const OrganizationSelector = ({ selectedOrgId, setSelectedOrgId }) => {
  const { data: organizations, isLoading } = useGetOrganizationsQuery({});

  const handleOrgChange = (event, newValue) => {
    setSelectedOrgId(newValue?.identityId);
  };

  return (
    <Autocomplete
      sx={{ width: '250px' }}
      disableClearable
      disabled={isLoading}
      options={organizations || []}
      value={organizations?.find((org) => org.identityId === selectedOrgId) || null}
      getOptionLabel={(option) => option.fullName || option.name || option.identityId}
      onChange={handleOrgChange}
      renderInput={(params) => (
        <InputV2
          {...params}
          variant="outlined"
          onChange={(event) => {
            if (event && event.target) {
              params.inputProps?.onChange?.(event);
            }
          }}
        />
      )}
    />
  );
};

export default OrganizationSelector;
