import { InfoOutlined } from '@mui/icons-material';
import React from 'react';

import TooltipInfo from '../Tooltip/TooltipInfo';
import styles from './InfoItem.module.scss';

interface InfoItemProps {
  text: string;
  description?: string;
}

const InfoItem = ({ text, description }: InfoItemProps) => {
  return (
    <div className={styles.infoItem}>
      {text}
      {description ? (
        <TooltipInfo title={description} followCursor>
          <div className={styles.infoItemIcon}>
            <InfoOutlined />
          </div>
        </TooltipInfo>
      ) : null}
    </div>
  );
};

export default InfoItem;
