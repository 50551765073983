import { Box } from '@mui/material';
import useHasDashboardPermissions from '@v1/hooks/useHasDashboardPermissions';
import React, { useMemo } from 'react';
import { ArrowDownRight } from 'react-feather';
import RGL, { WidthProvider } from 'react-grid-layout';

import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import permissionTags from '@/constants/permissionTags';
import tooltips from '@/utils/tooltips';

import { useDashboardActionsContext } from '../DashboardActionsProvider';
import DashboardPanel from '../DashboardPanel/DashboardPanel';
import { useDashboardVeltContext } from '../DashboardVeltProvider';
import { NUMBER_OF_COLUMNS } from '../helpers/DashboardConstants';
import getLayoutItems from '../helpers/getLayoutPanels';
import styles from './DashboardLayout.module.scss';

const ReactGridLayout = WidthProvider(RGL);

const DashboardLayout = ({ dashboard, isViewingPublicly = false }) => {
  const dashboardActions = useDashboardActionsContext();
  const dashboardVelt = useDashboardVeltContext();
  const isEditor = useHasDashboardPermissions(dashboard.id, [permissionTags.roleEditor]);
  const { layout, panels, baseParams } = dashboard;
  const layoutItems = useMemo(() => getLayoutItems(layout, panels), [layout, panels]);

  const onResizeOrReorder = (nextLayout) => {
    dashboardActions.updateLayout(nextLayout);
    dashboardVelt.onLayoutChangeEnd();
  };

  const onLayoutChangeStart = (nextLayout) => {
    if (isEditor) {
      dashboardActions.updateLayout(nextLayout);
      dashboardVelt.onLayoutChangeStart();
    }
  };

  return (
    <ReactGridLayout
      cols={NUMBER_OF_COLUMNS}
      draggableHandle=".panel-drag-handle"
      layout={layoutItems}
      onDragStart={isViewingPublicly ? undefined : onLayoutChangeStart}
      onDragStop={isViewingPublicly ? undefined : onResizeOrReorder}
      onResizeStart={isViewingPublicly ? undefined : onLayoutChangeStart}
      onResizeStop={isViewingPublicly ? undefined : onResizeOrReorder}
      margin={[28, 28]}
      resizeHandle={(
        <div
          className={`${styles.resizableButton} react-resizable-handle`}
          data-velt-sync-access="true"
        >
          <TooltipInfo title={tooltips.dashboard.resize}>
            <ArrowDownRight size={14} />
          </TooltipInfo>

        </div>
      )}
      rowHeight={100}
    >
      {layoutItems.map((item) => {
        const panel = panels[item.i];

        return (
          <Box
            key={item.i}
            border={panel.settings?.color && `8px solid ${panel.settings?.color} !important`}
          >
            <DashboardPanel panel={panel} dashboard={dashboard} isViewingPublicly={isViewingPublicly} />
          </Box>
        );
      })}
    </ReactGridLayout>
  );
};

export default DashboardLayout;
