import { LinearProgress } from '@mui/material';

import Overlay from './index';

const FetchingOverlay = () => (
  <Overlay>
    <LinearProgress />
  </Overlay>
);

export default FetchingOverlay;
