import { Box, Chip, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';

import RoleSelector from './RoleSelector';

const CustomInputWithChips = ({
  isQlooUser,
  searchInput,
  selectedIdentities,
  setSelectedIdentities,
  setSearchInput,
  setRole,
}) => {
  const handleInputChange = (event) => setSearchInput(event.target.value);

  const handleDeleteUser = useCallback((user) => {
    setSelectedIdentities(selectedIdentities.filter((u) => u.identityId !== user.identityId));
  }, [selectedIdentities, setSelectedIdentities]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      border="1px solid #ccc"
      borderRadius={1}
      padding={0.7}
      gap={0.5}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" flexWrap="wrap" maxHeight={145} overflow="auto">
          {selectedIdentities.map((user) => (
            <Tooltip
              key={user.identityId}
              title={(
                <Box display="flex" alignItems="center">
                  {user.fullName} - {user.loginEmailAddress}
                </Box>
              )}
            >
              <Chip
                label={user.fullName}
                onDelete={() => handleDeleteUser(user)}
                sx={{ marginRight: '4px', marginBottom: '4px', backgroundColor: 'hsl(var(--hover-light-border))' }}
              />
            </Tooltip>
          ))}
        </Box>
        <input
          value={searchInput}
          onChange={handleInputChange}
          placeholder={isQlooUser ? 'Search for user or org' : 'Search for user'}
          style={{
            flexGrow: 1,
            border: 'none',
            outline: 'none',
            padding: 8,
          }}
        />
      </Box>
      {selectedIdentities?.length > 0 && (
        <Box>
          <RoleSelector onRoleChange={setRole} />
        </Box>
      )}
    </Box>
  );
};

export default CustomInputWithChips;
