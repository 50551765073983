import { Typography } from '@mui/material';
import dayjs from 'dayjs';

import Identity from '@/components/Identity';

const AboutTab = ({ dashboard }) => {
  const {
    title,
    createdByIdentityId,
    history,
    summary,
    summaryGenerated,
  } = dashboard;

  const creationDate = history && history.length ? (
    dayjs(history.at(-1).effectiveRange.match(/"(.*?)"/g)[0]).format('M/D/YYYY h:mm A')
  ) : null;

  const lastUpdate = history && history.length ? (
    dayjs(history[0].effectiveRange.match(/"(.*?)"/g)[0]).format('M/D/YYYY h:mm A')
  ) : null;

  return (
    <>
      <Typography variant="h6" gutterBottom>About</Typography>

      <p>Title: {title}</p>
      {!!creationDate && (<p>Creation date: {creationDate}</p>)}
      {!!createdByIdentityId && (
        <p>
          <span>Created by: </span>
          <Identity identityId={createdByIdentityId}>
            {({ identity }) => (
              <span>{identity?.fullName}</span>
            )}
          </Identity>
        </p>
      )}
      {!!lastUpdate && (<p>Last updated: {lastUpdate}</p>)}

      {(!!summary || !!summaryGenerated) && (
        <p>
          <br />
          <div>Summary: </div>
          {summary || summaryGenerated}
        </p>
      )}
    </>
  );
};

export default AboutTab;
