import React, { createContext, useContext } from 'react';

import { api } from '@/constants';
import { Audience } from '@/entity/audience/AudienceTypes';
import useBatchedRequests from '@/hooks/useBatchedRequests';
import { useAppDispatch } from '@/store';

import { mergeAudiences } from '../store/slices/audiencesSlice';

interface AudienceBatchedRequestContextType {
  queueArg:(arg: string | undefined | null) => void | null
}

const fetchAudiences = async (
  audienceIds: string[],
): Promise<Audience[] | string> => {
  const url = `${
    api.qlooApiV2
  }/audiences?filter.results.audiences=${audienceIds.join(',')}`;

  const response = await fetch(url);
  if (response.ok) {
    const result = await response.json();

    return result?.results?.audiences || [];
  }

  return response.text();
};

const AudienceBatchedRequest = createContext<AudienceBatchedRequestContextType|null>(null);

export const AudienceBatchedRequestProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const dispatch = useAppDispatch();
  const onDone = (audiences: Audience[]) => {
    dispatch(mergeAudiences(audiences));
  };

  const audienceBatchedRequests = useBatchedRequests({
    callback: fetchAudiences,
    onDone,
  });
  return (
    <AudienceBatchedRequest.Provider value={audienceBatchedRequests}>
      {children}
    </AudienceBatchedRequest.Provider>
  );
};

export const useAudienceBatchedRequestsContext = () =>
  useContext(AudienceBatchedRequest);
