import useGPT from '@v1/lib/useGPT';

import { getBaseQueryLabel } from '../lib';
import CitationPopover from './CitationPopover';
import MarkdownView from './MarkdownView';

const COMPONENTS = {
  a(props) {
    const {
      node, href, children, ...rest
    } = props;
    if (!href || href === 'NO CITATIONS FOUND') {
      console.log('No href for citation', href);
      return children;
    }
    return <CitationPopover citation={href} {...rest}>{children}</CitationPopover>;
  },
};

const fixLinks = (markdown) => {
  return markdown?.replace(/\[([^\]]+)\]\(([^)]+)\)/g, (match, p1, p2) => {
    return `[${p1}](${encodeURIComponent(p2)})`;
  });
};

const MarkdownWithCitations = ({
  markdown, entities, tags, baseParams, extraCitations,
}) => {
  const { result: citedMarkdown } = useGPT(`
      * Your will process a markdown document that was generated based on an audience's interests and more inferred interests from Qloo's data
      * You will add citations to Qloo's data via links in markdown.
      * Preserve the original markdown formatting otherwise.
      * The links will be to concepts and entities in Qloo's data.
      * Do not use real https links, just textual concepts and entities.
      * The link targets should be plain text, and be any relevant citations from Qloo's data.
      * Use a few of the most important and relevant citations for each linked term in the markdown.
      * You can select multiple citations to link to the same target, include them as a comma-separated list in the link target.
      * Cite the name or concept, not the number or id.
      * Respond in markdown.
      * if you can’t find any citations, only reply with NO CITATIONS FOUND, do not return original text

      This is the audience: ${getBaseQueryLabel(baseParams)}

      Sources for citations from Qloo's Data:
      ${entities.length ? `Entities: ${entities.map((entity, index) => `${index + 1}. ${entity.name}`).join(', ')}` : ''}
      ${tags.length ? `Concepts: ${tags.map((tag, index) => `${index + 1}. ${tag.name}`).join(', ')}` : ''}
      ${extraCitations.map(({ label, value }) => `${label}: ${value}`).join('\n')}

      Markdown to add links to citations:\n\n${markdown}
  `, {
    key: `gpt-v2-citations-${markdown}`,
    required: [markdown, entities, tags],
  });

  return (
    <MarkdownView components={COMPONENTS}>
      {fixLinks(
        markdown?.length > 20
        && citedMarkdown
        && citedMarkdown?.replace(/[^\w]+/i, '') !== 'NOCITATIONSFOUND'
        && citedMarkdown?.length > markdown?.length
          ? citedMarkdown.replace('\nNO CITATIONS FOUND', '')
          : markdown,
      )}
    </MarkdownView>
  );
};

export default MarkdownWithCitations;
