import React from 'react';

import DashboardLayoutSkeleton from '../DashboardLayout/DashboardLayoutSkeleton';
import DashboardToolbarSkeleton from '../DashboardToolbar/DashboardToolbarSkeleton';
import styles from './DashboardBody.module.scss';

interface Props {
    disabled: boolean
}

const DashboardBodySkeleton = ({ disabled }: Props) => (
  <div className={styles.container}>
    <DashboardToolbarSkeleton disabled={disabled} />
    <DashboardLayoutSkeleton disabled={disabled} />
  </div>
);

export default DashboardBodySkeleton;
