// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from '../utils/customBaseQuery';

/// see https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#advanced-invalidation-with-abstract-tag-ids
const PRODUCT_TAG = 'product';
const FEATURE_TAG = 'feature';
const ENTITY_TAG = 'entity';

const productListObject = { type: PRODUCT_TAG, id: 'LIST' };
const featureListObject = { type: FEATURE_TAG, id: 'LIST' };
const entityListObject = { type: ENTITY_TAG, id: 'LIST' };

// Define a service using a base URL and expected endpoints
export const corporateApi = createApi({
  reducerPath: 'corporateApi',
  baseQuery: customBaseQuery,
  mode: 'cors',
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json');
    return headers;
  },
  endpoints: (builder) => ({

    // PRODUCTS ----------------------------------------------------------------

    productsGet: builder.query({
      query: () => '/product/productsGet',
      providesTags: (result) => (result
        ? [
          productListObject,
          ...result.map(
            ({ productId }) => ({ type: PRODUCT_TAG, id: productId }),
          ),
        ]
        : [productListObject]),
    }),
    productGet: builder.query({
      query: ({ productId }) => ({
        url: '/product/productGet',
        params: {
          productId,
        },
      }),
      providesTags: (
        result,
        error,
        { productId },
      ) => [{ type: PRODUCT_TAG, id: productId }],
    }),
    productUpsert: builder.mutation({
      query: (product) => ({
        url: '/product/productUpsert',
        method: 'PUT',
        body: product,
      }),
      invalidatesTags: (result, error, { productId }) => [
        { type: PRODUCT_TAG, id: productId },
        productListObject,
      ],
    }),
    productDelete: builder.mutation({
      query: (product) => ({
        url: '/product/productDelete',
        method: 'PUT',
        body: product,
      }),
      invalidatesTags: (result, error, { productId }) => [
        { type: PRODUCT_TAG, id: productId },
        productListObject,
      ],
    }),

    // PRODUCT FEATURES --------------------------------------------------------

    featuresGet: builder.query({
      query: () => '/product/featuresGet',
      providesTags: (result) => (result
        ? [
          featureListObject,
          ...result.map(
            ({ featureId }) => ({ type: FEATURE_TAG, id: featureId }),
          ),
        ]
        : [featureListObject]),
    }),
    featureGet: builder.query({
      query: ({ featureId }) => ({
        url: '/product/featureGet',
        params: {
          featureId,
        },
      }),
      providesTags: (
        result,
        error,
        { featureId },
      ) => [{ type: FEATURE_TAG, id: featureId }],
    }),
    featureUpsert: builder.mutation({
      query: (feature) => ({
        url: '/product/featureUpsert',
        method: 'PUT',
        body: feature,
      }),
      invalidatesTags: (result, error, { featureId }) => [
        { type: FEATURE_TAG, id: featureId },
        featureListObject,
      ],
    }),
    featureDelete: builder.mutation({
      query: (feature) => ({
        url: '/product/featureDelete',
        method: 'PUT',
        body: feature,
      }),
      invalidatesTags: (result, error, { featureId }) => [
        { type: FEATURE_TAG, id: featureId },
        featureListObject,
      ],
    }),
    featuresForActiveUserGet: builder.query({
      query: () => '/product/featuresForActiveUserGet',
      providesTags: (result) => (result
        ? [
          featureListObject,
          ...result.map(
            ({ featureId }) => ({ type: FEATURE_TAG, id: featureId }),
          ),
        ]
        : [featureListObject]),
    }),

    // ENTITIES ----------------------------------------------------------------

    entitiesGet: builder.query({
      query: () => '/entity/entitiesGet',
      providesTags: (result) => (result
        ? [
          entityListObject,
          ...result.map(
            ({ entityId }) => ({ type: ENTITY_TAG, id: entityId }),
          ),
        ]
        : [entityListObject]),
    }),
    entityGet: builder.query({
      query: ({ entityId }) => ({
        url: '/entity/entityGet',
        params: {
          entityId,
        },
      }),
      providesTags: (
        result,
        error,
        { entityId },
      ) => [{ type: ENTITY_TAG, id: entityId }],
    }),
    entityUpsert: builder.mutation({
      query: (entity) => ({
        url: '/entity/entityUpsert',
        method: 'PUT',
        body: entity,
      }),
      invalidatesTags: (result, error, { entityId }) => [
        { type: ENTITY_TAG, id: entityId },
        entityListObject,
      ],
    }),
    entityDelete: builder.mutation({
      query: (entity) => ({
        url: '/entity/entityDelete',
        method: 'PUT',
        body: entity,
      }),
      invalidatesTags: (result, error, { entityId }) => [
        { type: ENTITY_TAG, id: entityId },
        entityListObject,
      ],
    }),

  }),
});

export const {
  useProductsGetQuery,
  useProductGetQuery,
  useProductsForActiveUserGetQuery,
  useProductUpsertMutation,
  useProductDeleteMutation,

  useFeaturesGetQuery,
  useFeatureGetQuery,
  useFeaturesForActiveUserGetQuery,
  useLazyFeaturesForActiveUserGetQuery,
  useFeatureUpsertMutation,
  useFeatureDeleteMutation,

  useEntitiesGetQuery,
  useEntityGetQuery,
  useEntityUpsertMutation,
  useEntityDeleteMutation,

} = corporateApi;
