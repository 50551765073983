import React from 'react';

import LoadingForScreenshot from '@/components/LoadingForScreenshot';

import DashboardBodySkeleton from '../DashboardBody/DashboardBodySkeleton';
import DashboardHeaderSkeleton from '../DashboardHeader/DashboardHeaderSkeleton';

const DashboardViewSkeleton = (props) => (
  <div>
    <DashboardHeaderSkeleton />
    <DashboardBodySkeleton {...props} />
    <LoadingForScreenshot isLoading />
  </div>
);

export default DashboardViewSkeleton;
