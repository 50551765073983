// Where is the full "get base query in plain text, the way it reads on the screen" function?
import getInterestsLabel from '@/utils/getInterestsLabel';

import { allReadableInsights, getBaseQueryLabel } from '../../lib';

export default async ({
  baseParams, entities, fetchGpt, tags, userData, ...rest
}) => {
  const readableInsights = allReadableInsights({ entities, tags });

  console.log('WOULD RUN', {
    userData, entities, tags, readableInsights, ...rest,
  });

  const prompt = `
      * You are a marketing expert that helps people brainstorm ideas based on Qloo data.
      * You are given an audience, and a list of subjects that are relevant to that audience, according to Qloo data.
      * The audience is very important, and you should pay close attention to it.
      * First, write a thorough section about what all of these subjects have in common.
      * Then, based on the audience, think of a highly detailed action plan to solve the users request.
      * The audience's location is less important than their interests, unless this is a location-specific request.

      For this audience: ${getBaseQueryLabel(baseParams)}.
      ${
        //  TODO: this should be the interests from the base query on a line by itself, hard-coded muji for now
      ''}
      Who are definitely interested in: ${getInterestsLabel(baseParams)}

      ${readableInsights ? `And who statistically may also be interested in: ${readableInsights}` : ''}

      Brainstorm ideas to solve this problem: ${userData.instructions}

      Explain your reasoning for each step.

      Respond in markdown.
    `;

  const firstPass = await fetchGpt({ prompt });

  console.log('FIRST PASS', firstPass);

  const secondPass = await fetchGpt({
    prompt: `
    The following is a brainstorming session results based on an audience's interests and trying to solve this problem: ${userData.instructions}.

    First, write a title for the document, in less than 10 words, that encapsulates the objective of the task: Brainstorming ideas for ${userData.instructions} for the audience ${getBaseQueryLabel(baseParams)}.

    Shorten and summarize the first sections on commonalities and uniqueness into a single paragraph, remove any mentions of specific brands and focus on general ideas and abstractions.

    Leave the rest of the document, including the brainstorming, as-is.

    make sure to produce valid markdown. Use # for the title, ## for each section header.

    Content:

    ${firstPass}`,
  });

  return secondPass;
};
