import { Autocomplete, Box } from '@mui/material';
import { useState } from 'react';

import placeBrands from '@/constants/permissions/placeBrands';

import InputV2 from '../Input/InputV2';
import OmnisearchItemChip from '../OmnisearchItemChip';

const getNoOptionsText = (search, results) => {
  if (!search) {
    return 'Start typing to search';
  }
  if (results && results.length) {
    return 'Searching...';
  }
  return 'No results found';
};

const PlaceBrandsSearch = ({ value, onChange }) => {
  const [search, setSearch] = useState('');
  const [placeBrandsResults, setPlaceBrandsResults] = useState([]);

  const handleChange = (e, newValue) => {
    onChange(newValue);
  };

  const onSearch = (searchText) => {
    const newOptions = placeBrands.filter((pb) => pb.label.toUpperCase().includes(searchText.toUpperCase()))
      .sort((a, b) => { return a.label > b.label ? 1 : -1; });
    setSearch(searchText);
    setPlaceBrandsResults(newOptions);
  };

  const handleDeleteByIndex = (index) => () => {
    const nextValue = [...value];
    nextValue.splice(index, 1);
    onChange(nextValue);
  };

  const noOptionsText = getNoOptionsText(search, placeBrandsResults);

  return (
    <Box gap={2} display="flex" flexDirection="column">
      <Autocomplete
        multiple
        value={value || undefined}
        onChange={handleChange}
        id="placebrand-search"
        options={search.length ? placeBrandsResults || [] : []}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, place) => option.value === place.value}
        loadingText="Searching..."
        noOptionsText={noOptionsText}
        onClose={() => setSearch('')}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <InputV2
            {...params}
            variant="outlined"
            label=""
            placeholder="Filter by Place Brands"
            value={search}
            onChange={onSearch}
            sx={{ marginTop: 1 }}
          />
        )}
        renderTags={() => {}} // Hide the tags
      />
      <Box display="flex" gap={1}>
        {!!value && value.map((placeBrand, i) => (
          <OmnisearchItemChip
            key={placeBrand.value}
            item={{ id: placeBrand.value, name: placeBrand.label }}
            onDelete={handleDeleteByIndex(i)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default PlaceBrandsSearch;
