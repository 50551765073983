import React, { useEffect, useRef, useState } from 'react';
import { ArrowRight } from 'react-feather';

import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';

import styles from './PanelBodySuggestions.module.scss';

interface SuggestionCardProps {
    title: string;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    isHovered?: boolean;
    className: string;
}

const SuggestionCard = ({
  title,
  onClick,
  onMouseEnter,
  onMouseLeave,
  isHovered,
  className,
}: SuggestionCardProps) => {
  const textRef = useRef<HTMLSpanElement | null>(null);
  const [lineClamp, setLineClamp] = useState<number | null>(null);

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight, 10);
      const maxHeight = textRef.current.clientHeight;
      const lines = Math.floor(maxHeight / lineHeight);
      setLineClamp(lines);
    }
  }, [title]);

  return (
    <TooltipInfo title={tooltips.dashboardSuggestions.create}>
      <div
        className={`${className} ${isHovered ? styles.hovered : ''}`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <span
          ref={textRef}
          className={styles.suggestionText}
          style={{
            WebkitLineClamp: isHovered ? lineClamp : 'unset',
          }}
        >
          {title}
        </span>

        <div className={styles.sectionIcon}>
          {isHovered && <ArrowRight size={19} color="#0077FF" />}
        </div>
      </div>
    </TooltipInfo>
  );
};

export default SuggestionCard;
