import { Box } from '@mui/material';
import { useMemo } from 'react';

import FetchingOverlay from '@/components/Overlay/FetchingOverlay';
import { filterTypes, paramNames } from '@/constants';
import useActiveDashboard from '@/hooks/useActiveDashboard';
import { getMissingPanelParams } from '@/screens/Dashboard/DashboardPanel/helpers/getMissingPanelParams';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import { WktToGeoJson } from '@/utils/geoutils';
import serializeInsightsParams from '@/utils/serializeInsightsParams';

import { getHeatmapItems } from './helpers/getDataFromInsight';
import PanelBodyExplore from './PanelBodyExplore/PanelBodyExplore';
import PanelBodyHeatmap from './PanelBodyHeatmap';

const HeatmapLocationExplorer = ({ panelParams, panelType, ...props }) => {
  const { baseParams } = useActiveDashboard();

  const missingParams = useMemo(() => {
    return getMissingPanelParams(panelType, panelParams, baseParams);
  }, [panelType, panelParams, baseParams]);

  const locationWithGeoJson = useMemo(() => {
    const location = panelParams[paramNames.filterLocation];
    return {
      ...location,
      geoJson: location ? WktToGeoJson(location.value) : null,
    };
  }, [panelParams]);

  const serializedInsightsParams = useMemo(() => {
    // Force heatmap filter type
    const tempParams = {
      ...panelParams,
      [paramNames.filterType]: filterTypes.heatmap,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, panelParams]);

  const {
    data: panelData,
    isFetching,
    error: panelError,
  } = useGetInsightQuery(serializedInsightsParams, { skip: !missingParams.isValid });

  const heatmapData = useMemo(
    () => (panelError ? [] : getHeatmapItems(panelData?.results?.heatmap || [])),
    [panelData?.results?.heatmap, panelError],
  );

  return (
    <Box height="600px" position="relative">
      {isFetching && (
        <FetchingOverlay />
      )}
      <PanelBodyHeatmap
        data={heatmapData}
        location={locationWithGeoJson}
        isFetching={isFetching}
        defaultZoom={1.5}
        enableCollapsibleControls
        enableSearch
        {...props}
      />
    </Box>
  );
};

const PanelBodyLocationExplorer = ({ panel, setHasMoreExplorePages, isViewingPublicly }) => {
  const {
    panelId,
    params: panelParams,
    panelType,
    settings: panelSettings,
  } = panel;

  return (
    <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
      <HeatmapLocationExplorer
        panelType={panelType}
        panelParams={panelParams}
        panelId={panelId}
        panelSettings={panelSettings}
        isViewingPublicly={isViewingPublicly}
      />
      <Box flex="1" position="relative" overflow="auto" marginTop={2}>
        <PanelBodyExplore
          panel={panel}
          setHasMoreExplorePages={setHasMoreExplorePages}
          isLocationExplorer
        />
      </Box>
    </Box>
  );
};

export default PanelBodyLocationExplorer;
