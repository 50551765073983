import * as Sentry from '@sentry/react';

import permissionTags, { permissionTagsHierarchy } from '@/constants/permissionTags';
import { useAppSelector } from '@/store';
import arrayToBitMap from '@/utils/arrayToBitMap';

const getHighestRole = ({ identity, dashboard }): typeof permissionTags[keyof typeof permissionTags] | undefined => {
  if (!identity || !dashboard) {
    return undefined;
  }

  const userPermissionTags = identity.calculatedPermissionTagIds || [];

  if (userPermissionTags.includes(permissionTags.roleSuperuser)) {
    return permissionTags.roleSuperuser;
  }

  const parentIdentitiesIds = identity.hierarchy.map((il) => il.childIdentityId) || [];
  const parentIdentitiesIdsBitMap = arrayToBitMap(parentIdentitiesIds);

  const dashboardPermissionTags = (dashboard.identityLinks || dashboard.identities || []).reduce((acc, il) => {
    if (il.identityId === identity.identityId || parentIdentitiesIdsBitMap[il.identityId]) {
      acc.push(...il.calculatedPermissionTagIds);
    }
    return acc;
  }, []);

  return permissionTagsHierarchy.find((role) => dashboardPermissionTags.includes(role));
};

const useDashboardRole = (dashboardId: string): typeof permissionTags[keyof typeof permissionTags] | undefined => {
  const { identity } = useAppSelector((state) => state.app);
  const dashboard = useAppSelector((state) => state.dashboards.items[dashboardId]);

  const highestRole = getHighestRole({ identity, dashboard });

  if (!highestRole) {
    Sentry.captureMessage('No role found for user', {
      extra: {
        identityId: identity?.identityId,
        dashboardId,
        identityLinkIds: dashboard?.identityLinks.map((il) => il.identityId),
      },
    });
  }

  return highestRole;
};

export default useDashboardRole;
