import { urnsToSubcategory } from '@qloo/categories';
import isEqual from 'lodash.isequal';

import { paramNames } from '@/constants';
import panelTypes from '@/constants/panelTypes.js';
import { PanelSuggestion } from '@/screens/Dashboard/DashboardPanel/PanelBodySuggestions/helpers/types';
import { BaseParams } from '@/store/slices/dashboardSliceTypes';

import filterTypes from '../../../../../constants/filterTypes';

export const extractCategories = (entities: any[]): string[] => {
  return entities
    .map((entity) =>
      entity.types?.find((type: string) => type.startsWith('urn:entity:')))
    .filter(Boolean);
};

export const getCategoryValidation = (categories: string[]) => {
  const uniqueCategories = new Set(categories);

  return {
    isSingleCategory: uniqueCategories.size === 1,
    categoryForSuggestions: categories[0],
  };
};

export const formatCategoryName = (category: string) => {
  if (!category) return null;
  const formatted = String(urnsToSubcategory(category));
  return formatted.charAt(0).toUpperCase() + formatted.slice(1);
};

export const generateTitle = (
  baseTitle: string,
  entityName: string,
  location: any,
  formattedCategory: string,
  demographicsLabels: string[],
) => {
  let title = baseTitle;
  if (entityName) title = title.replace('{entity}', entityName);
  if (location) title = title.replace('{location}', location.label);
  if (formattedCategory) title = title.replace('{category}', formattedCategory);
  if (demographicsLabels.length > 0) title = title.replace('{demographics}', demographicsLabels.join(', '));
  return title;
};

export const getPanelSuggestions = (
  entityName: string,
  location: any,
  demographicsLabels: string[],
  isQlooUser?: boolean,
  isSingleCategory?: boolean,
): PanelSuggestion[] => {
  const isValidateAllInfo = !!(location && demographicsLabels.length > 0 && entityName);
  const isValidateIfHaveAllInfo = !!(!location && demographicsLabels.length < 1 && !entityName);
  const isValidateOnlyEntity = !!(entityName && demographicsLabels.length < 1 && !location);

  return [
    {
      id: 1,
      title: 'Compare different Books interests for Hobbies and Interests',
      panelType: panelTypes.table,
      condition: isValidateIfHaveAllInfo,
      additionalParams: {
        [paramNames.filterType]: filterTypes.book,
        [paramNames.audiences]: ['hobbies_and_interests'],
      },
      score: [],
    },
    {
      id: 2,
      title: 'Compare different movie interests for Global Issues',
      panelType: panelTypes.table,
      condition: isValidateIfHaveAllInfo,
      additionalParams: {
        [paramNames.filterType]: filterTypes.movie,
        [paramNames.audiences]: ['global_issues'],
      },
      score: [],
    },
    {
      id: 3,
      title: 'Compare {entity} with other {category}',
      panelType: panelTypes.table,
      condition: !!(entityName && location && demographicsLabels.length < 1 && !isSingleCategory),
      score: [paramNames.omnisearch, paramNames.signalLocation],
    },
    {
      id: 4,
      title: 'Research competitors to {category}',
      panelType: panelTypes.table,
      condition: !!(entityName && location && demographicsLabels.length < 1 && isSingleCategory),
      score: [paramNames.omnisearch, paramNames.signalLocation],
    },
    {
      id: 5,
      title: 'High popularity of {entity} among {demographics}',
      panelType: panelTypes.table,
      condition: isValidateAllInfo,
      additionalParams: { [paramNames.filterPopularity]: [0.8, 1] },
      score: [
        paramNames.omnisearch,
        paramNames.signalDemographicsAge,
        paramNames.audiences,
        paramNames.signalDemographicsGender],
    },
    {
      id: 6,
      title: 'Low popularity of {entity} among {demographics}',
      panelType: panelTypes.table,
      condition: isValidateAllInfo,
      additionalParams: { [paramNames.filterPopularity]: [0, 0.4] },
      score: [
        paramNames.omnisearch,
        paramNames.signalDemographicsAge,
        paramNames.audiences,
        paramNames.signalDemographicsGender],
    },
    {
      id: 7,
      title: 'Entity popularity by {demographics} in {location}',
      panelType: panelTypes.table,
      condition: !!(demographicsLabels.length > 0 && location),
      score: [paramNames.audiences, paramNames.signalLocation, paramNames.signalDemographicsGender],
    },
    {
      id: 8,
      title: 'Top interests of {entity} by region {location}',
      panelType: panelTypes.heatmap,
      condition: !!(entityName && location && demographicsLabels.length < 1),
      additionalParams: {
        [paramNames.filterLocation]: location,
        [paramNames.filterType]: filterTypes.heatmap,
      },
      score: [paramNames.omnisearch, paramNames.signalLocation],
    },
    {
      id: 9,
      title: 'Top interests of {entity} by {demographics}',
      panelType: panelTypes.heatmap,
      condition: isValidateAllInfo,
      additionalParams: {
        [paramNames.filterLocation]: location,
        [paramNames.filterType]: filterTypes.heatmap,
      },
      score: [paramNames.omnisearch, paramNames.signalDemographicsAge, paramNames.signalDemographicsGender],
    },
    {
      id: 10,
      title: 'Explore trending categories in {location} based on {entity} and {demographics}',
      panelType: panelTypes.explore,
      condition: isValidateAllInfo,
      score: [paramNames.omnisearch, paramNames.signalLocation, paramNames.signalDemographicsAge, paramNames.audiences],
    },
    {
      id: 11,
      title: 'Explore trending categories based on Women, aged up to 24',
      panelType: panelTypes.explore,
      condition: isValidateIfHaveAllInfo,
      additionalParams: {
        [paramNames.signalDemographicsAge]: ['24_and_younger'],
        [paramNames.signalDemographicsGender]: 'female',
      },
      score: [],
    },
    {
      id: 12,
      title: 'Explore trending categories based on Men, aged 25 to 29',
      panelType: panelTypes.explore,
      condition: isValidateIfHaveAllInfo,
      additionalParams: {
        [paramNames.signalDemographicsAge]: ['25_to_29'],
        [paramNames.signalDemographicsGender]: 'male',
      },
      score: [],
    },
    {
      id: 13,
      title: 'Explore different categories based on {entity}',
      panelType: panelTypes.explore,
      condition: isValidateOnlyEntity,
      score: [paramNames.omnisearch],
    },
    {
      id: 14,
      title: 'Discover categories in {location} related to {entity}',
      panelType: panelTypes.explore,
      condition: !!(location && entityName && (demographicsLabels.length < 1)),
      additionalParams: { [paramNames.signalLocation]: location },
      score: [paramNames.omnisearch, paramNames.signalLocation],
    },
    {
      id: 15,
      title: 'Discover and filter categories by location in {location}',
      panelType: panelTypes.locationExplorer,
      condition: !!(location && !entityName && demographicsLabels.length < 1),
      additionalParams: { [paramNames.filterLocation]: location },
      score: [paramNames.signalLocation],
    },
    {
      id: 16,
      title: 'Analyze demographic trends in {location} based on {entity} and {demographics}',
      panelType: panelTypes.locationExplorer,
      condition: isValidateAllInfo,
      additionalParams: { [paramNames.filterLocation]: location },
      score: [paramNames.omnisearch, paramNames.signalLocation, paramNames.signalDemographicsAge, paramNames.audiences],
    },
    {
      id: 17,
      title: 'Create a panel to write your notes on that dashboard',
      panelType: panelTypes.freeformText,
      condition: isQlooUser,
      score: [],
    },
  ].filter((item) => item.condition);
};

export const calculateScore = (params: string[], baseParams: BaseParams): number => {
  let score = 0;

  if (!params || !baseParams) return score;

  params.forEach((key) => {
    if (baseParams[key]) {
      score += 1;
    }
  });

  if (params.every((key) => baseParams[key])) {
    score += 2;
  }

  return score;
};

const getComparableParams = (params: any, panelType?: string, category?: string, baseQuery?: any) => {
  const comparableParams = { ...params };

  if (panelType === panelTypes.table) {
    comparableParams[paramNames.filterType] = category;
  }

  if (params?.[paramNames.filterLocation] && baseQuery) {
    comparableParams[paramNames.filterLocation] = baseQuery[paramNames.signalLocation] || '';
  }

  return comparableParams;
};

export const panelExists = (dashboard: any, panelType: string, additionalParams: any, category?: string) => {
  return Object.values(dashboard?.panels || {}).some((panel: any) => {
    const existingParams = getComparableParams(panel.params, panel.panelType, category, dashboard.baseParams);
    const newParams = getComparableParams(additionalParams, panelType, category, dashboard.baseParams);

    return (panel.panelType === panelType && isEqual(existingParams, newParams));
  });
};
