import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { bindDialog, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import useToggle from '@/hooks/useToggle';
import FeatureNoAccessTooltip from '@/screens/HomePage/FeatureNoAccessTooltip';
import { useCloneDashboardMutation } from '@/store/apis/dashboardsApi';
import tooltips from '@/utils/tooltips';

const CloneDialog = ({ dashboard, popupState }) => {
  const { dashboardId, title } = dashboard;

  const navigate = useNavigate();
  const [cloneDashboard, { isLoading }] = useCloneDashboardMutation();

  const [dashboardTitle, setDashboardTitle] = useState(`${title} (Copy)`);
  const isShareWithSamePeopleToggle = useToggle();

  const handleTitleChange = (e) => {
    setDashboardTitle(e.currentTarget.value);
  };

  const handleCheckboxChange = (e) => {
    isShareWithSamePeopleToggle.set(e.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const payload = {
        dashboardId,
        shareWithSamePeople: isShareWithSamePeopleToggle.value,
        newTitle: dashboardTitle,
      };

      const { data } = await cloneDashboard(payload);
      navigate(`/dashboards/view/${data.id}`);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <Dialog
      {...bindDialog(popupState)}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>Clone Dashboard</DialogTitle>
      <DialogContent sx={{ paddingTop: '14px !important' }}>
        <TextField
          required
          fullWidth
          autoFocus
          error={!dashboardTitle}
          label="Title"
          value={dashboardTitle}
          onChange={handleTitleChange}
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={isShareWithSamePeopleToggle.value}
              onChange={handleCheckboxChange}
            />
          )}
          label="Share it with the same people"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={popupState.close} color="secondary">Cancel</Button>
        <Button
          onClick={handleSubmit}
          loading={isLoading}
          disabled={!dashboardTitle}
          variant="contained"
        >
          Make a clone
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MenuItemClone = ({
  dashboard, disabled, onToggleKeyDown, ...props
}) => {
  const popupState = usePopupState({ variant: 'dialog', popupId: 'dashboardClone' });

  const handleClick = () => {
    onToggleKeyDown(true);
    popupState.open();
  };

  return (
    <FeatureNoAccessTooltip disabled={disabled}>
      <TooltipInfo title={tooltips.dashboardCard.clone} followCursor>
        <MenuItem onClick={handleClick} disabled={disabled} {...props}>
          <ListItemIcon><FileCopyOutlinedIcon /></ListItemIcon>
          <ListItemText>Clone</ListItemText>
        </MenuItem>
      </TooltipInfo>
      <CloneDialog
        dashboard={dashboard}
        popupState={{
          ...popupState,
          close: () => {
            onToggleKeyDown(false);
            popupState.close();
          },
        }}
      />
    </FeatureNoAccessTooltip>
  );
};

export default MenuItemClone;
