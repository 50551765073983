const personaInsightsRealEstate = ({ userData }) => {
  const { personaCount } = userData;
  const baseTake = Math.max(Math.floor(30 / personaCount), 3); // Base `take` calculation

  return {
    entities: [
      { category: 'artist', params: { take: Math.floor(baseTake / 3) } },
      { category: 'brand', params: { take: baseTake } },
      { category: 'movie', params: { take: Math.floor(baseTake / 2) } },
      { category: 'place', params: { 'filter.location.query': 'abu dhabi', take: baseTake } },
      { category: 'podcast', params: { take: Math.floor(baseTake / 3) } },
      { category: 'tv_show', params: { take: Math.floor(baseTake / 2) } },
    ],
    tags: [
      { category: 'artist', params: { take: Math.floor(baseTake / 3) } },
      { category: 'brand', params: { take: baseTake } },
      { category: 'movie', params: { take: Math.floor(baseTake / 2) } },
      { category: 'place', params: { take: baseTake } },
      { category: 'place', params: { 'filter.tags.types': 'urn:tag:good_for:place', take: 3 } },
      { category: 'podcast', params: { take: Math.floor(baseTake / 3) } },
      { category: 'tv_show', params: { take: Math.floor(baseTake / 2) } },
      { params: { 'filter.tags.types': 'urn:tag:audience:qloo', take: Math.floor(baseTake / 3) } }, // Fixed missing brace
      { params: { 'filter.tags.types': 'urn:tag:lifestyle:qloo', take: Math.floor(baseTake / 3) } }, // Fixed missing brace
    ],
  };
};

export default personaInsightsRealEstate;
