import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button, Menu, MenuItem, Typography,
} from '@mui/material';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useEffect, useState } from 'react';

import permissionTags, { permissionTagDescriptions, permissionTagLabels } from '@/constants/permissionTags';

const VALID_ROLES = [
  permissionTags.roleViewer,
  permissionTags.roleEditor,
  permissionTags.roleSuperuser,
  permissionTags.roleCommenter,
  permissionTags.roleClone,
];

interface Props {
  selectedRole?: string
  viewOnly: boolean
  isLoading: boolean
  onRoleChange?: (role: string) => void
  onDelete?: () => void
}

const RoleSelector = ({
  selectedRole = undefined,
  viewOnly = false,
  isLoading = false,
  onRoleChange = undefined,
  onDelete = undefined,
}: Props) => {
  const [selected, setSelected] = useState(selectedRole || permissionTags.roleViewer);
  const popupState = usePopupState({ variant: 'popover', popupId: 'roleSelector' });

  useEffect(() => {
    if (selectedRole) {
      setSelected(selectedRole);
    }
  }, [selectedRole]);

  const handleRoleChange = (role) => {
    setSelected(role);
    popupState.close();
    onRoleChange(role);
  };

  const handleDelete = () => {
    setSelected(null);
    popupState.close();
    onDelete();
  };

  if (viewOnly || selected === permissionTags.roleOwner) {
    return (
      <Typography variant="body2" mr="32px">{permissionTagLabels[selected]}</Typography>
    );
  }

  return (
    <>
      <Button
        color="secondary"
        endIcon={<KeyboardArrowDownIcon />}
        loading={isLoading}
        loadingPosition="end"
        {...bindTrigger(popupState)}
      >
        {permissionTagLabels[selected]}
      </Button>
      <Menu {...bindMenu(popupState)}>
        {VALID_ROLES.map((role) => (
          <MenuItem key={role} onClick={() => handleRoleChange(role)} selected={selected === role}>
            <Box display="flex" flexDirection="column">
              <Typography variant="body2">{permissionTagLabels[role]}</Typography>
              <Typography variant="caption" color="textSecondary">{permissionTagDescriptions[role]}</Typography>
            </Box>
          </MenuItem>
        ))}
        {!!onDelete && (
          <MenuItem onClick={handleDelete}>
            <Typography variant="body2" color="error">Remove Permissions</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default RoleSelector;
