import * as Sentry from '@sentry/react';
import useHasDashboardPermissions from '@v1/hooks/useHasDashboardPermissions';
import { useVeltClient } from '@veltdev/react';
import {
  createContext, useCallback, useContext, useEffect,
} from 'react';

import permissionTags from '@/constants/permissionTags';
import useToggle from '@/hooks/useToggle';
import { getHash } from '@/utils/format';

const useDashboardVelt = ({ veltClient }) => {
  const isCommentingToggle = useToggle();
  const isVeltInitializedToggle = useToggle(false);

  const init = useCallback(({ dashboardId }) => {
    try {
      veltClient.setDocumentId(dashboardId);
      const liveStateSyncElement = veltClient.getLiveStateSyncElement();
      liveStateSyncElement.enableSingleEditorMode({ customMode: true });
      liveStateSyncElement.enableDefaultSingleEditorUI();
      liveStateSyncElement.singleEditorModeContainerIds(['dashboard']);

      const commentElement = veltClient.getCommentElement();
      commentElement.disableCommentPinHighlighter();
      commentElement.disableTextComments();

      isVeltInitializedToggle.on();
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [veltClient, isVeltInitializedToggle]);

  const getQueryBuilderVeltLocation = useCallback(({ dashboardId }) => {
    const additional = {
      area: 'query-builder',
      dashboardId,
    };

    const locationString = JSON.stringify(additional);
    const veltLocationId = getHash(locationString);

    const location = {
      id: veltLocationId,
      locationName: 'Query Builder',
    };

    return location;
  }, []);

  const getPanelVeltLocation = useCallback(({ panelId }) => {
    const additional = {
      panelId,
    };

    const locationString = JSON.stringify(additional);
    const veltLocationId = getHash(locationString);
    const locationName = panelId;

    const location = {
      id: veltLocationId,
      locationName,
      ...additional,
    };

    return location;
  }, []);

  const onLayoutChangeStart = useCallback(() => {
    if (veltClient) {
      const liveStateSyncElement = veltClient.getLiveStateSyncElement();
      liveStateSyncElement.setUserAsEditor();
    }
  }, [veltClient]);

  const onLayoutChangeEnd = useCallback(() => {
    if (veltClient) {
      const liveStateSyncElement = veltClient.getLiveStateSyncElement();
      liveStateSyncElement.resetUserAccess();
    }
  }, [veltClient]);

  const setVeltLocation = useCallback((location) => {
    if (veltClient) {
      veltClient.setLocation(location, true);
    }
  }, [veltClient]);

  const setReadOnly = useCallback((readOnly) => {
    if (veltClient) {
      const commentElement = veltClient.getCommentElement();
      if (readOnly) {
        commentElement.enableReadOnly();
      } else {
        commentElement.disableReadOnly();
      }
    }
  }, [veltClient]);

  return {
    init,
    isCommentingToggle,
    isVeltInitialized: isVeltInitializedToggle.value,
    getQueryBuilderVeltLocation,
    getPanelVeltLocation,
    onLayoutChangeEnd,
    onLayoutChangeStart,
    setVeltLocation,
    setReadOnly,
    veltClient,
  };
};

const DashboardVeltContext = createContext(null);

export const useDashboardVeltContext = () => useContext(DashboardVeltContext);

const DashboardVeltProvider = ({ children, dashboard }) => {
  const { client: veltClient } = useVeltClient();
  const { dashboardId } = dashboard;
  const dashboardVelt = useDashboardVelt({ veltClient });
  const isEditor = useHasDashboardPermissions(dashboard.id, [permissionTags.roleEditor]);

  const { init, onLayoutChangeEnd, setReadOnly } = dashboardVelt;

  useEffect(() => {
    if (veltClient) {
      init({ dashboardId });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [veltClient]);

  useEffect(() => () => {
    onLayoutChangeEnd();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setReadOnly(!isEditor);
  }, [isEditor, setReadOnly, veltClient]);

  return (
    <DashboardVeltContext.Provider value={dashboardVelt}>
      {children}
    </DashboardVeltContext.Provider>
  );
};

export default DashboardVeltProvider;
