type KeyedObject<T> = Record<string, T>;

const keyBy = <T extends Record<string, any>>(
  array: T[],
  key: keyof T,
): KeyedObject<T> =>
    array.reduce((obj, item) => ({
      ...obj,
      [item[key]]: item,
    }), {});

export default keyBy;
