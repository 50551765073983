import flatten from 'lodash.flatten';

import tagTypesBySelectedCategory from '@/constants/tagTypesBySelectedCategory';

export const fetchEntities = ({ baseParams, entitiesRequests, wrappedFetchInsightsEntities }) => {
  return Promise.all(
    entitiesRequests.map((entitiesRequest) => wrappedFetchInsightsEntities({ baseParams, ...entitiesRequest })
      .then((result) => result?.results?.entities || [])),
  ).then(flatten);
};

const getFlattenedTagsRequests = (tagsRequests) => tagsRequests.reduce((arr, tagsRequest) => {
  if (tagsRequest.fetchByTagTypes) {
    const { category } = tagsRequest;
    const categoryUrn = `urn:entity:${category}`;
    const tagTypeUrns = tagTypesBySelectedCategory[categoryUrn] || [];
    const tagTypes = tagTypeUrns.map((tagTypeUrn) => {
      const parts = tagTypeUrn.split(':');
      return parts[2];
    });

    const tagTypetagRequests = tagTypes.map((tagType) => ({ ...tagsRequest, tagType }));
    return [...arr, ...tagTypetagRequests];
  }
  return [...arr, tagsRequest];
}, []);

const getTagType = (tagsRequest) => {
  if (tagsRequest.tagType) {
    return tagsRequest.tagType;
  }

  const tagType = tagsRequest.params?.['filter.tags.types'];
  if (tagType) {
    const parts = tagType.split(':');
    if (parts.length >= 3) {
      return parts[2];
    }
  }

  return '';
};

export const fetchTags = ({ baseParams, tagsRequests, wrappedFetchInsightsTags }) => {
  const flattenedTagsRequests = getFlattenedTagsRequests(tagsRequests);

  return Promise.all(
    flattenedTagsRequests.map((tagsRequest) => wrappedFetchInsightsTags({ baseParams, ...tagsRequest })
      .then((result) => result?.results?.tags || []).then(
        (tags) => tags.map((tag) => {
          const tagType = getTagType(tagsRequest);
          return {
            ...tag,
            id: tag.tag_id,
            tagId: tag.tag_id,
            category: tagsRequest.category,
            tagType,
          };
        }),
      )),
  ).then(flatten);
};
