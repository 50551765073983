import './CheckboxList.scss';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import React, { useMemo } from 'react';

// ensuring backwards compatibility when we had single value fields
const getValue = (value) => {
  if (value) {
    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  }

  return [];
};

const getBitmap = (value) => (value || []).reduce((obj, item) => ({ ...obj, [item]: 1 }), {});

const CheckboxList = ({
  disabled, onChange, options, ...props
}) => {
  const value = useMemo(() => getValue(props.value), [props.value]);

  const onChangeHandler = (optionValue) => (e) => {
    const { checked } = e.target;
    const nextValue = value.filter((valueItem) => valueItem !== optionValue);
    if (checked) {
      nextValue.push(optionValue);
    }

    onChange(nextValue);
  };

  const disabledBitmap = useMemo(() => getBitmap(disabled), [disabled]);
  const valueBitmap = useMemo(() => getBitmap(value), [value]);

  return (
    <FormGroup className="checkbox-list">
      {options.map((option) => {
        const isLegacy = option?.legacy;
        const hasValue = Boolean(valueBitmap[option.value]);

        // Determine whether the FormControlLabel should be rendered:
        // Regular inputs (checked or unchecked) should always be rendered
        // Legacy inputs should not be displayed if they are not checked
        const shouldRenderFormControlLabel = !isLegacy || hasValue;

        // Determine whether the FormControlLabel should be disabled
        // Regular inputs should not be disabled by default
        const shouldDisableCheckbox = isLegacy && hasValue;

        return (
          shouldRenderFormControlLabel ? (
            <FormControlLabel
              key={option.value}
              checked={valueBitmap[option.value]}
              control={(
                <Checkbox
                  checked={Boolean(disabledBitmap[option.value] || valueBitmap[option.value])}
                  disabled={Boolean(disabledBitmap[option.value]) || shouldDisableCheckbox}
                  onChange={onChangeHandler(option.value)}
                  size="small"
                />
              )}
              label={`${option.label}${option.legacy ? ' (Legacy)' : ''}`}
            />
          ) : null
        );
      })}
    </FormGroup>
  );
};

export default CheckboxList;
