import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { useLazyIdentityGetQuery, useLazyIdentitySubordinatesGetQuery } from '@/store/apis/identityApi';
import { setIdentity, setUser } from '@/store/slices/appSlice';

import findOrganizationId from '../lib/findOrganizationId';
import logout from '../lib/logout';
import userInformation from '../lib/userInformation';

const useIdentityGet = () => {
  const dispatch = useAppDispatch();
  const { identity } = useAppSelector((state) => state.app);

  const [triggerIdentityGetQuery] = useLazyIdentityGetQuery();
  const [triggerIdentitySubordinates] = useLazyIdentitySubordinatesGetQuery();

  useEffect(() => {
    const init = async () => {
      const userInfo = userInformation();
      if (!userInfo?.id) {
        logout();
        return;
      }

      const identityFound = await triggerIdentityGetQuery({ supertokensExternalId: userInfo.id }).unwrap();
      if (!identityFound) {
        logout();
        return;
      }

      const organizationId = findOrganizationId(identityFound);
      triggerIdentitySubordinates({ identityId: organizationId });

      const nextUser = {
        userId: userInfo.id,
        name: identityFound.fullName,
        email: userInfo.email,
        identityId: identityFound.identityId,
        organizationId,
      };

      dispatch(setUser(nextUser));
      dispatch(setIdentity(identityFound));
    };

    init();
  }, [dispatch, triggerIdentityGetQuery, triggerIdentitySubordinates]);

  return {
    identity,
  };
};

export default useIdentityGet;
