import clsx from 'clsx';
import React from 'react';

import DashboardPermissionControl from '@/components/DashboardPermissionControl';
import permissionControlVariants from '@/constants/permissionControlVariants';
import permissionTags from '@/constants/permissionTags';
import getPanelHeaderTitle from '@/utils/getPanelHeaderTitle';

import { useDashboardVeltContext } from '../DashboardVeltProvider';
import styles from './DashboardPanel.module.scss';
import PanelContextMenu from './PanelContextMenu';
import PanelParams from './PanelParams';

const PanelHeader = ({
  panel, panelId, panelParams, panelTitle, panelType, serializedInsightsParams, isViewingPublicly = false,
}) => {
  const dashboardVelt = useDashboardVeltContext();
  const { isCommentingToggle } = dashboardVelt;

  const { settings } = panel;

  return (
    <div className={styles.panelHeaderWrapper}>
      <div className={styles.panelHeader}>
        <div
          className={clsx({
            'panel-drag-handle': !isCommentingToggle.value,
            [styles.panelHeaderLeft]: true,
          })}
          data-velt-sync-access="true"
        >
          <span>{panelTitle || getPanelHeaderTitle({ panelType, settings })}</span>
        </div>

        {!isViewingPublicly && (
          <DashboardPermissionControl
            permissionsRequired={[permissionTags.roleEditor]}
            variant={permissionControlVariants.tooltip}
          >
            <div className={styles.panelHeaderRight}>
              <PanelContextMenu
                panelId={panelId}
                panelTitle={panelTitle}
                serializedInsightsParams={serializedInsightsParams}
                panelType={panelType}
              />
            </div>
          </DashboardPermissionControl>
        )}
      </div>
      <PanelParams
        panelType={panelType}
        panelId={panelId}
        panelParams={panelParams}
        isViewingPublicly={isViewingPublicly}
      />
    </div>
  );
};

export default PanelHeader;
