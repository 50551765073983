import useHasDashboardPermissions from '@v1/hooks/useHasDashboardPermissions';
import { PropsWithChildren } from 'react';

import permissionControlVariants from '@/constants/permissionControlVariants';
import useActiveDashboard from '@/hooks/useActiveDashboard';

import Tooltip from '../Tooltip';
import styles from './DashboardPermissionControl.module.scss';

interface Props extends PropsWithChildren {
  variant?: string
  permissionsRequired: string[]
  dashboardId?: string
}

const DashboardPermissionControl = ({
  variant,
  permissionsRequired,
  children,
  dashboardId = undefined,
}: Props) => {
  const activeDashboard = useActiveDashboard();

  const isAuthorized = useHasDashboardPermissions(dashboardId || activeDashboard.id.toString(), permissionsRequired);

  if (isAuthorized) {
    return children;
  }

  if (variant === permissionControlVariants.hidden) {
    return null;
  }

  if (variant === permissionControlVariants.disabled) {
    return (
      <div className={styles.disabled}>
        <div>
          {children}
        </div>
      </div>
    );
  }

  if (variant === permissionControlVariants.tooltip) {
    return (
      <Tooltip
        arrow
        followCursor
        placement="top"
        title="You don't have access to this feature"
      >
        <div className={styles.disabled}>
          <div>
            {children}
          </div>
        </div>
      </Tooltip>
    );
  }

  if (variant === permissionControlVariants.display) {
    return (
      <div className={styles.display}>
        {children}
      </div>
    );
  }

  return null;
};

export default DashboardPermissionControl;
