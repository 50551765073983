const getDashboardCountAndFirstId = (dashboardIds, items) => {
  if (!Array.isArray(dashboardIds) || typeof items !== 'object' || items === null) {
    return { count: 0, firstId: null };
  }

  let firstId = null;

  const count = dashboardIds.reduce((acc, id) => {
    if (id && items?.[id]) {
      if (!firstId) {
        firstId = id;
      }
      return acc + 1;
    }
    return acc;
  }, 0);

  return { count, firstId };
};

export default getDashboardCountAndFirstId;
