import { Identity } from '@/store/slices/appSlice';

interface Props {
  organizationIdentityId: string
  userIdentity: Identity
}

const getIsUserInOrganization = ({ organizationIdentityId, userIdentity }: Props) => Boolean(
  (userIdentity.hierarchy || [])
    .find((hierarchyItem) => hierarchyItem.childIdentityId === organizationIdentityId),
);

export default getIsUserInOrganization;
