import React, { useState } from 'react';
import { createPortal } from 'react-dom';

function usePortalDialog() {
  const [dialogContent, setDialogContent] = useState<React.ReactNode>(null);

  const openDialog = (content: React.ReactNode) => setDialogContent(content);
  const closeDialog = () => setDialogContent(null);

  const portal = dialogContent
    ? createPortal(dialogContent, document.body)
    : null;

  return { openDialog, closeDialog, portal };
}

export default usePortalDialog;
