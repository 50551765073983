import * as Sentry from '@sentry/react';
import Cookie from 'js-cookie';

import jwtParse from './jwtParse';

const userInformation = () => {
  const jwt = Cookie.get('st-access-token');
  if (!jwt) {
    console.warn('User has not authenticated.');
    return {};
  }
  let jwtDecoded;

  try {
    jwtDecoded = jwtParse(jwt);
  } catch (e) {
    Sentry.captureException(e);
    // NOTE: We should never see this because a user must have had a valid
    // token to get here.
    // eslint-disable-next-line max-len
    Sentry.captureMessage(`The jwt token provided was invalid. This should not be possible because the user must be logged in via the jwt token to gain access to the userInformation react function component. The parsing error was: ${e.message}`);
    return {};
  }

  if (!jwtDecoded?.user) {
    // eslint-disable-next-line max-len
    Sentry.captureMessage('The jwt token did not contain a user object when one should have existed. userInformation is returning an empty user object.');
  }

  return jwtDecoded?.user || {};
};

export default userInformation;
