import clsx from 'clsx';
import React from 'react';

import styles from './Locker.module.scss';

interface Props {
  isLocked: boolean;
  lockedBy?: string
}

const LockerOverlay = ({ isLocked, lockedBy }: Props) => {
  return (
    <div
      className={clsx({
        [styles.overlay]: true,
        [styles.overlayLocked]: isLocked,
      })}
    >
      {lockedBy ? `${lockedBy} is editing` : null}
    </div>
  );
};

export default LockerOverlay;
