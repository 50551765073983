import { Alert, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PermissionsValidatorWrapper from '@/components/PermissionsValidatorWrapper';
import { DashboardRole } from '@/constants/permissions/dashboard';
import { useAppSelector } from '@/store';
import getDashboardCountAndFirstId from '@/utils/getDashboardCountAndFirstId';

import DashboardCreateButton from '../Dashboard/DashboardTabs/DashboardCreateButton';
import DashboardOpenTabs from './DashboardOpenTabs';
import DashboardSearchInput from './DashboardSearchInput';

interface Props {
  search?: string;
  setSearch: (search: string) => void
}

const HomePageTopBar = ({ search, setSearch }: Props) => {
  const navigate = useNavigate();

  const { dashboardIds } = useAppSelector((state) => state.dashboardTabs);
  const { items } = useAppSelector((state) => state.dashboards);

  const [errorWhenCreatingDashboard, setErrorWhenCreatingDashboard] = useState(false);

  const { count: openDashboardCount, firstId } = getDashboardCountAndFirstId(
    dashboardIds,
    items,
  );

  const handleRedirect = (id: string) => {
    navigate(`/dashboards/view${id ? `/${id}` : ''}/`);
  };

  useEffect(() => {
    let timeoutId;
    if (errorWhenCreatingDashboard) {
      timeoutId = setTimeout(() => {
        setErrorWhenCreatingDashboard(false);
      }, 3000);
    }
    return () => clearTimeout(timeoutId);
  }, [errorWhenCreatingDashboard]);

  return (
    <>
      {errorWhenCreatingDashboard && (
        <Alert severity="error">An error occurred when trying to create a dashboard.</Alert>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={4}
        px={5}
        py={3}
        width="100%"
        bgcolor="background.paper"
      >
        <Typography variant="h6" fontWeight={600}>
          Dashboards
        </Typography>
        <DashboardSearchInput
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <PermissionsValidatorWrapper
          component={DashboardCreateButton}
          permission={DashboardRole.CREATE}
          additionalProps={{ setCreateError: setErrorWhenCreatingDashboard }}
        />
        <PermissionsValidatorWrapper
          component={DashboardOpenTabs}
          permission={DashboardRole.VIEW}
          additionalProps={{
            onClick: () => handleRedirect(firstId),
            openDashboardCount,
          }}
        />
      </Box>
    </>
  );
};

export default HomePageTopBar;
