import { useEffect } from 'react';

import { useAppSelector } from '@/store';
import { useLazyGetDashboardQuery } from '@/store/apis/dashboardsApi';

const useDashboard = (id: number) => {
  const [triggerGetDashboardQuery] = useLazyGetDashboardQuery();

  const dashboard = useAppSelector((state) => state.dashboards.items[id]);

  useEffect(() => {
    if (!dashboard && id) {
      triggerGetDashboardQuery({ id });
    }
  }, [dashboard, id, triggerGetDashboardQuery]);

  return dashboard;
};

export default useDashboard;
