import { fieldByParamName } from '@/constants';

import FieldRenderer from '../Field/FieldRenderer';
import ModalResetButton from '../ModalResetButton';
import styles from './FieldsModal.module.scss';

const SingleParamBody = ({
  paramName,
  baseParams,
  params,
  paramNames,
  onChange,
  onReset,
  showResetButton,
  resetPadding,
}) => (
  <div className={styles.bodySingle} style={resetPadding ? { padding: 0 } : {}}>
    <FieldRenderer
      disabled={baseParams[paramName]}
      field={fieldByParamName[paramName]}
      onChange={onChange(paramName)}
      params={params}
      value={params[paramName]}
      onReset={onReset}
    />
    {showResetButton && (
      <ModalResetButton
        onReset={onReset}
        paramNames={paramNames}
        params={params}
      />
    )}
  </div>
);

export default SingleParamBody;
