import { MRT_Cell as Cell } from 'material-react-table';
import React from 'react';

import AffinityBar from '@/components/AffinityBar';
import EntityImage from '@/components/EntityImage';
import EntityTrends from '@/components/EntityTrends';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import { filterTypes } from '@/constants';
import { EntityTypes } from '@/entity/EntityTypes';
import tooltips from '@/utils/tooltips';

import styles from '../../DashboardPanel.module.scss';
import { ColumnConfig } from './ColumnConfig';
import columnDefinitions from './columnDefinitions';

type CellType = Cell<EntityTypes.Entity>;
type FilterType = keyof typeof filterTypes;

const createTooltipHeader = (tooltipKey: string, label: string) => () => (
  <TooltipInfo title={tooltips.dashboard.table[tooltipKey]}>
    {label}
  </TooltipInfo>
);

const getNestedValue = (obj: Record<string, any>, path: string) => path.split('.').reduce((acc, key) => acc?.[key], obj);

const createColumn = ({
  fnAcc,
  acc,
  toolTipAcc,
  header,
  size = 90,
}: ColumnConfig) => ({
  ...(fnAcc
    ? {
      accessorFn: (row: EntityTypes.Entity) => getNestedValue(row?.properties, fnAcc) ?? null,
    }
    : { accessorKey: acc }),
  ...(toolTipAcc && { Header: createTooltipHeader(toolTipAcc, header) }),
  header,
  size,
});

export const nameColumn = createColumn({
  acc: 'name',
  header: 'Name',
  toolTipAcc: 'name',
  size: 200,
});

export const affinityColumn = {
  accessorFn: (row: EntityTypes.Entity) => row?.query?.affinity ?? null,
  header: 'Affinity',
  Cell: ({ renderedCellValue }) => <AffinityBar value={renderedCellValue} />,
  Header: createTooltipHeader('affinity', 'Affinity'),
  size: 140,
};

export const rankColumn = {
  accessorKey: 'rank',
  header: 'N°',
  Header: createTooltipHeader('rank', 'N°'),
  Cell: ({ row }: CellType) => (
    <div className={styles.tableRank}>
      <EntityImage entity={row.original} />
      {!!row.original.rank && (
        <div className={styles.tableRankNumber}>
          <div className={styles.tableRankNumberText}>{row.original.rank}</div>
        </div>
      )}
    </div>
  ),
  size: 70,
};

const popularityColumn = {
  accessorKey: 'popularity',
  header: 'Popularity',
  Header: createTooltipHeader('popularity', 'Popularity'),
  Cell: ({ row }: CellType) => (row.original.popularity === 1
    ? 1
    : parseFloat(row.original.popularity.toFixed(6))),
  size: 85,
};

const trendsColumn = {
  accessorFn: () => null,
  header: 'Trend: Percentile (6m avg)',
  Header: createTooltipHeader('trend', 'Trend: Percentile (6m avg)'),
  Cell: ({ row }: CellType) => (
    <EntityTrends
      key={row.original.entity_id}
      entityId={row.original.entity_id}
    />
  ),
  size: 180,
};

const productsColumn = {
  accessorFn: (row: EntityTypes.Entity) => {
    const products = row.properties.products || [];
    return products.join(', ');
  },
  header: 'Products',
  Header: createTooltipHeader('products', 'Products'),
  Cell: ({ row }: CellType) => row.original.properties?.products?.join(', '),
};

const closedColumn = {
  accessorFn: (row: EntityTypes.Entity) => row.properties.is_closed ?? null,
  header: 'Closed',
  Cell: ({ row }: CellType) => (
    <p>{row.original.properties.is_closed ? 'Yes' : 'No'}</p>
  ),
  size: 60,
};

const commonColumns = [
  rankColumn,
  nameColumn,
  affinityColumn,
  trendsColumn,
  popularityColumn,
];

const generateColumnsFromConfig = (filterType: FilterType) => [
  ...commonColumns,
  ...(columnDefinitions[filterType] || []).map(
    ({
      fnAcc, acc, toolTipAcc, header, size,
    }) => createColumn({
      fnAcc,
      acc,
      toolTipAcc,
      header,
      size,
    }),
  ),
];

const brandColumns = [
  ...generateColumnsFromConfig(filterTypes.brand as FilterType),
  productsColumn,
];

const placeColumns = [
  ...generateColumnsFromConfig(filterTypes.place as FilterType),
  closedColumn,
];

const tagColumns = [nameColumn, affinityColumn];

const columnConfigMap = {
  [filterTypes.brand]: brandColumns,
  [filterTypes.place]: placeColumns,
  [filterTypes.tag]: tagColumns,
};

const getValidColumnConfig = (filterType: FilterType) =>
  columnConfigMap[filterType] || generateColumnsFromConfig(filterType);

const getColumns = (filterType: FilterType) => getValidColumnConfig(filterType) || commonColumns;

export default getColumns;
