import { useAppDispatch, useAppSelector } from '@/store';
import { clearOwnership, setOwnership } from '@/store/slices/appSlice';

import useLiveState from '../screens/Dashboard/hooks/useLiveState';

const useOwnership = (key: string) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.app);
  const value = useAppSelector((state) => state.app.ownership[key]);

  const { emit } = useLiveState({
    liveStateDataId: key,
    onChange: ({ userId }) => {
      dispatch(setOwnership({ key, userId }));
    },
  });

  const set = () => {
    const { userId } = user;
    emit({ userId });
    dispatch(setOwnership({ key, userId }));
  };

  const clear = () => {
    emit({ userId: null });
    dispatch(clearOwnership({ key }));
  };

  return {
    value,
    set,
    clear,
  };
};

export default useOwnership;
