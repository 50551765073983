import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const locateWorkerApi = createApi({
  reducerPath: 'locateWorkerApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://locate.qloo.workers.dev/' }),
  tagTypes: ['LocateWorker'],
  endpoints: (builder) => ({
    getBiasLocation: builder.query({
      query: () => '',

    }),
  }),
});

export const {
  useGetBiasLocationQuery,
} = locateWorkerApi;
