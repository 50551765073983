import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { EntityTypes } from '@/entity/EntityTypes';
import useToggle from '@/hooks/useToggle';

import EntityImageLogo from './EntityImageLogo';

interface EntityImageProps {
  className?: string;
  entity?: EntityTypes.Entity;
  placeholder?: string;
}

const getImageUrl = (entity: EntityTypes.Entity): string | undefined => {
  if (entity.properties?.images && entity.properties.images.length) {
    return entity.properties.images[0].url;
  }
  return entity.properties?.image?.url;
};

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'imageUrl',
})<{ imageUrl: string }>(({ imageUrl }) => ({
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}));

const StyledImg = styled('img')({ display: 'none' });

const EntityImage: React.FC<EntityImageProps> = ({ className, entity, placeholder }) => {
  const hasErrorToggle = useToggle(false);
  const isWhiteSquareToggle = useToggle(false);
  const imageUrl = getImageUrl(entity);
  const isLoadingToggle = useToggle(true);
  const isLoadingSourceImageToggle = useToggle();
  const shouldUseSourceUrlToggle = useToggle(false);
  const sourceImageUrl = entity.properties?.image?.source_url;

  const onLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    isLoadingToggle.off();
    const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;

    if (naturalWidth === 30 && naturalHeight === 30) {
      isWhiteSquareToggle.on();
      hasErrorToggle.on();
    }
  };

  const onError = () => {
    isLoadingToggle.off();
    hasErrorToggle.on();
    if (sourceImageUrl) {
      isLoadingSourceImageToggle.on();
    }
  };

  const onSourceImageLoad = () => {
    shouldUseSourceUrlToggle.on();
    isLoadingSourceImageToggle.off();
  };

  const getBackgroundImageUrl = (): string => {
    if (hasErrorToggle.value || isWhiteSquareToggle.value) {
      return placeholder;
    }

    if (shouldUseSourceUrlToggle.value && sourceImageUrl) {
      return sourceImageUrl;
    }

    return imageUrl || '';
  };

  return (
    <StyledBox
      className={className}
      imageUrl={getBackgroundImageUrl()}
    >
      <EntityImageLogo
        hasError={hasErrorToggle.value || isWhiteSquareToggle.value || !imageUrl}
        isLoading={isLoadingToggle.value || isLoadingSourceImageToggle.value}
      />
      {imageUrl && !hasErrorToggle.value && (
        <StyledImg
          alt={entity.name}
          onError={onError}
          onLoad={onLoad}
          src={imageUrl}

        />
      )}
      {hasErrorToggle.value && sourceImageUrl && (
        <StyledImg
          alt={entity.name}
          onError={isLoadingSourceImageToggle.off}
          onLoad={onSourceImageLoad}
          src={sourceImageUrl}
        />
      )}
    </StyledBox>
  );
};

export default EntityImage;
