import {
  Box, Card, CardMedia, Typography,
} from '@mui/material';
import useDashboardRole from '@v1/hooks/useDashboardRole';
import useHasDashboardPermissions from '@v1/hooks/useHasDashboardPermissions';
import React, { useMemo } from 'react';

import ErrorBoundary from '@/components/ErrorBoundary';
import Identity from '@/components/Identity';
import Table from '@/components/Table';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import permissionTags from '@/constants/permissionTags';
import getTitle from '@/screens/Dashboard/helpers/getTitle';
import tooltips from '@/utils/tooltips';

import { ReactComponent as Logo } from '../../../../../../src/components/LogoSm.svg';
import DashboardUserChipAvatar from '../DashboardChipAvatar/DashboardUserChipAvatar';
import DashboardUserChipAvatarGroup from '../DashboardChipAvatar/DashboardUserChipAvatarGroup';
import DashboardContextMenu from '../DashboardContextMenu';
import { formatRangeDate } from '../helpers/dashboardHelpers';
import PermissionChips from '../PermissionChips';
import TableSkeleton from '../Skeleton/DashboardTableSkeleton';
import styles from './DashboardTable.module.scss';

const BaseDashboardTable = ({
  data,
  onRedirect,
  pageInfo,
  paginationTable,
  onPageChange,
  isLoadingPagination,
}) => {
  const columns = useMemo(() => [
    {
      accessorKey: 'thumbnail',
      header: 'View',
      Header: () => <TooltipInfo title={tooltips.dashboardTable.headers.view}>View</TooltipInfo>,
      Cell: ({ row }) => (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%">
          <Card sx={{ boxShadow: 'var(--Paper-shadow) !important', width: '100%' }}>
            {row?.original?.thumbnail ? (
              <CardMedia
                image={row.original.thumbnail}
                title={row.original.title}
              />
            ) : (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Logo className="logoSvgTable" />
              </Box>
            )}
          </Card>
        </Box>
      ),
      size: 155,
      grow: false,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: 'title',
      header: 'Title',
      Header: () => <TooltipInfo title={tooltips.dashboardTable.headers.title}>Title</TooltipInfo>,
      Cell: ({ row }) => (
        <Typography variant="subtitle2" className={styles.titleText}>
          {getTitle(row.original)}
        </Typography>
      ),
    },
    {
      accessorKey: 'summary',
      header: 'Summary',
      Header: () => <TooltipInfo title={tooltips.dashboardTable.headers.summary}>Summary</TooltipInfo>,
      Cell: () => (
        <Box>
          <Typography variant="subtitle2" className={styles.summaryText}>
            No dashboard description.
          </Typography>
        </Box>
      ),
    },
    {
      accessorFn: (row) => row?.history?.[0]?.effectiveRange,
      header: 'Created at',
      Header: () => <TooltipInfo title={tooltips.dashboardTable.headers.createdAt}>Created at</TooltipInfo>,
      size: 116,
      maxSize: 116,
      minSize: 116,
      grow: false,
      Cell: ({ row }) => {
        const createdAt = row?.original?.history?.[0]?.effectiveRange;
        return (
          <Typography variant="subtitle2" className={styles.createdAtText}>
            {createdAt && formatRangeDate(createdAt)}
          </Typography>
        );
      },
    },
    {
      accessorFn: (row) => (row?.history?.length ? row.history[row.history.length - 1]?.effectiveRange : null),
      header: 'Updated at',
      Header: () => <TooltipInfo title={tooltips.dashboardTable.headers.updatedAt}>Updated at</TooltipInfo>,
      size: 116,
      grow: false,
      Cell: ({ row }) => {
        const updatedAt = row?.original?.history?.length
          ? row.original.history[row.original.history.length - 1]?.effectiveRange
          : null;

        return (
          <Typography variant="subtitle2" className={styles.updatedAtText}>
            {updatedAt && formatRangeDate(updatedAt)}
          </Typography>
        );
      },
    },
    {
      accessorFn: (row) => (row?.history?.length ? row.history[row.history.length - 1] : null),
      Header: () => <TooltipInfo title={tooltips.dashboardTable.headers.lastEditedBy}>Last edited by</TooltipInfo>,
      header: 'Last edited by',
      Cell: ({ row }) => {
        const lastHistoryItem = row?.original?.history?.length ? row.original.history[row.original.history.length - 1] : {};

        return lastHistoryItem && (
          <Identity identityId={lastHistoryItem.identityId}>
            {({ identity }) => (
              <DashboardUserChipAvatar
                userId={identity?.identityId}
                label={identity?.firstName || identity?.fullName}
                email={identity?.loginEmailAddress}
                isShowLabel
              />
            )}
          </Identity>
        );
      },
    },
    {
      accessorKey: 'identities',
      header: 'Shared with',
      Header: () => <TooltipInfo title={tooltips.dashboardTable.headers.sharedWith}>Shared with</TooltipInfo>,
      Cell: ({ row }) => (
        <Box display="flex" flexDirection="row">
          <DashboardUserChipAvatarGroup
            data={
              row?.original?.identityLinks
                ?.filter((el) => !row?.original?.history?.some(
                  (historyItem) => historyItem?.operationTagId === 'rop.ins'
                    && historyItem?.identityId === el?.identityId,
                ))
            }
            className={styles.avatarGroup}
          />
        </Box>
      ),
    },
    {
      accessorKey: 'permissionTagIds',
      header: 'Permissions',
      Header: () => <TooltipInfo title={tooltips.dashboardTable.headers.permissions}>Permissions</TooltipInfo>,
      size: 90,
      grow: false,
      Cell: ({ row }) => {
        const userRole = useDashboardRole(row?.original?.id);
        const isOwner = useHasDashboardPermissions(row?.original?.id, [permissionTags.roleOwner]);

        return (
          <PermissionChips
            isOwner={isOwner}
            role={userRole}
          />
        );
      },
    },
    {
      accessorKey: 'options',
      header: '',
      Cell: ({ row }) => <DashboardContextMenu dashboardId={row?.original.id} showFavorite />,
      size: 64,
      grow: false,
      enableSorting: false,
      enableColumnActions: false,
    },
  ], []);

  return (
    <Table
      columns={columns}
      data={data}
      enablePagination
      enableBottomToolbar
      onRowClick={onRedirect}
      pagination={paginationTable}
      onPaginationChange={onPageChange}
      isLoading={isLoadingPagination}
      rowCount={pageInfo?.totalCount}
      pageSize={pageInfo?.pageSize}
    />
  );
};

const BrokenTable = () => {
  return (
    <Box position="relative">
      <Typography
        variant="subtitle2"
        sx={{
          color: 'red',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
        }}
      >
        Error loading table
      </Typography>
      <TableSkeleton />
    </Box>
  );
};

const DashboardTable = (props) => {
  return (
    <ErrorBoundary FallbackComponent={BrokenTable}>
      <BaseDashboardTable {...props} />
    </ErrorBoundary>
  );
};

export default DashboardTable;
