import clsx from 'clsx';
import React from 'react';

import InlineButton from '@/components/InlineButton';
import Edit from '@/icons/EditIcon';

import styles from './LocationParam.module.scss';

interface LocationParamProps {
  location: Record<string, any>;
  onClick: () => void;
  placeholder?: string;
  className?: string;
  color?: string;
  id: string;
  isEditor?: boolean;
}

const LocationParam = ({
  location,
  onClick,
  placeholder = 'Anywhere',
  className,
  color,
  id,
  isEditor,
}: LocationParamProps) => {
  return (
    <InlineButton
      color={color}
      className={clsx(className, styles.locationParam)}
      onClick={onClick}
      id={id}
    >
      {location?.value ? (
        <span>{location?.displayLabel || location.label}</span>
      ) : (
        <span>{placeholder}</span>
      )}
      {isEditor && <Edit />}
    </InlineButton>
  );
};

export default LocationParam;
