import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { v4 as uuidv4 } from 'uuid';

import { api } from '@/constants';

import getOneWeekAgoUTC from '../../utils/getOneWeekAgoUTC';
import {
  addCreatedDashboard,
  changeDeletedDashboard,
  changeMultipleUpdatedDashboards,
  changeUpdatedDashboard,
  changeUpdatedDashboardIsFavorite,
  setDashboards,
  setDashboardTabPosition,
} from '../slices/dashboardsSlice';
import { addAndPersistDashboardTabId, closeDashboardById, loadDashboardTabIds } from '../slices/dashboardTabsSlice';

export const dashboardsApi = createApi({
  reducerPath: 'dashboardsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${api.hostname}/corporateapi/dashboard` }),
  mode: 'cors',
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json');
    return headers;
  },
  tagTypes: ['Dashboard', 'Dashboards'],
  endpoints: (builder) => ({
    getDashboards: builder.query({
      query: () => '/dashboardListActiveUserV2',
      providesTags: (result) => {
        const tags = [{ type: 'Dashboards', id: 'LIST' }];

        if (result) {
          result.forEach((dashboard) => {
            tags.push({ type: 'Dashboard', id: dashboard.dashboardId });
            tags.push({ type: 'Dashboard', id: dashboard.id });
          });
        }

        return tags;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        dispatch(setDashboards(data));
        dispatch(loadDashboardTabIds(data));
      },
    }),
    getDashboard: builder.query({
      query: ({ id }) => `/dashboardGetV2?id=${id}`,
      providesTags: (result, error, arg) => {
        const tags = [{ type: 'Dashboard', id: arg.id }];

        if (result) {
          tags.push({ type: 'Dashboard', id: result.dashboardId });
        }

        if (error && error.status === 460) {
          tags.push({ type: 'Dashboard', id: error.data.dashboardId });
        }

        return tags;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(changeUpdatedDashboard(data));
        }
      },
    }),
    getDashboardDetails: builder.query({
      query: ({
        offset, limit, permissionTagIds, excludePermissions, isFavorite,
      }) => ({
        url: '/dashboardGetForActiveUserV2',
        params: {
          pageOffset: offset,
          pageSize: limit,
          permissionTagIds: `{${permissionTagIds?.join(',')}}`,
          excludePermissions: `{${excludePermissions?.join(',')}}`,
          ...(isFavorite && { isFavorite }),
        },
      }),
      providesTags: (result) => {
        if (result && result.data) {
          return [
            ...result.data.map(({ id }) => ({ type: 'Dashboard', id })),
            { type: 'Dashboards', id: 'LIST' },
          ];
        }
        return [{ type: 'Dashboards', id: 'LIST' }];
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            dispatch(changeMultipleUpdatedDashboards(data.data));
          }
        } catch (error) {
          console.error('Failed to fetch dashboard details:', error);
        }
      },
    }),
    getDashboardRecent: builder.query({
      query: ({ limit, startingOn }) => ({
        url: '/dashboardRecentEventGetV2',
        params: {
          pageSize: limit || 5,
          startingOn: startingOn || getOneWeekAgoUTC(),
        },
      }),
      providesTags: (result) => {
        if (result) {
          const tags = [{ type: 'Dashboards', id: 'LIST' }];
          result.forEach((dashboard) => {
            if (dashboard.id) {
              tags.push({ type: 'Dashboard', id: dashboard.id });
            }
            if (dashboard.dashboardId) {
              tags.push({ type: 'Dashboard', id: dashboard.dashboardId });
            }
          });
          return tags;
        }
        return [{ type: 'Dashboards', id: 'LIST' }];
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(changeMultipleUpdatedDashboards(data));
          }
        } catch (error) {
          console.error('Failed to fetch dashboard recent events:', error);
        }
      },
    }),
    getDashboardSearch: builder.query({
      query: ({ query, offset, limit }) => ({
        url: '/dashboardSearchV2',
        params: {
          query: `${query}:*`,
          pageOffset: offset,
          pageSize: limit,
        },
      }),
      providesTags: (result) => {
        const tags = [{ type: 'Dashboards', id: 'LIST' }];

        if (result?.data) {
          result.data.forEach((dashboard) => {
            tags.push({ type: 'Dashboard', id: dashboard.dashboardId });
            tags.push({ type: 'Dashboard', id: dashboard.id });
          });
        }

        return tags;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(changeMultipleUpdatedDashboards(data.data));
          }
        } catch (error) {
          console.error('Failed to fetch dashboard details:', error);
        }
      },
    }),
    createDashboard: builder.mutation({
      query: (body) => ({
        url: '/dashboardUpsert',
        method: 'PUT',
        body: {
          ...body,
          dashboardId: uuidv4(),
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(addCreatedDashboard({ id: data.id }));
        dispatch(addAndPersistDashboardTabId(data.id));
      },
    }),
    updateDashboardPosition: builder.mutation({
      query: ({ dashboardId, identityId, tabPosition }) => ({
        url: '/dashboardIdentityUpsert',
        method: 'PUT',
        body: { dashboardId, identityId, tabPosition },
      }),
      async onQueryStarted({ dashboardId, tabPosition }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          setDashboardTabPosition({ dashboardId, tabPosition }),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
      invalidatesTags: (arg) => [
        { type: 'Dashboard', id: arg.dashboardId },
      ],
    }),

    shareDashboard: builder.mutation({ // TODO: Check naming (similar to updateDashboardSharing)
      query: ({ dashboardId, identityIds, grantedPermissionTagIds }) => ({
        url: '/dashboardIdentitiesUpsert',
        method: 'PUT',
        body: { dashboardId, identityIds, grantedPermissionTagIds },
      }),
      invalidatesTags: (arg) => [
        { type: 'Dashboard', id: arg.dashboardId },
      ],
    }),
    updateDashboardName: builder.mutation({
      query: ({ dashboardId, title }) => ({
        url: '/dashboardUpsert',
        method: 'PUT',
        body: { dashboardId, title },
      }),
      invalidatesTags: (arg) => [
        { type: 'Dashboard', id: arg.dashboardId },
      ],
    }),
    updateDashboardBaseQuery: builder.mutation({
      query: ({ dashboardId, baseParams }) => ({
        url: '/dashboardUpsert',
        method: 'PUT',
        body: { dashboardId, baseParams },
      }),
    }),
    updateDashboardLayout: builder.mutation({
      query: ({ dashboardId, layout }) => ({
        url: '/dashboardlayoutupsert',
        method: 'PUT',
        body: { dashboardId, layout: JSON.stringify(layout) },
      }),
    }),
    updateDashboardPanels: builder.mutation({
      query: ({ dashboardId, panel }) => ({
        url: '/panelUpsert',
        method: 'PUT',
        body: {
          dashboardId,
          panelId: panel.panelId || uuidv4(),
          params: panel.params || {},
          settings: panel.settings || {},
          title: panel.title || '',
          panelType: panel.panelType,
        },
      }),
    }),
    updateIdentityDashboardIsFavorite: builder.mutation({
      query: ({ dashboardId, identityId, isFavorite }) => ({
        url: '/dashboardIdentityUpsert',
        method: 'PUT',
        body: { dashboardId, identityId, isFavorite },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(changeUpdatedDashboardIsFavorite(data));
      },
      invalidatesTags: (arg) => [
        { type: 'Dashboard', id: arg.dashboardId },
        { type: 'Dashboards', id: 'LIST' },
      ],
    }),
    deleteDashboardPanel: builder.mutation({
      query: ({ dashboardId, panelId, layout }) => ({
        url: '/dashboardPanelSoftDelete',
        method: 'PUT',
        body: { dashboardId, panelId, layout: JSON.stringify(layout) },
      }),
    }),
    deleteDashboard: builder.mutation({
      query: ({ dashboardId }) => ({
        url: '/dashboardSoftDelete',
        method: 'PUT',
        body: { dashboardId },
      }),
      async onQueryStarted({ id }, { dispatch }) {
        try {
          dispatch(closeDashboardById(id));
          dispatch(changeDeletedDashboard({
            id,
          }));
        } catch (error) {
          console.error('Failed to delete dashboard:', error);
        }
      },
      invalidatesTags: (arg) => [
        { type: 'Dashboard', id: arg.dashboardId },
        { type: 'Dashboards', id: 'LIST' },
      ],
    }),
    dashboardIdentitySoftDelete: builder.mutation({
      query: ({ dashboardId, identityId }) => ({
        url: '/dashboardIdentitySoftDelete',
        method: 'PUT',
        body: { dashboardId, identityId },
      }),
      invalidatesTags: (arg) => [
        { type: 'Dashboards', id: 'LIST' },
        { type: 'Dashboard', id: arg.dashboardId },
      ],
    }),
    restoreDashboard: builder.mutation({
      query: ({ dashboardId }) => ({
        url: '/dashboardRestore',
        method: 'PATCH',
        body: { dashboardId },
      }),
      invalidatesTags: (arg) => [
        { type: 'Dashboards', id: 'LIST' },
        { type: 'Dashboard', id: arg.dashboardId },
      ],
    }),
    updateDashboardScreenshot: builder.mutation({
      query: ({ dashboardId, thumbnail }) => ({
        url: '/dashboardUpsert',
        method: 'PUT',
        body: {
          dashboardId,
          thumbnail,
        },
      }),
    }),
    updateDashboardSharing: builder.mutation({
      query: ({ dashboardId, identityId, grantedPermissionTagIds }) => ({
        url: '/dashboardIdentityUpsert',
        method: 'PUT',
        body: { dashboardId, identityId, grantedPermissionTagIds },
      }),
      invalidatesTags: (arg) => [
        { type: 'Dashboard', id: arg.dashboardId },
      ],
    }),
    cloneDashboard: builder.mutation({
      query: ({ dashboardId, shareWithSamePeople, newTitle }) => ({
        url: '/dashboardClone',
        method: 'PUT',
        body: {
          dashboardId,
          shareWithSamePeople,
          newTitle,
          newDashboardId: uuidv4(),
        },
      }),
      async onQueryStarted({ dashboardId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(addAndPersistDashboardTabId(data.id));
        } catch (error) {
          console.error(`Failed to clone dashboard (ID ${dashboardId}):`, error);
        }
      },
    }),
  }),
});

export const {
  useGetDashboardsQuery,
  useGetDashboardQuery,
  useLazyGetDashboardQuery,
  useGetDashboardDetailsQuery,
  useGetDashboardRecentQuery,
  useGetDashboardSearchQuery,
  useCreateDashboardMutation,
  useShareDashboardMutation,
  useUpdateDashboardNameMutation,
  useUpdateDashboardPositionMutation,
  useUpdateIdentityDashboardIsFavoriteMutation,
  useUpdateDashboardLayoutMutation,
  useUpdateDashboardBaseQueryMutation,
  useUpdateDashboardPanelsMutation,
  useDeleteDashboardPanelMutation,
  useDeleteDashboardMutation,
  useDashboardIdentitySoftDeleteMutation,
  useRestoreDashboardMutation,
  useUpdateScreenshotDashboardMutation,
  useUpdateDashboardSharingMutation,
  useCloneDashboardMutation,
} = dashboardsApi;
