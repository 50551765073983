import { Chip } from '@mui/material';
import { styled } from '@mui/system';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import ErrorBoundary from '@/components/ErrorBoundary';
import useScrollBlock from '@/hooks/useScrollBlock';
import { useLazyGetInsightQuery } from '@/store/apis/insightsApi';
import { getInsightsEntitiesQuery, getInsightsTagsQuery } from '@/utils/fetch';

import Modal from '../Modal';
import Stepper from '../Stepper';
import styles from './CreativesModal.module.scss';
import CreativesModalContextMenu from './CreativesModalContextMenu';
import CreativesModalStepOne from './CreativesModalStepOne';
import CreativesModalStepTwo from './CreativesModalStepTwo';
import getMissingRequiredParams from './getMissingRequiredParams';
import templatesByKey from './templates';
import { fetchEntities, fetchTags } from './utils/fetch';

const BetaChip = styled(Chip)({
  position: 'relative',
  top: '-0.75em',
  left: '-0.5em',
  fontSize: '11px',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontVariantCaps: 'small-caps',
  letterSpacing: '1px',
  color: 'blue',
  backgroundColor: 'transparent',
  padding: 0,
});

const CreativesModal = ({
  availableCreatives, baseParams, close,
}) => {
  const [triggerGetInsight] = useLazyGetInsightQuery();
  const [creativeResults, setCreativeResults] = useState();
  const fetchInsightsEntities = (entitiesRequest) => {
    const serializedInsightsParams = getInsightsEntitiesQuery(entitiesRequest);
    return triggerGetInsight(serializedInsightsParams, true).unwrap();
  };

  const fetchInsightsTags = (tagsRequest) => {
    const serializedInsightsParams = getInsightsTagsQuery(tagsRequest);
    return triggerGetInsight(serializedInsightsParams, true).unwrap();
  };

  const [blockScroll, allowScroll] = useScrollBlock();
  const [activeIndex, setActiveIndex] = useState(0);
  const selectedTemplateKeyState = useState(
    (availableCreatives.length === 1 ? availableCreatives[0] : localStorage.getItem('lastSelectedCreative')),
  );
  const [selectedTemplateKey] = selectedTemplateKeyState;

  const userDataState = useState({});
  const [userData] = userDataState;

  const formRef = useRef(null);

  const prefetchInsights = (templateKey) => {
    const nextTemplate = templatesByKey[templateKey];
    // call only if insights isn't dependant on form generated user data
    if (nextTemplate && nextTemplate.insights && typeof nextTemplate.insights !== 'function') {
      const { insights } = nextTemplate;
      fetchEntities({
        baseParams,
        entitiesRequests: insights?.entities || [],
        wrappedFetchInsightsEntities: fetchInsightsEntities,
      });
      fetchTags({ baseParams, tagsRequests: insights?.tags || [], wrappedFetchInsightsTags: fetchInsightsTags });
    }
  };

  const steps = useMemo(
    () => [
      {
        label: 'Setup',
        isDisabled: !selectedTemplateKey,
        render: () => (
          <CreativesModalStepOne
            availableCreatives={availableCreatives}
            baseParams={baseParams}
            formRef={formRef}
            prefetchInsights={prefetchInsights}
            selectedTemplateKeyState={selectedTemplateKeyState}
            userDataState={userDataState}
          />
        ),
        validate: () => {
          const form = formRef.current;
          if (form) {
            const formIsValid = form.validateForm();
            if (!formIsValid) {
              return false;
            }
          }

          const template = templatesByKey[selectedTemplateKey];
          if (template?.required) {
            const missingRequiredParams = getMissingRequiredParams(
              baseParams,
              template.required,
            );
            if (missingRequiredParams.length) {
              return false;
            }
          }

          return true;
        },
      },
      {
        label: 'Result',
        render: () => (
          <CreativesModalStepTwo
            baseParams={baseParams}
            fetchInsightsEntities={fetchInsightsEntities}
            fetchInsightsTags={fetchInsightsTags}
            template={templatesByKey[selectedTemplateKey]}
            userData={userData}
            setCreativeResults={setCreativeResults}
          />
        ),
      },
    ],
    [selectedTemplateKey, userData],
  );

  useEffect(() => {
    blockScroll();

    return () => {
      allowScroll();
    };
  }, []);

  return (
    <ErrorBoundary>
      <Modal
        isOpen
        close={close}
        title={(
          <div className={styles.title}>
            <div className={styles.titleLeft}>
              <span>Creatives</span>
              <BetaChip color="primary" label="Beta" size="small" />
            </div>
            <CreativesModalContextMenu creativeResults={creativeResults} />
          </div>
        )}
        renderContent={() => (
          <div className={styles.container}>
            <Stepper
              activeIndex={activeIndex}
              className={styles.stepper}
              containerClassName={styles.stepperContent}
              onChange={setActiveIndex}
              onSubmit={close}
              steps={steps}
              submitLabel="Done"
            />
          </div>
        )}
      />
    </ErrorBoundary>
  );
};

export default CreativesModal;
