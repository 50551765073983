import { Box } from '@mui/material';

import Skeleton from '@/components/Skeleton';

import DashboardCardSkeleton from './DashboardCardSkeleton';

const RecentDashboardsSkeleton = () => (
  <Box p={5} display="flex" flexDirection="column" gap={2}>
    <Skeleton variant="text" width={100} height={32} />
    <Box display="flex" gap={3} flexWrap="wrap">
      <DashboardCardSkeleton />
      <DashboardCardSkeleton />
      <DashboardCardSkeleton />
    </Box>
  </Box>
);

export default RecentDashboardsSkeleton;
