import useHasPermission from '@v1/hooks/useHasPermission';
import { VeltComments, VeltCursor } from '@veltdev/react';
import React from 'react';

import PanelBodySuggestions from '@/screens/Dashboard/DashboardPanel/PanelBodySuggestions/PanelBodySuggestions';
import { useAppSelector } from '@/store';
import { DashboardItem } from '@/store/slices/dashboardSliceTypes';

import DashboardLayout from '../DashboardLayout/DashboardLayout';
import DashboardToolbar from '../DashboardToolbar/DashboardToolbar';
import { useDashboardVeltContext } from '../DashboardVeltProvider';
import styles from './DashboardBody.module.scss';

interface DashboardBodyProps {
  dashboard: DashboardItem;
}

const DashboardBody = ({ dashboard }: DashboardBodyProps) => {
  const dashboardVelt = useDashboardVeltContext();
  const { isVeltInitialized } = dashboardVelt;
  const { isDarkMode } = useAppSelector((state) => state.app);
  const hasCreatePermission = useHasPermission('feature.dashboard.panel.suggestion');

  return (
    <>
      <div className={styles.container}>
        <DashboardToolbar />
        <DashboardLayout dashboard={dashboard} />
        {hasCreatePermission && <PanelBodySuggestions dashboard={dashboard} />}
      </div>
      {isVeltInitialized ? (
        <>
          <VeltCursor />
          <VeltComments
            allowedElementClassNames={['comment-able']}
            status={false}
            darkMode={isDarkMode}
          />
        </>
      ) : null}
    </>
  );
};

export default DashboardBody;
