import { ageRangeLabels, paramNames } from '@/constants';

// Fix before merge
import getGenderLabel from '../screens/Dashboard/helpers/getGenderLabel';
import getAgeLabel from './getAgeLabel';

const getDemographicsLabel = (params) => {
  const ageLabel = getAgeLabel(params[paramNames.signalDemographicsAge], ageRangeLabels.length);
  const genderLabel = getGenderLabel(params);

  const audienceIds = params[paramNames.audiences] || [];

  if ((!ageLabel || !ageLabel.length) && !genderLabel && !audienceIds.length) {
    return 'Anyone';
  }

  const audiencesLabel = audienceIds.map((audienceId) => audienceId.split(':').pop()).join(', ');

  return `${genderLabel || ''}${ageLabel && genderLabel ? ', ' : ''}${ageLabel || ''}${audiencesLabel && (ageLabel || genderLabel) ? ', ' : ''}${audiencesLabel || ''}`;
};

export default getDemographicsLabel;
