import form from './formSchema.json';
import formUiSchema from './formUiSchema.json';
import render from './Render';
import runner from './runner';

const brainstorm = {
  name: '🧠 Marketing Brainstorm',
  description: 'Generate marketing strategies for any product or service',
  form,
  formUiSchema,
  defaultFormData: {
    Categories: [{ name: 'Brands' }],
    // instructions: 'Market a line of soap to this audience',
  },
  insights: {
    entities: [
      { category: 'brand', params: { take: 10 } },
    ],
    tags: [
      {
        category: 'brand',
        fetchByTagTypes: true,
        params: {
          take: 5,
        },
      },
    ],
  },
  runner,
  render,
};

export default brainstorm;
