import { fieldByParamName, fieldTypes, paramNames } from '@/constants';
import formatAudienceId from '@/utils/formatAudienceId';

import DescribeLocation from './DescribeLocation';

const getCheckboxListLabel = ({ options, value }) => {
  const labelByValue = options.reduce((obj, option) => ({
    ...obj,
    [option.value]: option.label,
  }), {});

  if (Array.isArray(value)) {
    return value.map((valueItem) => labelByValue[valueItem]).join(', ');
  }

  return value?.replaceAll('_', ' ');
};

const getRangeLabel = (value, { beforeEach = '', afterEach = '' } = {}) => {
  const [min, max] = value;
  if (min && max) {
    return `${beforeEach}${min}${afterEach} - ${beforeEach}${max}${afterEach}`;
  }
  if (min) {
    return `> ${beforeEach}${min}${afterEach}`;
  }
  if (max) {
    return `< ${beforeEach}${max}${afterEach}`;
  }
  return '';
};

const getRangeLabelOptions = (paramName) => {
  if (paramName === paramNames.filterPrice_level) {
    return { beforeEach: '$', afterEach: '' };
  }

  return {};
};

const getPillPickerLabel = ({ options = [], value }) => options.find((option) => option.value === value)?.label || '';

const ParamLabel = ({ paramName, value }) => {
  const field = fieldByParamName[paramName];
  if (field) {
    const { options, type } = field;

    switch (type) {
      case fieldTypes.checkboxList:
        return getCheckboxListLabel({ options, value });
      case fieldTypes.location:
        return <DescribeLocation location={value} />;
      case fieldTypes.pillPicker:
        return getPillPickerLabel({ options, value });
      case fieldTypes.rangeInputs:
      case fieldTypes.ratingRange:
      case fieldTypes.slider:
        return getRangeLabel(value, getRangeLabelOptions(paramName));
      case fieldTypes.audiences:
        return formatAudienceId(value);
      default:
        return value;
    }
  }
  return null;
};

export default ParamLabel;
