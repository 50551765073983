import MuiButton from '@mui/material/Button';
import React from 'react';

import styles from './ModalResetButton.module.scss';

const ModalResetButton = ({ onReset, children = undefined }) => {
  return (
    <div className={styles.resetWrapper}>
      <MuiButton
        color="secondary"
        size="xs"
        font="Inter"
        onClick={onReset}
      >
        {children ?? 'CLEAR'}
      </MuiButton>
    </div>
  );
};

export default ModalResetButton;
