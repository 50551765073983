import {
  Avatar, Box, Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import Jazzicon from 'react-jazzicon';

const diameter = 36;

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fontSizeLabel' && prop !== 'isStyleGroupAvatar',
})(({ fontSizeLabel, isStyleGroupAvatar }) => ({
  color: 'white',
  textShadow: '2px 2px black',
  fontWeight: 'bold',
  fontSize: fontSizeLabel || '2em',
  borderRadius: 50,
  zIndex: 10,
  width: diameter,
  height: diameter,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: isStyleGroupAvatar ? '-6px' : '',
  marginTop: isStyleGroupAvatar ? '3px' : '',
}));

const toHash = (userId) => {
  let hash = 0;
  for (let i = 0; i < userId.length; i += 1) {
    hash += userId.charCodeAt(i);
  }
  return hash * 10000000;
};

const DashboardUserChipAvatar = ({
  label, isShowLabel = false, email, userId, fontSizeLabel, isStyleGroupAvatar,
}) => {
  return (
    <Tooltip title={<Box display="flex" alignItems="center">{label} {email && `- ${email}`}</Box>}>
      <Box position="relative" display="flex" alignItems="center" gap={1}>
        <Avatar
          src=""
          alt={label}
          sx={{
            width: diameter, height: diameter, position: 'absolute', top: 0, left: 0,
          }}
        >
          <Jazzicon
            diameter={diameter}
            seed={toHash(userId)}
          />
        </Avatar>
        <StyledBox fontSizeLabel={fontSizeLabel} isStyleGroupAvatar={isStyleGroupAvatar}>
          {label?.slice(0, 1)}
        </StyledBox>
        {isShowLabel && (
          <Typography
            variant="subtitle2"
            sx={{ color: 'hsl(var(--gray))', whiteSpace: 'nowrap' }}
          >
            {label}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};

export default DashboardUserChipAvatar;
