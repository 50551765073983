import { Grid } from '@mui/material';

import TagV2 from '../TagV2';

/**
 * Renders a list of tags.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {Object} getItemsProps - Props passed to the tags list to make it behave like an item
 * @param {Object} tagsStartIndex - Start index based on top result
 * @param {Boolean} showMoreTags - Boolean prop to show more or less tags
 * @param {Object} rest - Rest of properties to add item behavior to tags
 * @param {Array<Object>} props.tags - An array of tag objects to display.
 * @returns {JSX.Element} The rendered list of tags.
 */
const TagsList = ({
  tags, valueBitmap, getItemProps, tagsStartIndex, showMoreTags,
}) => {
  // const colorEnhancedTags = enhanceListWithColors(tags);
  return (
    <Grid container spacing={1} sx={{ alignContent: 'flex-start' }}>
      {(showMoreTags ? tags : tags.slice(0, 16))?.map((tag, index) => {
        const isSelected = valueBitmap ? valueBitmap[tag.entity_id || tag.id] : false;

        return (
          <Grid sx={{ padding: 0 }} item key={`grid_wrapper_${tag.entity_id || tag.id}`}>
            <TagV2
              tag={tag}
              selected={isSelected}
              {...(getItemProps ? getItemProps({
                index: tagsStartIndex + index,
                item: tag,
              }) : null)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TagsList;
