import ErrorBoundary from '@/components/ErrorBoundary';

import styles from './DashboardPanel.module.scss';
import PanelBody from './PanelBody';
import PanelContentErrorFallback from './PanelContentErrorFallback';

const PanelContent = (props) => {
  return (
    <ErrorBoundary FallbackComponent={PanelContentErrorFallback}>
      <div className={styles.panelContent}>
        <PanelBody {...props} />
      </div>
    </ErrorBoundary>
  );
};

export default PanelContent;
