const panelTypes = {
  table: 'table',
  heatmap: 'heatmap',
  explore: 'explore',
  locationExplorer: 'location_explorer',
  freeformText: 'freeform_text',
};

export const panelTypeLabels = {
  [panelTypes.table]: 'Correlation Table',
  [panelTypes.heatmap]: 'Heatmap',
  [panelTypes.locationExplorer]: 'Location Explorer',
  [panelTypes.explore]: 'Summary Overview',
  [panelTypes.freeformText]: 'Text',
};

export default panelTypes;
