import React from 'react';

import PanelSkeleton from '../DashboardPanel/skeleton/PanelSkeleton';
import styles from './DashboardLayout.module.scss';

const DashboardLayoutSkeleton = (props) => (
  <div className={styles.layoutSkeleton}>
    <div className={styles.layoutSkeletonItem}>
      <PanelSkeleton {...props} />
    </div>
    <div className={styles.layoutSkeletonItem}>
      <PanelSkeleton {...props} />
    </div>
    <div className={styles.layoutSkeletonItem}>
      <PanelSkeleton {...props} />
    </div>
  </div>
);

export default DashboardLayoutSkeleton;
