const hierarchyFromUrn = (urn: string) => urn?.replace(/^urn:(audience:)?/, '').split(':');
const slugToSentenceCaseName = (slug: string) => (
  slug
    ?.split(/[-_:]+/)
    .map((word) => word.replace(/([A-Z])/g, ' $1')
      .replace(/^\s/, '')
      .replace(/^./, (char) => char.toUpperCase()))
    .join(' ')
);
const bestGuessUrnName = (maybeUrn) => (
  hierarchyFromUrn(maybeUrn).map(slugToSentenceCaseName)
);

export default bestGuessUrnName;
