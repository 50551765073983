import { createSlice } from '@reduxjs/toolkit';

import keyBy from '@/utils/keyBy';

export const entitiesSlice = createSlice({
  name: 'entities',
  initialState: {
    byId: {},
  },
  reducers: {
    fetchEntityStart: (state, action) => {
      const { byId } = state;
      const entityId = action.payload;

      return {
        ...state,
        byId: {
          ...byId,
          [entityId]: {
            ...(byId[entityId] || {}),
          },
        },
      };
    },
    mergeEntities: (state, action) => {
      const entities = action.payload;

      return {
        ...state,
        byId: { ...state.byId, ...keyBy(entities, 'entity_id') },
      };
    },
  },
});

export const {
  fetchEntityStart,
  mergeEntities,
} = entitiesSlice.actions;
