import { AppBar as MuiAppBar } from '@mui/material';
import Slide from '@mui/material/Slide';
import {
  alpha,
  styled,
} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import React from 'react';
import { useLocation } from 'react-router-dom';

import useIsScreenshotting from '@/hooks/useIsScreenshotting';

import styles from './AppBar.module.scss';

export const useScrolledFromTop = ({ threshold = 20 } = {}) => {
  const [scrollTriggered, setScrollTriggered] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = Math.abs(currPos.y) > threshold;
    if (isShow !== scrollTriggered) {
      setScrollTriggered(isShow);
    }
  }, [scrollTriggered]);
  return scrollTriggered;
};

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'drawerOpen',
})(({ theme }) => {
  const trigger = useScrolledFromTop({ threshold: 200 });
  const location = useLocation();
  const isDashboardView = location.pathname.startsWith('/dashboards/view');

  return ({
    maxHeight: 64,
    zIndex: theme.zIndex.drawer + 4,
    backgroundColor: alpha(theme.palette.background.paper, 1),
    boxShadow: 'rgba(0,0,0,0) 0px 2px 10px',
    transition: theme.transitions.create(['width', 'margin', 'box-shadow'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(trigger && {
      backgroundColor: alpha(theme.palette.background.paper, 0.7),
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 10px',
      backdropFilter: 'blur(6px)',
      display: isDashboardView ? 'none' : 'auto',
    }),
  });
});

const AppBar = ({ children }) => {
  const trigger = useScrollTrigger();
  const isScreenshotting = useIsScreenshotting();

  if (isScreenshotting) {
    return null;
  }

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <StyledAppBar position="fixed">
        <Toolbar className={styles.toolbar}>{children}</Toolbar>
      </StyledAppBar>
    </Slide>
  );
};

export default AppBar;
