import { Box } from '@mui/material';
import isEqual from 'lodash.isequal';
import React, {
  memo,
  useEffect, useMemo, useRef, useState,
} from 'react';

import ErrorBoundary from '@/components/ErrorBoundary';
import LockerOverlay from '@/components/Locker/LockerOverlay';
import { paramNames } from '@/constants';
import buildMissingParamsErrorMessage from '@/screens/Dashboard/DashboardPanel/helpers/buildMissingParamsErrorMessage';
import { getMissingPanelParams } from '@/screens/Dashboard/DashboardPanel/helpers/getMissingPanelParams';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import serializeInsightsParams from '@/utils/serializeInsightsParams';

import { useDashboardVeltContext } from '../DashboardVeltProvider';
import { defaultPageSize } from './helpers/DashboardPanelConstants';
import getDataFromInsight from './helpers/getDataFromInsight';
import getIsInsightPanel from './helpers/getIsInsightPanel';
import PanelContent from './PanelContent';
import PanelErrorFallback from './PanelErrorFallback';
import PanelFooter from './PanelFooter';
import PanelHeader from './PanelHeader';

const PanelErrorFallbackComponent = ({ panelId, ...props }) => <PanelErrorFallback panelId={panelId} {...props} />;

const DashboardPanel = ({
  dashboard, isEditingUser, panel, isViewingPublicly = false,
}) => {
  const {
    panelId,
    title,
    settings: panelSettings,
    panelType,
    params: panelParams,
  } = panel;
  const panelRef = useRef(null);
  const { baseParams } = dashboard;

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [hasMoreExplorePages, setHasMoreExplorePages] = useState(false);

  const missingParams = useMemo(() => {
    return getMissingPanelParams(panelType, panelParams, baseParams);
  }, [panelType, panelParams, baseParams]);

  const customErrorMessage = buildMissingParamsErrorMessage(missingParams);

  const serializedInsightsParams = useMemo(() => {
    const tempParams = { ...panelParams };

    if (tempParams[paramNames.take]) {
      // Get one more record than needed to determine if there are more pages
      tempParams[paramNames.take] += 1;
    }

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, panelParams]);

  const isInsightPanel = getIsInsightPanel(panelType);

  const {
    data: panelData,
    isLoading,
    isFetching,
    error: panelErrorFromApi,
  } = useGetInsightQuery(serializedInsightsParams, {
    skip: !isInsightPanel || !missingParams.isValid,
  });

  const panelError = !missingParams.isValid && panelType !== 'location_explorer'
    ? { data: { errors: [{ message: customErrorMessage }] } }
    : panelErrorFromApi;

  useEffect(() => {
    if (isFirstLoad && !isLoading) {
      setIsFirstLoad(false);
    }
  }, [isFirstLoad, isLoading]);

  const mappedPanelData = useMemo(
    () => getDataFromInsight({ panelParams, panelData }),
    [panelParams, panelData],
  );
  const hasMorePages = useMemo(
    () => mappedPanelData?.length > (panelParams[paramNames.take] || defaultPageSize),
    [mappedPanelData?.length, panelParams],
  );

  useEffect(() => {
    if (panel.isNew && panelRef.current) {
      panelRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [panel.isNew]);

  const dashboardVelt = useDashboardVeltContext();
  const { getPanelVeltLocation, setVeltLocation } = dashboardVelt;

  const veltLocation = useMemo(
    () => getPanelVeltLocation({ panelId }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [panelId],
  );

  useEffect(() => {
    setVeltLocation(veltLocation);
  }, [veltLocation, setVeltLocation]);

  return (
    <ErrorBoundary
      FallbackComponent={(errorFallbackProps) => <PanelErrorFallbackComponent panelId={panelId} {...errorFallbackProps} />}
    >
      <Box
        className="comment-able"
        data-velt-location={JSON.stringify(veltLocation)}
        ref={panelRef}
        position="relative"
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
      >
        <PanelHeader
          panel={panel}
          panelId={panelId}
          panelParams={panelParams}
          panelTitle={title}
          panelType={panelType}
          serializedInsightsParams={serializedInsightsParams}
          isViewingPublicly={isViewingPublicly}
        />
        <PanelContent
          dashboardId={dashboard.id}
          panel={panel}
          panelData={hasMorePages ? mappedPanelData.slice(0, -1) : mappedPanelData}
          panelError={panelError}
          isFetching={isFetching}
          serializedInsightsParams={serializedInsightsParams}
          setHasMoreExplorePages={setHasMoreExplorePages}
          isViewingPublicly={isViewingPublicly}
        />
        <PanelFooter
          panel={panel}
          panelError={panelError}
          hasData={!!mappedPanelData.length}
          hasMorePages={hasMorePages || hasMoreExplorePages}
          hasSelectedCategory={!!panelSettings?.selectedCategory}
        />
        <div>{isEditingUser ? `${isEditingUser} is editing` : null}</div>
        <LockerOverlay isLocked={isEditingUser} lockedBy={isEditingUser} />
      </Box>
    </ErrorBoundary>
  );
};

export default memo(DashboardPanel, (prev, next) => {
  return isEqual(prev, next);
});
