import React, { PropsWithChildren } from 'react';

import styles from './Overlay.module.scss';

interface Props extends PropsWithChildren {}

const Overlay = ({ children }: Props) => (
  <div className={styles.overlay}>
    {children}
  </div>
);

export default Overlay;
