import { useEffect, useMemo } from 'react';

import { filterTypes } from '@/constants';
import { useAppDispatch } from '@/store';
import { useGetTagsQuery } from '@/store/apis/tagsApi';
import { mergeTags } from '@/store/slices/tagsSlice';

import useDebounce from './useDebounce';

interface UseTagsSearchProps {
  search: string;
  parentType: string;
  take?: number;
  debounce?: number;
}

const TAG_TYPES = [filterTypes.tag];

const useTagsSearch = ({
  search, parentType, take = 15, debounce = 250,
}: UseTagsSearchProps) => {
  const dispatch = useAppDispatch();
  const debouncedSearch = useDebounce(search, debounce);

  const { data, isLoading, isFetching } = useGetTagsQuery({
    query: debouncedSearch,
    take,
    parentType,
    tagTypes: TAG_TYPES,
  }, {
    skip: !debouncedSearch,
  });

  const isDebouncing = useMemo(() => debouncedSearch !== search, [debouncedSearch, search]);

  useEffect(() => {
    if (data) {
      dispatch(mergeTags(data));
    }
  }, [data, dispatch]);

  return {
    isLoading,
    isFetching,
    isDebouncing,
    results: data,
  };
};

export default useTagsSearch;
