import React from 'react';

import Skeleton from '@/components/Skeleton';

import QueryBuilderSkeleton from '../QueryBuilder/QueryBuilderSkeleton';
import styles from './DashboardHeader.module.scss';

const DashboardHeaderSkeleton = () => (
  <div className={styles.container}>
    <div className={styles.queryBuilder}>
      <QueryBuilderSkeleton />
    </div>

    <div className="skeletonTopPadding">
      <Skeleton width={12} height={25} />
    </div>
  </div>
);

export default DashboardHeaderSkeleton;
