import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import clsx from 'clsx';

import styles from '../CreativesModal.module.scss';

interface Props {
  tldr: string
  className: string
}

const TLDRContainer = ({ tldr, className }: Props) => (
  <div className={clsx(styles.tldr, className)}>
    <AutoAwesomeIcon />
    {tldr}
  </div>
);

export default TLDRContainer;
