import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { ellipsisText } from '@/utils/format';

import getDescribeLocation from '../utils/getDescribeLocation';

// For legacy support
// Old locations are not described (they are missing the `label` and/or 'displayLabel` fields)
const useDescribeLocation = (location) => {
  const [nextLocation, setNextLocation] = useState({
    ...(location ?? {}),
    label: location?.value ? ellipsisText(JSON.stringify(location.value)) : '',
  });

  useDeepCompareEffect(() => {
    const request = async () => {
      const result = await getDescribeLocation(location);

      if (result.label) {
        setNextLocation(result);
      }
    };

    if (location) {
      request();
    } else {
      setNextLocation(null);
    }
  }, [location || {}]);

  return nextLocation;
};

export default useDescribeLocation;
