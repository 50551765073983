import { Box, Card } from '@mui/material';

import Skeleton from '@/components/Skeleton';

import DashboardTableSkeleton from './DashboardTableSkeleton';

const TableSectionSkeleton = () => (
  <Box bgcolor="background.paper" px={5} py={3} width="100%">
    <Box display="flex" justifyContent="right">
      <Box pt={0.7} pb={0.7}>
        <Skeleton width={204} height={36} />
      </Box>
    </Box>
    <Card>
      <Box overflow="auto">
        <DashboardTableSkeleton />
      </Box>
    </Card>
  </Box>
);

export default TableSectionSkeleton;
