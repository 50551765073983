import { createTheme } from '@mui/material/styles';

const mainTheme = createTheme({
  cssVariables: true,
  palette: {
    mode: 'light',
    background: {
      default: '#F3F5F7',
      paper: '#FFFFFF',
    },
    default: {
      main: '#FFFFFF',
      contrastText: '#020817',
    },
    text: {
      primary: '#020817',
    },
    primary: {
      main: '#2878FF',
      light: '#5A9AFF',
      dark: '#1D5BCC',
      contrastText: '#F8FAFC',
    },
    secondary: {
      main: '#0F172A',
      contrastText: '#F8FAFC',
    },
    accent: {
      main: '#F2F5F8',
      contrastText: '#0F172A',
    },
    muted: {
      main: '#F2F5F8',
      contrastText: '#64748B',
    },
    destructive: {
      main: '#EF4444',
      contrastText: '#F8FAFC',
    },
    success: {
      main: '#00CC33',
      contrastText: '#E6F9EA',
    },
    danger: {
      main: '#FF3300',
      contrastText: '#FFEBE5',
    },
    orange: {
      main: '#FFEEEB',
      light: '#FFF5F3',
      dark: '#E6D6D3',
      contrastText: '#FF5C33',
    },
    purple: {
      main: '#F1EBF5',
      light: '#F6F0FA',
      dark: '#D6C9E1',
      contrastText: '#77329F',
    },
    blue: {
      main: '#E4F3F6',
      light: '#ECF7F9',
      dark: '#C2E0E4',
      contrastText: '#0095A8',
    },
    gray: {
      main: '#CFCFCF',
      contrastText: '#F3F5F7',
    },
    yellow: {
      main: '#FF9900',
      contrastText: '#F7EFD4',
    },
    yellowInverted: {
      main: '#F7EFD4',
      contrastText: '#FF9900',
    },
    lightBlue: {
      main: '#e5f6ff',
      light: '#c1e3f5',
      dark: '#a2ddfc',
      contrastText: '#a0c9ff',
    },
    darkBlue: {
      main: '#1a3b4d',
      light: '#285b76',
      dark: '#126ca1',
      contrastText: '#e0f2ff',
    },
    primaryDark: {
      main: '#2878FF',
      light: '#5A9AFF',
      dark: '#020817',
      contrastText: '#F8FAFC',
    },
    greyLight: {
      main: '#F4F6F8',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: '#000',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({
          fontWeight: 700,
          color: '#8C96A0 !important',
          textTransform: 'none',
          '&.Mui-selected': {
            fontWeight: '700 !important',
            color: '#000 !important',
            fill: '#000 !important',
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({
          noPadding, uppercase, bold,
        }) => ({
          textTransform: 'none',
          fontWeight: 700,
          boxShadow: 'none',
          ...(noPadding && {
            padding: '0 !important',
            minWidth: '34px',
          }),
          ...(uppercase && {
            textTransform: 'uppercase',
          }),
          ...(bold && {
            fontWeight: 800,
          }),
        }),
        sizeSmall: ({ noPadding }) => ({
          padding: noPadding ? 0 : '4px 8px',
          fontSize: '0.75rem',
        }),
      },
      variants: [
        {
          props: { color: 'primary', variant: 'contained' },
          style: () => ({
            backgroundColor: '#0166FF',
            color: '#FFF',

            '&:hover': {
              backgroundColor: '#000 !important',
            },
          }),

        },
        {
          props: { variant: 'underlined' },
          style: ({ theme, ownerState }) => {
            const color = ownerState.color || 'primary';

            return {
              textDecoration: 'none',
              backgroundColor: 'transparent',
              color: theme.palette[color].contrastText,

              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            };
          },
        },
        {
          props: { variant: 'inline' },
          style: ({ theme, ownerState, color }) => {
            const ownerColor = ownerState.color || 'primary';

            return {
              textDecoration: 'none',
              display: 'inline',
              padding: '0px',
              fontSize: '25px',
              fontWeight: 'bold',
              lineHeight: '1.2',
              borderRadius: 0,
              textAlign: 'left',
              backgroundColor: theme.palette[ownerColor].main,
              color: theme.palette[ownerColor].contrastText,

              '&:hover': {
                backgroundColor: theme.palette[color].contrastText,
                color: theme.palette[color].main,
              },

              '& > *': {
                display: 'inline',
                lineHeight: 1.6,
              },
            };
          },
        },
        {
          props: { variant: 'minimal' },
          style: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
          },
        },
        {
          props: { variant: 'map' },
          style: {
            width: '29px',
            height: '29px',
            minWidth: '29px',
            margin: '2px 2px 0 0',
            backgroundColor: '#fff',
            boxShadow: '0 0 0 2px rgba(0, 0, 0, .1)',
            borderRadius: '4px',

            '&:hover': {
              backgroundColor: '#f2f2f2',
              boxShadow: '0 0 0 2px rgba(0, 0, 0, .1)',
            },
          },
        },
        {
          props: { variant: 'map', selected: true },
          style: ({ theme, ownerState }) => {
            const color = ownerState.color || 'primary';

            return {
              backgroundColor: theme.palette[color].main,
              color: theme.palette[color].contrastText,
              boxShadow: 'none',

              '&:hover': {
                backgroundColor: theme.palette[color].dark,
              },
            };
          },
        },
        {
          props: { shape: 'rounded' },
          style: {
            borderRadius: 50,
            minWidth: '32px',
          },
        },
        {
          props: { shape: 'square' },
          style: {
            borderRadius: 0,
          },
        },
        {
          props: { size: 'xs' },
          style: {
            padding: '2px 4px',
            fontSize: '0.65rem',
          },
        },
        {
          props: { font: 'Inter' },
          style: {
            fontFamily: 'Inter',
            letterSpacing: '1px',
          },
        },
        {
          props: { align: 'left' },
          style: {
            justifyContent: 'flex-start',
          },
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ noPadding }) => ({
          ...(noPadding && {
            padding: 0,
            minWidth: 0,
          }),
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '1em',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputSizeSmall: {
          fontSize: '0.75rem',
        },
      },
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeightRegularMedium: 500,
  },
});

export default mainTheme;
