import clsx from 'clsx';
import React from 'react';

import Button from '../Button';
import styles from './PillPicker.module.scss';

const PillPickerMultiple = ({
  className, onChange, options, value,
}) => {
  const handleChange = (selectedValue) => {
    const tempValue = value ? (Array.isArray(value) ? value : [value]) : [];

    if (tempValue.includes(selectedValue)) {
      if (tempValue.length === 1) {
        onChange(null);
        return;
      }

      onChange(tempValue.filter((item) => item !== selectedValue));
      return;
    }
    onChange([...tempValue, selectedValue]);
  };

  return (
    <div
      className={clsx({
        [styles.pillPicker]: true,
        [className]: className,
      })}
    >
      {options.map((option) => (
        <Button
          key={option.value}
          onClick={() => handleChange(option.value)}
          variant="outlined"
          color={option.value === value || (Array.isArray(value) && value.includes(option.value)) ? 'primary' : 'secondary'}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
};
export default PillPickerMultiple;
