import { ArrowForwardIos } from '@mui/icons-material';
import { Box } from '@mui/material';
import { urnsToDisplayName } from '@qloo/categories';
import clx from 'clsx';
import React from 'react';

import Button from '@/components/Button';
import EntitiesList from '@/components/Omnisearch/EntitiesList';

import styles from '../PanelBodyExplore.module.scss';
import CategoryDataError from './CategoryDataError';
import CategoryDataTags from './CategoryDataTags';

const CategoryData = ({
  gptResult, tagsResult, category, onSelect, entitiesResult, error, tagTypes, tagsFetcher, isEmptyBaseQuery,
}) => {
  const hasError = Boolean(error);
  if (hasError) {
    return <CategoryDataError category={category} error={error} />;
  }

  return (
    <Box
      key={category}
      className={clx(
        styles.categoryData,
      )}
      onClick={onSelect(category)}
    >
      {!isEmptyBaseQuery && (
        <div
          className={clx(styles.overlay)}
        />
      )}

      <div
        className={styles.categoryHeader}
      >
        <span>{urnsToDisplayName(category)}</span>
        <Button color="link"><ArrowForwardIos /></Button>
      </div>
      <span className={styles.categoryDescription}>
        {gptResult}
      </span>
      <div className={styles.separator} />
      <CategoryDataTags category={category} tagsResult={tagsResult} tagTypes={tagTypes} tagsFetcher={tagsFetcher} />
      <div className={styles.separator} />
      <EntitiesList
        resetPadding
        entities={entitiesResult?.results?.entities}
        entitiesGridClassName={styles.entitiesGridClassName}
        showMini
      />
    </Box>
  );
};

export default CategoryData;
