/* eslint-disable no-param-reassign */
import QlooAPIURL from '@qloo/qloo-api-url';
import { randomString } from '@qloogle/data-utils';
import React, {
  useCallback, useContext, useMemo,
} from 'react';
import { useImmerReducer } from 'use-immer';

export const newRequestShape = ({ url, ...args } = {}) => ({
  id: randomString() + (+new Date()),
  url: new QlooAPIURL(url),
  response: null,
  ...args,
});

export const RequestsContext = React.createContext([]);
const RequestIndexContext = React.createContext(0);
const DispatchContext = React.createContext();

export const ServerContext = React.createContext();
export const RequestContext = React.createContext({});

export const useRequestsContext = () => useContext(RequestsContext);
export const useRequestIndexContext = () => useContext(RequestIndexContext);
export const useDispatchContext = () => useContext(DispatchContext);
export const useRequestContext = () => useContext(RequestContext);
export const useServerContext = () => {
  const server = useContext(ServerContext);
  const { request } = useContext(RequestContext);
  return server || request?.url?.server;
};

export const useRequestServerContext = () => {
  const { request } = useContext(RequestContext);
  return [request?.url?.server];
};

export const useRequestDispatchContext = () => {
  const index = useRequestIndexContext();
  const dispatch = useDispatchContext();
  return useCallback((props) => dispatch({
    ...props,
    index,
  }), [index, dispatch]);
};

const initialState = { requests: [] };

const ensureRequest = ({ draft, index }) => {
  const r = draft.requests[index];
  if (!r) {
    console.error(`No requests found in drafts.request[${index}]`, { index, draft });
  }
};

const urlIsSame = (urlA, urlB) => ((new QlooAPIURL(urlA)).toString === (new QlooAPIURL(urlB)).toString);

function reducer(draft, actionConfig) {
  const { type, index, ...action } = actionConfig;

  if (typeof index === 'number') {
    ensureRequest({ draft, index });
  }

  switch (type) {
    case 'reset':
      return initialState;
    case 'addRequest': {
      draft.requests.push(newRequestShape({ url: action.value }));
      break;
    }
    case 'removeRequest': {
      draft.requests.splice(index, 1);
      break;
    }
    case 'changeBlock':
      draft.requests[index].isBlocked = true;
      draft.requests[index].blockMessage = action.value;
      break;
    case 'removeBlock':
      draft.requests[index].isBlocked = false;
      draft.requests[index].blockMessage = null;
      break;
    case 'changeServer': {
      const r = draft.requests[index];
      if (r.url.server !== action.value) {
        r.url.server = action.value;
        // This is a kludge to get immer/React to recognize the url change.
        r.urlUpdated = (r.urlUpdated || 0) + 1;
      }
      break;
    }
    case 'changePathname': {
      const r = draft.requests[index];
      if (r.url.pathname !== action.value) {
        r.url.pathname = action.value;
        r.urlUpdated = (r.urlUpdated || 0) + 1;
      }
      break;
    }
    case 'changeParam': {
      const r = draft.requests[index];
      const { key, value } = action.value;
      if (r.url.searchParams.get(key) !== value) {
        if (value === undefined) {
          r.url.searchParams.delete(key);
        } else {
          r.url.searchParams.set(key, value);
        }
        r.urlUpdated = (r.urlUpdated || 0) + 1;
      }
      break;
    }
    case 'changePathAndQuery': {
      const r = draft.requests[index];
      r.url.pathAndQuery = action.value;
      r.urlUpdated = (r.urlUpdated || 0) + 1;
      break;
    }
    case 'togglePauseRequest': {
      const r = draft.requests[index];
      r.isPaused = !r.isPaused;
      break;
    }
    case 'changeLoading':
      draft.requests[index].isLoading = action.value;
      break;
    case 'changeResponse': {
      const { response, error } = action.value;
      draft.requests[index].response = response;
      draft.requests[index].error = error;
      break;
    }
    case 'setRequests':
      // Check each URL and compare to what we want it to be
      action.value.forEach((url, ii) => {
        if (!urlIsSame(draft.requests?.[ii]?.url, url)) {
          draft.requests[ii] = newRequestShape({
            ...(draft.requests[ii] || {}),
            url,
          });
        }
      });
      // Remove extras
      draft.requests.splice(action.value.length);
      break;
    case 'cloneRequest':
      draft.requests.splice(
        index + 1,
        0,
        newRequestShape({ url: draft.requests[index].url }),
      );
      break;
    default:
      throw new Error(`Don't know what action to perform: ${ JSON.stringify(actionConfig || 'no action object')}`);
  }
  return undefined;
}

export const RequestsProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(reducer, { requests: [] });

  return (
    <DispatchContext.Provider value={dispatch}>
      <RequestsContext.Provider value={state}>
        {children}
      </RequestsContext.Provider>
    </DispatchContext.Provider>
  );
};

export const RequestProvider = ({ index = 0, request, children }) => {
  const requestMemo = useMemo(() => ({ request }), [request]);
  return (
    <RequestIndexContext.Provider value={index} key={request.id}>
      <RequestContext.Provider value={requestMemo}>
        { children }
      </RequestContext.Provider>
    </RequestIndexContext.Provider>
  );
};
