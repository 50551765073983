import useHasDashboardPermissions from '@v1/hooks/useHasDashboardPermissions';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import InlineButton from '@/components/InlineButton';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import { paramNames, popovers } from '@/constants';
import muiVeltIds from '@/constants/muiVeltIds';
import permissionTags from '@/constants/permissionTags';
import useDescribeLocation from '@/hooks/useDescribeLocation';
import Edit from '@/icons/EditIcon';
import getIsFieldValueEmpty from '@/utils/getIsFieldValueEmpty';
import tooltips from '@/utils/tooltips';

import { demographicsParamNames } from '../constants';
import getInterestsLabels from '../helpers/getInterestsLabels';
import LocationParam from '../LocationParam/LocationParam';
import DemographicsLabel from './DemographicsLabel';

const QueryBuilder = ({
  children, params, onOpen, veltLocation,
}) => {
  const describedLocation = useDescribeLocation(
    params[paramNames.signalLocation],
  );
  const { id: dashboardId } = useParams();

  const isEditor = useHasDashboardPermissions(dashboardId, [
    permissionTags.roleEditor,
  ]);
  const interestsLabel = useMemo(() => getInterestsLabels(params), [params]);

  const generateVeltId = (id) => `${veltLocation.id}-${id}`;

  const DemographicsComponent = useCallback(
    ({ color, className }) => (
      <TooltipInfo title={tooltips.dashboard.anyone} followCursor>
        <div>
          <InlineButton
            color={color}
            className={className}
            onClick={onOpen(popovers.demographics)}
            id={generateVeltId(muiVeltIds.buttons.demographics)}
          >
            <DemographicsLabel params={params} />
            {isEditor && <Edit />}
          </InlineButton>
        </div>
      </TooltipInfo>
    ),
    [onOpen, params, isEditor],
  );

  const LocationComponent = useCallback(
    ({ color, className }) => (
      <TooltipInfo title={tooltips.dashboard.anywhere} followCursor>
        <div>
          <LocationParam
            color={color}
            className={className}
            location={describedLocation}
            onClick={onOpen(popovers.locations)}
            id={generateVeltId(muiVeltIds.buttons.locationParam)}
            isEditor={isEditor}
          />
        </div>
      </TooltipInfo>
    ),
    [describedLocation, isEditor],
  );

  const InterestsComponent = useCallback(
    ({ color, className }) => (
      <TooltipInfo title={tooltips.dashboard.anything} followCursor>
        <div>
          <InlineButton
            color={color}
            className={className}
            onClick={onOpen(popovers.interests)}
            id={generateVeltId(muiVeltIds.buttons.interests)}
          >
            {interestsLabel}
            {isEditor && <Edit />}
          </InlineButton>
        </div>
      </TooltipInfo>
    ),
    [interestsLabel, isEditor],
  );

  return (
    <div data-velt-location={JSON.stringify(veltLocation)}>
      {children({
        hasDemographics: demographicsParamNames.find(
          (paramName) => !getIsFieldValueEmpty(params[paramName]),
        ),
        hasInterests: interestsLabel !== 'Anything',
        hasLocation: Boolean(describedLocation?.value),

        demographicsLabel: <DemographicsLabel params={params} />,
        locationLabel: describedLocation?.displayLabel,
        interestsLabel,

        openLocationPopover: onOpen(popovers.locations),
        openDemographicsPopover: onOpen(popovers.demographics),
        openInterestsPopover: onOpen(popovers.interests),

        DemographicsComponent,
        LocationComponent,
        InterestsComponent,
      })}
    </div>
  );
};

export default QueryBuilder;
