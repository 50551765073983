export const personaProperties = [
  { key: 'summary', label: 'At a glance', placeholder: 'No summary available' },
  {
    label: 'Overview',
    items: [
      {
        key: 'description.overview',
        label: 'Lifestyle',
        placeholder: 'No insights available',
      },
      {
        key: 'description.projectedPassions',
        label: 'Core passions',
        placeholder: 'No passions available',
      },
      {
        key: 'description.predictedInterests',
        label: 'Key tastes',
        placeholder: 'No predicted interests available',
      },
    ],
  },
  {
    label: 'Interests',
    items: [
      {
        key: 'interests.lifestyleBrands',
        label: 'Brands they love',
        placeholder: 'No lifestyle brands available',
      },
      {
        key: 'interests.frequentPlaces',
        label: 'Where they gather',
        placeholder: 'No frequent places available',
      },
      {
        key: 'interests.entertainment',
        label: 'Entertainment choices',
        placeholder: 'No entertainment data available',
      },
      {
        key: 'interests.readingPodcasts',
        label: 'Media consumption',
        placeholder: 'No reading or podcast data available',
      },
      {
        key: 'interests.music',
        label: 'Music taste',
        placeholder: 'No music data available',
      },
    ],
  },
  {
    label: 'Action Plan',
    items: [
      {
        key: 'recommendations.actionableInsightsRealEstate',
        label: 'Real Estate',
        placeholder: 'No real estate insights available',
      },
      {
        key: 'recommendations.actionableInsightsCPG',
        label: 'CPG',
        placeholder: 'No CPG insights available',
      },
      {
        key: 'recommendations.actionableInsightsEntertainment',
        label: 'Entertainment',
        placeholder: 'No entertainment insights available',
      },
      {
        key: 'recommendations.actionableInsightsMedia',
        label: 'Media',
        placeholder: 'No media insights available',
      },
      {
        key: 'recommendations.actionableInsightsRetail',
        label: 'Retail',
        placeholder: 'No retail insights available',
      },
      {
        key: 'recommendations.actionableInsightsAgencies',
        label: 'Agencies',
        placeholder: 'No agencies insights available',
      },
      {
        key: 'recommendations.idealAmenities',
        format: (value:string[]) => (value?.length ? value.map((v) => `* ${v}`).join('\n') : null),
        label: 'Amenities Wishlist',
        placeholder: 'No ideal amenities listed',
      },
    ],
  },
];

export default personaProperties;
