import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import FeatureNoAccessTooltip from '@/screens/HomePage/FeatureNoAccessTooltip';
import tooltips from '@/utils/tooltips';

import ShareWithDialog from './ShareWith/ShareWithDialog';

const MenuItemShare = ({
  dashboard, disabled, onToggleKeyDown, ...props
}) => {
  const popupState = usePopupState({ variant: 'dialog', popupId: 'dashboardShare' });

  const handleClick = () => {
    onToggleKeyDown(true);
    popupState.open();
  };

  return (
    <FeatureNoAccessTooltip disabled={disabled}>
      <TooltipInfo title={tooltips.dashboardCard.share} followCursor>
        <MenuItem onClick={handleClick} disabled={disabled} {...props}>
          <ListItemIcon><PersonAddAltIcon /></ListItemIcon>
          <ListItemText>Share</ListItemText>
        </MenuItem>
      </TooltipInfo>
      <ShareWithDialog
        dashboard={dashboard}
        popupState={{
          ...popupState,
          close: () => {
            onToggleKeyDown(false);
            popupState.close();
          },
        }}
      />
    </FeatureNoAccessTooltip>
  );
};

export default MenuItemShare;
