import React from 'react';

import PanelContentSkeleton from './PanelContentSkeleton';
import PanelFooterSkeleton from './PanelFooterSkeleton';
import PanelHeaderSkeleton from './PanelHeaderSkeleton';

const PanelSkeleton = (props) => (
  <div>
    <PanelHeaderSkeleton {...props} />
    <PanelContentSkeleton {...props} />
    <PanelFooterSkeleton {...props} />
  </div>
);

export default PanelSkeleton;
