export const UNIT = {
  metric: 'metric',
  imperial: 'imperial',
} as const;

export type UnitType = typeof UNIT[keyof typeof UNIT];

export const metersToKm = (meters: number | null): string => {
  if (!meters) return '';
  const kmValue = meters / 1000;
  return kmValue % 1 === 0 ? kmValue.toFixed(0) : kmValue < 1 ? kmValue.toFixed(2) : kmValue.toFixed(1);
};

export const kmToMeters = (km: string | number): number => {
  if (!km) return 0;
  return parseFloat(km.toString()) * 1000;
};

export const metersToMiles = (meters: number | null): string => {
  if (!meters) return '';
  return (meters / 1609.34).toFixed(2);
};

export const milesToMeters = (miles: string | number): number => {
  if (!miles) return 0;
  return parseFloat(miles.toString()) * 1609.34;
};

export const formatDistance = (distance: number | null, unit: UnitType = UNIT.metric): string => { if (distance === null) return '';
  if (unit === UNIT.imperial) {
    const miles = metersToMiles(distance);
    return parseFloat(miles) < 1 ? `${(parseFloat(miles) * 5280).toFixed(0)} ft` : `${miles} mi`;
  }

  return distance < 1000 ? `${distance} m` : `${(distance / 1000).toFixed(2)} km`;
};

export const isValidDecimalInput = (value: string): boolean => {
  return /^\d*\.?\d*$/.test(value);
};
