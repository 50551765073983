import { Box } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Breadcrumb.module.scss';

interface Props {
  items: { label: string, onClick: ()=>void }[]
  separator?: string
  ariaLabel?: string
}

const Breadcrumb = ({ items, separator = '/', ariaLabel = 'breadcrumb' }: Props) => {
  return (
    <Breadcrumbs
      separator={<span className={styles.separator}>{separator}</span>}
      aria-label={ariaLabel}
    >
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;

        if (!isLastItem) {
          return (
            <Box onClick={item.onClick} key={item.label} className={styles.link}>
              {item.label}
            </Box>
          );
        }

        return (
          <span key={item.label} className={styles.link}>
            {item.label}
          </span>
        );
      })}
    </Breadcrumbs>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
    }),
  ).isRequired,
  separator: PropTypes.node,
  ariaLabel: PropTypes.string,
};

export default Breadcrumb;
