import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { api } from '@/constants';
import { getHash } from '@/utils/format';

import { InsightsResponse } from './types';

export const insightsApi = createApi({
  reducerPath: 'insightsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.qlooApiV2}/insights`,
  }),
  tagTypes: ['Insight'],
  endpoints: (builder) => ({
    getInsight: builder.query<InsightsResponse, string>({
      query: (query) => `?${query}`,
      providesTags: (_result, _error, arg) => {
        return [
          { type: 'Insight', id: getHash(arg) },
        ]
      },
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetInsightQuery, useLazyGetInsightQuery } = insightsApi;
