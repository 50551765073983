import clx from 'clsx';
import React from 'react';

import Skeleton from '@/components/Skeleton';

import styles from '../PanelBodyExplore.module.scss';

const CategoryDataSkeleton = ({
  conciseView,
}) => {
  return (
    <div className={clx(
      styles.categoryData,
      styles.categoryDataConcise,
    )}
    >
      <div className={styles.overlay} />
      <div className={styles.categoryHeader}>
        <Skeleton variant="text" width={150} height={30} />
        {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
        <Skeleton variant="rectangular" width={30} height={30} style={{ marginLeft: 'auto' }} />
      </div>
      <Skeleton variant="text" width="100%" height={20} />
      <div className={styles.separator} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      <div className={styles.separator} />
      {!conciseView && <Skeleton variant="rectangular" width="100%" height={150} />}
    </div>
  );
};

export default CategoryDataSkeleton;
