import { Identity } from '@/store/slices/appSlice';

import getIsUserInOrganization from './getIsUserInOrganization';

const getIsQlooUser = (userIdentity: Identity) => getIsUserInOrganization({
  organizationIdentityId: '42924822-32d0-11ee-938f-02d6856f817b',
  userIdentity,
});

export default getIsQlooUser;
