'use client';

import './styles/index.scss';
import '@qloo/design/src/colors.css';

import { VeltProvider } from '@veltdev/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';

import ErrorBoundary from '@/components/ErrorBoundary';

import AppErrorFallback from './AppErrorFallback';
import Router from './Router';

dayjs.extend(relativeTime);

const App = () => (
  <ErrorBoundary FallbackComponent={AppErrorFallback}>
    <VeltProvider apiKey={import.meta.env.VITE_VELT_API_KEY}>
      <Router />
    </VeltProvider>
  </ErrorBoundary>
);

export default App;
