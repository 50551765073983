/// NOTE: This is our corporate api tags and not the qloo api tags
/// provided by the tagsApi.js

import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { api } from '@/constants';
import { mergeTags } from '@/store/slices/tagSlice';
import { getHash } from '@/utils/format';

const backoffDelays = [125, 250, 250, 500, 500];

// eslint-disable-next-line no-unused-vars
const customBackOff = async (attempt = 0, maxRetries = 5) => {
  const attempts = Math.min(backoffDelays.length - 1, maxRetries);

  await new Promise((resolve) => {
    setTimeout(resolve, backoffDelays[attempts]);
  });
};

// eslint-disable-next-line no-secrets/no-secrets
/// see https://stackoverflow.com/questions/76831664/rtk-query-maxretries-backoff
const customBaseQuery = retry(
  fetchBaseQuery({ baseUrl: `${api.hostname}/corporateapi/tag` }),
  { backoff: customBackOff, maxRetries: 5 },
);

export const tagApi = createApi({
  reducerPath: 'tagApi',
  baseQuery: customBaseQuery,
  mode: 'cors',
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json');
    return headers;
  },
  tagTypes: ['tag'],
  endpoints: (builder) => ({
    tagsGet: builder.query({
      query: ({ tagFilters, returnDisabled }) => {
        if (!Array.isArray(tagFilters)) {
          throw new Error('useTagsGetQuery.tagFilters must be an array');
        }

        if (typeof returnDisabled !== 'boolean') {
          throw new Error('useTagsGetQuery.returnDisabled must be a boolean');
        }

        const tagFiltersArrayLiteral = `{${tagFilters?.join(',')}}`;
        return ({
          url: '/tagsGet',
          params: {
            tagFilters: tagFiltersArrayLiteral,
            returnDisabled,
          },
        });
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(mergeTags(data));
      },
      providesTags: (result, error, arg) => [{ type: 'tag', id: getHash(arg) }],
    }),

  }),
});

export const {
  useTagsGetQuery,
} = tagApi;
