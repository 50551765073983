import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useMemo, useState } from 'react';

import Table from '@/components/Table';
import Tag from '@/components/Tag';
import Tooltip from '@/components/Tooltip';
import { paramNames } from '@/constants';
import useActiveDashboard from '@/hooks/useActiveDashboard';
import { affinityColumn, nameColumn } from '@/screens/Dashboard/DashboardPanel/helpers/getColumns';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import serializeInsightsParams from '@/utils/serializeInsightsParams';

import { defaultPageOffset, defaultPageSize } from '../../helpers/DashboardPanelConstants';
import formatTagType from '../formatTagType';
import styles from '../PanelBodyExplore.module.scss';
import { barChartTagTypesBitmap } from '../PanelBodyExploreConstants';
import useTagsFetcher from '../useTagsFetcher';

const defaultTagType = 'Default';

const TagCell = ({ row, selectedCategory }) => {
  return (
    <Tag
      noBorder
      noHover
      key={row.tagId}
      useCategoryColor
      {...row.original}
      isSelected={false}
      showSelectedIcon={false}
      type={row?.original?.subtype}
      category={selectedCategory}
    />
  );
};

const getResultsByTagType = ({ tagType, tags, tagsFetcher }) => (tagType === defaultTagType
  ? tags || []
  : tagsFetcher.state[tagType]?.result || []);

const SelectedCategoryContentTagsTabs = ({
  panelParams,
  selectedCategory,
  tagTypes,
  highlightedSelectedCategory,
}) => {
  const { baseParams } = useActiveDashboard();

  const serializedTagsInsightsParams = useMemo(() => {
    const {
      [paramNames.filterLocation]: filterLocation,
      ...restPanelParams
    } = panelParams;

    const tempParams = {
      ...restPanelParams,
      [paramNames.signalLocation]: filterLocation || restPanelParams[paramNames.signalLocation],
      [paramNames.filterType]: 'urn:tag',
      [paramNames.filterParentTypes]: selectedCategory,
      offset: panelParams?.offset || defaultPageOffset,
      take: (panelParams?.take || defaultPageSize) + 1,
    };

    if (highlightedSelectedCategory) {
      tempParams[paramNames.filterTags] = { [highlightedSelectedCategory]: 'include' };
    }

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, panelParams, selectedCategory, highlightedSelectedCategory]);

  const {
    data: tagsResult,
    isLoading: isTagsLoading,
    error: insightsTagsError,
  } = useGetInsightQuery(serializedTagsInsightsParams);

  const tags = useMemo(
    () => (insightsTagsError ? [] : tagsResult?.results?.tags.slice(0, panelParams?.take || defaultPageSize)),
    [tagsResult?.results?.tags, insightsTagsError, panelParams],
  );

  const [tabValue, setTabValue] = useState(defaultTagType);
  const handleTabChange = (e, nextTabValue) => {
    setTabValue(nextTabValue);
  };

  const tagsFetcher = useTagsFetcher();
  useEffect(() => {
    tagsFetcher.fetchByTagTypes({
      baseParams,
      panelParams,
      selectedCategory,
      tagTypes,
      highlightedSelectedCategory,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseParams, selectedCategory, highlightedSelectedCategory]);

  const tagTypesWithDefault = [defaultTagType, ...tagTypes].filter((tagType) => !barChartTagTypesBitmap[tagType]);

  if (isTagsLoading) {
    return null;
  }

  return (
    <div className={styles.tableWithTitle}>
      <div className={styles.tableTitle}>Tags</div>
      <TabContext value={tabValue}>
        <div className={styles.tabs}>
          <Tabs onChange={handleTabChange} scrollButtons value={tabValue}>
            {tagTypesWithDefault.map((tagType) => {
              const formattedTagType = formatTagType(tagType);
              const label = tagType === defaultTagType ? 'Default' : formattedTagType;
              const result = getResultsByTagType({ tagType, tags, tagsFetcher });
              const disabled = result.length === 0;

              if (disabled) {
                return (
                  <Tooltip
                    arrow
                    followCursor
                    placement="top"
                    title={`No results for ${formattedTagType}`}
                    key={tagType}
                  >
                    <div>
                      <Tab
                        disabled
                        label={label}
                        value={tagType}
                        key={tagType}
                      />
                    </div>
                  </Tooltip>
                );
              }

              return (
                <Tab
                  label={label}
                  value={tagType}
                  key={tagType}
                />
              );
            })}
          </Tabs>
        </div>
        {tagTypesWithDefault.map((tagType) => {
          const isLoading = tagType === defaultTagType ? isTagsLoading : tagsFetcher.state[tagType]?.isLoading || false;
          const result = getResultsByTagType({ tagType, tags, tagsFetcher });
          return (
            <TabPanel value={tagType} key={tagType}>
              <Table
                isLoading={isLoading}
                columns={[
                  {
                    ...nameColumn,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    Cell: (props) => <TagCell {...props} selectedCategory={selectedCategory} />,
                  },
                  affinityColumn,
                ]}
                data={result}
              />
            </TabPanel>
          );
        })}
      </TabContext>
    </div>
  );
};

export default SelectedCategoryContentTagsTabs;
