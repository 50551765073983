import { Button as MuiButton } from '@mui/base/Button';
import { ButtonProps, CircularProgress } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import styles from './Button.module.scss';

interface CustomButtonProps extends ButtonProps {
  isLoading?: boolean;
  noPadding?: boolean;
  bold?: boolean;
}

const Button: React.FC<CustomButtonProps> = ({
  children,
  className,
  variant = 'contained',
  color = 'primary',
  isLoading = false,
  size = 'medium',
  noPadding = false,
  disabled = false,
  bold = false,
  id,
  ...rest
}) => {
  return (
    <MuiButton
      disabled={disabled}
      id={id}
      className={clsx(
        styles.button,
        styles[color],
        styles[variant],
        styles[size],
        {
          [styles.noPadding]: noPadding,
          [styles.disabled]: disabled,
          [styles.bold]: bold,
        },
        className,
      )}
      {...rest}
    >
      {!!isLoading && (
        <>
          <CircularProgress size={16} color="inherit" />
          &nbsp;
        </>
      )}
      {children}
    </MuiButton>
  );
};

export default Button;
