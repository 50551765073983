import { Typography } from '@mui/material';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { useState } from 'react';
import { ArrowRight } from 'react-feather';

import DashboardPermissionControl from '@/components/DashboardPermissionControl';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import { panelTypeLabels } from '@/constants/panelTypes.js';
import permissionControlVariants from '@/constants/permissionControlVariants';
import permissionTags from '@/constants/permissionTags';
import usePanelSuggestions from '@/hooks/usePanelSuggestions';
import { PanelSuggestionItem } from '@/screens/Dashboard/DashboardPanel/PanelBodySuggestions/helpers/types';
import SuggestionsSidebar from '@/screens/Dashboard/DashboardPanel/PanelBodySuggestions/SideBar/SuggestionsSidebar';
import SuggestionCard from '@/screens/Dashboard/DashboardPanel/PanelBodySuggestions/SuggestionCard';
import { DashboardItem } from '@/store/slices/dashboardSliceTypes';
import tooltips from '@/utils/tooltips';

import { generateTitle } from './helpers/panelHelpers';
import styles from './PanelBodySuggestions.module.scss';

interface PanelBodySuggestionsProps {
    dashboard: DashboardItem;
}

const PanelBodySuggestions = ({ dashboard }: PanelBodySuggestionsProps) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'suggestionsSidebar' });
  const {
    panelSuggestions,
    addPanelWithParams,
    entityName,
    location,
    formattedCategory,
    demographicsLabels,
    isLayout,
    generateAllPanels,
  } = usePanelSuggestions({ dashboard });
  const [hoveredCard, setHoveredCard] = useState<string | number>();

  return (
    <div className={styles.suggestionsContainer}>
      <div className={styles.containerTitle}>
        {!isLayout && (<h3 className={styles.title}>Suggested for you</h3>)}
        <DashboardPermissionControl
          permissionsRequired={[permissionTags.roleEditor]}
          variant={permissionControlVariants.tooltip}
        >
          <TooltipInfo title={isLayout ? tooltips.dashboardSuggestions.view : tooltips.dashboardSuggestions.createAll}>
            <Typography
              onClick={isLayout ? popupState.open : generateAllPanels}
              className={styles.textLinkSuggestions}
            >
              {isLayout
                ? <span className={styles.containerSubtitleWithIcon}> View Suggestions <ArrowRight size={16} /></span>
                : 'Create All'}
            </Typography>
          </TooltipInfo>
        </DashboardPermissionControl>
      </div>
      {!isLayout && Object.entries(panelSuggestions)?.map(([panelType, suggestions]) => (
        <div key={panelType} className={styles.containerPanelType}>
          <h4 className={styles.panelTitle}>{panelTypeLabels[panelType] || 'Other Panels'}</h4>
          <div className={styles.suggestionsGrid}>

            {(suggestions as PanelSuggestionItem[]).map((item) => (
              <DashboardPermissionControl
                key={item.id}
                permissionsRequired={[permissionTags.roleEditor]}
                variant={permissionControlVariants.tooltip}
              >
                <SuggestionCard
                  className={styles.suggestionItem}
                  title={generateTitle(
                    item.title,
                    entityName,
                    location,
                    formattedCategory,
                    demographicsLabels,
                  )}
                  onClick={() => addPanelWithParams(item.panelType, item.additionalParams)}
                  onMouseEnter={() => setHoveredCard(item.id)}
                  onMouseLeave={() => setHoveredCard(null)}
                  isHovered={hoveredCard === item.id}
                />
              </DashboardPermissionControl>
            ))}
          </div>
        </div>
      ))}
      <SuggestionsSidebar
        dashboard={dashboard}
        popupState={{
          ...popupState,
          close: () => {
            popupState.close();
          },
        }}
      />
    </div>
  );
};

export default PanelBodySuggestions;
