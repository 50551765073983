export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  properties: {
    personaCount: {
      type: 'number',
      title: 'Number of personas to generate',
      label: 'How many personas would you like to generate?',
      minimum: 1,
      maximum: 5,
    },
  },
  required: ['personaCount'],
};
