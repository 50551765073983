import { Box } from '@mui/material';

import PanelBodySkeleton from './PanelBodySkeleton';
import PanelParamsSkeleton from './PanelParamsSkeleton';

const PanelContentSkeleton = (props) => (
  <Box display="flex" flexDirection="column" height="100%">
    <PanelParamsSkeleton {...props} />
    <PanelBodySkeleton {...props} />
  </Box>
);

export default PanelContentSkeleton;
