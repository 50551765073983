import { paramNames } from '@/constants';
import getDemographicsLabel from '@/utils/getDemographicsLabel';
import getParamsLabel from '@/utils/getParamsLabel';

const PROMPTS_BY_PLATFORM = {
  Email: `
    # Variations
    Write multiple variations of the email copy, exploring different tones and approaches, such as:
    * Concise & Professional – Straight to the point, with a clear CTA.
    * Conversational & Friendly – More informal and engaging, as if writing to a friend.
    * Storytelling Approach – Start with a relatable anecdote or problem before presenting the solution.
    * Urgency & Scarcity – Highlight limited availability or time-sensitive benefits.
    * Data-Driven & Logical – Emphasize statistics, testimonials, or research-backed insights.
    * Emotional Appeal – Use emotional language to connect with the reader.
    * Humor – Use humor to make the copy more engaging.
  `,
  TikTok: `
    Craft engaging TikTok captions, hooks, and CTA prompts for a video targeting this audience.

    # Variations
    Generate multiple variations of the TikTok copy, exploring different angles and tones, including:
    * Short & Punchy Hook – A quick, eye-catching line that grabs attention within the first 3 seconds.
    * Humorous & Trend-Based – A playful caption or witty take that aligns with TikTok trends.
    * FOMO & Urgency – A caption that makes users feel they can’t afford to miss out.
    * Storytelling & Relatable – A more narrative-driven approach that hooks viewers emotionally.
    * Direct & Action-Oriented – A CTA-focused line that encourages comments, shares, or saves.
    * Challenge or Engagement-Based – A caption that invites audience participation (e.g., "Duet this if you agree!").
    * Data-Driven & Informational – A stat or fact that intrigues users to keep watching.
    * Edgy & Controversial – A thought-provoking or bold take designed to spark discussion.
    * Each variation should be optimized for TikTok’s short-form nature and encourage interaction through comments, likes, shares, or stitches/duets.
    * Bonus: Suggest relevant hashtags and a CTA to maximize engagement.
  `,
  Twitter: `
    Craft engaging tweets for this audience. Ensure the tweets feel authentic, engaging, and optimized for virality. If relevant, suggest hashtags, emojis, or follow-up engagement strategies.

    # Variations
    Generate multiple tweet variations exploring different styles and approaches, including:
    * Concise & Impactful – A short, high-impact tweet that makes users stop scrolling.
    * Thread Starter – A compelling first tweet that encourages users to read a multi-tweet thread.
    * Hot Take / Controversial Opinion – A bold or contrarian statement that sparks engagement.
    * Relatable & Conversational – A tweet that feels personal, funny, or highly relatable.
    * List Format – A tweet that presents quick, actionable insights in a numbered or bullet-point format.
    * Question / Poll – A tweet designed to encourage replies and interaction.
    * Storytelling Approach – A short narrative or anecdote that leads to a takeaway.
    * Data-Driven & Authority-Building – A tweet that shares a compelling stat, insight, or industry trend.
    * Call-to-Action (CTA) Focused – A tweet that directly encourages retweets, likes, or replies.
    * Meme or Trend-Based – A tweet that integrates a trending meme or cultural moment for engagement.
    * Bonus: Include an option for a long-form tweet (X Premium) for deeper storytelling or thought leadership.
  `,
  Instagram: `
    Crafting engaging captions and CTAs for targeting on Instagram. Each caption should be designed for Instagram’s visual-first nature and optimized for engagement (likes, shares, saves, and comments). If relevant, suggest emoji usage, line breaks for readability, and strategic hashtag placement.

    Generate multiple variations of the Instagram caption, exploring different tones and styles, including:

    # Variations
    * Short & Snappy – A quick, attention-grabbing caption that hooks users immediately.
    * Storytelling & Relatable – A caption that shares a personal or emotional story to build connection.
    * Humorous & Playful – A fun, lighthearted take that makes the audience smile or laugh.
    * Question or Engagement-Driven – A caption designed to encourage comments and discussions.
    * FOMO & Urgency – A caption that creates a sense of exclusivity or a time-sensitive opportunity.
    * Educational & Value-Packed – A tip, stat, or fact that provides immediate value to the reader.
    * Motivational & Inspirational – A caption that uplifts and encourages action.
    * Call-to-Action (CTA) Focused – A direct invitation to like, comment, share, or take the next step.
    * Trend & Meme-Based – A caption that taps into current Instagram trends, memes, or viral challenges.
    * Hashtag-Optimized – A version that incorporates the best hashtags to increase reach and discoverability.
    * Bonus: Include options for Instagram Reels captions with engaging hooks that complement short-form video content.
`,
  'Out-Of-Home advertising': `
      Craft compelling, high-impact copy. The goal of this out-of-home (OOH) advertisement is to build brand awareness and drive curiosity that leads to purchases. Each variation should be optimized for fast readability (5-7 words max for billboards), high impact, and strong brand recall. If relevant, suggest design or layout considerations to enhance the copy’s effectiveness. Generate multiple variations of billboard and transit ad copy, exploring different creative angles, including:

      # Variations
      * Short & Bold – A punchy, memorable tagline that can be read in seconds.
      * Curiosity-Driven – A mysterious or thought-provoking line that sparks intrigue.
      * Humorous & Playful – A witty or clever phrase that makes people smile.
      * Benefit-Focused – A message that highlights the product’s key advantage in an instant.
      * Call-to-Action (CTA) Driven – A version that directs viewers to take immediate action (e.g., "Scan Here," "Try Today").
      * Emotional & Storytelling-Based – A brief but powerful message that evokes feeling.
      * Trend & Pop Culture-Inspired – A line that taps into current social or cultural trends.
      * Minimalist & Visual-Dependent – A version where the copy is ultra-simple, relying on design to complete the message.
      * Hyper-Local Angle – A variation that speaks directly to the specific location or city where the ad is placed.
      * Disruptive & Unexpected – A copy approach that surprises or challenges conventional messaging.
      * Bonus: Provide variations for different OOH formats, such as:
      * * Billboards (highway, urban, digital)
      * * Bus stop & transit ads
      * * Street posters & wild postings
      * * Airport & mall signage
  `,
};

export default async ({
  baseParams, entities, fetchGpt, tags, userData,
}) => {
  try {
    const demographicsLabel = getDemographicsLabel(baseParams);
    const paramsLabel = getParamsLabel(baseParams);

    const audience = `${paramsLabel} ${demographicsLabel}${baseParams[paramNames.signalLocation] ? `and lives in ${baseParams[paramNames.signalLocation].label}` : ''}`;

    const personalityProfile = await fetchGpt({
      prompt: `
        Generate a personality profile for someone who is considered: ${audience}.
        They have a strong interest in ${entities.map((entity) => entity.name).join(', ')}
        and may also be drawn to: ${tags.map((tag) => tag.name).join(', ')}.

        Describe their age, background, and lifestyle in the third person. Without explicitly naming their interests, explain what connects these subjects and what they mean to them. Provide insight into their daily routine and how their passions influence their worldview. Conclude with an overview of their personality traits and characteristics.
      `,
    });

    const summarized = await fetchGpt({
      mode: 'markdown',
      prompt: `
        You are an expert copywriter crafting a compelling copy for ${userData.brand}, targeting someone who is considered: ${audience}. ${userData.instructions}
        This audience likes ${entities.map((entity) => entity.name).join(', ')}
        and might be interested in: ${tags.map((tag) => tag.name).join(', ')}.
        The audience is described as: ${personalityProfile}

        Notes for tone:
        * Match the Brand Voice: Stay consistent with the company’s existing tone (e.g., casual vs. formal).
        * Clarity & Simplicity: Avoid jargon or overly complex language.
        * Strong Call to Action (CTA): Make it easy for the reader to take the next step.
        * Emphasize “You” Over “We”: Focus on how the message benefits the reader, not just what the company does.
        * Write for Skimmers: Use short paragraphs, bullet points, and bold key phrases.
        * Test Subject Lines: Create multiple variations to optimize open rates.
        * A/B Testing Considerations: Suggest different angles for testing engagement levels.

        Write a markdown document with the following sections, only return the markdown document, no leading text.

        ${PROMPTS_BY_PLATFORM[userData.platform] || PROMPTS_BY_PLATFORM.Email}
    `,
    });

    const tldr = await fetchGpt({
      prompt: `
        You are an expert copywriter crafting a compelling copy for ${userData.brand}, targeting someone who is considered: ${audience}. ${userData.instructions}
        The audience is described as: ${personalityProfile}
        This audience likes ${entities.map((entity) => entity.name).join(', ')}
        and might be interested in: ${tags.map((tag) => tag.name).join(', ')}.

        This copy was written for ${userData.platform}. In one concise paragraph, explain and summarize the copy and techniques here in a few sentences, using marketing theory, and how it relates to the audience, brand, and platform in combination.

        ${summarized}
      `,
    });

    const final = `# Introduction
${tldr}
${summarized}`;

    return final;
  } catch (e) {
    return 'Error';
  }
};
