import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/system';
import * as React from 'react';
import { Check, X } from 'react-feather';

const ToggleButtonColor = styled(ToggleButton)(({ selectedColor }) => ({
  width: 30,
  height: 24,
  padding: 7,
  fontSize: '10px',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: selectedColor,
  },
  '& .MuiSvgIcon-root': {
    width: 17,
  },
}));

const ToggleButtonGroupColor = styled(ToggleButtonGroup)(({ value }) => ({
  '&.MuiToggleButtonGroup-root': {
    width: 46,
    height: 24,
    backgroundColor: value
      ? 'hsl(var(--status-success))' : value === null ? 'hsl(var(--muted))' : 'hsl(var(--status-danger))',
    borderRadius: 'var(--border-radius-rounded)',
    padding: 2,
    gap: 2,
    transition: 'all 250ms ease',
    '& .MuiToggleButton-root': {
      width: 20,
      height: 20,
      border: 0,
      padding: 0,
      borderRadius: '30px !important',
    },
    '& .MuiToggleButtonGroup-firstButton': {
      backgroundColor: value === false ? 'white' : 'transparent',
      svg: {
        strokeWidth: 4,
        opacity: (value || value === null) ? 0.6 : 1,
        color: value ? 'hsl(var(--white))' : value === null ? 'hsl(var(--gray))' : 'hsl(var(--status-danger))',
      },
    },
    '& .MuiToggleButtonGroup-lastButton': {
      backgroundColor: value ? 'hsl(var(--white))' : 'transparent',
      svg: {
        strokeWidth: 4,
        opacity: !value ? 0.6 : 1,
        color: value ? 'hsl(var(--status-success))' : value === null ? 'hsl(var(--gray))' : 'hsl(var(--white))',
      },
    },
  },
}));

const EnableDisableToggle = ({
  className, name, onChange, value,
}) => {
  const handleChange = (_, newState) => {
    onChange(name, newState);
  };

  return (
    <ToggleButtonGroupColor size="small" exclusive className={className} onChange={handleChange} value={value}>
      <ToggleButtonColor value={false} key="Disabled">
        <X size={12} />
      </ToggleButtonColor>
      <ToggleButtonColor value key="Enabled">
        <Check size={12} />
      </ToggleButtonColor>
    </ToggleButtonGroupColor>
  );
};

export default EnableDisableToggle;
