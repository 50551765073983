import ReactMarkdown from 'react-markdown';

import QuantityInput from '@/components/QuantityInput';

import getPersonaResultMarkdown from '../shared/getPersonaResultMarkdown';
import personaForm from '../shared/personaForm';
import personaInsights from '../shared/personaInsights';
import personaSchema from '../shared/personaSchema';

const mapEntity = (entity) =>
  `Entity: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const mapTag = (entity) =>
  `Tag Genre: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const personas = {
  name: 'Personas',
  description: 'Generate personas with action plans across various industries',
  form: personaForm,
  formUiSchema: {
    personaCount: {
      'ui:widget': QuantityInput,
    },
  },
  insights: personaInsights,
  render: ({ result }) => {
    const personasResult = result?.personas || [];
    const markdown = getPersonaResultMarkdown(personasResult);
    return <ReactMarkdown>{markdown}</ReactMarkdown>;
  },
  runner: async ({
    entities, fetchGpt, tags, userData,
  }) => {
    const { personaCount } = userData;
    //    //Ensure that any regional, locational, cultural nuances already present in the input data are highlighted.
    const prompt = `
    As a persona developer, your task is to create ${personaCount} rich and distinct personas using only the provided input data.
    Input Data:
    Tags: ${tags.map(mapTag).join(' ')}
    Entities: ${entities.map(mapEntity).join(' ')}
    - Utilize the urn:tag:audience:qloo and urn:tag:lifestyle:qloo to construct the personas.

    Rules:
    Do NOT assign a name to the persona.
    **NO generic personas.** Every persona must have a clear, unique identity and **realistic habits**. Infuse micro-moments into the personas.
    **Vary tone and voice.** Each persona should feel **different**.
    **Make behaviors specific.** Frame interests as **real-world habits, decisions, and moments. ** Avoid vague statements.
    **Minimal overlap.** Each persona should **contrast** with the others in lifestyle, motivation, and engagement.
    **Affinity scores must justify every choice.** If a brand or place entity or tag is listed, explain why it matters based on the score.

    Persona Breakdown & Writing Style
    Each persona should include **rich details, unique behaviors, and contextual depth.**
    ** Summary (3-4 sentences):**
    - Who they are, their **core lifestyle traits**, and what makes them distinct.

    **Overview:**
    - Lifestyle (2-3 sentences): Describe how they navigate their world through their interests.
    - Core Passions (2-3 sentences): Explain why their interests matter and **how they experience them.**
    - Key Tastes (2-3 sentences): Highlight how they engage with brands, trends, or experiences.

    **Interests (Justification-Based Formatting): Each interest must be backed by affinity scores to ensure specificity.**
    - **Brands They Love (3-5 examples with scores to support):**
    - **Where They Gather (3-5 examples with scores to support):**
    - **Entertainment Choices (3-5 examples with scores to support):**
    - **Music Taste (3-5 examples with scores to support):**

    **Action Plan (How to engage this persona):**
    - Each recommendation must be **practical, strategic, and based on affinity scores.**

    Persona Writing Guide:
    **Ensure all insights are grounded in affinity scores and persona context.**
    **Force contrast:** Every persona should feel different from the others in interests, tone, and lifestyle.
    **Use micro-moments:** Instead of vague statements, describe **specific behaviors.**
    **No fluff, no generic filler.** Every detail must have a **purpose and connection** to the input data.
    **Write like a human, not a template.** If it sounds very predictable, it’s wrong.
    Ensure **contextually relevant** activities, environments, and lifestyle preferences.
    `;

    try {
      const gptResult = await fetchGpt({
        mode: 'json',
        prompt,
        schema: personaSchema(personaCount),
      });
      return gptResult;
    } catch (e) {
      return 'Error';
    }
  },
};

export default personas;

// Do NOT assume residence unless clearly supported by highest scoring geographic tag and overall data.
// Do NOT generate generic personas, each must feel like a unique individual with real habits.
// Ensure diversoty across persona - minimal overlap between them.
// Use affinity scores to justify choices, every preference must be backed by explicit scores from the input data.

// How to Construct a Distinct and Compelling Persona
// Each persona should include **rich details, unique behaviors, and contextual depth.**
// Use realistic behaviors & micro-moments, avoid generic and vague statements.
// Frame interests within real-life actions.
// Prioritize entities that complement the persona's broader theme.
// Location Handling:
// - If a geographic tag has the highest affinity score, it may define residence.
// - If it is lower-scoring, treat it as a cultural influence instead of residence.

//     Additional Notes:
//     Ensure **contextually relevant** activities, environments, and lifestyle preferences.
//     No **generic recommendations**—each persona must be **richly detailed** and **unique.**
//     Follow **justification-based formatting** to connect interests with affinity scores.
// // 1. Create ${personaCount} distinct Personas
// Each persona must directly reflect and be grounded in the provided data. Ensure regional, cultural and locational nuances already present in the input are highlighted.
// Highlight only the dominant location when relevant.
// Avoid over-representing a single location. Prioritize diversity across personas.
// Input Data:
// Tags: ${tags.map(mapTag).join(' ')}
// Entities: ${entities.map(mapEntity).join(' ')}

// 2. Data Requirements:
// Use only the provided tags, entities, and their affinity scores to define each persona. Avoid creative archetypes or backstories. Do not repeat tags, entities, or venues across personas.
// Favor a mix of high-affinity and lower-affinity entities/tags to produce more variety. Prioritize entities that complement the persona's broader theme.
// If a geographic tag is categorized as a trend rather than a place of residence, highlight it as an influence rather than as a home location.

// 3. Justifications for Interests:
// Provide explicit reasons why a tag or entity aligns with the persona. Example:
// "This persona connects with Revolution Brewing (score: 0.999) for its community-driven ethos, which aligns with their passion for local cultural events."
// Ensure activities, preferences, and lifestyle align dynamically with the context of the input data (e.g., outdoor activities for nature-related tags, or urban experiences for city-specific data). Avoid including activities or preferences unsupported by the input data.

// 4. Key Requirements:
// Minimal Overlap: Avoid repeating entities or tags unless justified by shared overarching themes.
// Specificity: Use affinity scores to justify the selection of all tags and entities.
// Adaptability: Ensure each persona's activities, preferences, and lifestyle adapt to the context inferred from the provided data.

// 5.Final Output:
// Return all personas in a JSON array structured according to this schema: ${JSON.stringify(personaSchema)}

// 6. Additional Notes:
// Make sure to dynamically infer and emphasize activities, environments, and preferences based on the provided data's context, ensuring the personas remain contextually relevant.
