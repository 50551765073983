import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Audience } from '@/entity/audience/AudienceTypes';
import keyBy from '@/utils/keyBy';

interface AudienceState {
  byId: Record<string, Audience>;
}

const initialState: AudienceState = {
  byId: {},
};

export const audiencesSlice = createSlice({
  name: 'audiences',
  initialState,
  reducers: {
    fetchAudienceStart: (state, action: PayloadAction<string>) => {
      const { byId } = state;
      const audienceId = action.payload;

      return {
        ...state,
        byId: {
          ...byId,
          [audienceId]: {
            ...(byId[audienceId] || {}),
          } as Audience,
        },
      };
    },
    mergeAudiences: (state, action: PayloadAction<Audience[]>) => {
      const audiences = action.payload;

      return {
        ...state,
        byId: { ...state.byId, ...keyBy(audiences, 'id') },
      };
    },
  },
});

export const { fetchAudienceStart, mergeAudiences } = audiencesSlice.actions;
