/* eslint-disable no-inline-styles/no-inline-styles */
import Dot from '@mui/icons-material/FiberManualRecord';
import clsx from 'clsx';
import React, { memo } from 'react';

import { useRequestContext } from '../../lib/RequestContext';
import { ReactComponent as LogoSmSvg } from '../LogoSm.svg';
import classes from './index.module.scss';
import { ReactComponent as LoaderSvg } from './Loader.svg';

export const LoaderBase = memo(({
  size = 22, isLoading, isSuccess, isError, resetMargin, ...props
}) => (
  <div
    className={clsx({
      [classes.container]: true,
      [classes.resetMargin]: resetMargin,
    })}
    style={size && { width: `${size}px` }}
  >
    {
      isLoading
        ? <LoaderSvg {...props} />
        : isSuccess
          ? <Dot {...props} className={classes.success} />
          : isError
            ? <Dot {...props} className={classes.error} />
            : <LogoSmSvg {...props} />
    }
  </div>
));

const Loader = () => {
  const { request } = useRequestContext();

  return (
    <LoaderBase
      isLoading={request.isLoading}
      isSuccess={request?.response?.status === 200}
      isError={request?.response?.status && request?.response?.status !== 200}
    />
  );
};

export default Loader;
