import React from 'react';

import Skeleton from '@/components/Skeleton';

import styles from '../DashboardPanel.module.scss';

interface Props {
  disabled: boolean
}

const PanelBodySkeleton = ({ disabled }: Props) => (
  <div className={styles.panelBodyContainer}>
    <div style={{ height: '100%', width: '100%' }}>
      <Skeleton disabled={disabled} height="98%" />
    </div>
  </div>
);

export default PanelBodySkeleton;
