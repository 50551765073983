import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu } from '@mui/material';
import useHasDashboardPermissions from '@v1/hooks/useHasDashboardPermissions';
import React, { useEffect, useState } from 'react';

import DashboardPermissionControl from '@/components/DashboardPermissionControl';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import muiVeltIds from '@/constants/muiVeltIds';
import permissionControlVariants from '@/constants/permissionControlVariants';
import permissionTags from '@/constants/permissionTags';
import useDashboard from '@/hooks/useDashboard';
import usePortalDialog from '@/hooks/usePortalDialog';
import useScrollBlock from '@/hooks/useScrollBlock';
import tooltips from '@/utils/tooltips';

import MenuItemAbout from './MenuItemAbout';
import MenuItemClone from './MenuItemClone';
import MenuItemDelete from './MenuItemDelete';
import MenuItemFavorite from './MenuItemFavorite';
import MenuItemOpenCreativesModal from './MenuItemOpenCreativesModal';
import MenuItemRename, { RenameDialog } from './MenuItemRename';
import MenuItemShare from './MenuItemShare';

const DashboardContextMenu = ({
  dashboardId,
  showFavorite = undefined,
  onConfirmDelete = undefined,
  onOpenCreativesModal = undefined,
  shouldShowCreatives = undefined,
}) => {
  const dashboard = useDashboard(dashboardId);
  const [anchorEl, setAnchorEl] = useState(null);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [isEnableKeyDown, setIsEnableKeyDown] = useState(false);
  const { openDialog, closeDialog, portal } = usePortalDialog();

  const isEditor = useHasDashboardPermissions(dashboardId, [permissionTags.roleEditor]);
  const isOwner = useHasDashboardPermissions(dashboardId, [permissionTags.roleOwner]);
  const isCloner = useHasDashboardPermissions(dashboardId, [permissionTags.roleClone]);
  const isDeleter = useHasDashboardPermissions(dashboardId, [permissionTags.roleDeleter]);

  const handleOpen = (event) => {
    event.stopPropagation();
    blockScroll();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    allowScroll();
    setAnchorEl(null);
  };

  const handleConfirmDelete = () => {
    if (onConfirmDelete) {
      onConfirmDelete();
    }
    handleClose();
  };

  // Enable scrolling when the component is unmounted
  useEffect(() => {
    return () => {
      allowScroll();
    };
  }, [allowScroll]);

  const handleOpenCreativesModal = () => {
    handleClose();
    onOpenCreativesModal();
  };

  const handleOpenRenameDialog = () => {
    openDialog(
      <RenameDialog
        dashboard={dashboard}
        open
        onClose={closeDialog}
        onConfirm={closeDialog}
      />,
    );
    handleClose(); // Close menu, but keep rename dialog open
  };

  return (
    <div>
      <TooltipInfo title={tooltips.dashboardCard.menu}>
        <IconButton aria-label="settings" onClick={handleOpen} id={`${dashboardId}-${muiVeltIds.buttons.dashboardMenu}`}>
          <MoreVertIcon color="secondary" />
        </IconButton>
      </TooltipInfo>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        disableAutoFocus
        onKeyDownCapture={(e) => {
          if (isEnableKeyDown) e.stopPropagation();
        }}
      >
        <MenuItemAbout dashboard={dashboard} />

        <MenuItemClone
          dashboard={dashboard}
          disabled={!isCloner}
          onToggleKeyDown={setIsEnableKeyDown}
        />

        <DashboardPermissionControl
          dashboardId={dashboardId}
          permissionsRequired={[permissionTags.roleEditor]}
          variant={permissionControlVariants.tooltip}
        >
          <MenuItemDelete dashboard={dashboard} disabled={!isDeleter} onDeleted={handleConfirmDelete} />
        </DashboardPermissionControl>

        {!!showFavorite && (
          <MenuItemFavorite dashboard={dashboard} hasHorizontalPadding />
        )}

        <DashboardPermissionControl
          dashboardId={dashboardId}
          permissionsRequired={[permissionTags.roleEditor]}
          variant={permissionControlVariants.tooltip}
        >
          <MenuItemRename
            onClick={handleOpenRenameDialog}
            dashboard={dashboard}
            disabled={!isEditor}
            onClose={handleClose}
          />

          <MenuItemShare
            dashboard={dashboard}
            disabled={!isOwner}
            onToggleKeyDown={setIsEnableKeyDown}
          />

          {shouldShowCreatives ? <MenuItemOpenCreativesModal onClick={handleOpenCreativesModal} /> : null}
        </DashboardPermissionControl>
      </Menu>

      {/* Render the rename dialog inside the portal (sibling to the menu) */}
      {portal}
    </div>
  );
};

export default DashboardContextMenu;
