// NavigationBlockerContext.jsx
import React, { createContext } from 'react';

import { useAppSelector } from '@/store';

import useBeforeUnload from './useBeforeUnload';

const NavigationBlockerContext = createContext();

/// NOTE: The built in useBlocker does not work with a HashRouter.
/// see https://reactrouter.com/6.28.1/hooks/use-blocker for details.

const NavigationBlockerProvider = ({ children }) => {
  // Access the Redux state
  const hasUnsavedChanges = useAppSelector((state) => state.unsavedChanges.hasChanges);
  const blockMessage = 'You have unsaved changes. Are you sure you want to leave this page?';

  // Apply navigation blocking
  useBeforeUnload(hasUnsavedChanges, blockMessage);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <NavigationBlockerContext.Provider value={{ hasUnsavedChanges }}>
      {children}
    </NavigationBlockerContext.Provider>
  );
};

export default NavigationBlockerProvider;
