import Menu from '@mui/material/Menu';
import { urnsToDisplayName } from '@qloo/categories';
import { useCategoryPerms } from '@v1/lib/usePermissions';
import clsx from 'clsx';
import { nestedMenuItemsFromObject } from 'mui-nested-menu';
import React, { useMemo, useState } from 'react';

import Button from '@/components/Button';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import muiVeltIds from '@/constants/muiVeltIds';
import panelTypes, { panelTypeLabels } from '@/constants/panelTypes';
import Insight from '@/icons/InsightIcon';
import { useAppSelector } from '@/store';
import featureFlagUtils, { featureFlags } from '@/utils/featureFlagUtils';
import getIsQlooUser from '@/utils/getIsQlooUser';
import tooltips from '@/utils/tooltips';

import { useDashboardActionsContext } from '../DashboardActionsProvider';
import styles from './DashboardToolbar.module.scss';

const getMenuItemsData = ({
  categories, dashboardActions, isQlooUser, isTextInsightEnabled,
}) => {
  const menuItems = [
    {
      id: panelTypes.table,
      label: panelTypeLabels[panelTypes.table],
      callback: () => {
        dashboardActions.addPanel();
      },
    },
    {
      id: panelTypes.heatmap,
      label: panelTypeLabels[panelTypes.heatmap],
      callback: () => {
        dashboardActions.addHeatmapPanel();
      },
    },
    {
      id: panelTypes.locationExplorer,
      label: panelTypeLabels[panelTypes.locationExplorer],
      callback: () => {
        dashboardActions.addLocationExplorerPanel();
      },
    },
    {
      id: panelTypes.explore,
      label: panelTypeLabels[panelTypes.explore],
      callback: () => {
        dashboardActions.addExplorePanel();
      },
      items: [
        {
          id: 'All',
          label: 'All',
          callback: () => {
            dashboardActions.addExplorePanel();
          },
        },
        ...categories
          .map((category) => ({
            id: category,
            label: urnsToDisplayName(category)[0] || '',
            callback: () => {
              dashboardActions.addExplorePanel({}, { initialSelectedCategory: category });
            },
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ],
    },
    {
      id: panelTypes.freeformText,
      label: panelTypeLabels[panelTypes.freeformText],
      callback: () => {
        dashboardActions.addFreeformTextPanel();
      },
    },
  ];

  return isTextInsightEnabled ? menuItems : menuItems.filter((item) => item.id !== panelTypes.freeformText);
};

const NewInsightPopover = () => {
  const categoryPerms = useCategoryPerms();
  const dashboardActions = useDashboardActionsContext();
  const { identity } = useAppSelector((state) => state.app);

  const menuItemsData = useMemo(() => {
    const isTextInsightEnabled = featureFlagUtils.isEnabled(identity, featureFlags.text_insight);
    const categories = Object.keys(categoryPerms);
    const isQlooUser = Boolean(getIsQlooUser(identity));
    return getMenuItemsData({
      categories, dashboardActions, isQlooUser, isTextInsightEnabled,
    });
  }, [dashboardActions, identity]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const menuItems = nestedMenuItemsFromObject({
    handleClose,
    isOpen: open,
    menuItemsData,
  });

  return (
    <div className={styles.newInsightButtonContainer}>
      <Button onClick={handleClick} variant="text" id={muiVeltIds.buttons.newInsight}>
        <div className={clsx({
          [styles.newInsightButton]: true,
          [styles.newInsightButtonOpen]: open,
        })}
        >
          <div className={styles.newInsightButtonIcon}>
            <Insight />
          </div>
          <TooltipInfo title={tooltips.dashboard.newInsights}>
            <span className={styles.newInsightButtonText}>
              New Insight
            </span>
          </TooltipInfo>
        </div>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems}
      </Menu>
    </div>
  );
};

export default NewInsightPopover;
