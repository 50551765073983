const InsightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <g stroke="#000" strokeMiterlimit={10} strokeWidth={2} clipPath="url(#a)">
      <rect x={1} y={1} width={5} height={5} stroke="black" fill="none" />
      <rect x={1} y={10} width={5} height={5} stroke="black" fill="none" />
      <rect x={10} y={10} width={5} height={5} stroke="black" fill="none" />
      <line
        x1="12.5"
        y1="1"
        x2="12.5"
        y2="5.91"
        stroke="black"
        strokeLinecap="square"
      />
      <line
        x1="10"
        y1="3.45"
        x2="15"
        y2="3.45"
        stroke="black"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default InsightIcon;
