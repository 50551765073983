import permissionTags, { PermissionTag, permissionTagDescriptions } from '@/constants/permissionTags';

const navigationTooltips = {
  dashboards: (
    <div>Search and view dashboards &nbsp;&nbsp;
      <a href="https://docs.qloo.com/docs/generating-insights#dashboards" target="__blank">Learn more.</a>
    </div>
  ),
  requests: 'Send direct API requests',
  compare: 'Compare the interests of two personas',
  shareables: 'Save and share API request results',
};

const dashboardCardTooltips = {
  favorite: 'Favorite this dashboard for quick access',
  menu: 'More actions',
  share: 'Share this dashboard with a user or organization',
  rename: 'Rename this dashboard',
  clone: 'Duplicate this dashboard',
  delete: 'Permanently delete this dashboard in all locations',
};

const dashboardTableTooltips = {
  headers: {
    view: 'Click on a row to open the dashboard',
    title: 'Dashboard title',
    summary: 'AI-generated dashboard description',
    createdAt: 'Dashboard creation date',
    updatedAt: 'Last updated on this date',
    lastEditedBy: 'Last edited by this user',
    sharedWith: 'Users with access to this dashboard',
    permissions: 'Your dashboard permissions',
  },
};

const dashboardTooltips = {
  anywhere: 'Location',
  anyone: 'Demographics',
  anything: 'Entities',
  grid: 'Home',
  plus: 'Open or create a dashboard',
  x: 'Close tab',
  moreActions: 'More actions',
  modifier: 'Refine this panel with more demographics, locations, or entities',
  clonePanel: 'Make a copy of this panel within the current dashboard',
  openInRequestBrowser: 'Open the current panel selections as an API request',
  delete: 'Permanently delete this panel from your dashboard',
  export: 'Download a CSV file containing all panel data',
  filter: 'Add filters to this panel',
  resize: 'Click and drag to resize this panel',
  newInsights: (
    <div>
      Add an insights panel. Read about &nbsp;
      <a href="https://docs.qloo.com/docs/generating-insights#panel-types" target="_blank" rel="noreferrer">panel types</a>.
    </div>),
  table: {
    affinity: (
      // eslint-disable-next-line max-len
      <div>Affinity score: How closely an entity is connected to the selected entity.Scores range from 0 to 100, with higher scores indicating a stronger relationship.&nbsp;
        <a href="https://docs.qloo.com/docs/affinity-score" target="__blank">Learn more</a>.
      </div>
    ),
    rank: (
      <div>Entity ranking.&nbsp;
        <a href="https://docs.qloo.com/docs/generating-insights#panel-types" target="__blank">Learn more</a>.
      </div>
    ),
    trend: (
      <div>Shows how an interest is trending compared to others in the same category.&nbsp;
        <a href="https://docs.qloo.com/docs/generating-insights#table" target="__blank">Learn more</a>.
      </div>
    ),
    popularity: (
      <div>The percentile rank of an entity within its category.&nbsp;
        <a href="https://docs.qloo.com/docs/generating-insights#table" target="__blank">Learn more</a>.
      </div>
    ),
    name: 'Name',
    language: 'Language',
    placeOfBirth: 'Place of birth',
    dateOfBirth: 'Date of birth',
    headquarters: 'City of the brand\'s headquarters',
    inception: 'Date the brand was founded',
    url: 'URL of the brand\'s official website',
    products: 'Lists key products offered by the brand',
    region1: 'State, Province, or Region',
    region2: 'City or County',
    countryCode: 'The ISO code of the country where the destination is located',
    bookDisambiguation: 'Release year and author to distinguish the book from others',
    bookYear: 'Release year',
    bookPageCount: 'Number of pages',
    bookFormat: 'Book format, including hardcover, ebook, Kindle edition and more',
    moviesDisambiguation: 'Release year and director to distinguish the movies from others',
    citizenship: 'The country where this person holds citizenship',
    instrument: 'Instruments used by this person',
    personShortDescription: 'Short description of the person',
    personDisambiguation: 'Date of birth to differentiate from people with the same name',
    placeDisambiguation: 'Address to differentiate from other places with the same name',
    placeAddress: 'The place’s full address',
    placePhone: 'The place’s phone number',
    placeRating: 'The business’s rating from 1-5',
    tvRating: 'Audience suitability content rating',
    tvDuration: 'Episode length in minutes',
    tvFinaleYear: 'The year the series or show ended',
    releaseDate: 'The original release date',
    videoGameContentRating: 'Audience suitability content rating',
    videoGamePlatforms: 'Platforms compatible with the game',
    videoGamePublisher: 'The company that published the game',
    videoGameDeveloper: 'The company that developed the game',
  },
};

const dashboardSuggestionsTooltips = {
  view: 'View Suggestions',
  create: 'Create Suggestion',
  createAll: 'Create All Suggestions',
  x: 'Close Suggestion',
};

const commentsTooltips = {
  bubble: 'Click, then click on the dashboard to add a comment',
  inbox: 'See all comments',
};

const heatmapTooltips = {
  affinity: 'Affinity Score: Toggle heatmap by affinity score. Red is above 50 affinities, blue is below 50 affinities.',
  popularity: `Popularity Score: Toggle heatmap by popularity. 
               Red shows areas above 0.5 affinities, highlighting a stronger presence.`,
  affinityRange: `Affinity Score by Location: 
                  Toggle heatmap by affinity rank. See how interest in an entity compares locally and globally.`,
};

const tooltips = {
  navigation: navigationTooltips,
  dashboardTable: dashboardTableTooltips,
  dashboard: dashboardTooltips,
  dashboardSuggestions: dashboardSuggestionsTooltips,
  dashboardCard: dashboardCardTooltips,
  comments: commentsTooltips,
  heatmap: heatmapTooltips,
  ownershipFilter: 'Filter the list of dashboards by ownership or favorites',
  newDashboard: 'Create a new dashboard',
  viewOpenTabs: 'View all of your open dashboard tabs',
  searchEntity: 'Search for entities in the Requests tab',
  summaryDetail: 'View more detailed entity and tag data',
  searchLocation: 'Search for a location by name',
  filterSubCategory: 'Filter with subcategories',
  backToMainCategories: 'Back to main categories',
  permission: (isOwner: boolean, role: PermissionTag) => (
    isOwner
      ? permissionTagDescriptions[permissionTags.roleOwner]
      : permissionTagDescriptions[role]
  ),
  userProfile: (username: string) => `You're logged in as ${username}`,
};

export default tooltips;
