import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { api } from '@/constants';

export const recommendationsApi = createApi({
  reducerPath: 'recommendationsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${api.hostname}/api/${api.server}/recommendations` }),
  tagTypes: ['Describe'],
  endpoints: (builder) => ({
    getDescribe: builder.query({
      query: () => 'describe',
      providesTags: ['Describe'],
    }),
  }),
});

export const {
  useGetDescribeQuery,
} = recommendationsApi;
