import React, { useMemo } from 'react';

import MarkdownView from './MarkdownViewer/MarkdownView';

const parseResult = (result) => {
  if (typeof result === 'string') {
    return { parsedResult: result, rest: {} };
  }

  if (typeof result === 'object') {
    if (result?.result) {
      const { result: parsedResult, ...rest } = result;
      return { parsedResult, rest };
    }
  }

  return { parsedResult: result, rest: {} };
};

const CreativesModalResultRendered = ({
  baseParams,
  entities,
  tags,
  template,
  result,
  userData,
}) => {
  const { parsedResult, rest } = parseResult(result);

  const extraCitations = useMemo(
    () => (template.extraCitations ? template.extraCitations({ result: parsedResult, ...rest }) : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [template.extraCitations],
  );

  if (template.render) {
    return template.render({
      baseParams,
      entities,
      extraCitations,
      result: parsedResult,
      tags,
      template,
      userData,
      ...rest,
    });
  }

  return <MarkdownView>{parsedResult}</MarkdownView>;
};

export default CreativesModalResultRendered;
