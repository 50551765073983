import { ButtonProps } from '@mui/base';
import MuiButton from '@mui/material/Button';

interface InlineButtonProps extends ButtonProps {}

const InlineButton = ({
  children,
  color,
  className,
  onClick,
  id,
}: InlineButtonProps) => (
  // @ts-ignore
  <MuiButton
    variant="inline"
    component="div"
    disableRipple
    color={color}
    className={className}
    onClick={onClick}
    id={id}
  >
    {children}
  </MuiButton>
);

export default InlineButton;
