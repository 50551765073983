import { useParams } from 'react-router-dom';

import { useGetDashboardQuery } from '@/store/apis/dashboardsApi';

import DashboardActionsProvider from '../DashboardActionsProvider';
import DashboardHeader from '../DashboardHeader/DashboardHeader';
import DashboardLayout from '../DashboardLayout/DashboardLayout';
import DashboardVeltProvider from '../DashboardVeltProvider';
import styles from './PublicDashboardView.module.scss';
import getTitle from '../helpers/getTitle';

const PublicDashboardView = () => {
  const { id } = useParams();
  const dashboard = useGetDashboardQuery({ id });

  if (dashboard.isFetching) {
    return null;
  }

  return (
    <DashboardVeltProvider dashboard={dashboard}>
      <DashboardActionsProvider dashboard={dashboard}>
        <div className={styles.header}>
          <img src="/qloo-icon-blk.svg" alt="Qloo logo" className={styles.logo} />
          <div className={styles.title}>
            <div className={styles.innerTitle}>{getTitle(dashboard.data)}</div>
          </div>
        </div>
        <DashboardHeader dashboard={dashboard.data} isViewingPublicly />
        <DashboardLayout dashboard={dashboard.data} isViewingPublicly />
      </DashboardActionsProvider>
    </DashboardVeltProvider>
  );
};

export default PublicDashboardView;
