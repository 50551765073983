import React from 'react';

import styles from './DashboardPanel.module.scss';
import TablePageSizeSelector from './TablePageSizeSelector';
import TablePagination from './TablePagination';

const PanelFooterTable = ({
  panelId, panelParams, hasMorePages,
}) => {
  return (
    <div className={styles.panelFooter}>
      <TablePageSizeSelector
        panelId={panelId}
        panelParams={panelParams}
      />
      <TablePagination
        panelId={panelId}
        panelParams={panelParams}
        hasMorePages={hasMorePages}
      />
      <div />
    </div>
  );
};

export default PanelFooterTable;
