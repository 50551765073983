import { Box, Chip } from '@mui/material';
import React from 'react';

import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import permissionTags, { permissionTagLabels } from '@/constants/permissionTags';
import tooltips from '@/utils/tooltips';

interface Props {
  isOwner: boolean
  role: string
}

// TODO: Refactor this
const PermissionChips = ({ isOwner, role }: Props) => {
  return (
    <TooltipInfo title={tooltips.permission(isOwner, role)}>
      <Box display="flex" gap={1} flexWrap="wrap" alignItems="center">
        <Chip
          label={isOwner ? permissionTagLabels[permissionTags.roleOwner] : permissionTagLabels[role]}
          variant="outlined"
          color="secondary"
          size="small"
          disabled
        />
      </Box>
    </TooltipInfo>

  );
};

export default PermissionChips;
