import { filterTypes } from '@/constants';

import { ColumnConfig, ColumnSizes } from './ColumnConfig';

const columnDefinitions: { [key: string]: ColumnConfig[] } = {
  [filterTypes.artist]: [
    { fnAcc: 'date_of_birth', toolTipAcc: 'dateOfBirth', header: 'DOB' },
    {
      fnAcc: 'place_of_birth',
      toolTipAcc: 'placeOfBirth',
      header: 'Place of Birth',
    },
  ],
  [filterTypes.book]: [
    { fnAcc: 'publication_year', toolTipAcc: 'bookYear', header: 'Year' },
    { fnAcc: 'page_count', toolTipAcc: 'bookPageCount', header: 'Page Count' },
    {
      acc: 'disambiguation',
      toolTipAcc: 'bookDisambiguation',
      header: 'Disambiguation',
    },
  ],
  [filterTypes.brand]: [
    {
      fnAcc: 'headquartered',
      toolTipAcc: 'headquarters',
      header: 'Headquarters',
    },
    {
      fnAcc: 'inception',
      toolTipAcc: 'inception',
      header: 'Inception',
      size: ColumnSizes.DATE,
    },
    {
      fnAcc: 'official_site',
      toolTipAcc: 'url',
      header: 'Official Site',
      size: ColumnSizes.OFFICIAL_SITE,
    },
  ],
  [filterTypes.destination]: [
    {
      fnAcc: 'geocode.admin1_region',
      toolTipAcc: 'region1',
      header: 'Region 1',
    },
    {
      fnAcc: 'geocode.admin2_region',
      toolTipAcc: 'region2',
      header: 'Region 2',
    },
    {
      fnAcc: 'geocode.country_code',
      toolTipAcc: 'countryCode',
      header: 'Country Code',
    },
  ],
  [filterTypes.person]: [
    { fnAcc: 'citizenship', toolTipAcc: 'citizenship', header: 'Citizenship' },
    { fnAcc: 'instrument', toolTipAcc: 'instrument', header: 'Instrument' },
    { fnAcc: 'lifestyle', toolTipAcc: 'lifestyle', header: 'Lifestyle' },
    {
      fnAcc: 'place_of_birth',
      toolTipAcc: 'placeOfBirth',
      header: 'Place of Birth',
    },
    {
      fnAcc: 'short_description',
      toolTipAcc: 'personShortDescription',
      header: 'Short Description',
    },
    {
      acc: 'disambiguation',
      toolTipAcc: 'personDisambiguation',
      header: 'Disambiguation',
    },
  ],
  [filterTypes.place]: [
    { fnAcc: 'address', toolTipAcc: 'placeAddress', header: 'Address' },
    { fnAcc: 'phone', toolTipAcc: 'placePhone', header: 'Phone' },
    {
      fnAcc: 'business_rating',
      toolTipAcc: 'placeRating',
      header: 'Business Rating',
    },
    {
      acc: 'disambiguation',
      toolTipAcc: 'placeDisambiguation',
      header: 'Disambiguation',
    },
  ],
  [filterTypes.tvShow]: [
    {
      fnAcc: 'release_date',
      toolTipAcc: 'releaseDate',
      header: 'Release Date',
      size: ColumnSizes.DATE,
    },
    {
      fnAcc: 'finale_year',
      toolTipAcc: 'tvFinaleYear',
      header: 'Finale Year',
    },
  ],
  [filterTypes.videogame]: [
    {
      fnAcc: 'publisher',
      toolTipAcc: 'videoGamePublisher',
      header: 'Publisher',
    },
    {
      fnAcc: 'release_date',
      toolTipAcc: 'releaseDate',
      header: 'Release Date',
      size: ColumnSizes.DATE,
    },
    {
      fnAcc: 'developer',
      toolTipAcc: 'videoGameDeveloper',
      header: 'Developer',
    },
    {
      fnAcc: 'platforms',
      toolTipAcc: 'videoGamePlatforms',
      header: 'Platforms',
    },
  ],
  [filterTypes.movie]: [
    {
      fnAcc: 'release_date',
      toolTipAcc: 'releaseDate',
      header: 'Release Date',
      size: ColumnSizes.DATE,
    },
    {
      acc: 'disambiguation',
      toolTipAcc: 'moviesDisambiguation',
      header: 'Disambiguation',
      size: ColumnSizes.DISAMBIGUATION,
    },
  ],
};

export default columnDefinitions;
