import area from '@turf/area';
import convex from '@turf/convex';
import simplify from '@turf/simplify';

import { DEFAULT_RADIUS } from '@/constants/index';

const formatLocation = (location) => {
  const nextLocation = {};

  const {
    geometry, displayName, name, entityId,
  } = location;

  nextLocation.label = displayName || null;
  nextLocation.name = name || null;
  nextLocation.value = geometry;
  nextLocation.entityId = entityId;

  if (geometry.type === 'Point') {
    nextLocation.radius = DEFAULT_RADIUS;

    return nextLocation;
  }

  if (geometry.type === 'MultiPolygon') {
    const AREA_THRESHOLD_PERCENTAGE = 0.01;

    const totalArea = area(geometry);

    // Filter out small polygons
    const filteredPolygons = geometry.coordinates.filter((polygon) => {
      const polygonArea = area({ type: 'Polygon', coordinates: polygon });
      return (polygonArea / totalArea) > AREA_THRESHOLD_PERCENTAGE;
    });

    const simplifiedPolygons = filteredPolygons.map((polygon) => {
      const simplifiedPolygon = simplify({
        type: 'Polygon',
        coordinates: polygon,
      }, {
        highQuality: true,
        tolerance: 0.00006,
      });

      const convexPolygon = convex(simplifiedPolygon);

      return convexPolygon?.geometry.coordinates || simplifiedPolygon.coordinates;
    });

    nextLocation.value = {
      type: 'MultiPolygon',
      coordinates: simplifiedPolygons,
    };

    return nextLocation;
  }

  // Simplify geometry (value)
  const cleanShape = convex(simplify(geometry, {
    highQuality: true,
    tolerance: 0.00006,
  }));

  nextLocation.value = cleanShape?.geometry;

  return nextLocation;
};

export default formatLocation;
