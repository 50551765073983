type Params = ((string | number)[] | (string | boolean)[])[];

const generateUrlSearchParams = (params: Params) => {
  const searchParams = new URLSearchParams();

  params.forEach(([key, value]) => {
    if (value) {
      searchParams.set(key.toString(), String(value));
    }
  });

  return searchParams.toString();
};

export default generateUrlSearchParams;
