import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  Chip,
  ListItemIcon, ListItemText, MenuItem,
} from '@mui/material';
import { styled } from '@mui/system';

const BetaChip = styled(Chip)({
  position: 'relative',
  left: '-0.5em',
  top: '-0.5em',
  fontSize: '10px',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontVariantCaps: 'small-caps',
  letterSpacing: '1px',
  color: 'blue',
  backgroundColor: 'transparent',
});

const MenuItemOpenCreativesModal = ({ onClick, ...props }) => {
  return (
    <MenuItem onClick={onClick} {...props}>
      <ListItemIcon><AutoFixHighIcon /></ListItemIcon>
      <ListItemText>Creatives</ListItemText>
      <BetaChip color="primary" label="BETA" size="small" />
    </MenuItem>
  );
};

export default MenuItemOpenCreativesModal;
