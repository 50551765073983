import * as Sentry from '@sentry/react';
import {
  createContext, useCallback, useContext, useEffect, useMemo, useRef,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAppSelector } from '@/store';
import { useLazyIdentitiesGetQuery } from '@/store/apis/identityApi';

const IdentityBatchContext = createContext();

export const IdentityBatchProvider = ({ children }) => {
  const identityIdsBatch = useRef([]);
  const [triggerIdentityGetQuery] = useLazyIdentitiesGetQuery();
  const existingIdentities = useAppSelector((state) => state.identities.byId);

  const request = useCallback(() => {
    (async () => {
      try {
        const filteredIdentityIds = identityIdsBatch.current.filter((identityId) => !existingIdentities[identityId]);
        if (filteredIdentityIds.length === 0) {
          return;
        }
        await triggerIdentityGetQuery({ identityIds: filteredIdentityIds }).unwrap();
        identityIdsBatch.current = [];
      } catch (error) {
        console.error(`Error fetching identities: ${error.message}`, error);
        Sentry.captureException(error);
      }
    })();
  }, [existingIdentities, triggerIdentityGetQuery]);

  const debouncedCallback = useDebouncedCallback(request, 200);

  const queueIdentityId = useCallback((identityId) => {
    if (identityId) {
      identityIdsBatch.current.push(identityId);
      debouncedCallback();
    }
  }, [debouncedCallback]);

  const queueIdentityIds = useCallback((newIdentityIds) => {
    identityIdsBatch.current.push(...newIdentityIds);
    debouncedCallback();
  }, [debouncedCallback]);

  const contextValue = useMemo(() => ({ queueIdentityId, queueIdentityIds }), [queueIdentityId, queueIdentityIds]);

  useEffect(() => {
    return () => {
      identityIdsBatch.current = [];
    };
  }, []);

  return (
    <IdentityBatchContext.Provider value={contextValue}>
      {children}
    </IdentityBatchContext.Provider>
  );
};

export const useIdentityBatch = () => {
  const context = useContext(IdentityBatchContext);

  if (!context) {
    throw new Error('useIdentityBatch must be used within an IdentityBatchProvider component');
  }

  return context;
};
