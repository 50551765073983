import { BaseError, MethodContractError } from '@qloo/errors';

/// Yep. No simple parse jwt libraries out there that looked good. So,
/// we'll do it live
const jwtParse = (token) => {
  if (token === null || token === undefined) {
    throw new MethodContractError({
      method: 'jwtParse',
      parameter: 'token',
      message: 'jwtParse requires a token as a parameter.',
      errorCode: 'JWT_PARSE_001',
    });
  }

  if (typeof window === 'undefined') {
    throw new BaseError({
      message: 'jwtParse only works in a browser.',
      errorCode: 'JWT_PARSE_002',
    });
  }

  const base64PayloadRaw = token.split('.')[1];

  if (!base64PayloadRaw) {
    throw new BaseError({
      message: 'jwtParse: token provided was invalid. Payload not found (2nd part of token separated by ".")',
      errorCode: 'JWT_PARSE_003',
    });
  }

  const base64Payload = base64PayloadRaw.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(
    window?.atob(base64Payload)?.split('')?.map((c) => `%${ (`00${ c?.charCodeAt(0)?.toString(16)}`)?.slice(-2)}`)?.join(''),
  );

  try {
    return JSON.parse(jsonPayload);
  } catch (e) {
    throw new BaseError({
      message: `jwtParse: token provided was invalid. Payload not a valid json object. ${e.message}`,
      errorCode: 'JWT_PARSE_004',
    });
  }
};

export default jwtParse;
