import WhatshotIcon from '@mui/icons-material/Whatshot';
import {
  Box, Button, ButtonGroup, Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import muiVeltIds from '@/constants/muiVeltIds';
import permissionControlVariants from '@/constants/permissionControlVariants';
import permissionTags from '@/constants/permissionTags';
import useToggle from '@/hooks/useToggle';

import DashboardPermissionControl from '../DashboardPermissionControl';
import HeatmapOpacitySlider from './HeatmapOpacitySlider';
import HeatmapRangeSlider from './HeatmapRangeSlider';
import HeatmapToggle from './HeatmapToggle';

const HeatmapControls = ({ isCollapsible, ...props }) => {
  const controlsToggle = useToggle();

  return (
    <Box
      position="absolute"
      top="8px"
      left={isCollapsible ? 'auto' : '8px'}
      right="8px"
      display="flex"
      gap="10px"
      justifyContent={isCollapsible ? 'flex-end' : 'flex-start'}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Collapse
        orientation="horizontal"
        in={!isCollapsible || controlsToggle.value}
      >
        <InnerHeatmapControls {...props} />
      </Collapse>
      {isCollapsible && (
        <Button
          onClick={controlsToggle.toggle}
          variant="map"
          color="secondary"
          selected={controlsToggle.value}
          size="small"
          disableElevation
          id={`${props.panelId}-${muiVeltIds.heatmapControls.collapse}`}
        >
          <WhatshotIcon fontSize="small" />
        </Button>
      )}
    </Box>
  );
};

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '4px',
  margin: '2px',
  height: '29px',
  boxShadow: '0 0 0 2px rgba(0, 0, 0, .1)',
  overflow: 'hidden',

  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
    borderColor: theme.palette.gray.main,
  },
}));

const InnerHeatmapControls = ({ panelSettings, onControlsChange, panelId }) => {
  const generateVeltId = (id) => `${panelId}-${id}`;
  return (
    <DashboardPermissionControl
      permissionsRequired={[permissionTags.roleEditor]}
      variant={permissionControlVariants.tooltip}
    >
      <Container>
        <ButtonGroup color="secondary" variant="text" sx={{ height: '100%' }}>
          <HeatmapToggle
            value={panelSettings.heatmapType}
            onChange={onControlsChange('heatmapType')}
            id={generateVeltId(muiVeltIds.heatmapControls.toggle)}
          />
          <Button
            disableRipple
            sx={{ cursor: 'default' }}
            id={generateVeltId(muiVeltIds.heatmapControls.range)}
          >
            <HeatmapRangeSlider
              value={panelSettings.heatmapRange}
              onChange={onControlsChange('heatmapRange')}
            />
          </Button>
          <Button
            disableRipple
            sx={{ cursor: 'default' }}
            id={generateVeltId(muiVeltIds.heatmapControls.opacity)}
          >
            <HeatmapOpacitySlider
              value={panelSettings.heatmapOpacity}
              onChange={onControlsChange('heatmapOpacity')}
            />
          </Button>
        </ButtonGroup>
      </Container>
    </DashboardPermissionControl>
  );
};

export default HeatmapControls;
