import useHasDashboardPermissions from '@v1/hooks/useHasDashboardPermissions';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import DashboardPermissionControl from '@/components/DashboardPermissionControl';
import LocationPopover from '@/components/LocationPopover';
import muiVeltIds from '@/constants/muiVeltIds';
import permissionControlVariants from '@/constants/permissionControlVariants';
import permissionTags from '@/constants/permissionTags';
import useDescribeLocation from '@/hooks/useDescribeLocation';

import LocationParam from '../LocationParam/LocationParam';

const PanelParamLocation = ({
  location,
  onChange,
  onReset,
  isViewingPublicly = false,
  panelId,
}) => {
  const describedLocation = useDescribeLocation(location);
  const { id: dashboardId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const isEditor = useHasDashboardPermissions(dashboardId, [
    permissionTags.roleEditor,
  ]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <DashboardPermissionControl
        permissionsRequired={[permissionTags.roleEditor]}
        variant={permissionControlVariants.display}
      >
        <LocationParam
          color="blue"
          location={describedLocation}
          onClick={isViewingPublicly ? undefined : handleClick}
          placeholder="Select location"
          id={`${panelId}-${muiVeltIds.buttons.locationParam}`}
          isEditor={isEditor}
        />
      </DashboardPermissionControl>
      <LocationPopover
        location={describedLocation}
        anchorEl={anchorEl}
        onClose={handleClose}
        onChange={onChange}
        onReset={onReset}
      />
    </div>
  );
};

export default PanelParamLocation;
