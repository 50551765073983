const EditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <g stroke="currentColor" strokeLinejoin="round" clipPath="url(#a)">
      <path strokeWidth={2} d="M15 5.92 5.92 15H1v-4.92L10.08 1 15 5.92Z" />
      <path d="m7.24 3.81 4.95 4.95" />
    </g>
    <defs />
  </svg>
);
export default EditIcon;
