import { Box } from '@mui/material';
import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

import CategoriesModal from '@/components/CategoriesModal';
import Popover from '@/components/Popover';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import tooltips from '@/utils/tooltips';

import styles from '../PanelBodyExplore.module.scss';

const SelectedCategorySubCategoryDialog = ({
  selectedCategory,
  selectedSubCategories,
  onSubCategoryChange,
  onSubcategoriesReset,
  buttonLabel,
}) => {
  return (
    <Popover
      color="accent"
      variant="contained"
      size="small"
      renderContent={({ close }) => (
        <CategoriesModal
          category={selectedCategory}
          subCategories={selectedSubCategories}
          close={close}
          onReset={onSubcategoriesReset}
          onCategoryChange={() => null}
          onSubcategoriesChange={onSubCategoryChange}
          showSubCategorySelect
          hideCategorySelector
        />
      )}
      useDynamicPosition
      renderCustomTrigger={({ open }) => (
        <TooltipInfo title={tooltips.filterSubCategory}>
          <Box onClick={open} className={styles.modifierButtonLabel}>
            <span>{buttonLabel}</span>
            <FaChevronDown className={styles.arrowIcon} />
          </Box>
        </TooltipInfo>
      )}
    />
  );
};

export default SelectedCategorySubCategoryDialog;
