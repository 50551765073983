import { useMemo } from 'react';

import panelTypes from '@/constants/panelTypes';
import { paramNames } from '@/constants/paramNames';
import { useDashboardActionsContext } from '@/screens/Dashboard/DashboardActionsProvider';
import {
  calculateScore, extractCategories,
  formatCategoryName, getCategoryValidation,
  getPanelSuggestions, panelExists,
} from '@/screens/Dashboard/DashboardPanel/PanelBodySuggestions/helpers/panelHelpers';
import { PanelSuggestion } from '@/screens/Dashboard/DashboardPanel/PanelBodySuggestions/helpers/types';
import { useAppSelector } from '@/store';
import { DashboardItem } from '@/store/slices/dashboardSliceTypes';
import getDemographicsLabel from '@/utils/getDemographicsLabel';
import getIsQlooUser from '@/utils/getIsQlooUser';

interface PanelSuggestionsProps {
  dashboard: DashboardItem;
}

const usePanelSuggestions = ({ dashboard }: PanelSuggestionsProps) => {
  const { baseParams, layout } = dashboard;
  const { identity } = useAppSelector((state) => state.app);
  const isQlooUser = useMemo(() => Boolean(getIsQlooUser(identity)), [identity]);

  const dashboardActions = useDashboardActionsContext() as any;

  const isLayout = layout?.length > 0;

  const validEntities = baseParams?.omnisearch?.filter((item: any) =>
    item.types?.some((type: string) => type.startsWith('urn:entity:'))) || [];

  const entityName = validEntities?.map((entity: any) => entity.name).join(', ');

  const location = baseParams?.[paramNames.signalLocation];

  const demographicsLabels = baseParams ? getDemographicsLabel(baseParams).split(', ')
    .filter((label: string) => label !== 'Anyone') : [];

  const categories = extractCategories(validEntities);

  const { categoryForSuggestions, isSingleCategory } = getCategoryValidation(categories);

  const formattedCategory = categoryForSuggestions
    ? formatCategoryName(categoryForSuggestions)
    : null;

  const panelSuggestions = useMemo(
    () => {
      return getPanelSuggestions(entityName, location, demographicsLabels, isQlooUser, isSingleCategory)
        .map((item) => ({
          ...item,
          finalScore: calculateScore(item.score, dashboard.baseParams),
        }))
        .filter((item) =>
          !panelExists(dashboard, item.panelType, item.additionalParams, categoryForSuggestions))
        .sort((a, b) => b.finalScore - a.finalScore)
        .reduce((acc: any, item: any) => {
          if (!acc[item.panelType]) {
            acc[item.panelType] = [];
          }
          acc[item.panelType].push(item);
          return acc;
        }, {});
    },
    [dashboard],
  );

  const addPanelWithParams = async (panelType: string, additionalParams: any) => {
    const params = { ...additionalParams };

    // eslint-disable-next-line no-shadow
    const panelActions: Record<string, (params: any) => Promise<void>> = {
      [panelTypes.table]: dashboardActions.addPanel,
      [panelTypes.heatmap]: dashboardActions.addHeatmapPanel,
      [panelTypes.explore]: dashboardActions.addExplorePanel,
      [panelTypes.locationExplorer]: dashboardActions.addLocationExplorerPanel,
      [panelTypes.freeformText]: dashboardActions.addFreeformTextPanel,
    };

    const action = panelActions[panelType] || dashboardActions.addPanel;

    if (panelType === panelTypes.table) {
      params[paramNames.filterType] = categoryForSuggestions || additionalParams[paramNames.filterType];
      await action({ params });
    } else {
      await action(params);
    }
  };

  const generateAllPanels = async () => {
    const promises = Object.values(panelSuggestions)
      .flat()
      .map(async (suggestion: PanelSuggestion) => {
        try {
          await addPanelWithParams(suggestion.panelType, suggestion.additionalParams);
        } catch (error) {
          console.error(`Error ${suggestion.panelType}:`, error);
        }
      });

    await Promise.all(promises);
  };

  return {
    addPanelWithParams,
    generateAllPanels,
    panelSuggestions,
    entityName,
    location,
    formattedCategory,
    demographicsLabels,
    isLayout,
  };
};

export default usePanelSuggestions;
