import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';
import { WidgetProps } from '@rjsf/utils';
import * as React from 'react';

import styles from './QuantityInput.module.scss';

const QuantityInput: React.FC<WidgetProps> = ({
  value = 0,
  onChange,
  schema,
  id,
}) => {
  const maxValue = schema?.maximum || 5;

  const increment = () => {
    if (value < maxValue) onChange(value + 1);
  };

  const decrement = () => {
    if (value > 0) onChange(value - 1);
  };

  return (
    <div className={styles.quantityInput}>
      {schema?.label && <div className={styles.mainText}>{schema.label}</div>}
      <div className={styles.quantityInputContainer}>
        <div className={styles.quantityInputInnerContainer}>
          <IconButton
            color="primary"
            onClick={decrement}
            disabled={value === 0}
            id={`${id}-decrement`}
          >
            <RemoveIcon fontSize="small" />
          </IconButton>
          <span className={styles.mainText}>{value}</span>
          <IconButton
            onClick={increment}
            color="primary"
            disabled={value === maxValue}
            id={`${id}-increment`}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </div>
        <div className={styles.maxLimitText}>Max. {maxValue}</div>
      </div>
    </div>
  );
};

export default QuantityInput;
