import { Box } from '@mui/material';
import React from 'react';

import Skeleton from '@/components/Skeleton';

import styles from './DashboardTabsSkeleton.module.scss';
import TabButtonSkeleton from './TabButtonSkeleton';

const DashboardTabsSkeleton = () => (
  <div className={styles.container}>
    <TabButtonSkeleton />
    <TabButtonSkeleton />

    <Box
      color="accent"
      flex={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Skeleton width={130} />
    </Box>
    <Box
      color="accent"
      flex={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Skeleton width={130} />
    </Box>
    <Box
      color="accent"
      flex={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Skeleton width={130} />
    </Box>
  </div>
);

export default DashboardTabsSkeleton;
