import CloseIcon from '@mui/icons-material/Close';
import MuiChip from '@mui/material/Chip';
import { styled } from '@mui/system';
import clsx from 'clsx';
import React from 'react';

import styles from './Chip.module.scss';

const BaseChip = ({
  children, className, color, onDelete, onClick, deleteIconClassName, ...props
}) => (
  <MuiChip
    classes={{
      label: styles.chipLabel,
      root: clsx({ [styles.chip]: true, [className]: className }),
      deleteIcon: deleteIconClassName,
    }}
    clickable={Boolean(onClick)}
    color={color}
    deleteIcon={<CloseIcon fontSize="small" />}
    label={children}
    onDelete={onDelete}
    onClick={onClick || (() => { })}
    {...props}
  />
);

const Chip = styled(BaseChip)(({ theme }) => ({
  '& .MuiChip-deleteIcon': {
    order: -1,
    margin: `0 ${theme.spacing(0.25)}`,
  },
  '&.MuiChip-deletable .MuiChip-label': {
    paddingLeft: 0,
  },
  '&.Mui-disabled': {
    opacity: 1,
    backgroundColor: 'hsl(var(--gray-ghost)) !important;',
    color: 'hsl(var(--gray-medium))',
  },
}));

export default Chip;
