import { MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import DashboardPermissionControl from '@/components/DashboardPermissionControl';
import permissionControlVariants from '@/constants/permissionControlVariants';
import permissionTags from '@/constants/permissionTags';

import { useDashboardActionsContext } from '../DashboardActionsProvider';
import styles from './DashboardPanel.module.scss';
import { defaultPageSize, rowSizeOptions } from './helpers/DashboardPanelConstants';

const TablePageSizeSelector = ({ panelId, panelParams }) => {
  const dashboardActions = useDashboardActionsContext();

  const handlePageSizeChange = (e) => {
    const nextTake = e.target.value;

    dashboardActions.changePanelParams({
      panelId,
      nextParams: {
        ...panelParams,
        take: nextTake,
        offset: 0,
      },
    });
  };

  return (
    <DashboardPermissionControl
      permissionsRequired={[permissionTags.roleEditor]}
      variant={permissionControlVariants.tooltip}
    >
      <Box display="flex" gap="8px">
        <Typography fontSize="14px" className={styles.pageSizeLabel}>Rows per page:</Typography>
        <Select
          labelId="page-size"
          id="page-size"
          value={panelParams.take || defaultPageSize}
          label="Page Size"
          onChange={handlePageSizeChange}
          size="small"
          variant="standard"
          inputProps={{
            className: styles.pageSizeInput,
          }}
        >
          {rowSizeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </DashboardPermissionControl>
  );
};

export default TablePageSizeSelector;
