import { useAppSelector } from '@/store';
import getColorsByTypes from '@/utils/getColorsByTypes';

const useItemColor = (item) => {
  const entity = useAppSelector((state) => state.entities.byId[item.id]);
  const tag = useAppSelector((state) => state.tags.byId[item.id]);

  if (item.types) {
    return getColorsByTypes(item.types);
  }

  if (entity) {
    return getColorsByTypes(entity.types);
  }

  if (tag) {
    const parentsTypes = tag.parents ? tag.parents.map((parent) => parent.type) : [];
    return getColorsByTypes([tag.type, ...parentsTypes]);
  }

  return {};
};

export default useItemColor;
