import React from 'react';

import OmnisearchItemText from '@/components/OmnisearchItemText';
import { paramNames } from '@/constants';
import { EntityTypes } from '@/entity/EntityTypes';
import { TagTypes } from '@/entity/tag/TagTypes';

type Interest = EntityTypes.Entity | TagTypes.Tag;

const getInterestsLabels = (baseQueryValue: Record<string, unknown>) => {
  const items = baseQueryValue[paramNames.omnisearch] || [];

  if (Array.isArray(items) && items.length) {
    return items.map(
      (item: Interest, index: number) => (
        <OmnisearchItemText key={item.id} item={item} index={index} />
      ),
    );
  }

  return 'Anything';
};

export default getInterestsLabels;
