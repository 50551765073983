import json5 from 'json5';

const tryParseJson = (s: string) => {
  try {
    return json5.parse(s);
  } catch (e) {
    return null;
  }
};

export default tryParseJson;
