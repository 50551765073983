import { useParams } from 'react-router-dom';

import useDashboard from './useDashboard';

const useActiveDashboard = () => {
  const { id } = useParams();

  const dashboard = useDashboard(Number(id));

  return dashboard;
};

export default useActiveDashboard;
