import React from 'react';

import { fieldByParamName } from '@/constants';

import styles from './Field.module.scss';
import FieldRenderer from './FieldRenderer';

const Field = ({
  disabled, onChange, paramName, value, noLabel, filterType,
}) => {
  const field = fieldByParamName[paramName];
  if (!field) {
    console.error(`Field with paramName ${paramName} not found`);
    return null;
  }

  return (
    <div className={styles.field} key={field.label}>
      {!noLabel && (!!field.title || !!field.label) && (
        <div className={styles.fieldLabel}>{field.title || field.label}</div>
      )}
      <FieldRenderer disabled={disabled} field={field} onChange={onChange} value={value} filterType={filterType} />
    </div>
  );
};

export default Field;
