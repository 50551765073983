import React from 'react';

import PanelParamsModifiersSkeleton
  from '@/screens/Dashboard/DashboardPanel/PanelParamsModifiers/PanelParamsModifiersSkeleton';

import styles from '../DashboardPanel.module.scss';

const PanelParamsSkeleton = ({ disabled, ...rest }) => (
  <div className={styles.panelParams}>
    <PanelParamsModifiersSkeleton disabled={disabled} {...rest} />
  </div>
);

export default PanelParamsSkeleton;
