import { filterTypes, paramNames } from '@/constants';

const DEFAULT_FILTER_PARAM_NAMES = [
  paramNames.filterEntities,
  paramNames.filterPopularity,
  paramNames.filterExternalExists,
];

const FILTER_TYPE_PARAMS: Record<string, string[]> = {
  [filterTypes.destination]: [
    ...DEFAULT_FILTER_PARAM_NAMES,
    paramNames.filterLocation,
  ],
  [filterTypes.place]: [
    ...DEFAULT_FILTER_PARAM_NAMES,
    paramNames.filterLocation,
    paramNames.filterReferencesBrand,
  ],
  [filterTypes.movie]: [...DEFAULT_FILTER_PARAM_NAMES, paramNames.filterRating],
  [filterTypes.tvShow]: [
    ...DEFAULT_FILTER_PARAM_NAMES,
    paramNames.filterRating,
  ],
};

/**
 * Function to get filter parameter names based on the type
 */
const getFilterParamNames = (type: string) => {
  return FILTER_TYPE_PARAMS[type] || DEFAULT_FILTER_PARAM_NAMES;
};

export default getFilterParamNames;
