import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import getColorsByTypes from '@/utils/getColorsByTypes';
import { formatTagCategories } from '@/utils/tagCategories';

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => !['colors', 'selected'].includes(prop),
})(({ theme, colors, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '4px 10px',
  cursor: 'pointer',
  border: `1px solid ${theme.palette.gray.main}`,
  ...(colors && {
    borderLeft: `2px solid ${colors.main}`,
  }),
  ...(selected && {
    backgroundColor: colors?.contrastText || theme.palette.gray.main,
  }),
  '&:hover': {
    backgroundColor: colors?.contrastText || theme.palette.gray.main,
  },
  '& > *': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const TagV2 = ({ tag, selected, ...props }) => {
  const parentsTypes = tag.parents ? tag.parents.map((parent) => parent.type) : [];
  const colors = getColorsByTypes([tag.type, ...parentsTypes]);
  const categories = formatTagCategories(tag.type);

  return (
    <StyledBox colors={colors} selected={selected} {...props}>
      <Typography
        fontSize="14px"
        fontWeight="bold"
      >
        {tag.name}
      </Typography>
      <Typography
        variant="caption"
        fontWeight="bold"
        textTransform="capitalize"
        color={colors.main}
      >
        {categories}
      </Typography>
    </StyledBox>
  );
};

export default TagV2;
