import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect } from 'react';

import useRequest from '@/hooks/useRequest';
import { fetchGpt } from '@/utils/fetch';

import styles from './CreativesModal.module.scss';
import CreativesModalOutput from './CreativesModalOutput';
import CreativesModalResult from './CreativesModalResult';
import useOutput from './useOutput';
import { fetchEntities, fetchTags } from './utils/fetch';

const mapEntitiesByName = (item) => item.name;

const CreativesModalStepTwo = ({
  baseParams, fetchInsightsEntities, fetchInsightsTags, template, userData, setCreativeResults,
}) => {
  const output = useOutput();
  const { wrapFn } = output;

  const entitiesRequest = useRequest(fetchEntities);
  const tagsRequest = useRequest(fetchTags);

  const wrappedFetchInsightsEntities = wrapFn(fetchInsightsEntities, 'Fetching insights entities');
  const wrappedFetchInsightsTags = wrapFn(fetchInsightsTags, 'Fetching insights tags');
  const wrappedFetchGpt = wrapFn(fetchGpt, 'Fetching llm request');

  const runnerRequest = useRequest(async () => {
    const insights = typeof template?.insights === 'function' ? template.insights({ userData }) : template.insights;

    const [entities, tags] = await Promise.all([
      entitiesRequest.call({ baseParams, entitiesRequests: insights?.entities || [], wrappedFetchInsightsEntities }),
      tagsRequest.call({ baseParams, tagsRequests: insights?.tags || [], wrappedFetchInsightsTags }),
    ]);

    const insightsNames = [...(entities || []), ...(tags || [])].map(mapEntitiesByName);

    const result = await template.runner({
      baseParams,
      entities,
      fetchGpt: wrappedFetchGpt,
      insightsNames,
      tags,
      userData,
      wrapFn,
    });
    setCreativeResults(result);
    return result;
  });

  useEffect(() => {
    runnerRequest.call();

    return () => {
      setCreativeResults(undefined);
    };
  }, []);

  return (
    <>
      <div className={styles.contentContainer}>
        <div className={styles.result}>
          <CreativesModalResult
            baseParams={baseParams}
            entitiesRequest={entitiesRequest}
            runnerRequest={runnerRequest}
            tagsRequest={tagsRequest}
            template={template}
            userData={userData}
          />
          {runnerRequest.error ? (
            <div>{runnerRequest.error?.toString ? runnerRequest.error.toString() : JSON.stringify(runnerRequest.error)}</div>
          ) : null}
        </div>
      </div>
      {runnerRequest.isLoading ? <LinearProgress /> : null}
      <CreativesModalOutput output={output} />
    </>
  );
};

export default CreativesModalStepTwo;
