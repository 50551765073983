import WifiOffIcon from '@mui/icons-material/WifiOff';
import {
  Box, Button, Stack, Typography,
} from '@mui/material';
import { getReasonPhrase } from 'http-status-codes';
import React from 'react';

import useOnlineStatus from '@/hooks/useOnlineStatus';
import extractErrorMessage from '@/utils/extractErrorMessages';

import styles from './DashboardErrorFallback/DashboardErrorFallback.module.scss';

const getErrorMessages = (error) => {
  if (error?.status === 423) {
    return 'You don’t have access to the dashboard feature.';
  }

  if (error instanceof Error) {
    return extractErrorMessage(error);
  }

  if (typeof error === 'string') {
    return error;
  }

  return 'An error occurred. Please contact support';
};

interface EnhancedError extends Error {
  title?: string
  status?: number
}

interface Props {
  error: EnhancedError
}

const DashboardPageErrorFallback = ({ error }: Props) => {
  const defaultTitle = 'Error';
  const httpTitle = error?.status ? `${error?.status} - ${getReasonPhrase(error?.status)}` : null;
  const title = error?.title || httpTitle || defaultTitle;

  const isOnline = useOnlineStatus();

  return (
    <Box className={styles.container}>
      <Stack spacing={2} direction="column">
        {!isOnline ? <WifiOffIcon fontSize="large" /> : null}
        {!isOnline ? <Typography variant="h5" fontWeight="bold">Connection Failed</Typography> : null}
        {!isOnline ? <Typography variant="h6" color="textSecondary">You are offline</Typography> : null}
        <Box className={styles.message}>
          <Typography variant="h5" fontWeight="bold">{title}</Typography>
          <Box component="pre">{getErrorMessages(error)}</Box>
        </Box>

        <Box className={styles.actions}>
          <Button variant="outlined" key="reload" onClick={() => window.location.reload()}>Retry</Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default DashboardPageErrorFallback;
