// hooks/useUserIdentity.js

import { useAppSelector } from '@/store';

function useUserIdentity() {
  const { user } = useAppSelector((state) => state.app);
  const { byId } = useAppSelector((state) => state.identities);
  return byId[user.identityId];
}

export default useUserIdentity;
