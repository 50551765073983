import Button from '@/components/Button';
import LogoLoading from '@/components/LogoLoading';
import Table from '@/components/Table';

import styles from './PanelBodyExplore.module.scss';

const TableWithTitle = ({
  title, data, columns, isLoading, onSelect,
}) => {
  return (
    <div className={styles.tableWithTitle}>
      <Button variant="text" className={styles.tableTitle} onClick={onSelect}>{title}</Button>
      <Table
        isLoading={isLoading}
        columns={columns}
        data={data}
        getRowId={({ row }) => row.entity_id}
        renderEmptyRowsFallback={() => (
          <div className={styles.tableEmptyFallback}>
            {isLoading
              ? <LogoLoading shouldPulse size="large" />
              : <span>No Results Found</span>}
          </div>
        )}
      />
    </div>
  );
};

export default TableWithTitle;
