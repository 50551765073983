import { paramNames } from '@/constants';
import getDemographicsLabel from '@/utils/getDemographicsLabel';
import getParamsLabel from '@/utils/getParamsLabel';

export default async ({
  baseParams, entities, fetchGpt, tags, userData,
}) => {
  try {
    const demographicsLabel = getDemographicsLabel(baseParams);
    const paramsLabel = getParamsLabel(baseParams);

    const summarized = await fetchGpt({
      prompt: `
        Assume the role of someone who is considered: ${paramsLabel} ${demographicsLabel}${baseParams[paramNames.signalLocation] ? `and lives in ${baseParams[paramNames.signalLocation].label}` : ''}

        Imagine you like ${entities.map((entity) => entity.name).join(', ')}
        and might be interested in: ${tags.map((tag) => tag.name).join(', ')}.
  
        Write a paragraph explaining your age, who you are, what all of these subjects have in common without mentioning them by name, what they mean to you, what a typical day is like in your life, then write about what your personality is.
    `,
    });

    const tldr = await fetchGpt({
      prompt: `
        From a third-person perspective, summarize the personality of this person in 10 words or less:

        ${paramsLabel} ${demographicsLabel}${baseParams[paramNames.signalLocation] ? `and lives in ${baseParams[paramNames.signalLocation].label}` : ''}

        Here's what they wrote about themselves: ${summarized}
    `,
    });

    const answer = await fetchGpt({
      prompt: `
        Assume the role of someone who fits this description: ${summarized}

        Speak and think as this character, considering their worldview and biases.

        With that role in mind, answer the following question using conversational language appropriate for the role,
        and in a writing style that is typical for the role.

        Do not use any trite words like tapestry, vibrant, and other words that are overused.

        Rewrite to use conversational language as in the tone between a friend and a friend.

        Just answer the question, don't include any other information:

        ${userData.instructions}
      `,
    });

    return { answer, tldr };
  } catch (e) {
    return 'Error';
  }
};
