import { createSlice, current } from '@reduxjs/toolkit';

import { DashboardItem } from '@/store/slices/dashboardSliceTypes';
import getDashboardItemByDashboardId from '@/utils/getDashboardItemByDashboardId';

import legacyDashboardMapper from './legacyDashboardMapper';

/**
 * Set the favorite status of a dashboard for a specific identity
 */
const setDashboardFavorite = (dashboards, dashboardId, identityId, isFavorite) => {
  const targetDashboardId = getDashboardItemByDashboardId(dashboards, dashboardId);

  if (!targetDashboardId) {
    console.error(`Could not set favorite status for dashboard ${dashboardId} and identity ${identityId}`);
    return dashboards;
  }

  const existingDashboard = dashboards[targetDashboardId];

  if (existingDashboard.identities) {
    const nextIdentities = existingDashboard.identities.map(
      (identity) => (identity.identityId === identityId ? { ...identity, isFavorite } : identity),
    );

    return {
      ...dashboards,
      [targetDashboardId]: {
        ...existingDashboard,
        identities: nextIdentities,
      },
    };
  }

  if (existingDashboard.identityLinks) {
    const nextIdentityLinks = existingDashboard.identityLinks.map(
      (identityLink) => (identityLink.identityId === identityId ? { ...identityLink, isFavorite } : identityLink),
    );

    return {
      ...dashboards,
      [targetDashboardId]: {
        ...existingDashboard,
        identityLinks: nextIdentityLinks,
      },
    };
  }

  console.error('No identities or identityLinks found for dashboard', existingDashboard);
  return dashboards;
};

export const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState: {
    items: {} as Record<string, DashboardItem>,
    usersToShare: [],
    dashboardsPageInfo: {},
    isExploreConciseView: false,
  },
  reducers: {
    setDashboards: (state, action) => {
      const dashboards = action.payload;

      const nextItems = dashboards.reduce((obj, dashboard) => ({
        ...obj,
        [dashboard.id]: {
          ...(state.items[dashboard.id] || {}),
          ...dashboard,
        },
      }), {});

      return {
        ...state,
        items: {
          ...state.items,
          ...nextItems,
        },
      };
    },
    addCreatedDashboard: (state, action) => {
      const dashboard = action.payload;

      return {
        ...state,
        items: {
          ...state.items,
          [dashboard.id]: dashboard,
        },
      };
    },
    setDashboardTabPosition: (state, action) => {
      const { tabPosition, dashboardId } = action.payload;
      const items = current(state.items);
      const itemId = getDashboardItemByDashboardId(items, dashboardId);
      const existingDashboard = state.items[itemId] || {} as DashboardItem;

      if (existingDashboard.tabPosition === tabPosition) {
        return state;
      }

      return {
        ...state,
        items: {
          ...state.items,
          [itemId]: {
            ...existingDashboard,
            tabPosition,
          },
        },
      };
    },

    changeUpdatedDashboard: (state, action) => {
      const updatedDashboard = legacyDashboardMapper(action.payload);

      const existingDashboard = state.items[updatedDashboard.id] || {};

      return {
        ...state,
        items: {
          ...state.items,
          [updatedDashboard.id]: {
            ...existingDashboard,
            ...updatedDashboard,
          },
        },
      };
    },
    changeUpdatedDashboardIsFavorite: (state, action) => {
      const nextDashboard = action.payload;
      const { dashboardId, identityId, isFavorite } = nextDashboard;

      const items = current(state.items);

      const nextItems = setDashboardFavorite(items, dashboardId, identityId, isFavorite);

      return {
        ...state,
        items: nextItems,
      };
    },
    changeUpdatedBaseQuery: (state, action) => {
      const { id, nextBaseQuery } = action.payload;
      const dashboard = state.items[id];

      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...dashboard,
            baseParams: nextBaseQuery,
          },
        },
      };
    },
    changeUpdatedPanels: (state, action) => {
      const { id, panel } = action.payload;
      const dashboard = state.items[id];
      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...dashboard,
            panels: {
              ...(dashboard.panels || {}),
              [panel.panelId]: panel,
            },
          },
        },
      };
    },
    changeUpdatedLayout: (state, action) => {
      const { id, nextLayout } = action.payload;
      const dashboard = state.items[id];

      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...dashboard,
            layout: nextLayout,
          },
        },
      };
    },
    changeDeletedDashboard: (state, action) => {
      const { id } = action.payload;

      const { [id]: removedItem, ...remainingItems } = state.items;

      return {
        ...state,
        items: remainingItems,
      };
    },
    changeMultipleUpdatedDashboards: (state, action) => {
      const updatedDashboards = action.payload;

      const nextItems = { ...state.items };

      updatedDashboards.forEach((dashboard) => {
        const { id } = dashboard;

        nextItems[id] = {
          ...(nextItems[id] || {}),
          ...dashboard,
        };
      });

      return {
        ...state,
        items: nextItems,
      };
    },
    updateDashboard: (state, action) => {
      const { id, ...attributes } = action.payload;

      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...(state.items[id] || {}),
            ...attributes,
          },
        },
      };
    },
    toggleExploreConciseView: (state) => {
      return {
        ...state,
        isExploreConciseView: !state.isExploreConciseView,
      };
    },
  },
});

export const {
  setDashboards,
  setDashboardTabPosition,
  addCreatedDashboard, // TODO: Remove
  changeUpdatedDashboardIsFavorite,
  changeUpdatedDashboard,
  changeMultipleUpdatedDashboards,
  changeUpdatedBaseQuery,
  changeUpdatedPanels,
  changeUpdatedLayout,
  changeDeletedDashboard,
  updateDashboard,
  toggleExploreConciseView,
} = dashboardsSlice.actions;
