import * as Sentry from '@sentry/react';
import findOrganizationId from '@v1/lib/findOrganizationId';
import logout from '@v1/lib/logout';
import userInformation from '@v1/lib/userInformation';
import { useEffect } from 'react';

import { useAppDispatch } from '@/store';
import { useLazyIdentityGetQuery, useLazyIdentitySubordinatesGetQuery } from '@/store/apis/identityApi';
import { setIdentity, setUser } from '@/store/slices/appSlice';

const useInitializeIdentity = () => {
  const dispatch = useAppDispatch();

  const [triggerIdentityGetQuery] = useLazyIdentityGetQuery();
  const [triggerIdentitySubordinates] = useLazyIdentitySubordinatesGetQuery();

  useEffect(() => {
    const init = async () => {
      try {
        const userInfo = userInformation();
        if (!userInfo?.id) {
          logout();
          return;
        }

        const identityFound = await triggerIdentityGetQuery({ supertokensExternalId: userInfo.id }).unwrap();
        if (!identityFound) {
          logout();
          return;
        }

        const organizationId = findOrganizationId(identityFound);
        triggerIdentitySubordinates({ identityId: organizationId });

        const nextUser = {
          userId: userInfo.id,
          name: identityFound.fullName,
          email: userInfo.email,
          identityId: identityFound.identityId,
          organizationId,
        };

        Sentry.setUser({ email: userInfo.email });
        dispatch(setUser(nextUser));
        dispatch(setIdentity(identityFound));
      } catch (e) {
        logout();
      }
    };

    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useInitializeIdentity;
