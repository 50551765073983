import dot from 'dot-object';
import { jsPDF as JSPDF } from 'jspdf';

import personaProperties from './personaProperties';

interface Item {
  label: string;
  content: string | string[];
}

interface Section {
  label: string;
  items?: Item[];
  content?: string | string[];
}

interface Persona {
  title: string;
  sections: Section[];
}

// PDF settings
const leftMargin = 40;
const topMargin = 60;
const lineHeight = 18;
const titleSpacing = 10;
const sectionSpacing = 30;
const contentSpacing = 2;
const fontName = 'helvetica';

const transformPersonaData = (
  personasResult?: Record<string, any>[],
): Persona[] => {
  return (personasResult || []).map((personaResult, index) => {
    const persona = personaResult?.properties || personaResult;

    const sections = personaProperties.map((property) => {
      if (!property.items) {
        return {
          label: property.label,
          content: dot.pick(property.key, persona) || property.placeholder,
        };
      }

      return {
        label: property.label,
        items: property.items.map((item) => ({
          label: item.label,
          content: dot.pick(item.key, persona) || item.placeholder,
        })),
      };
    });

    return {
      title: `Persona ${index + 1}`,
      sections,
    };
  });
};

const generatePdfFromPersonaData = (personasResult?: Record<string, any>[]) => {
  const personaData = transformPersonaData(personasResult);
  const doc = new JSPDF({
    unit: 'pt',
    format: 'letter',
  });

  let currentY = topMargin;

  const addNewPageIfNeeded = (additionalHeight = 0) => {
    if (currentY + additionalHeight > doc.internal.pageSize.getHeight() - 40) {
      doc.addPage();
      currentY = topMargin;
    }
  };

  const renderContent = (content: string | string[], indent: number) => {
    currentY += contentSpacing;
    if (Array.isArray(content)) {
      content.forEach((item) => {
        const bulletPoint = `• ${item}`;
        const wrapped = doc.splitTextToSize(bulletPoint, 500 - indent) as string[];
        addNewPageIfNeeded(wrapped.length * lineHeight);
        wrapped.forEach((line) => {
          doc.text(line, leftMargin + indent, currentY);
          currentY += lineHeight;
        });
      });
    } else {
      const wrapped = doc.splitTextToSize(content, 500 - indent) as string[];
      addNewPageIfNeeded(wrapped.length * lineHeight);

      wrapped.forEach((line) => {
        doc.text(line, leftMargin + indent, currentY);
        currentY += lineHeight;
      });
    }
    currentY += contentSpacing;
  };

  personaData.forEach((persona, personaIndex) => {
    doc.setFontSize(16);
    doc.setFont(fontName, 'bold');
    doc.text(persona.title, leftMargin, currentY);
    currentY += lineHeight + titleSpacing;

    persona.sections.forEach((section) => {
      addNewPageIfNeeded(lineHeight * 2);

      doc.setFontSize(14);
      doc.setFont(fontName, 'bold');
      doc.text(section.label, leftMargin, currentY);
      currentY += lineHeight + contentSpacing;

      if ('items' in section) {
        section.items.forEach((item) => {
          addNewPageIfNeeded(lineHeight * 2);

          doc.setFontSize(12);
          doc.setFont(fontName, 'bold');
          doc.text(item.label, leftMargin + 10, currentY);
          currentY += lineHeight + contentSpacing;

          doc.setFont(fontName, 'normal');
          renderContent(item.content, 20);
        });
      } else {
        doc.setFontSize(12);
        doc.setFont(fontName, 'normal');
        renderContent(section.content, 10);
      }

      currentY += sectionSpacing;
    });

    if (personaIndex < personaData.length - 1) {
      doc.addPage();
      currentY = topMargin;
    }
  });

  doc.output('dataurlnewwindow', { filename: 'persona.pdf' });
};

export default generatePdfFromPersonaData;
