import { Box } from '@mui/material';
import { useIdentify } from '@veltdev/react';
import html2canvas from 'html2canvas';
import React, { useEffect, useState } from 'react';

import DashboardUserChipAvatar from './screens/HomePage/DashboardChipAvatar/DashboardUserChipAvatar';
import { useAppSelector } from './store';
import { Identity } from './store/slices/appSlice';

interface IdentifyProps extends React.PropsWithChildren {
  identity: Identity
  encodedAvatar: string;
}

const Identify = (props: IdentifyProps) => {
  useIdentify({
    userId: props.identity.identityId,
    name: props.identity.fullName,
    email: props.identity.loginEmailAddress,
    photoUrl: props.encodedAvatar,
  });

  return props.children;
};

interface InnerProps extends React.PropsWithChildren {
  identity: Identity
}

const VeltIdentityInner = (props: InnerProps) => {
  const [encodedAvatar, setEncodedAvatar] = useState<string>();
  useEffect(() => {
    const createEncodedAvatar = setTimeout(() => {
      const avatar = document.getElementById('UserAvatar');

      html2canvas(avatar, {
        allowTaint: true,
        foreignObjectRendering: true,
        width: 40,
        height: 40,
      }).then((canvas) => {
        // Velt only lets us choose colors or images.
        // We don't have images, so we convert the off screen avatar into one.
        setEncodedAvatar(canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'));
      });
    });
    return () => {
      clearTimeout(createEncodedAvatar);
    };
  }, []);

  if (!encodedAvatar) {
    return null;
  }

  return <Identify identity={props.identity} encodedAvatar={encodedAvatar}>{props.children}</Identify>;
};

const VeltIdentity = ({ children }) => {
  const { identity } = useAppSelector((state) => state.app);

  return (
    <>
      {/* Needs to be rendered, so render it off screen */}
      <Box position="absolute" left="-100px">
        <div id="UserAvatar">
          <DashboardUserChipAvatar
            label={identity.firstName || identity.fullName}
            email={identity.loginEmailAddress}
            userId={identity.identityId}
            isStyleGroupAvatar={false}
            fontSizeLabel="1.5em"
          />
        </div>
      </Box>
      <VeltIdentityInner identity={identity}>{children}</VeltIdentityInner>
    </>
  );
};

export default VeltIdentity;
