import React from 'react';

import Button from '@/components/Button';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import TooltipWarning from '@/components/Tooltip/TooltipWarning';
import tooltips from '@/utils/tooltips';

const DashboardOpenTabs = ({ isAuthorized, onClick, openDashboardCount }) => {
  return (
    <TooltipWarning
      placement="top-start"
      message="You don't have permission to access a dashboard."
      conditionalRender={!isAuthorized}
    >
      <div>
        <TooltipInfo title={tooltips.viewOpenTabs}>
          <div>
            <Button disabled={!isAuthorized} onClick={onClick}>
              View my open tabs ({openDashboardCount})
            </Button>
          </div>
        </TooltipInfo>
      </div>
    </TooltipWarning>
  );
};

export default DashboardOpenTabs;
