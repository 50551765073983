import Box from '@mui/material/Box';

import TLDRContainer from '../../MarkdownViewer/TLDRContainer';
import styles from './Render.module.scss';

const Render = ({ result, userData }) => (
  <Box className={styles.container}>
    <Box className={styles.instructions}>{userData.instructions}</Box>
    <Box className={styles.answer}>{result.answer}</Box>
    <TLDRContainer className={styles.tldr} tldr={`This was written using personality modeling from the perspective of someone who is: ${result.tldr}`} />
  </Box>
);

export default Render;
