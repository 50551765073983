import qs from 'qs';
import Session from 'supertokens-auth-react/recipe/session';

import { api, paramNames } from '@/constants';

import { serializeInsightsParamsObject } from './serializeInsightsParams';
import tryParseJson from './tryParseJson';

// TODO: use async/await
export const fetchJson = (url, options = {}) => {
  return fetch(url, options).then(
    async (response) => {
      if (response.ok) {
        const text = await response.text();
        try {
          return JSON.parse(text);
        } catch (e) {
          return Promise.reject(text);
        }
      }

      const text = await response.text();
      return Promise.reject(new Error(`Error fetching url: ${url}: status code: ${response.status}: error: ${text}`));
    },
    (error) => {
      Promise.reject(error);
    },
  );
};

export const fetchInsightsJson = (params) => {
  const url = `${api.qlooApiV2}/insights?${qs.stringify(params)}`;
  return fetchJson(url);
};

export const getInsightsTagsQuery = ({
  baseParams, category, params, tagType,
}) => {
  const tempParams = {
    [paramNames.filterType]: 'urn:tag',
    ...(tagType ? { [paramNames.filterTagTypes]: `urn:tag:${tagType}:qloo` } : {}),
    ...(category ? { [paramNames.filterParentTypes]: `urn:entity:${category}` } : {}),

  };

  const insightsParams = serializeInsightsParamsObject(baseParams, tempParams);
  return qs.stringify({ ...insightsParams, ...(params || {}) });
};

export const getInsightsEntitiesQuery = ({ baseParams, category, params }) => {
  const tempParams = {
    [paramNames.filterType]: `urn:entity:${category}`,
  };
  const insightsParams = serializeInsightsParamsObject(baseParams, tempParams);
  return qs.stringify({ ...insightsParams, ...(params || {}) });
};

export const fetchInsightsTags = ({
  baseParams, category, params, tagType,
}) => {
  const tempParams = {
    [paramNames.filterType]: 'urn:tag',
    ...(tagType ? { [paramNames.filterTagTypes]: `urn:tag:${tagType}:qloo` } : {}),
    ...(category ? { [paramNames.filterParentTypes]: `urn:entity:${category}` } : {}),

  };

  const insightsParams = serializeInsightsParamsObject(baseParams, tempParams);
  return fetchInsightsJson({ ...insightsParams, ...(params || {}) });
};

export const fetchInsightsEntities = ({ baseParams, category, params }) => {
  const tempParams = {
    [paramNames.filterType]: `urn:entity:${category}`,
  };
  const insightsParams = serializeInsightsParamsObject(baseParams, tempParams);
  return fetchInsightsJson({ ...insightsParams, ...(params || {}) });
};

const parseGptContent = (result, mode) => {
  if (result.indexOf(`\`\`\`${mode}`) === 0) {
    const content = result.slice(3 + mode.length, -3);
    return content;
  }

  return result;
};

const parseJson = (result) => {
  const content = parseGptContent(result, 'json');
  return tryParseJson(content);
};

const parseMarkdown = (result) => {
  const content = result.replace(/```markdown(.+)```/sm, (_, e) => e);
  const lines = content.split('\n').map((line) => line.trim());
  const markdown = lines.join('\n');

  return markdown;
};

const parseGpt = (mode) => (result) => {
  switch (mode) {
    case 'json':
      return parseJson(result);
    case 'markdown':
      return parseMarkdown(result);
    default:
      return result;
  }
};

export const fetchGpt = ({ mode, prompt, schema }) => {
  return Session.getAccessToken()
    .then((accessToken) => {
      const headers = new Headers();
      headers.append('Content-type', 'application/json');
      headers.append('Authorization', `Bearer ${accessToken}`);
      return fetchJson(
        import.meta.env.VITE_GPT_LAMBDA_URL,
        { method: 'POST', headers, body: JSON.stringify({ prompt, schema }) },
      );
    })
    .then((result) => result?.results?.choices?.[0]?.message.content?.trim?.())
    .then(parseGpt(mode));
};
