import React, { createContext, useContext } from 'react';

import useDashboardActions from './useDashboardActions';

const DashboardContext = createContext({});
export const useDashboardActionsContext = () => useContext(DashboardContext);

const DashboardActionsProvider = ({ children, dashboard }) => {
  const dashboardActions = useDashboardActions({ dashboard });

  return (
    <DashboardContext.Provider value={dashboardActions}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardActionsProvider;
