import { Input as MuiInput } from '@mui/base/Input';
import clsx from 'clsx';
import React from 'react';

import styles from './Input.module.scss';

const Input = ({
  className,
  onChange,
  variant,
  size,
  type = 'text',
  min,
  max,
  ...rest
}) => {
  const onChangeHandler = (e) => {
    const { value } = e.currentTarget;
    const numericValue = type === 'number' ? Number(value) : value;

    if (type === 'number') {
      if ((min !== undefined && numericValue < min) || (max !== undefined && numericValue > max)) {
        return;
      }
    }
    onChange(value, e);
  };

  return (
    <MuiInput
      onChange={onChangeHandler}
      slotProps={{
        input: {
          className: clsx({
            [styles.input]: true,
            [styles.outlined]: !variant || variant === 'outlined',
            [styles.small]: size === 'small',
            [className]: className,
          }),
          type,
          min: type === 'number' ? min : undefined,
          max: type === 'number' ? max : undefined,
        },
      }}
      {...rest}
    />
  );
};

export default Input;
