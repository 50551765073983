import { Box, Card, Divider } from '@mui/material';

import Skeleton from '@/components/Skeleton';

const DashboardCardSkeleton = () => (
  <Card sx={{
    width: 345, height: 416, display: 'flex', flexDirection: 'column',
  }}
  >
    <Box display="flex" p={2} gap={2}>
      <Skeleton circle width={30} height={30} />
      <Box flex={1} display="flex" flexDirection="column" gap={0.5}>
        <Skeleton width={180} height={30} sx={{ marginBottom: 1 }} />
        <Skeleton width={100} height={20} />
      </Box>
      <Skeleton width={12} height={28} />
    </Box>
    <Box display="flex" flexDirection="column" alignItems="center" px={2} pb={2}>
      <Skeleton width={310} height={215} />
    </Box>
    <Divider />
    <Box display="flex" flexDirection="column" justifyContent="center" height="100%" px={2} gap={0.5}>
      <Box display="flex" alignItems="center" gap={1}>
        <Skeleton circle width={24} height={24} />
        <Skeleton variant="text" width={150} height={20} />
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Skeleton circle width={24} height={24} />
        <Skeleton variant="text" width={150} height={20} />
      </Box>
    </Box>
  </Card>
);

export default DashboardCardSkeleton;
