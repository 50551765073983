import React from 'react';

import styles from './CategorizedTags.module.scss';

const CategorizedTagsItems = ({ tags, limit = 4 }) => {
  return (
    <div className={styles.categoryDataTagItemNames}>
      {tags.slice(0, limit).map((tag, index) => (
        <span className={styles.categoryDataTagItemName} key={tag}>
          {index > 0 ? <span>{', '}</span> : null}
          <span>{tag.name}
          </span>
        </span>
      ))}
    </div>
  );
};

export default CategorizedTagsItems;
