import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { api, paramNames } from '@/constants';
import { TagTypes } from '@/entity/tag/TagTypes';
import { getHash } from '@/utils/format';
import serializeInsightsParams from '@/utils/serializeInsightsParams';

import generateUrlSearchParams from './utils/generateUrlSearchParams';

interface GetTagsParams {
  query?: string;
  parentType?: string;
  tagTypes?: string[];
  limit?: number;
  take?: number;
  tags?: string;
  offset?: number;
}

interface TagsResponse {
  success: boolean;
  totalRequestDuration: number;
  results: {
    tags: TagTypes.Tag[];
  };
}

export const tagsApi = createApi({
  reducerPath: 'tagsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.qlooApiV2}/tags`,
  }),
  tagTypes: ['Tags'],
  endpoints: (builder) => ({
    getTags: builder.query<TagTypes.Tag[], GetTagsParams>({
      query: ({
        query,
        parentType,
        tagTypes,
        limit,
        take,
        tags,
        offset,
        ...baseParams
      }) => {
        const params = [
          [paramNames.filterQuery, query],
          [paramNames.filterTags, !query ? tags : undefined],
          [paramNames.filterParentTypes, parentType],
          [paramNames.limit, limit],
          [paramNames.offset, offset],
          [paramNames.take, take],
          [paramNames.filterTagTypes, tagTypes ? String(tagTypes) : undefined],
          [paramNames.featureTypoTolerance, query ? true : undefined],
        ];

        const urlSearchParams = generateUrlSearchParams(params);

        const serializedBaseParams = serializeInsightsParams(baseParams, {});

        return `?${urlSearchParams}&${serializedBaseParams}`;
      },
      transformResponse: (response: TagsResponse) => response.results.tags,
      providesTags: (_result, _error, args) => [
        { type: 'Tags', id: getHash(args) },
      ],
    }),
  }),
});

export const { useGetTagsQuery } = tagsApi;
