import InputBase from '@mui/material/InputBase';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/system';
import * as React from 'react';

import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import SearchIcon from '@/icons/SearchIcon';
import tooltips from '@/utils/tooltips';

export const Search = styled('div')(({ theme, isExpanded }) => ({
  position: 'relative',
  borderRadius: 20,
  backgroundColor: theme.palette.greyLight.main,
  color: alpha(theme.palette.background.default, 1),
  transition: theme.transitions.create(['width', 'background-color', 'border-radius'], {
    duration: theme.transitions.duration.short,
  }),
  width: '2.5rem',
  height: '2.5rem',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  ...(isExpanded && {
    width: '15rem',
    backgroundColor: alpha(theme.palette.text.primary, 0.25),
    justifyContent: 'flex-start',
  }),

  '&:hover': {
    backgroundColor: alpha(theme.palette.text.primary, 0.30),
  },
}));

const SearchIconWrapper = styled('div')(({ isExpanded }) => ({
  padding: isExpanded ? '0 16px' : 0,
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme, isExpanded }) => ({
  color: 'inherit',
  width: isExpanded ? '100%' : 0,
  opacity: isExpanded ? 1 : 0,
  transition: theme.transitions.create(['width', 'opacity']),
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: '100%',
  },
}));

const HeaderSearchField = ({
  onBlur, value = '', onUpdateSearch, ...props
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleClick = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsExpanded(false);
      onBlur?.(event);
    }
  };

  const handleChange = (event) => {
    const { value: tv } = event.target;
    onUpdateSearch?.(tv);
  };

  return (
    <TooltipInfo title={tooltips.searchEntity}>
      <Search
        {...props}
        isExpanded={isExpanded}
        onClick={handleClick}
        onBlur={handleBlur}
        tabIndex={0}
      >
        <SearchIconWrapper isExpanded={isExpanded}>
          <SearchIcon />
        </SearchIconWrapper>

        <StyledInputBase
          inputProps={{ 'aria-label': 'search' }}
          value={value}
          onChange={handleChange}
          isExpanded={isExpanded}
        />
      </Search>
    </TooltipInfo>
  );
};

export default HeaderSearchField;
