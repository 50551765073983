import './style.scss';

import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';

import ErrorBoundary from '@/components/ErrorBoundary';

import DashboardPageErrorFallback from '../Dashboard/DashboardPageErrorFallback';
import HomePageRecentDashboards from './HomePageRecentDashboards';
import HomePageTableSection from './HomePageTableSection';
import HomePageTopBar from './HomePageTopBar';

const InnerHomePage = () => {
  const [search, setSearch] = useState('');

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <HomePageTopBar search={search} setSearch={setSearch} />
      <HomePageRecentDashboards search={search} />
      <HomePageTableSection search={search} />
    </Box>
  );
};

const HomePage = (props) => (
  <ErrorBoundary FallbackComponent={DashboardPageErrorFallback} onError={Sentry.captureException}>
    <InnerHomePage {...props} />
  </ErrorBoundary>
);

export default HomePage;
