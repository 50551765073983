import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';

const DashboardTableSkeleton = () => {
  const columns = [
    { width: 56 },
    { width: 220 },
    { width: 180 },
    { width: 220 },
    { width: 120 },
    { width: 120 },
    { width: 150 },
    { width: 200 },
    { width: 150 },
  ];

  const rows = Array.from(new Array(5));

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map((col, index) => (
            <TableCell key={index}>
              <Skeleton width={col.width} />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            {columns.map((col, colIndex) => (
              <TableCell key={colIndex}>
                <Skeleton width={col.width} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DashboardTableSkeleton;
