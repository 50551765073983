import { createSlice } from '@reduxjs/toolkit/react';

interface User {
  userId: string
  name: string
  email: string
  identityId: string
  organizationId: string
}

 interface Hierarchy {
  childTypeTagId: string
  hierarchyLevel: number
  childIdentityId: string
  parentIdentityId: string
}

export interface Identity {
  name: string
  profile: {}
  fullName: string
  lastName: string
  createdAt: string
  firstName: string
  hierarchy: Hierarchy[]
  typeTagId: string
  chipTagIds: string[]
  identityId: string
  isDisabled: boolean
  lastUpdatedAt: string
  permissionTagIds: any[]
  calculatedProfile: {}
  loginEmailAddress: string
  immediateSubordinates: any[]
  supertokensExternalId: string
  grantedPermissionTagIds: any[]
  calculatedPermissionTagIds: string[]
}

interface State {
  ownership: {},
  user?: User
  identity?: Identity
  presenceUsers: {},
  isDarkMode: boolean
}

const initialState = {
  ownership: {},
  user: null,
  identity: null,
  presenceUsers: {},
  isDarkMode: false,
} as State;

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIdentity: (state, action) => {
      return {
        ...state,
        identity: action.payload,
      };
    },
    setOwnership: (state, action) => {
      return {
        ...state,
        ownership: {
          ...state.ownership,
          [action.payload.key]: action.payload.userId,
        },
      };
    },
    clearOwnership: (state, action) => {
      return {
        ...state,
        ownership: {
          ...state.ownership,
          [action.payload.key]: null,
        },
      };
    },
    setUser: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    setPresenceUsers: (state, action) => {
      return {
        ...state,
        presenceUsers: action.payload,
      };
    },
    toggleDarkMode: (state) => {
      return {
        ...state,
        isDarkMode: !state.isDarkMode,
      };
    },
  },
});

export const {
  setIdentity,
  setOwnership,
  clearOwnership,
  setUser,
  setPresenceUsers,
  toggleDarkMode,
} = appSlice.actions;
