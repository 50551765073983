import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { api, paramNames } from '@/constants';
import { Audience } from '@/entity/audience/AudienceTypes';

import generateUrlSearchParams from './utils/generateUrlSearchParams';

interface GetAudiencesParams {
  query?: string;
  popularityMax?: number;
  tags?: string;
  take?: number;
}

interface AudiencesResponse {
  results: {
    audiences: Audience[];
  };
  success: boolean;
  totalRequestDuration: number;
}

export const audiencesApi = createApi({
  reducerPath: 'audiencesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.qlooApiV2}/audiences`,
  }),
  tagTypes: ['Audiences'],
  endpoints: (builder) => ({
    getAudiences: builder.query<Audience[], GetAudiencesParams>({
      query: ({
        query, popularityMax, tags, take,
      }) => {
        const params = [
          [paramNames.filterQuery, query],
          [paramNames.filterPopularityMax, popularityMax],
          [paramNames.take, take],
          [paramNames.filterTags, tags],
        ];

        const urlSearchParams = generateUrlSearchParams(params);

        return `?${urlSearchParams}`;
      },
      transformResponse: (response: AudiencesResponse) => response.results.audiences,
      providesTags: ['Audiences'],
    }),
  }),
});

export const { useGetAudiencesQuery } = audiencesApi;
