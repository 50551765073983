import { Drawer, IconButton, Typography } from '@mui/material';
import { PopupState } from 'material-ui-popup-state/hooks';
import React, { useState } from 'react';
import { X } from 'react-feather';

import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import { panelTypeLabels } from '@/constants/panelTypes';
import usePanelSuggestions from '@/hooks/usePanelSuggestions';
import { generateTitle } from '@/screens/Dashboard/DashboardPanel/PanelBodySuggestions/helpers/panelHelpers';
import { PanelSuggestionItem } from '@/screens/Dashboard/DashboardPanel/PanelBodySuggestions/helpers/types';
import SuggestionCard from '@/screens/Dashboard/DashboardPanel/PanelBodySuggestions/SuggestionCard';
import { DashboardItem } from '@/store/slices/dashboardSliceTypes';
import tooltips from '@/utils/tooltips';

import styles from './SuggestionsSidebar.module.scss';

interface SuggestionsSidebarProps {
  popupState: PopupState;
  dashboard: DashboardItem;
}

const SuggestionsSidebar = ({ popupState, dashboard }: SuggestionsSidebarProps) => {
  const [hoveredItem, setHoveredItem] = useState<string | number>();
  const {
    panelSuggestions, addPanelWithParams, entityName, location, formattedCategory, demographicsLabels, generateAllPanels,
  } = usePanelSuggestions({ dashboard });

  const onGenerateAllPanels = () => {
    generateAllPanels();
    popupState.close();
  };

  return (
    <Drawer
      anchor="right"
      open={popupState.isOpen}
      onClose={popupState.close}
      classes={{ paper: styles.sidebar }}
      slotProps={{
        backdrop: { sx: { backgroundColor: 'transparent' } },
      }}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '0px',
          boxShadow: '-4px 4px 20px 0px rgba(0, 0, 0, 0.08)',
        },
      }}
    >
      <div className={styles.sidebarHeader}>
        <div className={styles.containerTitleCreateAll}>
          <h4>Suggested for you</h4>
          <TooltipInfo title={tooltips.dashboardSuggestions.createAll}>
            <Typography onClick={onGenerateAllPanels} className={styles.textLinkSuggestions}>
              Create All
            </Typography>
          </TooltipInfo>
        </div>
        <TooltipInfo title={tooltips.dashboardSuggestions.x}>
          <IconButton onClick={popupState.close}>
            <X size={18} color="#1C1B1F" />
          </IconButton>
        </TooltipInfo>
      </div>
      <div className={styles.sidebarContent}>
        {Object.entries(panelSuggestions).map(([panelType, suggestions]) => (
          <div key={panelType} className={styles.panelTypeSection}>
            <h4 className={styles.panelTitle}>{panelTypeLabels[panelType] || 'Other Panels'}</h4>
            <div className={styles.suggestionList}>
              {(suggestions as PanelSuggestionItem[])?.map((item) => (
                <SuggestionCard
                  key={item.id}
                  className={styles.suggestionItem}
                  title={generateTitle(
                    item.title,
                    entityName,
                    location,
                    formattedCategory,
                    demographicsLabels,
                  )}
                  onClick={() => addPanelWithParams(item.panelType, item.additionalParams)}
                  onMouseEnter={() => setHoveredItem(item.id)}
                  onMouseLeave={() => setHoveredItem(null)}
                  isHovered={hoveredItem === item.id}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default SuggestionsSidebar;
