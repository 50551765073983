// Need to use the React-specific entry point to import createApi
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { api } from '@/constants';
import { setError } from '@/store/slices/errorSlice';

import { decrementFetching, incrementFetching } from '../slices/fetchingSlice';

const baseQuery = fetchBaseQuery({ baseUrl: `${api.hostname}/corporateapi` });

const backoffDelays = [125, 250, 250, 500, 500, 750, 750, 1000, 1000, 1000];

const customBackOff = async (
  maxRetries = 0,
) => {
  /// make sure our attempts are within the bounds of our backoffDelays array
  const attempts = Math.min(backoffDelays.length - 1, maxRetries);

  await new Promise((resolve) => {
    setTimeout(resolve, backoffDelays[attempts]);
  });
};

const customBaseQuery = async (args, mainApi, extraOptions) => {
  let error = null;
  let result = null;
  let attempt = 0;
  const maxRetries = 5;

  mainApi.dispatch(incrementFetching());

  while (attempt <= maxRetries) {
    try {
      // eslint-disable-next-line no-await-in-loop
      result = await baseQuery(args, mainApi, extraOptions);
      if (result.error?.data?.error) {
        result.error.message = result.error.data.error;
        delete result.error.data;
      }
      if (!result.error) {
        break; // Exit the loop on success
      }
    } catch (err) {
      error = err;
    }

    // eslint-disable-next-line no-await-in-loop
    await customBackOff(maxRetries);
    attempt += 1;
  }

  mainApi.dispatch(decrementFetching());
  if (result.error) {
    mainApi.dispatch(setError(result.error));
  }
  return error ? { error } : result;
};

export default customBaseQuery;
