import { AlertTriangle } from 'react-feather';

import Button from '@/components/Button';
import Card from '@/components/Card';
import ErrorFallback from '@/components/ErrorFallback';
import extractErrorMessage from '@/utils/extractErrorMessages';

import { useDashboardActionsContext } from '../DashboardActionsProvider';
import styles from './DashboardPanel.module.scss';
import PanelSkeleton from './skeleton/PanelSkeleton';

const ErrorCard = ({ error, resetErrorBoundary, panelId }) => {
  const dashboardActions = useDashboardActionsContext();

  const handlePanelDelete = () => {
    dashboardActions.deletePanel(panelId);
  };

  return (
    <Card
      className={styles.errorContainer}
      title={<><AlertTriangle color="hsl(var(--status-danger))" /> Error:</>}
      actions={[
        <Button
          key="contact"
          variant="link"
          noPadding
          href="mailto:saas@qloo.com?subject=query help&body=Hi!, I need help with..."
        >
          Contact Support
        </Button>,
        <Button key="delete" variant="outlined" onClick={handlePanelDelete}>Delete Panel</Button>,
        <Button key="retry" onClick={resetErrorBoundary}>Retry</Button>,
      ]}
    >
      <div className={styles.errorMessage}>
        <pre>{extractErrorMessage(error)}</pre>
      </div>
    </Card>
  );
};

const PanelErrorFallback = (props) => (
  <ErrorFallback
    backgroundRenderer={() => <PanelSkeleton disabled />}
    errorRenderer={() => <ErrorCard {...props} />}
  />
);

export default PanelErrorFallback;
