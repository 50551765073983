import { filterTypes, paramNames } from '@/constants';
import { FilterType } from '@/constants/filterTypes/types';
import { EntityTypes } from '@/entity/EntityTypes';
import { Heatmap } from '@/heatmap/HeatmapTypes';
import { InsightsResponse } from '@/store/apis/insightsApi/types';

// Retrieves data based on the specified filter type

interface GetDataFromInsightParams {
  panelParams: Record<string, any>;
  panelData: InsightsResponse;
}

// Transforms heatmap data into an array of items with location details

export const getHeatmapItems = (data: Heatmap[]) => (
  data.map((item) => ({
    lat: item.location.latitude,
    lon: item.location.longitude,
    geohash: item.location.geohash,
    query: item.query,
  }))
);

// Processes entity data by adding rank information

const processEntities = (entities: EntityTypes.Entity[], offset: number) => (
  entities.map((entity, i) => ({ ...entity, rank: offset + i + 1 }))
);
// Retrieves and transforms data based on the specified filter type

const getDataFromInsight = ({
  panelParams,
  panelData,
}: GetDataFromInsightParams) => {
  if (panelData?.error || panelData?.errors) {
    return {
      error: panelData.error,
      errors: panelData.errors,
    };
  }

  const filterType = panelParams[paramNames.filterType] as FilterType;
  const offset = Number(panelParams[paramNames.offset] || 0);

  switch (filterType) {
    case filterTypes.tag:
      return panelData?.results?.tags || [];

    case filterTypes.heatmap:
      return getHeatmapItems(panelData?.results?.heatmap || []);

    default:
      return processEntities(panelData?.results?.entities || [], offset);
  }
};

export default getDataFromInsight;
