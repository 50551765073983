import { Grid } from 'react-feather';

import TabButton from '@/components/TabButton';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import muiVeltIds from '@/constants/muiVeltIds';
import tooltips from '@/utils/tooltips';

import styles from './DashboardTabs.module.scss';

const DashboardTabsHomeButton = () => (
  <TooltipInfo title={tooltips.dashboard.grid}>
    <div>
      <TabButton
        href="#/dashboards"
        sx={{
          height: '100%',
          width: 38,
          minWidth: 38,
        }}
        id={muiVeltIds.buttons.tabHome}
        color="primaryDark"
      >
        <Grid className={styles.buttonIcon} size={14} />
      </TabButton>
    </div>
  </TooltipInfo>
);

export default DashboardTabsHomeButton;
