const realEstateForm = {
  title: 'Real Estate Persona Generation',
  description: 'Generate personas specific to real estate asset types.',
  type: 'object',
  properties: {
    useCase: {
      type: 'string',
      title: 'Type of Real Estate Use Case',
      description: 'Enter the type of real estate use case.',
    },
    assetType: {
      type: 'string',
      title: 'Type of Real Estate Asset',
      description: 'Enter the type of asset',
    },
    personaCount: {
      type: 'number',
      title: 'Number of Personas',
      description: 'Enter the number of personas to generate.',
    },
  },
  required: ['useCase', 'assetType', 'personaCount'], // Only required for real estate
};

export default realEstateForm;
