import { urnsToDisplayName } from '@qloo/categories';
import { useCategoryPerms, useCategoryPermsWithTag } from '@v1/lib/usePermissions';
import React, { useMemo } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

import Select from '../Select';

const CategorySelect = ({
  value, includeTag, onChange, className,
}) => {
  const categoryPermsWithTag = useCategoryPermsWithTag();
  const categoryPerms = useCategoryPerms();
  const categories = Object.keys(includeTag ? categoryPermsWithTag : categoryPerms);

  const options = ['', ...categories].map((urn) => ({ label: urn ? urnsToDisplayName(urn) : 'All Categories', value: urn }));

  const sortedOptions = useMemo(() => {
    return options.sort((a, b) => {
      if (typeof a.label === 'string') {
        return a.label.localeCompare(b.label[0]);
      }
      return a.label[0].localeCompare(b.label[0]);
    });
  }, [options]);

  return (
    <Select
      value={value}
      options={sortedOptions}
      onChange={onChange}
      className={className}
      customIcon={<IoIosArrowDown size={14} color="hsl(var(--accent-outlined))" strokeWidth={4} />}
    />
  );
};

export default CategorySelect;
