import askAudience from './askAudience';
import brainstorm from './brainstorm';
import copywriter from './copywriter';
import custom from './custom';
import objectionAnalysis from './objectionAnalysis';
import personas from './personas';
import realEstate from './realEstate';
import spokesperson from './spokesperson/Spokesperson';

const templates = {
  askAudience,
  brainstorm,
  copywriter,
  custom,
  objectionAnalysis,
  personas,
  realEstate,
  spokesperson,
};

const cleanName = (name) => name.replace(/[^\w]/g, '');

// Sort the names alphabetically / remove emojis to sort correctly
export default Object.fromEntries(
  Object.entries(templates)
    .sort(([, a], [, b]) => cleanName(a.name || '').localeCompare(cleanName(b.name || '')))
    // Lowercase keys for permission tags
    .map(([key, value]) => [key.toLowerCase(), value]),
);
