import {
  VeltCommentTool,
  VeltPresence,
} from '@veltdev/react';
import React from 'react';

import DashboardPermissionControl from '@/components/DashboardPermissionControl';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import permissionControlVariants from '@/constants/permissionControlVariants';
import permissionTags from '@/constants/permissionTags';
import { useAppDispatch, useAppSelector } from '@/store';
import { setPresenceUsers } from '@/store/slices/appSlice';
import keyBy from '@/utils/keyBy';
import tooltips from '@/utils/tooltips';

import { useDashboardVeltContext } from '../DashboardVeltProvider';
import CommentsSidebar from './CommentsSidebar';
import styles from './DashboardToolbar.module.scss';
import NewInsightPopover from './NewInsightPopover';

const DashboardToolbar = () => {
  const { isCommentingToggle, isVeltInitialized } = useDashboardVeltContext();

  const dispatch = useAppDispatch();
  const { isDarkMode } = useAppSelector((state) => state.app);

  const handlePresenceUserChange = (nextUsers) => {
    const userByIds = keyBy(nextUsers, 'userId');
    dispatch(setPresenceUsers(userByIds));
  };

  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbarLeft}>
        <DashboardPermissionControl
          permissionsRequired={[permissionTags.roleEditor]}
          variant={permissionControlVariants.hidden}
        >
          <NewInsightPopover />
        </DashboardPermissionControl>
      </div>
      <div className={styles.toolbarRight}>
        <div className={styles.toolbarItem}>
          {isVeltInitialized ? (
            <VeltPresence
              flockMode
              onPresenceUserChange={handlePresenceUserChange}
            />
          ) : null}
        </div>
        {isVeltInitialized ? (
          <>
            <DashboardPermissionControl
              permissionsRequired={[permissionTags.roleEditor]}
              variant={permissionControlVariants.hidden}
            >
              <div className={styles.toolbarItem}>
                <TooltipInfo title={tooltips.comments.bubble}>
                  <div>
                    <VeltCommentTool onCommentModeChange={isCommentingToggle.set} darkMode={isDarkMode} />
                  </div>
                </TooltipInfo>
              </div>
            </DashboardPermissionControl>
            <div className={styles.toolbarItem}>
              <TooltipInfo title={tooltips.comments.inbox}>
                <div>
                  <CommentsSidebar />
                </div>
              </TooltipInfo>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DashboardToolbar;
