import React from 'react';
import {
  useClaimValue,
  useSessionContext,
} from 'supertokens-auth-react/recipe/session';
import {
  UserRoleClaim /* PermissionClaim */,
} from 'supertokens-auth-react/recipe/userroles';

import { SessionContextProvider } from './lib/SessionContext';

const SupertokensSessionAuthProvider = ({ children }) => {
  const claimValue = useClaimValue(UserRoleClaim);
  const sessionContext = useSessionContext();
  return (
    <SessionContextProvider claimValue={claimValue} sessionContext={sessionContext}>
      {children}
    </SessionContextProvider>
  );
};

export default SupertokensSessionAuthProvider;
