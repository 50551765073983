/* eslint-disable simple-import-sort/imports */
import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { interestTypes } from '@/constants';
import { fetchEntityStart } from '@/store/slices/entitiesSlice';
import { fetchTagStart } from '@/store/slices/tagsSlice';
import { useEntityBatchedRequestsContext } from '@/contexts/EntityBatchedRequestsContext';
import { useTagBatchedRequestsContext } from '@/contexts/TagBatchedRequestsContext';
import { useAppDispatch, useAppSelector } from '@/store';
import { EntityTypes } from '@/entity/EntityTypes';
import { TagTypes } from '@/entity/tag/TagTypes';

interface OmnisearchItemTextProps {
  item: EntityTypes.Entity | TagTypes.Tag;
  className?: string;
  index: number;
}

const OmnisearchItemText = ({
  className,
  item,
  index,
}: OmnisearchItemTextProps) => {
  const isTag = item.type === interestTypes.tag;

  const entityBatchedRequest = useEntityBatchedRequestsContext();
  const tagBatchedRequest = useTagBatchedRequestsContext();

  const dispatch = useAppDispatch();

  const entity = useAppSelector((state) => state.entities.byId[item.id]);
  const tag = useAppSelector((state) => state.tags.byId[item.id]);

  const fetchStart = isTag ? fetchTagStart : fetchEntityStart;
  const queueArg = isTag
    ? tagBatchedRequest.queueArg
    : entityBatchedRequest.queueArg;

  // Get label from item or store
  const label = useMemo(() => {
    if (item.name) return item.name;

    return isTag ? tag?.name : entity?.name;
  }, [item, isTag, tag, entity]);

  // Fetch entity/tag if not in store
  const fetchIfNeeded = () => {
    if (!label) {
      dispatch(fetchStart(item.id));
      queueArg(item.id);
    }
  };

  useEffect(() => {
    fetchIfNeeded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {typeof index === 'number' && index > 0 ? <span>{',\u00A0'}</span> : null}
      <span className={clsx({ [className]: className })}>
        {label || `${String(item.id).slice(0, 11)}...`}
      </span>
    </>
  );
};

export default OmnisearchItemText;
