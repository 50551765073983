import { getReasonPhrase } from 'http-status-codes';

const getHttpPhrase = (code: number) => {
  if (code === 460) {
    return 'Archived';
  }

  return getReasonPhrase(code);
};

export default getHttpPhrase;
