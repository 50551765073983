import React from 'react';
import { Provider } from 'react-redux';

import store from '../v2/src/app/store';
import Router from './Router';

const App = () => {
  // Netlify uses build caches that cannot be disabled.
  // If a commit is cherry-picked into main production will end up with a cached build for staging.
  // Also update .env.production when changing this value.
  if (window.location.hostname === 'insights.qloo.com'
    && import.meta.env.VITE_QLOO_API_SERVER !== 'https://ci4n68g2r8.execute-api.us-east-1.amazonaws.com') {
    return <div>Site configuration error.</div>;
  }
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
};

export default App;
