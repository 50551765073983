import AddIcon from '@mui/icons-material/Add';
import {
  Box, Card, CardActionArea, CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useCreateDashboardMutation } from '@/store/apis/dashboardsApi';

const AddDashboardCard = () => {
  const navigate = useNavigate();
  const [createDashboard, { isLoading: isCreating }] = useCreateDashboardMutation();

  const handleCreate = async () => {
    const newDashboard = await createDashboard().unwrap();
    navigate(`/dashboards/view/${newDashboard.id}`);
  };

  return (
    <Card sx={{ minHeight: '416px' }}>
      <CardActionArea sx={{ width: '100%', height: '100%' }} onClick={handleCreate}>
        <Box
          width="345px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {isCreating ? (
            <CircularProgress size={60} />
          ) : (
            <AddIcon color="disabled" sx={{ fontSize: '110px' }} />
          )}
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default AddDashboardCard;
