import tooltips from '@/utils/tooltips';

export const TABS = {
  dashboards: {
    label: 'Dashboards',
    to: '/dashboards',

    tooltip: tooltips.navigation.dashboards,
  },

  requests: {
    label: 'Requests',
    to: '/requests',
    alias: '/recs',

    tooltip: tooltips.navigation.requests,
  },

  compare: {
    label: 'Compare',
    to: '/compare',

    tooltip: tooltips.navigation.compare,
  },

  shareables: {
    label: 'Shareables',
    to: '/shareables',

    tooltip: tooltips.navigation.shareables,
  },

  admin: {
    label: 'Admin',
    to: '/admin',

  },

};

export const USABLE_PAGES = Object.keys(TABS);
export const SEARCH_ENABLED = !import.meta.env.VITE_LAYOUT_SIMPLE;
export const ENTITY_VIEW_ENABLED = !import.meta.env.VITE_LAYOUT_SIMPLE;
