import { LinearProgress } from '@mui/material';
import { findPriority, useCategoryPerms } from '@v1/lib/usePermissions';
import clx from 'clsx';
import React, { useMemo, useState } from 'react';

import Overlay from '@/components/Overlay';
import Skeleton from '@/components/Skeleton';
import useActiveDashboard from '@/hooks/useActiveDashboard';
import useGPTExplainCategories from '@/hooks/useGPTExplainCategories';
import buildMissingParamsErrorMessage from '@/screens/Dashboard/DashboardPanel/helpers/buildMissingParamsErrorMessage';
import { getMissingPanelParams } from '@/screens/Dashboard/DashboardPanel/helpers/getMissingPanelParams';
import PanelBodyError from '@/screens/Dashboard/DashboardPanel/PanelBodyError';

import CategoryDataView from './CategoryDataView';
import styles from './PanelBodyExplore.module.scss';

const CategoriesDataList = ({
  onSelect,
  visible,
  panel,
}) => {
  const { baseParams } = useActiveDashboard();
  const { params: panelParams, panelType } = panel;

  const missingParams = getMissingPanelParams(panelType, panelParams, baseParams);
  const customErrorMessage = buildMissingParamsErrorMessage(missingParams);

  const categories = Object.keys(useCategoryPerms()).sort((a, b) => {
    const aPriority = findPriority(a);
    const bPriority = findPriority(b);
    if (aPriority !== bPriority) {
      return aPriority - bPriority;
    }
    return a.localeCompare(b);
  });

  const [categoriesData, setCategoriesData] = useState(
    categories.map((category) => ({
      category,
      loaded: false,
      gptResult: '',
    })),
  );

  const updateCategoryData = (category, gptResult, error) => {
    setCategoriesData((prev) => {
      const existingCategory = prev.find((data) => data.category === category);
      if (existingCategory?.loaded) {
        return prev;
      }
      return prev.map((data) => (data.category === category
        ? { ...data, loaded: true, gptResult: error ? '' : gptResult }
        : data));
    });
  };

  const loadedCount = categoriesData.filter((data) => data.loaded).length;
  const totalCategories = categoriesData.length;
  const allLoaded = loadedCount === totalCategories;
  const gptCategoriesData = useMemo(() => {
    if (allLoaded) {
      return categoriesData.map((data) => data.gptResult);
    }
    return [];
  }, [allLoaded, categoriesData]);

  const {
    result: gptResult,
    isLoading: isLoadingGPT,
  } = useGPTExplainCategories(
    {
      categories: gptCategoriesData,
    },
    {
      skip: !allLoaded || !missingParams.isValid,
    },
  );

  return (
    <div
      className={clx(
        styles.mainContainer,
        { [styles.hidden]: !visible },
      )}
    >
      {missingParams.isValid
      && (
        <>
          {!allLoaded && (
            <>
              <LinearProgress />
              <div className={styles.progressBarContainer}>
                <div className={styles.progressBar}>
                  Loading {loadedCount} of {totalCategories}
                </div>
              </div>
            </>
          )}
          {allLoaded && (
            <span className={styles.gptMessage}>
              {isLoadingGPT && (
                <Skeleton
                  width="95%"
                  height={40}
                />
              )}
              {!isLoadingGPT && gptResult}
            </span>
          )}

          <div
            className={clx(
              styles.categoriesDataListGrid,
              styles.categoriesDataListGridConcise,
            )}
          >
            {categories.map((category) => (
              <CategoryDataView
                category={category}
                panelParams={panelParams}
                key={category}
                onSelect={onSelect}
                conciseView
                isEmptyBaseQuery={!Object.keys(baseParams).length}
                updateCategoryData={updateCategoryData}
              />
            ))}
          </div>
        </>
      )}
      {(panelType === 'location_explorer' && !missingParams.isValid)
          && (
            <Overlay>
              <PanelBodyError
                message={customErrorMessage}
                className={styles.panelBodyErrorToExploreLocation}
              />
            </Overlay>
          )}
    </div>
  );
};

export default CategoriesDataList;
