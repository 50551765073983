import React from 'react';
import { X } from 'react-feather';

import { fieldByParamName } from '@/constants';

import styles from './FilterTag.module.scss';

const FilterTag = ({
  content, paramName, onDelete, onClick,
}) => {
  const field = fieldByParamName[paramName];

  const handleDelete = (e) => {
    e.stopPropagation();

    onDelete(paramName);
  };

  return (
    <div
      className={styles.filterTag}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') onClick(event);
      }}
    >
      <div
        className={styles.filterTagAction}
        onClick={handleDelete}
        role="button"
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter') handleDelete(event);
        }}
      >
        <X size={13} />
      </div>
      <span>{field.label}:{'\u00A0'}</span>
      <span className={styles.filterTagContent}>
        {field.renderTag ? field.renderTag(content) : content}
      </span>
    </div>
  );
};

export default FilterTag;
