import { createSlice } from '@reduxjs/toolkit';

import keyBy from '@/utils/keyBy';

export const tagsSlice = createSlice({
  name: 'tags',
  initialState: {
    byId: {},
  },
  reducers: {
    fetchTagStart: (state, action) => {
      const { byId } = state;
      const tagId = action.payload;

      return {
        ...state,
        byId: {
          ...byId,
          [tagId]: {
            ...(byId[tagId] || {}),
          },
        },
      };
    },
    mergeTags: (state, action) => {
      const tags = action.payload;

      return {
        ...state,
        byId: { ...state.byId, ...keyBy(tags, 'id') },
      };
    },
  },
});

export const {
  fetchTagStart,
  mergeTags,
} = tagsSlice.actions;
