import { AudienceBatchedRequestProvider } from '@/contexts/AudienceBatchedRequestContext';
import { EntityBatchedRequestProvider } from '@/contexts/EntityBatchedRequestsContext';
import { IdentityBatchProvider } from '@/contexts/IdentityBatchContext';
import { TagBatchedRequestProvider } from '@/contexts/TagBatchedRequestsContext';
import useInitializeIdentity from '@/hooks/useInitializeIdentity';
import { useAppSelector } from '@/store';
import VeltIdentity from '@/VeltIdentity';

import PublicDashboardView from './PublicDashboardView';

const PublicDashboardPage = () => {
  const { identity } = useAppSelector((state) => state.app);

  useInitializeIdentity();

  if (!identity || !identity.calculatedPermissionTagIds) {
    return null;
  }

  return (
    <VeltIdentity>
      <AudienceBatchedRequestProvider>
        <EntityBatchedRequestProvider>
          <IdentityBatchProvider>
            <TagBatchedRequestProvider>
              <PublicDashboardView />
            </TagBatchedRequestProvider>
          </IdentityBatchProvider>
        </EntityBatchedRequestProvider>
      </AudienceBatchedRequestProvider>
    </VeltIdentity>
  );
};

export default PublicDashboardPage;
