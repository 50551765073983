import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { IconButton } from '@mui/material';
import { useState } from 'react';

import permissionControlVariants from '@/constants/permissionControlVariants';
import permissionTags from '@/constants/permissionTags';

import DashboardPermissionControl from '../DashboardPermissionControl';
import LocationPopover from '../LocationPopover';

const DrawControl = ({ location, onChange, onReset }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <DashboardPermissionControl
        permissionsRequired={[permissionTags.roleEditor]}
        variant={permissionControlVariants.tooltip}
      >
        <IconButton onClick={handleClick}>
          <ZoomOutMapIcon fontSize="small" />
        </IconButton>
      </DashboardPermissionControl>
      <LocationPopover
        location={location}
        anchorEl={anchorEl}
        onClose={handleClose}
        onChange={onChange}
        onReset={onReset}
      />
    </div>
  );
};

export default DrawControl;
