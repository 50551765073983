import Popover from '@mui/material/Popover';
import { ReactComponent as LogoSmSvg } from '@v1/components/LogosmWhite.svg';
import * as React from 'react';

import styles from './CitationPopover.module.scss';

const cleanCitation = (citation: string) => citation && decodeURIComponent(citation.replace(/,(?!\s)/g, ', '));

interface Props extends React.PropsWithChildren {
  citation: string
}

const CitationPopover = ({ citation, children }: Props) => {
  const [anchorPosition, setAnchorPosition] = React.useState({ top: 0, left: 0 });
  const [open, setOpen] = React.useState(false);

  const handleMouseMove = (event) => {
    setAnchorPosition({
      left: event.clientX,
      top: event.clientY + 10, // offset slightly below cursor
    });
  };

  const handlePopoverOpen = () => {
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseMove={handleMouseMove}
        onMouseLeave={handlePopoverClose}
        className={styles.citation}
      >
        {children}
      </span>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        elevation={0}
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        classes={{
          paper: styles.citationPopover,
        }}
      >
        <div>
          <div>
            <LogoSmSvg />
            <h4>from Qloo&#39;s analysis of</h4>
          </div>
          <span>
            &ldquo;{cleanCitation(citation)}&rdquo;
          </span>
        </div>
      </Popover>
    </>
  );
};

export default CitationPopover;
