import React, { useCallback } from 'react';

import { paramNames } from '@/constants';

import CategoryPopover from '../../../components/CategoryPopover';
import hasGeo from '../helpers/hasGeo';

const PanelParamCategory = ({
  panelParams, onParamsChange, enforceInlineBehavior, isViewingPublicly = false,
}) => {
  const selectedCategory = panelParams[paramNames.filterType];
  const subCategories = panelParams[paramNames.filterTags_status];

  const handleCategoryChange = useCallback((nextCategory) => {
    const { [paramNames.filterLocation]: filterLocation, ...restParams } = panelParams;
    const isGeo = hasGeo(nextCategory);

    const nextParams = {
      ...restParams,
      [paramNames.filterType]: nextCategory,
      [paramNames.filterTags_status]: {},
    };

    // Remove location if new category is not geo
    if (filterLocation) {
      nextParams[paramNames.filterLocation] = isGeo ? filterLocation : null;
    }

    onParamsChange(nextParams);
  }, [onParamsChange, panelParams]);

  const handleSubCategoryChange = useCallback(
    (keyName, newValue) => {
      const nextValue = { ...subCategories };

      if (newValue !== null) {
        nextValue[keyName] = newValue ? 'include' : 'exclude';
      } else {
        delete nextValue[keyName];
      }

      onParamsChange({
        ...panelParams,
        [paramNames.filterTags_status]: nextValue,
      });
    },
    [subCategories, onParamsChange, panelParams],
  );

  const handleReset = () => {
    onParamsChange({
      ...panelParams,
      [paramNames.filterType]: null,
      [paramNames.filterTags_status]: {},
    });
  };

  return (
    <CategoryPopover
      enforceInlineBehavior={enforceInlineBehavior}
      category={selectedCategory}
      subCategories={subCategories}
      onCategoryChange={handleCategoryChange}
      onSubcategoriesChange={handleSubCategoryChange}
      showSubCategorySelect
      onReset={handleReset}
      isViewingPublicly={isViewingPublicly}
    />
  );
};

export default PanelParamCategory;
