import { useSnackbar } from 'notistack';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useClipboard } from 'use-clipboard-copy';

const CopyAndNotify = ({ children, text, successMessage = 'Copied URL to clipboard' }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleCopied = () => {
    enqueueSnackbar(successMessage, {
      variant: 'success',
      autoHideDuration: 3000,
    });
  };

  return (
    <CopyToClipboard text={text} onCopy={handleCopied}>
      <div>
        {children}
        {text}
      </div>
    </CopyToClipboard>
  );
};

export default CopyAndNotify;

export const useCopyAndNotify = ({ successMessage = 'Copied URL to clipboard' }) => {
  const { enqueueSnackbar } = useSnackbar();
  const clipboard = useClipboard({
    onSuccess() {
      enqueueSnackbar(successMessage, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
  });

  return [clipboard.copy];
};
