import { useMemo } from 'react';

import permissionTags from '@/constants/permissionTags';
import { useAppSelector } from '@/store';
import arrayToBitMap from '@/utils/arrayToBitMap';

const getHasDashboardPermissions = ({ identity, dashboard, requiredPermissions }): boolean => {
  if (!identity || !dashboard || !requiredPermissions || !requiredPermissions.length) {
    return false;
  }

  const userPermissionTags = identity.calculatedPermissionTagIds || [];

  if (userPermissionTags.includes(permissionTags.roleSuperuser)) {
    return true;
  }

  const requiredPermissionsBitMap = arrayToBitMap(requiredPermissions);

  const parentIdentitiesIds = (identity.hierarchy || []).map((il) => il.childIdentityId);
  const parentIdentitiesIdsBitMap = arrayToBitMap(parentIdentitiesIds);

  return (dashboard.identityLinks || dashboard.identities || []).some((il) => (
    (il.identityId === identity.identityId || parentIdentitiesIdsBitMap[il.identityId])
    && il.calculatedPermissionTagIds.some((pt) => requiredPermissionsBitMap[pt] || pt === permissionTags.roleSuperuser)
  ));
};

const useHasDashboardPermissions = (dashboardId: string, requiredPermissions: string[]): boolean => {
  const { identity } = useAppSelector((state) => state.app);
  const dashboard = useAppSelector((state) => state.dashboards.items[dashboardId]);

  const hasPermission = useMemo(
    () => getHasDashboardPermissions({ identity, dashboard, requiredPermissions }),
    [identity, dashboard, requiredPermissions],
  );

  return hasPermission;
};

export default useHasDashboardPermissions;
