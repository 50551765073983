import RadarIcon from '@mui/icons-material/Radar';
import {
  Box, InputAdornment, TextField,
} from '@mui/material';
import React, { useState } from 'react';

import {
  isValidDecimalInput,
  kmToMeters,
  metersToKm,
  metersToMiles,
  milesToMeters,
  UNIT,
  UnitType,
} from '@/components/MapDrawShape/helpers/units';

import styles from './MapDrawShape.module.scss';

interface RadiusSelectorProps {
    radius: number;
    onChange: (radius: number) => void;
}

const RadiusSelector = ({ radius, onChange }: RadiusSelectorProps) => {
  const [unit] = useState<UnitType>(UNIT.metric);
  const [inputValue, setInputValue] = useState<string>(unit === UNIT.imperial ? metersToMiles(radius) : metersToKm(radius));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (isValidDecimalInput(newValue)) {
      setInputValue(newValue);

      let parsedValue = parseFloat(newValue);

      if (Number.isNaN(parsedValue) || parsedValue <= 0) {
        parsedValue = 1;
      }

      const convertedValue = unit === UNIT.imperial ? milesToMeters(parsedValue) : kmToMeters(parsedValue);
      onChange(convertedValue);
    }
  };

  return (
    <Box display="flex" alignItems="center" gap="8px" padding="0 10px" minWidth={250}>
      <RadarIcon fontSize="small" />
      <span>Radius:</span>

      <TextField
        value={inputValue}
        onChange={handleChange}
        sx={{
          '& .MuiInputBase-root': {
            paddingRight: '10px',
            borderRadius: '4px',
          },
          '& .MuiInputBase-input': {
            padding: '8px 0 8px 14px',
          },
        }}
        className={styles.radiusInput}
        InputProps={{
          disableUnderline: true,
          endAdornment: <InputAdornment position="end">km</InputAdornment>,
        }}
      />
    </Box>
  );
};

export default RadiusSelector;
