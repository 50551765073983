import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import {
  Navigate, Route, Routes, useParams,
} from 'react-router-dom';

import Dashboard, { PermissionError } from '@/constants/permissions/dashboard';
import DashboardPage from '@/screens/Dashboard/DashboardPage';
import HomePage from '@/screens/HomePage/HomePage';
import NotFound from '@/screens/NotFound/NotFound';
import slugify from '@/utils/slugify';

import { AudienceBatchedRequestProvider } from './contexts/AudienceBatchedRequestContext';
import { EntityBatchedRequestProvider } from './contexts/EntityBatchedRequestsContext';
import { IdentityBatchProvider } from './contexts/IdentityBatchContext';
import { TagBatchedRequestProvider } from './contexts/TagBatchedRequestsContext';
import useInitializeIdentity from './hooks/useInitializeIdentity';
import getTitle from './screens/Dashboard/helpers/getTitle';
import { useAppSelector } from './store';
import VeltIdentity from './VeltIdentity';

const DashboardWithTitleWrapper = () => {
  const { id, slug } = useParams();
  const dashboard = useAppSelector((state) => state.dashboards.items[id]);
  const title = getTitle(dashboard);

  useEffect(() => {
    const nextSlug = slugify(title);

    // Update url if slug is not present or title changes
    if (nextSlug && (!slug || slug !== nextSlug)) {
      window.history.replaceState(null, null, `/#/dashboards/view/${id}/${nextSlug}`);
    }
  }, [slug, id, title]);

  return (
    <>
      <Helmet>
        <title>Dashboard - {title}</title>
      </Helmet>
      <DashboardPage />
    </>
  );
};

const DashboardNoTabsWrapper = () => (
  <>
    <Helmet>
      <title>Qloo Insight Dashboard View</title>
    </Helmet>
    <DashboardPage />
  </>
);

const DashboardHomeWrapper = () => (
  <>
    <Helmet>
      <title>Qloo Insight Dashboard Database</title>
    </Helmet>
    <HomePage />
  </>
);

const ProtectedRoute = ({ permission, element, throwError = true }) => {
  const { identity } = useAppSelector((state) => state.app);

  if (
    identity
    && (
      (identity.calculatedPermissionTagIds || []).includes(permission.role)
      || (identity.calculatedPermissionTagIds || []).includes('role.superuser')
    )
  ) {
    return element;
  }

  if (throwError) {
    return (
      <element>
        {(() => {
          throw new PermissionError(permission);
        })()}
      </element>
    );
  }

  return <Navigate to="/" replace />;
};

const Router = () => {
  const { identity } = useAppSelector((state) => state.app);

  useInitializeIdentity();

  if (!identity || !identity.calculatedPermissionTagIds) {
    return null;
  }

  return (
    <VeltIdentity>
      <AudienceBatchedRequestProvider>
        <EntityBatchedRequestProvider>
          <IdentityBatchProvider>
            <TagBatchedRequestProvider>
              <Routes>
                <Route
                  path="/dashboards"
                  element={(
                    <ProtectedRoute
                      permission={Dashboard.FEATURE}
                      element={<DashboardHomeWrapper />}
                    />
                  )}
                />

                <Route
                  path="/dashboards/view"
                  element={(
                    <ProtectedRoute
                      permission={Dashboard.VIEW}
                      element={<DashboardNoTabsWrapper />}
                    />
                  )}
                />
                <Route
                  path="/dashboards/view/:id"
                  element={(
                    <ProtectedRoute
                      permission={Dashboard.VIEW}
                      element={<DashboardWithTitleWrapper />}
                    />
                  )}
                />
                <Route
                  path="/dashboards/view/:id/:slug"
                  element={(
                    <ProtectedRoute
                      permission={Dashboard.VIEW}
                      element={<DashboardWithTitleWrapper />}
                    />
                  )}
                />
                {/* Not Found Route, place routes above it */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </TagBatchedRequestProvider>
          </IdentityBatchProvider>
        </EntityBatchedRequestProvider>
      </AudienceBatchedRequestProvider>
    </VeltIdentity>
  );
};

export default Router;
