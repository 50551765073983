import React from 'react';

import Skeleton from '@/components/Skeleton';

import styles from '../DashboardPanel.module.scss';

interface Props {
  disabled: boolean
}

const PanelHeaderSkeleton = ({ disabled }: Props) => (
  <div className={styles.panelHeader}>
    <div className={styles.panelHeaderLeft}>
      <Skeleton disabled={disabled} width={182} height={22} />
    </div>
    <div>
      <Skeleton disabled={disabled} width={12} height={24} />
    </div>
  </div>
);

export default PanelHeaderSkeleton;
