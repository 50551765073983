import { paramNames } from '@/constants';
import getDemographicsLabel from '@/utils/getDemographicsLabel';
import getParamsLabel from '@/utils/getParamsLabel';

export default async ({
  baseParams, entities, fetchGpt, tags, userData,
}) => {
  try {
    const demographicsLabel = getDemographicsLabel(baseParams);
    const paramsLabel = getParamsLabel(baseParams);

    const audience = `${paramsLabel} ${demographicsLabel}${baseParams[paramNames.signalLocation] ? `and lives in ${baseParams[paramNames.signalLocation].label}` : ''}`;

    const personality = await fetchGpt({
      prompt: `
        Assume the role of someone who is considered: ${audience}
        Imagine you like ${entities.map((entity) => entity.name).join(', ')}
        and might be interested in: ${tags.map((tag) => tag.name).join(', ')}.
  
        Write a paragraph explaining your age, who you are, what all of these subjects have in common without mentioning them by name, what they mean to you, what a typical day is like in your life, then write about what your personality is.
    `,
    });

    const summarized = await fetchGpt({
      prompt: `
        Assume the role of someone who is considered: ${paramsLabel} ${demographicsLabel}${baseParams[paramNames.signalLocation] ? `and lives in ${baseParams[paramNames.signalLocation].label}` : ''}
        Imagine you like ${entities.map((entity) => entity.name).join(', ')}
        and might be interested in: ${tags.map((tag) => tag.name).join(', ')}.
        The personality of the person is: ${personality}

        Write a markdown document with the following sections, only return the markdown document, no leading text.
        
        ## Pain points discovery:
        Assume the role of someone who is considered: ${audience}
        Describe your typical day, focusing on 5 main problems.
        and stresses related to ${userData.instructions}. Use everyday language and specific situations.

        ## Objections analysis prompt
        You are a potential customer for ${userData.instructions}. Write 10 most
        honest reasons why you would NOT buy this product. Use direct speech and real-life examples.

        ## Purchase trigger
        Imagine you're ${audience} and are overcoming your objections to purchase ${userData.instructions}
        Describe in list:
        - what would prompt you to buy?
        - what event would lead to this decision?
        - what emotions would you experience when making this decision?

        ## Target audience language
        Analyze 3 main fears and desires of ${audience} regarding ${userData.instructions}.
        Write 7 exact phrases and expressions they use when discussing each
        fear/desire in real life.
    `,
    });

    const tldr = await fetchGpt({
      prompt: `
        * The following was generated using data science and personality techniques from the perspective of someone who is considered: ${audience}.
        * The personality of the person is: ${personality}

        In one concise paragraph, from a third-person perspective, summarize the opinions of the person as it relates to ${userData.instructions}, based on the following:

        ${summarized}
      `,
    });

    const final = `# Introduction
${tldr}
${summarized}`;

    return final;
  } catch (e) {
    return 'Error';
  }
};
