/* eslint-disable max-len */
import permissionTags from '@/constants/permissionTags';

import { TYPES_FILTERS } from '../HomePageConsts';

const defaultParams = {
  permissionTagIds: [permissionTags.roleSuperuser, permissionTags.roleViewer, permissionTags.roleCreator, permissionTags.roleOwner],
  excludePermissions: [],
  isFavorite: null,
};

const paramsByTypeFilter = {
  [TYPES_FILTERS.OWNED_BY_ME]: {
    permissionTagIds: [permissionTags.roleSuperuser, permissionTags.roleCreator, permissionTags.roleOwner],
    excludePermissions: [],
    isFavorite: null,
  },
  [TYPES_FILTERS.NOT_OWNED_BY_ME]: {
    permissionTagIds: [permissionTags.roleViewer, permissionTags.roleCommenter, permissionTags.roleDeleter, permissionTags.roleEditor],
    excludePermissions: [permissionTags.roleSuperuser, permissionTags.roleCreator, permissionTags.roleOwner],
    isFavorite: null,
  },
  [TYPES_FILTERS.MY_FAVORITES]: {
    ...defaultParams,
    isFavorite: true,
  },
};

const getDashboardDetailsQueryParams = (ownershipFilter) => (
  paramsByTypeFilter[ownershipFilter] || defaultParams
);

export default getDashboardDetailsQueryParams;
