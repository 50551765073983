import { Box } from '@mui/material';

import Button from '../Button';
import LocationSelector from './LocationSelector';
import styles from './MapDrawShape.module.scss';
import RadiusSelector from './RadiusSelector';

const filterLocationResults = (results) => results.filter(
  (r) => !!r?.geometry && r.geometry.type !== 'LineString',
);

// TODO: Rename it to SearchControl
const MapControls = ({
  location,
  color,
  size,
  hideRadius,
  placeholder,
  onLocationSelect,
  onRadiusChange,
  onReset,
}) => {
  const isPoint = location.geoJson?.type === 'Point';

  return (
    <Box className={styles.mapControls} height={size === 'small' ? '40px' : '64px'}>
      <LocationSelector
        location={location}
        className={styles.locationSelector}
        color={color}
        size={size}
        placeholder={placeholder}
        filterResults={filterLocationResults}
        onSelected={onLocationSelect}
      />
      {!hideRadius && isPoint && <RadiusSelector radius={location.radius} onChange={onRadiusChange} />}
      {!!onReset && !!location.geoJson && (
        <Button
          color="secondary"
          variant="text"
          className={styles.resetButton}
          onClick={onReset}
        >
          Clear
        </Button>
      )}
    </Box>
  );
};

export default MapControls;
