import clsx from 'clsx';
import { Plus } from 'react-feather';

import styles from './PanelParamsModifiers.module.scss';

const ModifierItem = ({ label, color, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={clsx({
        [styles.modifierItem]: true,
        [styles.orange]: color === 'orange',
        [styles.purple]: color === 'purple',
        [styles.blue]: color === 'blue',
      })}
      role="button"
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.keycode === 13) onClick();
      }}
    >
      <Plus size={13} />
      <span>{label}</span>
    </div>
  );
};

export default ModifierItem;
