import InfoIcon from '@mui/icons-material/Info';
import {
  Button, Dialog, DialogActions, DialogContent, ListItemIcon, ListItemText, MenuItem,
  Tab,
  Tabs,
} from '@mui/material';
import { bindDialog, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useState } from 'react';

import AboutTab from './AboutTab';
import HistoryLog from './HistoryLog';

const MenuItemAbout = ({ dashboard }) => {
  const popupState = usePopupState({ variant: 'dialog', popupId: 'dashboardAbout' });
  const [tabValue, setTabValue] = useState('about');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <MenuItem {...bindTrigger(popupState)}>
        <ListItemIcon><InfoIcon /></ListItemIcon>
        <ListItemText>About</ListItemText>
      </MenuItem>
      <Dialog {...bindDialog(popupState)} fullWidth maxWidth="sm">
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="About" value="about" />
          <Tab label="History Log" value="history" />
        </Tabs>
        <DialogContent>
          {tabValue === 'about' && <AboutTab dashboard={dashboard} />}
          {tabValue === 'history' && <HistoryLog history={dashboard.history} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={popupState.close}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MenuItemAbout;
