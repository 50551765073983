import { capitalize, Card, Typography } from '@mui/material';
import dayjs from 'dayjs';

import Identity from '@/components/Identity';
import Table from '@/components/Table';
import { logOperationLabels } from '@/constants/logOperations';

import DashboardUserChipAvatar from '../DashboardChipAvatar/DashboardUserChipAvatar';

const columns = [
  {
    accessorKey: 'identityId',
    header: 'User',
    Header: 'User',
    Cell: ({ row }) => (
      <Identity identityId={row.original.identityId}>
        {({ identity }) => (
          <DashboardUserChipAvatar
            userId={identity?.identityId}
            label={identity?.fullName}
            email={identity?.loginEmailAddress}
            isShowLabel
          />
        )}
      </Identity>
    ),
  },
  {
    accessorFn: (row) => dayjs(row.effectiveRange.match(/"(.*?)"/g)[0]).format('M/D/YYYY h:mm A'),
    header: 'Date',
    Header: 'Date',
  },
  {
    accessorFn: (row) => capitalize(logOperationLabels[row.operationTagId]),
    header: 'Operation',
    Header: 'Operation',
  },
];

const HistoryLog = ({ history }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>History Log</Typography>
      <Card>
        <Table
          columns={columns}
          data={history}
        />
      </Card>
    </>
  );
};

export default HistoryLog;
