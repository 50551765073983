import { getNewTabPositionForMove, rebalancePositions, shouldRebalance } from './dashboardTabsUtils';

async function updateDashboardPositionsAfterDrag({
  dashboards,
  oldIndex,
  newIndex,
  identityId,
  updateDashboardPosition,
}) {
  const updatedDashboards = [...dashboards];
  const [movedTab] = updatedDashboards.splice(oldIndex, 1);
  updatedDashboards.splice(newIndex, 0, movedTab);

  const positions = updatedDashboards.map(
    (tab) => tab.dashboard.tabPosition,
  );

  const prevPosition = newIndex > 0 ? positions[newIndex - 1] : null;
  const nextPosition = newIndex < positions.length - 1 ? positions[newIndex + 1] : null;

  const newTabPosition = getNewTabPositionForMove(prevPosition, nextPosition);

  if (shouldRebalance(positions)) {
    const newPositions = rebalancePositions(positions.length);

    await Promise.all(
      updatedDashboards.map((tab, index) => updateDashboardPosition({
        dashboardId: tab.dashboard.dashboardId,
        identityId,
        tabPosition: newPositions[index],
      })),
    );
  } else {
    await updateDashboardPosition({
      dashboardId: movedTab.dashboard.dashboardId,
      identityId,
      tabPosition: newTabPosition,
    });
  }
}

export default updateDashboardPositionsAfterDrag;
