import { useEffect, useRef } from 'react';

interface BatchedRequestsProps<T> {
  callback: (args: string[]) => Promise<T>;
  onDone: (result: T) => void;
}

interface BatchedRequestsReturn {
  queueArg: (arg: string | undefined | null) => void;
}

const useBatchedRequests = <T, >({
  callback,
  onDone,
}: BatchedRequestsProps<T>): BatchedRequestsReturn => {
  const argsBitmapRef = useRef<Record<string, number>>({});
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const call = () => {
    const args = Object.keys(argsBitmapRef.current);
    argsBitmapRef.current = {};

    if (args.length) {
      callback(args).then(onDone);
    }
  };

  const queueArg = (arg: string | undefined | null): void => {
    if (arg !== undefined && arg !== null) {
      const prevArgsBitmap = argsBitmapRef.current;

      argsBitmapRef.current = {
        ...prevArgsBitmap,
        [arg]: 1,
      };

      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(call, 200);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return {
    queueArg,
  };
};

export default useBatchedRequests;
