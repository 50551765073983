export const TEMPLATE_STATUSES = {
  INTERNAL: 'Internal',
  EXPERIMENTAL: 'Experimental',
  PUBLISHED: 'Published',
};

export const colorByTemplateStatus = {
  [TEMPLATE_STATUSES.INTERNAL]: '#ff0000',
  [TEMPLATE_STATUSES.PUBLISHED]: '#00cc33',
  [TEMPLATE_STATUSES.EXPERIMENTAL]: '#ff9900',
};

export const statuses = {
  realestate: TEMPLATE_STATUSES.EXPERIMENTAL,
  brainstorm: TEMPLATE_STATUSES.PUBLISHED,
  custom: TEMPLATE_STATUSES.INTERNAL,
  spokesperson: TEMPLATE_STATUSES.PUBLISHED,
  personas: TEMPLATE_STATUSES.PUBLISHED,
};
