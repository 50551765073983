import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
id: string;
className: string
classNameWithDragging: string
}

const Draggable = ({
  children,
  id,
  className,
  classNameWithDragging,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
  };

  const appliedClassName = clsx(
    className,
    isDragging && classNameWithDragging,
  );

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={appliedClassName}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
};

export default Draggable;
