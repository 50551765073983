/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
export const ellipsisText = (text: string, maxLength = 100) => (typeof text === 'string' && text.length > maxLength + 1
  ? `${text.substr(0, maxLength)}...`
  : text);

export const getHash = (input: any) => {
  let hash = 0;
  const len = input.length;
  for (let i = 0; i < len; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i);
    hash |= 0; // to 32bit integer
  }
  return hash;
};
