import dayjs from 'dayjs';

export const formatRangeDate = (historyItem) => {
  if (!historyItem || !historyItem) return '';

  const range = historyItem.split(',');

  const cleanDates = range.map((date) => date.replace(/[()[\]]/g, '').trim());

  const date = cleanDates[1] === 'infinity' ? cleanDates[0] : cleanDates[cleanDates.length - 1];

  return dayjs(date).isValid() ? dayjs(date).format('MMM D, YYYY') : '';
};

export const getFilteredDashboards = (dashboards, valueFilter) => {
  return Array.isArray(dashboards)
    ? dashboards.filter((dashboard) => dashboard.title?.toLowerCase().includes(valueFilter?.toLowerCase()))
    : [];
};

export const removeDuplicates = (array, key) => {
  return array.reduce((acc, current) => {
    const x = acc.find((item) => item[key] === current[key]);
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []);
};

export const getDashboardSharedWithText = (otherIdentities, existingIdentity) => {
  const otherPersonShared = [];
  const otherOrganizationShared = [];

  const identitiesById = existingIdentity?.byId || {};
  otherIdentities.forEach((el) => {
    const currentIdentity = identitiesById[el.identityId];
    if (currentIdentity) {
      if (currentIdentity.typeTagId === 'identity.type.company') {
        otherOrganizationShared.push(currentIdentity);
      } else {
        otherPersonShared.push(currentIdentity);
      }
    }
  });

  let response = 'Shared with You';
  const personCount = otherPersonShared.length;
  const orgCount = otherOrganizationShared.length;

  const getFirstName = (arr) => arr?.[0]?.fullName?.split(' ')[0]?.replace(',', '') || '';

  if (personCount === 0 && orgCount === 0) {
    return response;
  } if (personCount >= 2 && orgCount >= 2) {
    return `Shared with You, ${personCount} others and ${orgCount} orgs`;
  } if (orgCount === 0 && personCount > 0) {
    return personCount === 1
      ? `Shared with You and ${getFirstName(otherPersonShared)}`
      : `Shared with You, ${getFirstName(otherPersonShared)} and ${personCount - 1} others`;
  } if (personCount === 0 && orgCount > 0) {
    return orgCount === 1
      ? `Shared with You and ${getFirstName(otherOrganizationShared)}`
      : `Shared with You, ${getFirstName(otherOrganizationShared)} and ${orgCount - 1} others`;
  }
  response = 'Shared with You';
  let total = 0;
  if (personCount > 0) {
    response += `, ${getFirstName(otherPersonShared)}`;
    total += personCount - 1;
  }
  if (orgCount > 0) {
    response += `, ${getFirstName(otherOrganizationShared)}`;
    total += orgCount - 1;
  }
  response += (total > 0 ? ` and ${total} others` : '');

  if (personCount === 1 && orgCount === 1) {
    return `Shared with You, ${getFirstName(otherPersonShared)} and ${getFirstName(otherOrganizationShared)}`;
  }

  return response;
};
