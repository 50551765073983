import { Box } from '@mui/material';
import circle from '@turf/circle';
import { Source } from '@urbica/react-map-gl';
import Draw from '@urbica/react-map-gl-draw';
import React, { useMemo } from 'react';

import useStateWithDebounce from '@/hooks/useStateWithDebounce';
import { featureToCollection, geoJsonToWkt } from '@/utils/geoutils';

import LoadingForScreenshot from '../LoadingForScreenshot';
import Map from '../Map';
import formatLocation from '../MapDrawShape/helpers/formatLocation.js';
import MapControls from '../MapDrawShape/MapControls';
import DrawControl from './DrawControl';
import HeatmapControls from './HeatmapControls';
import HeatmapLayer from './HeatmapLayer';
import styles from './MapHeatmap.module.scss';

// Define styles as an array of objects
const drawStyles = [
  {
    id: 'polygon-fill',
    type: 'fill',
    paint: {
      'fill-color': '#ADD8E6', // Red fill color
      'fill-opacity': 0.3, // 50% opacity
    },
  },
  {
    id: 'polygon-border',
    type: 'line',
    paint: {
      'line-color': 'transparent', // No border
      'line-width': 0,
    },
  },
];

const heatmapToGeojson = (heatmapData) => ({
  type: 'FeatureCollection',
  features: Array.isArray(heatmapData) ? heatmapData.map(({ lat, lon, query }) => ({
    type: 'Feature',
    properties: {
      ...query,
      affinity: query.affinity * 0.5,
      affinity_rank: query?.affinity_rank,
    },
    geometry: {
      type: 'Point',
      coordinates: [lon, lat, 1],
    },
  })) : [],
});

const MapHeatmap = ({
  data,
  location,
  controls,
  isFetching,
  defaultZoom = 6,
  enableCollapsibleControls,
  enableSearch,
  onControlsChange,
  onLocationChange,
  onReset,
  isViewingPublicly = false,
  panelId,
}) => {
  const geoJson = useMemo(() => heatmapToGeojson(data), [data]);
  const baseControls = useMemo(() => ({
    heatmapType: controls?.heatmapType || 'affinity',
    heatmapRange: controls?.heatmapRange && !Number.isNaN(controls?.heatmapRange) ? controls.heatmapRange : 19,
    heatmapOpacity: controls?.heatmapOpacity && !Number.isNaN(controls?.heatmapOpacity) ? controls.heatmapOpacity : 0.6,
  }), [controls]);

  const [panelSettings, setPanelSettings] = useStateWithDebounce({
    baseValue: baseControls,
    onChange: onControlsChange,
  });

  const drawData = useMemo(() => {
    if (!location) return null;

    let nextFeature = location.geoJson;

    if (location.geoJson?.type === 'Point') {
      // For points, create a circle (but keep the point)
      const circleFeature = circle(location.geoJson.coordinates, location.radius, { units: 'meters' });
      nextFeature = circleFeature.geometry;
    }

    return featureToCollection(nextFeature);
  }, [location]);

  const handleHeatmapControlsChange = (key) => (value) => {
    setPanelSettings({
      ...panelSettings,
      [key]: value,
    });
  };

  const handleLocationSelect = (selectedLocation) => {
    const nextLocation = formatLocation(selectedLocation);
    nextLocation.value = geoJsonToWkt(nextLocation.value);

    onLocationChange(nextLocation);
  };

  const handleRadiusChange = (nextRadius) => {
    onLocationChange({
      radius: nextRadius,
      value: location.value,
      label: location.label,
    });
  };

  const isInitialFetching = isFetching && (!data || !data.length);
  const hasHeatmapData = !!geoJson.features.length;

  return (
    <Box
      className={styles.container}
      sx={{
        '.mapboxgl-ctrl-top-right': {
          paddingTop: enableCollapsibleControls && hasHeatmapData ? '40px' : 0,
        },
        '.mapboxgl-ctrl-logo': {
          display: enableSearch ? 'none' : 'block',
        },
      }}
    >
      <LoadingForScreenshot isLoading={isFetching} />
      {!isInitialFetching && (
        <Map
          followNewFeatures
          features={hasHeatmapData ? geoJson : drawData}
          defaultZoom={defaultZoom}
          isFetching={isFetching}
          onLocationChange={onLocationChange}
          isViewingPublicly={isViewingPublicly}
        >
          <Source id="heatmapSource" type="geojson" data={geoJson} />
          <HeatmapLayer panelSettings={panelSettings} />
          {hasHeatmapData && !isViewingPublicly && (
            <HeatmapControls
              panelSettings={panelSettings}
              onControlsChange={handleHeatmapControlsChange}
              isCollapsible={enableCollapsibleControls}
              panelId={panelId}
            />
          )}

          {/* Needs location with geojson */}
          {enableSearch && !isViewingPublicly && (
            <Box
              position="absolute"
              bottom={0}
              left={0}
              width="70%"
              borderRadius={({ shape }) => `0 ${shape.borderRadius}px 0 0`}
              overflow="hidden"
              display="flex"
              bgcolor="background.paper"
              borderTop="1px solid #f2f2f2"
              borderRight="1px solid #f2f2f2"
            >
              <MapControls
                location={location}
                size="small"
                hideRadius
                placeholder="World"
                onLocationSelect={handleLocationSelect}
                onRadiusChange={handleRadiusChange}
              />
              <DrawControl
                location={location}
                onChange={onLocationChange}
                onReset={onReset}
              />
            </Box>
          )}

          {!hasHeatmapData && !isViewingPublicly && (
            <Draw
              data={drawData}
              lineStringControl={false}
              pointControl={false}
              polygonControl={false}
              trashControl={false}
              combineFeaturesControl={false}
              uncombineFeaturesControl={false}
              styles={drawStyles}
            />
          )}
        </Map>
      )}
    </Box>
  );
};

export default MapHeatmap;
