import QlooAPIURL from '@qloo/qloo-api-url';
import { isGuid } from '@qloogle/data-utils';

// This regex is too fancy for Safari
// export const joinUrls = (...parts) => parts.join('/').replace(/(?<!:)\/{2,}/g, '/');
export const joinUrls = (...parts) => parts.join('/').replace(/(:?)\/{2,}/g, (a, b) => (b === ':' ? '://' : '/'));

// export const lambdaAPIPath = (...paths) => joinUrls('/api', ...paths);

export const queryParams = (obj) => `?${new URLSearchParams(Object.keys(obj).sort()
  .filter((e) => obj[e] != null)
  .reduce((o, e) => {
    // eslint-disable-next-line no-param-reassign
    o[e] = obj[e];
    return o;
  }, {})).toString()}`;

// TODO there should be a convenience method in the lib itself, not here
export const APIURLFromParams = (path, params) => {
  const qurl = new QlooAPIURL(path);
  Object.entries(params).map(([k, v]) => !!v && qurl.searchParams.set(k, v));
  return qurl;
};

export const entityUrl = ({ entity_ids }) => `/entities${queryParams({ entity_ids })}`;

export const searchUrl = ({ query, ...params }) => {
  if (isGuid(query)) {
    return entityUrl({ entity_ids: query });
  }

  return `/search${queryParams({ query, ...params })}`;
};

export const recsUrl = (props) => `/recommendations${queryParams(props)}`;

export const isPseudoPath = (value) => value?.substr(0, 8) === '/pseudo/';
export const unPseudoPath = (value) => (isPseudoPath(value) ? value.substr(7) : value);

// TODO this is a bit dumb, it should just modify everything after entity_id in the url string.
export const imageUrl = ({
  entity, width, height = undefined, strategy,
}) => {
  const iUrl = entity?.properties?.image?.url || entity?.properties?.images?.[0]?.url;
  return (new URL(`/i/${entity.entity_id}-${width}x${height}-${strategy}.jpg`, iUrl || 'https://images.qloo.com')).toString();
};
