import OpacityIcon from '@mui/icons-material/Opacity';
import { Tooltip } from '@mui/material';

import Slider from '../Slider';
import styles from './MapHeatmap.module.scss';

const HeatmapOpacitySlider = ({ value, onChange }) => (
  <Tooltip title="Opacity">
    <div className={styles.sliderContainer}>
      <OpacityIcon fontSize="small" />
      <Slider
        min={0}
        max={1}
        value={value}
        onChange={onChange}
        step={0.01}
        withInput
        size="small"
        debounceDelay={0}
        inputProps={{
          variant: 'standard',
          disableUnderline: true,
          sx: { width: '25px' },
          className: styles.heatmapInput,
        }}
      />
    </div>
  </Tooltip>
);

export default HeatmapOpacitySlider;
