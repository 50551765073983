import { alpha, styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import TooltipInfo from '@/components/Tooltip/TooltipInfo';

import { usePagePerms } from '../../lib/usePermissions';

const StyledTabs = styled(Tabs)(() => ({
  alignSelf: 'stretch',
  '& .MuiTabs-flexContainer': {
    height: '100%',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#06f !important',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  fill: '#8c96a0',
  color: '#8c96a0',
  padding: '0 2px',
  margin: '0 12px',
  minHeight: 'unset',
  // GMAIL style tabs
  minWidth: '102px',
  justifyContent: 'center',
  textTransform: 'unset',
  fontWeight: `${theme.typography.fontWeightRegularMedium} !important`,
  '& svg': {
    width: '22px',
    marginRight: '15px !important',
  },
  // END

  '&:hover': {
    background: alpha(theme.palette.text.primary, 0.05),
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 'unset',
  },
  '&.Mui-selected': {
    color: '#06f !important',
    fill: '#06f !important',
    fontWeight: `${theme.typography.fontWeightRegularMedium} !important`,
  },
  '& .nav-label': {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

}));

const NavigationButtons = () => {
  const location = useLocation();
  const baseRoute = location.pathname?.split('/')?.splice(0, 2)?.join('/');
  const { validPages } = usePagePerms();
  const matchWithAlias = validPages.find(
    (tab) => [].concat(tab.to, tab.alias).indexOf(baseRoute) > -1,
  )?.to;

  return (
    <StyledTabs value={!!matchWithAlias && matchWithAlias}>
      {validPages
        .filter((tab) => tab.label)
        .map((tab, ii) => (
          <StyledTab
            component={Link}
            // eslint-disable-next-line react/no-array-index-key
            key={ii}
            to={tab.to}
            value={tab.to}
            label={(
              <TooltipInfo title={tab.tooltip}>
                <span className="nav-label">{tab.label}</span>
              </TooltipInfo>
            )}
          />
        ))}
    </StyledTabs>
  );
};

export default NavigationButtons;
