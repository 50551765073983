import clsx from 'clsx';
import React from 'react';

import { useAppSelector } from '@/store';

import styles from './OwnershipOverlay.module.scss';

const OwnershipOverlay = ({ children, tag }) => {
  const appState = useAppSelector((state) => state.app);

  const { user } = appState;
  const ownerUserId = tag ? appState.ownership[tag] : null;
  const owner = ownerUserId ? appState.presenceUsers[ownerUserId] : null;

  const isLocked = owner && owner.onlineStatus === 'online' && ownerUserId !== user.userId
    ? Boolean(ownerUserId)
    : false;

  const name = owner ? owner.name || owner.email : 'Someone';

  return (
    <div className={styles.container}>
      {children}
      <div
        className={clsx({
          [styles.overlay]: true,
          [styles.overlayLocked]: isLocked,
        })}
      >
        <div className={styles.message}>
          {isLocked ? `${name} is editing` : null}
        </div>
      </div>
    </div>
  );
};

export default OwnershipOverlay;
