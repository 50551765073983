import { Box, Card } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

import useDebounce from '@/hooks/useDebounce';
import { useGetDashboardDetailsQuery, useGetDashboardSearchQuery } from '@/store/apis/dashboardsApi';

import DashboardTable from './DashboardTable/DashboardTable';
import getDashboardDetailsQueryParams from './helpers/getDashboardDetailsQueryParams';
import { TYPES_FILTERS } from './HomePageConsts';
import TableSectionSkeleton from './Skeleton/TableSectionSkeleton';
import SortButton from './SortButton';

const HomePageTableSection = ({ search }) => {
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();
  const [ownershipFilter, setOwnershipFilter] = useState(TYPES_FILTERS.OWNED_BY_ANYONE);
  const debouncedSearch = useDebounce(search, 500);
  const [paginationTable, setPaginationTable] = useState({ pageIndex: 0, pageSize: 10 });

  const {
    data: searchResults,
    isLoading: isSearchLoading,
    isFetching: isSearchFetching,
    error: searchError,
  } = useGetDashboardSearchQuery({
    query: debouncedSearch,
    offset: paginationTable.pageIndex,
    limit: paginationTable.pageSize,
  }, {
    skip: !debouncedSearch,
  });

  const { permissionTagIds, excludePermissions, isFavorite } = useMemo(
    () => getDashboardDetailsQueryParams(ownershipFilter),
    [ownershipFilter],
  );

  const {
    data: dashboardsDetails,
    isLoading: isDetailsLoading,
    isFetching: isDetailsFetching,
    error: dashboardDetailsError,
  } = useGetDashboardDetailsQuery({
    offset: paginationTable.pageIndex,
    limit: paginationTable.pageSize,
    permissionTagIds,
    excludePermissions,
    isFavorite,
  }, {
    skip: !!search,
  });

  const tableData = useMemo(() => {
    if (search && search.length) {
      return searchResults?.data || [];
    }

    return dashboardsDetails?.data || [];
  }, [dashboardsDetails, search, searchResults]);

  const handleRedirect = (id) => {
    navigate(`/dashboards/view${id ? `/${id}` : ''}/`);
  };

  // Reset pagination when search is changed
  useEffect(() => {
    setPaginationTable({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [search]);

  useEffect(() => {
    if (searchError) {
      showBoundary(searchError);
    }
  }, [searchError, showBoundary]);

  useEffect(() => {
    if (dashboardDetailsError) {
      showBoundary(dashboardDetailsError);
    }
  }, [dashboardDetailsError, showBoundary]);

  if (isSearchLoading || isDetailsLoading) {
    return <TableSectionSkeleton />;
  }

  return (
    <Box bgcolor="background.paper" px={5} py={3} width="100%">
      <Box display="flex" justifyContent="right">
        <SortButton selectedOption={ownershipFilter} onSelect={setOwnershipFilter} />
      </Box>
      <Card>
        <Box overflow="auto">
          <DashboardTable
            data={tableData}
            onRedirect={handleRedirect}
            paginationTable={paginationTable}
            onPageChange={setPaginationTable}
            isLoadingPagination={search ? isSearchFetching : isDetailsFetching}
            pageInfo={search ? searchResults?.pageInfo.pageInfo : dashboardsDetails?.pageInfo.pageInfo}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default HomePageTableSection;
