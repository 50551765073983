import { createSlice } from '@reduxjs/toolkit';

export const identitiesSlice = createSlice({
  name: 'identities',
  initialState: {
    byId: {},
  },
  reducers: {
    mergeIdentities: (state, action) => {
      const identities = action.payload;
      const nextById = { ...state.byId };
      identities.forEach((identity) => {
        nextById[identity.identityId] = { ...(state.byId[identity.identityId] || {}), ...identity };
      });

      return {
        ...state,
        byId: nextById,
      };
    },
  },
});

export const {
  mergeIdentities,
} = identitiesSlice.actions;
