import { List, Typography } from '@mui/material';
import { useMemo } from 'react';

import ShareWithListItem from './ShareWithListItem';

const ShareWithList = ({
  title, identities, dashboardId, viewOnly, onIdentityCheck,
}) => {
  const formattedIdentities = useMemo(() => {
    return identities.filter((i) => i.identityId !== import.meta.env.VITE_PUBLIC_DASHBOARD_IDENTITY)
      .sort((a, b) => a.fullName.localeCompare(b.fullName));
  }, [identities]);

  return (
    <div>
      {!!title && <Typography variant="h6">{title}</Typography>}
      <List>
        {formattedIdentities.map((identity) => (
          <ShareWithListItem
            key={identity.identityId}
            identity={identity}
            dashboardId={dashboardId}
            viewOnly={viewOnly}
            onCheck={onIdentityCheck}
          />
        ))}
      </List>
    </div>
  );
};

export default ShareWithList;
