import panelTypes from '@/constants/panelTypes';
import { urnsToDisplayName } from '@qloo/categories';

interface Args {
  panelType: keyof typeof panelTypes;
  settings: Record<string, unknown>;
}

function getPanelHeaderTitle({ panelType, settings }: Args) {
  switch (panelType) {
    case panelTypes.explore: {
      if (settings.initialSelectedCategory) {
        return urnsToDisplayName(settings.initialSelectedCategory);
      }

      return 'Summary overview';
    }
    case panelTypes.heatmap:
      return 'Heatmap';
    case panelTypes.locationExplorer:
      return 'Location Explorer';
    case panelTypes.freeformText:
      return 'Text';
    case panelTypes.table:
    default:
      return 'Correlation Table';
  }
}

export default getPanelHeaderTitle;
