import React from 'react';

import Button from '@/components/Button';

import styles from './PanelBodyFreeformTextEditorButton.module.scss';

const PanelBodyFreeformTextEditorButton = ({
  onClick,
  active,
  Icon,
}) => {
  const classesNames = [styles.button];
  if (active) {
    classesNames.push(styles.activeButton);
  }

  return <Button onClick={onClick} className={classesNames.join(' ')}><Icon /></Button>;
};

export default PanelBodyFreeformTextEditorButton;
