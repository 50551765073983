import React, { useEffect, useMemo } from 'react';

import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import { filterTypes, paramNames } from '@/constants';
import tagTypesBySelectedCategory from '@/constants/tagTypesBySelectedCategory';
import useActiveDashboard from '@/hooks/useActiveDashboard';
import useGPTExplainRecs from '@/hooks/useGPTExplainRecs';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import serializeInsightsParams from '@/utils/serializeInsightsParams';
import tooltips from '@/utils/tooltips';

import validateQueryError from '../helpers/validateQueryError';
import CategoryData from './CategoryData/CategoryData';
import CategoryDataSkeleton from './CategoryData/CategoryDataSkeleton';
import ResumedCategoryData from './ResumedCategory/ResumedCategoryData';
import useTagsFetcher from './useTagsFetcher';

const CategoryDataView = ({
  category,
  panelParams,
  onSelect,
  conciseView,
  isEmptyBaseQuery = false,
  updateCategoryData = () => {},
}) => {
  const { baseParams } = useActiveDashboard();

  const serializedTagsInsightsParams = useMemo(() => {
    const tempParams = {
      ...panelParams,
      [paramNames.filterType]: filterTypes.tag,
      [paramNames.filterParentTypes]: category,
      take: 4,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [category, baseParams]);

  const {
    data: tagsResult,
    isLoading: isLoadingTags,
    error: tagsError,
  } = useGetInsightQuery(serializedTagsInsightsParams);

  const serializedInsightsParams = useMemo(() => {
    const tempParams = {
      ...panelParams,
      [paramNames.filterType]: category,
      [paramNames.take]: 10,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, category]);

  const {
    data: entitiesResult,
    isLoading: isLoadingEntities,
    error: insightsError,
  } = useGetInsightQuery(serializedInsightsParams);

  const tagsFetcher = useTagsFetcher();
  const tagTypes = useMemo(() => tagTypesBySelectedCategory[category] || [], [category]);
  const tags = tagsResult?.results?.tags || [];
  const entities = entitiesResult?.results?.entities || [];
  const {
    result: gptResult,
    isLoading: isLoadingGPT,
    error: gptError,
  } = useGPTExplainRecs(
    {
      category, tags, entities, showMini: conciseView,
    },
    {
      skip: isLoadingEntities || isLoadingTags,
    },
  );

  const errorData = useMemo(() => {
    const errors = [insightsError, tagsError, gptError];
    return validateQueryError({ errors });
  }, [insightsError, tagsError, gptError]);

  useEffect(() => {
    if (!isLoadingEntities && !isLoadingTags && !isLoadingGPT) {
      if (gptResult || gptError) {
        updateCategoryData(category, gptResult || '', gptError);
      }
    }
  }, [category, isLoadingEntities, isLoadingTags, isLoadingGPT, gptResult, gptError, updateCategoryData]);

  useEffect(() => {
    if (tagTypes.length) {
      tagsFetcher.fetchByTagTypes({
        baseParams,
        panelParams: {
          ...panelParams,
          [paramNames.offset]: 0,
          [paramNames.take]: 9,
        },
        selectedCategory: category,
        tagTypes,
      });
    }
  }, [baseParams, panelParams, category, tagTypes]);

  if (isLoadingEntities || isLoadingGPT || isLoadingTags) {
    return <CategoryDataSkeleton conciseView={conciseView} />;
  }

  const categoryColor = `var(--category-${formatCategoryUrnToCssVar(category)})`;
  const isEmpty = !gptResult && !tagsResult?.results?.tags?.length && !entitiesResult?.results?.entities?.length;

  if (isEmpty) {
    return null;
  }

  return (
    <>
      {/* removed for now. Until data is correctly returned from the backend */}
      {/*
        {HIGHLIGHT_DATA.some((data) => data.category === category)
          && getTagsForCategory(category).map((tag, index) => {
            return !conciseView ? (
              <CategoryHighlightedData
                baseParams={baseParams}
                category={category}
                onSelect={onSelect}
                tag={tag}
                conciseView={conciseView}
                key={index}
                tagTypes={tagTypes}
                tagsFetcher={tagsFetcher}
              />
            ) : (
              <ResumedCategoryHighlightedData
                baseParams={baseParams}
                category={category}
                onSelect={onSelect}
                tag={tag}
                conciseView={conciseView}
                key={index}
                categoryColor={categoryColor}
                tagTypes={tagTypes}
                tagsFetcher={tagsFetcher}
              />
            );
          })}
        */}

      <TooltipInfo title={tooltips.summaryDetail} followCursor>
        <div>
          {conciseView ? (
            <ResumedCategoryData
              category={category}
              onSelect={onSelect}
              tagsResult={tagsResult}
              entitiesResult={entitiesResult}
              categoryColor={categoryColor}
              error={errorData}
              gptResult={gptResult}
              tagTypes={tagTypes}
              tagsFetcher={tagsFetcher}
            />
          ) : (
            <CategoryData
              category={category}
              onSelect={onSelect}
              tagsResult={tagsResult}
              entitiesResult={entitiesResult}
              gptResult={gptResult}
              error={errorData}
              isEmptyBaseQuery={isEmptyBaseQuery}
              tagTypes={tagTypes}
              tagsFetcher={tagsFetcher}
            />
          )}
        </div>
      </TooltipInfo>
    </>
  );
};

export default CategoryDataView;
