const typeTagIds = {
  company: 'identity.type.company',
  companyUnit: 'identity.type.company.unit',
  person: 'identity.type.person',
  team: 'identity.type.team',
  test: 'identity.type.test',
};

export const typeTagLabels = {
  [typeTagIds.company]: 'Organization',
  [typeTagIds.companyUnit]: 'Organization Unit',
  [typeTagIds.person]: 'Person',
  [typeTagIds.team]: 'Team',
  [typeTagIds.test]: 'Test',
};

export default typeTagIds;
