import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import Alert from '@mui/material/Alert';
import circle from '@turf/circle';
import Draw from '@urbica/react-map-gl-draw';
import React, { useMemo } from 'react';

import { DEFAULT_RADIUS } from '@/constants';
import { featureToCollection } from '@/utils/geoutils';

import Map from '../Map';
import MapToWktHOC from '../MapToWktHOC';
import formatLocation from './helpers/formatLocation';
import MapControls from './MapControls';

const MapDrawShape = ({
  onChange = () => {},
  children,
  location,
  disableControls = false,
  color,
  onReset,
  ...props
}) => {
  const data = useMemo(() => {
    let nextFeature = location.geoJson;

    if (location.geoJson?.type === 'Point') {
      // For points, create a circle (but keep the point)
      const circleFeature = circle(location.geoJson.coordinates, location.radius, { units: 'meters' });
      nextFeature = circleFeature.geometry;
    }

    return featureToCollection(nextFeature);
  }, [location]);

  const handleLocationSelect = (selectedLocation) => {
    const nextLocation = formatLocation(selectedLocation);

    onChange(nextLocation);
  };

  const handleRadiusChange = (nextRadius) => {
    onChange({
      radius: nextRadius,
      value: location.geoJson,
      label: location.label,
    });
  };

  const handleDrawChange = (nextCollection) => {
    const nextLocation = {};

    // Get last change
    const lastFeature = nextCollection?.features.at(-1);
    const { geometry } = lastFeature;

    if (geometry.type === 'Point') {
      nextLocation.radius = DEFAULT_RADIUS;
    }

    nextLocation.value = geometry;

    onChange(nextLocation);
  };

  return (
    <>
      {!disableControls && (
        <MapControls
          location={location}
          color={color}
          onLocationSelect={handleLocationSelect}
          onRadiusChange={handleRadiusChange}
          onReset={onReset}
        />
      )}
      <Map {...props} followNewFeatures features={data}>
        {!disableControls && data.features?.length > 1 && (
          <Alert severity="error">
            Only one point/shape allowed. Ignoring others.
          </Alert>
        )}
        {children}
        <Draw
          data={data}
          lineStringControl={false}
          pointControl={!disableControls}
          polygonControl={!disableControls}
          trashControl={false}
          combineFeaturesControl={false}
          uncombineFeaturesControl={false}
          onChange={handleDrawChange}
        />
      </Map>
    </>
  );
};

export default MapToWktHOC(MapDrawShape);
