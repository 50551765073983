import { urnToCategoryData } from '@qloo/categories';

import { filterTypes, paramNames } from '@/constants';
import slugify from '@/utils/slugify';

const getPanelName = (panel) => {
  const filterType = panel?.params[paramNames.filterType];

  if (filterType === filterTypes.heatmap) {
    const heatmapType = panel?.settings?.heatmapType || 'affinity';

    return `heatmap-${heatmapType}`;
  }

  return urnToCategoryData(filterType)?.name || null;
};

const getDefaultFileName = (dashboard, panelId) => {
  const dashboardTitle = dashboard?.title;
  const panelName = getPanelName(dashboard?.panels[panelId]);

  if (!panelName) {
    // TODO: Category is required. If not, you should not be able to export
    console.error('Panel category is missing');
    return slugify(`${dashboardTitle}-${panelId}`);
  }

  if (!dashboardTitle) {
    return slugify(`${panelName}-${panelId}`);
  }

  return slugify(`${dashboardTitle}-${panelName}`);
};

export default getDefaultFileName;
