import QlooAPIURL from '@qloo/qloo-api-url';
import { useCallback } from 'react';
import { signOut } from 'supertokens-auth-react/recipe/passwordless';

import defaultFetch, {
  clearCache,
  useFetch as baseUseFetch,
  useFetchDebounced as baseUseFetchDebounced,
} from './fetching';
import { useServerContext } from './RequestContext';
import {
  APIURLFromParams,
  entityUrl, joinUrls, recsUrl, searchUrl,
} from './urls';

const API_ROUTE_PREFIX = '/api/';
const SERVER_LEN = import.meta.env.VITE_QLOO_API_SERVER.length;
const PREFIX_LEN = API_ROUTE_PREFIX.length;
const COMBINED_LEN = SERVER_LEN + PREFIX_LEN;

const fixUrl = (e) => {
  const toReturn = { ...e };

  if (e.url) {
    toReturn.url = e.url
      ?.substr(SERVER_LEN, PREFIX_LEN) === API_ROUTE_PREFIX ? e.url?.substr(COMBINED_LEN) : e.url?.substr(SERVER_LEN);
  }

  return toReturn;
};

// This presumes that everything will be run through the lambda, and will be auth-aware.
// The lambda handles Qloo API requests and custom endpoints that don't ever hit the Qloo API.
export const useAuthFetchFn = ({ expires = 60 } = {}) => {
  const authFetchFn = useCallback((key, uurl, { headers = {}, ...config } = {}) => (
    defaultFetch(key, joinUrls(import.meta.env.VITE_QLOO_API_SERVER, uurl), {
      expires,
      ...config,
      headers: {
        ...headers,
        // Supertokens sets this for us.
        // See https://supertokens.com/docs/session/quick-setup/handling-session-tokens
        // NO LONGER REQUIRED Authorization: `Bearer ${user.accessToken}`,
      },
    }).then((r) => {
      if (r.status === 401) {
        console.error('useAuthFetchFn 010: 401 received so signing out the user and routing them to home page to re-login');

        (async () => {
          await signOut(); // Assuming signOut is an async function
          window.location.href = '/';
        })();
      }

      return fixUrl(r);
    })
  ), [expires]);

  return authFetchFn;
};

// This wraps the above to deal with Qloo API requests.
export const useQlooAPIAuthFetchFn = (...params) => {
  const server = useServerContext();
  const authFetchFn = useAuthFetchFn(...params);
  return useCallback((key, url, config) => {
    const uurl = new QlooAPIURL(url, server);
    const useServer = server?.server || uurl?.server;
    const useKey = key ? `${key}-${useServer}` : `${uurl}`;
    return authFetchFn(useKey, joinUrls(API_ROUTE_PREFIX, uurl), config);
  }, [server, authFetchFn]);
};

const useFetch = (url, {
  useAuthFetchFnDefault = useAuthFetchFn,
  useFetchHook = baseUseFetch,
  ...params
} = {}) => {
  const authFetchFn = useAuthFetchFnDefault(params);
  return useFetchHook(url, { fetchFn: authFetchFn, ...params });
};

export default useFetch;

export const useFetchExpensive = (url, {
  key, useFetchFn = useFetch, expires = 21600, ...config
}) => {
  const clearExpensiveCache = useCallback(() => clearCache(key), [key]);
  const request = useFetchFn(url, { key, expires, ...config });
  return [request, clearExpensiveCache];
};

// Note this should only be used for something like search, or anything likely to change rapidly.
// We get debounce-like behavior in default fetch based on pooling duplicate requests.
export const useFetchDebounce = (url, config) => useFetch(url, { ...config, useFetchHook: baseUseFetchDebounced });

export const useServerContextURL = (url, serverOverride) => {
  const server = useServerContext();
  return new QlooAPIURL(url, serverOverride || server);
};

// This takes the above and prefaces URLs with /api, which is like an auth-aware proxy of our base API.
export const useQlooAPI = (uurl, { key, ...config } = {}) => {
  const url = useServerContextURL(uurl, config.serverOverride);
  const useKey = key || JSON.stringify(url);
  return (
    useFetch(url, {
      key: useKey,
      useAuthFetchFnDefault: useQlooAPIAuthFetchFn,
      ...config,
    })
  );
};

export const useQlooAPIWithParams = (path, params, rest) => (
  useQlooAPI(APIURLFromParams(path, params), { ...rest, useFetchHook: baseUseFetchDebounced })
);

export const useQlooAPISearch = (config, rest) => (
  useQlooAPI(searchUrl(config), { ...rest, useFetchHook: baseUseFetchDebounced })
);

// Dirty dirty business
export const useServerContextURLStaging = (url) => new QlooAPIURL(url, 'https://staging.api.qloo.com');
export const useQlooAPIStaging = (uurl, { key, ...config } = {}) => {
  const url = useServerContextURLStaging(uurl);
  const useKey = key || JSON.stringify(url);
  return (
    useFetch(url, {
      key: useKey,
      useAuthFetchFnDefault: useQlooAPIAuthFetchFn,
      ...config,
    })
  );
};
export const useQlooAPISearchStaging = (config, rest) => (
  useQlooAPIStaging(searchUrl(config), { ...rest, useFetchHook: baseUseFetchDebounced })
);

export const useQlooAPIRecs = (config, rest) => useQlooAPI(recsUrl(config), rest);
export const useQlooAPIDetails = ({ entity_ids }, rest) => useQlooAPI(entityUrl({ entity_ids }), rest);

// TODO: A smarter version of the direct API call.
// TODO: lookup cache in fetcherWithKey for each individual entity
// TODO: maybe piggyback on that cache with another QLoo lookup cache? :(
export const useDetails = ({ entity_ids }, rest) => {
  const { result, ...other } = useQlooAPI(entityUrl({ entity_ids }), rest);
  return {
    ...other,
    result,
    entities: result?.body?.results,
  };
};
