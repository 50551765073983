import { styled, Tooltip as MuiTooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '12px',
    border: '0.5px solid #B4BEC8',
    fontSize: '11px',
    backgroundColor: '#FFF',
    color: '#000',
    borderRadius: '6px',
    '& a': {
      color: '#0066FF',
    },
  },
}));

const TooltipInfo = ({
  children, title, followCursor = false,
}) => {
  const slotProps = followCursor ? {
    popper: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ],
    },
  } : undefined;

  const child = typeof children === 'string' ? <div>{children}</div> : children;

  return (
    <Tooltip
      title={title}
      enterDelay={500}
      enterNextDelay={500}
      followCursor={followCursor}
      slotProps={slotProps}
    >
      {child}
    </Tooltip>
  );
};

export default TooltipInfo;
