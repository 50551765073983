/* eslint-disable max-len */
import dot from 'dot-object';

import personaProperties from './personaProperties';

const mapProperty = (sectionPrefix, persona) => (property) => {
  const value = dot.pick(property.key, persona);
  const formattedValue = property.format ? property.format(value) : value;
  return `${sectionPrefix} ${property.label}\n${formattedValue || property.placeholder}`;
};

const getPersonaResultMarkdown = (personasResult) => (personasResult || []).map((personaResult, i) => {
  const persona = personaResult?.properties || personaResult;
  return `# Persona ${i + 1}\n${personaProperties.map((property) => {
    if (property.items) {
      return `## ${property.label}\n${property.items.map(mapProperty('###', persona)).join('\n')}`;
    }

    return mapProperty('##', persona)(property);
  }).join('\n')}`;
}).join('\n');

export default getPersonaResultMarkdown;
