import TuneIcon from '@mui/icons-material/Tune';
import { Button } from '@mui/material';
import React, { useMemo, useState } from 'react';

import DashboardPermissionControl from '@/components/DashboardPermissionControl';
import FieldsModal from '@/components/FieldsModal';
import Popover from '@/components/Popover';
import { paramNames } from '@/constants';
import muiVeltIds from '@/constants/muiVeltIds';
import permissionControlVariants from '@/constants/permissionControlVariants';
import permissionTags from '@/constants/permissionTags';

import getFilterParamNames from '../helpers/getFilterParamNames';
import FilterTag from './FilterTag';
import styles from './FilterTag.module.scss';
import { getFiltersApplied } from './helpers';

const FilterTagsContainer = ({
  params, onParamsChange, onParamDelete, onReset, panelId,
}) => {
  const [defaultOpenParam, setDefaultOpenParam] = useState(null);

  const filterParamNames = useMemo(() => getFilterParamNames(params[paramNames.filterType]), [params]);
  const filtersApplied = useMemo(() => getFiltersApplied(params, filterParamNames), [filterParamNames, params]);

  const handleTagClick = (paramName, callback) => (e) => {
    setDefaultOpenParam(paramName);
    callback(e);
  };

  const handleReset = (close) => {
    onReset();
    close();
  };

  return (
    <div className={styles.tagsContainer}>
      <Popover
        renderContent={({ close }) => (
          <FieldsModal
            title="Filters"
            params={params}
            paramNames={filterParamNames}
            defaultOpenParam={defaultOpenParam}
            onChange={onParamsChange}
            onReset={() => handleReset(close)}
          />
        )}
        renderCustomTrigger={({ open }) => (
          <DashboardPermissionControl
            permissionsRequired={[permissionTags.roleEditor]}
            variant={permissionControlVariants.tooltip}
          >
            <div className={styles.tagsWrapper}>
              {Object.entries(filtersApplied).map(([paramName, content]) => (
                <FilterTag
                  key={paramName}
                  paramName={paramName}
                  content={content}
                  onDelete={onParamDelete}
                  onClick={handleTagClick(paramName, open)}
                />
              ))}
              <Button
                onClick={open}
                variant="contained"
                size="small"
                shape="rounded"
                color="accent"
                disableElevation
                id={`${panelId}-${muiVeltIds.buttons.filter}`}
              >
                <TuneIcon fontSize="small" />
              </Button>
            </div>
          </DashboardPermissionControl>
        )}
      />
    </div>
  );
};

export default FilterTagsContainer;
