import clsx from 'clsx';
import React from 'react';

import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';

import { barChartTagTypesBitmap } from '../PanelBodyExploreConstants';
import formatTagType from '../formatTagType';
import styles from '../PanelBodyExplore.module.scss';
import CategoryDataTagsNames from './CategoryDataTagsNames';

const defaultTagType = 'Default';

const TagView = ({
  category,
  label,
  result,
  tagType,
}) => {
  return (
    <div
      className={clsx({
        [styles.categoryDataTagsItem]: true,
        [styles.categoryDataTagsItemBarChart]: barChartTagTypesBitmap[tagType],
      })}
      style={{
        '--dynamic-category-color':
                `var(--category-${formatCategoryUrnToCssVar(category)})`,
      }}
    >
      <span className={styles.categoryDataTagsItemLabel}>{label}</span>
      <CategoryDataTagsNames result={result} tagType={tagType} />
    </div>
  );
};

const CategoryDataTags = ({
  category, tagsResult, tagTypes, tagsFetcher,
}) => {
  if (tagTypes?.length) {
    const tagTypesWithDefault = [defaultTagType, ...tagTypes];
    return (
      <div className={styles.categoryDataTags}>
        {tagTypesWithDefault.map((tagType, i) => {
          const label = tagType === defaultTagType ? 'Top' : formatTagType(tagType);
          const result = tagType === defaultTagType
            ? tagsResult?.results?.tags || []
            : tagsFetcher.state[tagType]?.result || [];

          if (!result.length) {
            return null;
          }

          return (
            <TagView category={category} tagType={tagType} label={label} result={result} key={i} />
          );
        })}
      </div>
    );
  }
  if (tagsResult?.results?.tags?.length) {
    return (
      <div className={styles.categoryDataTags}>
        <TagView
          category={category}
          tagType={undefined}
          label="Top"
          result={tagsResult?.results?.tags}
        />
      </div>
    );
  }

  return null;
};

export default CategoryDataTags;
