/* eslint-disable max-len */
const permissionTags = {
  roleViewer: 'role.viewer',
  roleCommenter: 'role.commenter',
  roleClone: 'role.clone',
  roleEditor: 'role.editor',
  roleDeleter: 'role.deleter',
  roleCreator: 'role.creator',
  roleOwner: 'role.owner',
  roleSuperuser: 'role.superuser',
};

export type PermissionTag = typeof permissionTags[keyof typeof permissionTags];

export const permissionTagsHierarchy: PermissionTag[] = [
  permissionTags.roleSuperuser,
  permissionTags.roleOwner,
  permissionTags.roleCreator,
  permissionTags.roleDeleter,
  permissionTags.roleEditor,
  permissionTags.roleClone,
  permissionTags.roleCommenter,
  permissionTags.roleViewer,
];

export const permissionTagLabels: Record<PermissionTag, string> = {
  [permissionTags.roleViewer]: 'Viewer',
  [permissionTags.roleEditor]: 'Editor',
  [permissionTags.roleClone]: 'Clone',
  [permissionTags.roleDeleter]: 'Deleter',
  [permissionTags.roleSuperuser]: 'Admin',
  [permissionTags.roleCommenter]: 'Commenter',
  [permissionTags.roleCreator]: 'Creator',
  [permissionTags.roleOwner]: 'Owner',
};

export const permissionTagDescriptions: Record<PermissionTag, string> = {
  [permissionTags.roleViewer]: 'Can view content but cannot make changes',
  [permissionTags.roleEditor]: 'Can view and edit content',
  [permissionTags.roleClone]: 'Can view and clone content',
  [permissionTags.roleDeleter]: 'Can view, edit, clone, and delete content',
  [permissionTags.roleSuperuser]: 'Can manage all content',
  [permissionTags.roleCommenter]: 'Can view content and add comments',
  [permissionTags.roleCreator]: 'Can view, edit, clone, comment, and create content', // TODO: Check this
  [permissionTags.roleOwner]: 'Can manage all content',
};

export const inheritedPermissionTags: Record<PermissionTag, PermissionTag[]> = {
  [permissionTags.roleViewer]: [permissionTags.roleViewer],
  [permissionTags.roleCommenter]: [permissionTags.roleViewer, permissionTags.roleCommenter],
  [permissionTags.roleClone]: [permissionTags.roleViewer, permissionTags.roleClone],
  [permissionTags.roleEditor]: [permissionTags.roleViewer, permissionTags.roleEditor, permissionTags.roleClone, permissionTags.roleCommenter],
  [permissionTags.roleDeleter]: [permissionTags.roleViewer, permissionTags.roleEditor, permissionTags.roleClone, permissionTags.roleCommenter, permissionTags.roleDeleter],
  [permissionTags.roleCreator]: [permissionTags.roleViewer, permissionTags.roleEditor, permissionTags.roleClone, permissionTags.roleCommenter, permissionTags.roleDeleter, permissionTags.roleCreator],
  [permissionTags.roleOwner]: [permissionTags.roleViewer, permissionTags.roleEditor, permissionTags.roleClone, permissionTags.roleCommenter, permissionTags.roleDeleter, permissionTags.roleCreator, permissionTags.roleOwner],
  [permissionTags.roleSuperuser]: [permissionTags.roleViewer, permissionTags.roleEditor, permissionTags.roleClone, permissionTags.roleCommenter, permissionTags.roleDeleter, permissionTags.roleCreator, permissionTags.roleOwner, permissionTags.roleSuperuser],
};

export default permissionTags;
