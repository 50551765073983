import { PropsWithChildren, useEffect, useState } from 'react';

interface Props extends PropsWithChildren {}

const PublicDashboardSignIn = ({ children }: Props) => {
  const loginUrl = `${import.meta.env.VITE_AUTH_MIDDLE_TIER_URL}/auth/signin`;
  const email = import.meta.env.VITE_PUBLIC_DASHBOARD_EMAIL;
  const password = import.meta.env.VITE_PUBLIC_DASHBOARD_PASSWORD;

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const body = {
      formFields: [
        { id: 'email', value: email },
        { id: 'password', value: password },
      ],
    };

    fetch(loginUrl, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then(() => {
      setIsLoggedIn(true);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn) {
    return children;
  }
  return null;
};

export default PublicDashboardSignIn;
