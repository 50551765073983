import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  IconButton, Link, Menu, MenuItem,
} from '@mui/material';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Download as DownloadIcon } from 'react-feather';

import muiVeltIds from '@/constants/muiVeltIds';
import useScrollBlock from '@/hooks/useScrollBlock';
import tooltips from '@/utils/tooltips';

import TooltipInfo from '../Tooltip/TooltipInfo';
import styles from './CreativesModal.module.scss';
import generatePdfFromPersonaData from './templates/shared/generatePdfFromPersonaData';

interface CreativesModalContextMenuProps {
  creativeResults: Record<string, any>;
}

const CreativesModalContextMenu = ({
  creativeResults,
}: CreativesModalContextMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [blockScroll, allowScroll] = useScrollBlock();

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    blockScroll();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    allowScroll();
    setAnchorEl(null);
  };

  const handleDownloadPdf = () => {
    handleClose();
    generatePdfFromPersonaData(creativeResults.personas);
  };

  useEffect(() => {
    return () => {
      allowScroll();
    };
  }, []);

  return (
    <div>
      <TooltipInfo title={tooltips.dashboardCard.menu}>
        <IconButton
          aria-label="creatives-menu"
          id={muiVeltIds.buttons.creativesMenu}
          onClick={handleOpen}
        >
          <MoreHorizIcon color="secondary" />
        </IconButton>
      </TooltipInfo>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {creativeResults?.personas && (
          <MenuItem
            onClick={handleDownloadPdf}
            className={styles.creativesMenuItem}
          >
            <DownloadIcon size={16} strokeWidth={2.5} />
            <p>Export</p>
          </MenuItem>
        )}
        <MenuItem>
          <Link
            href="https://docs.qloo.com/docs/creatives"
            target="_blank"
            rel="noreferrer"
            color="inherit"
            underline="none"
            className={styles.creativesMenuItem}
            onClick={handleClose}
          >
            <ArticleOutlinedIcon sx={{ fontSize: 16, strokeWidth: 2.5 }} />
            <p>Documentation</p>
          </Link>
        </MenuItem>

      </Menu>
    </div>
  );
};

export default CreativesModalContextMenu;
