import render from './Render';
import runner from './runner';

const askAudience = {
  name: 'Ask audience a question',
  description: 'Ask this audience a question about their opinion on anything',
  features: {
    categorySelect: false,
    interactive: true,
    citations: 'hidden',
    experimental: true,
  },
  form: {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    properties: {
      instructions: {
        type: 'string',
        title: 'Question',
        description: 'Ask this audience a question about their opinion on anything',
      },
    },
    required: ['instructions'],
  },
  insights: {
    entities: [
      { category: 'artist', params: { take: 3 } },
      { category: 'brand', params: { take: 5 } },
      { category: 'movie', params: { take: 4 } },
      { category: 'person', params: { take: 5 } },
      { category: 'place', params: { take: 5 } },
      { category: 'podcast', params: { take: 3 } },
      { category: 'tv_show', params: { take: 4 } },
    ],
    tags: [
      { category: 'artist', params: { take: 3 } },
      { category: 'brand', params: { take: 5 } },
      { category: 'movie', params: { take: 4 } },
      { category: 'person', params: { take: 5 } },
      { category: 'place', params: { take: 5 } },
      { category: 'podcast', params: { take: 3 } },
      { category: 'tv_show', params: { take: 4 } },
    ],
  },
  runner,
  render,
};

export default askAudience;
