import { Divider } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';

import InfoItem from '@/components/InfoItem';
import { paramNames as allParamNames } from '@/constants/paramNames';
import QueryBuilder from '@/screens/Dashboard/QueryBuilder/QueryBuilder';
import { useAppSelector } from '@/store';
import getIsQlooUser from '@/utils/getIsQlooUser';

import styles from './CreativesModal.module.scss';
import CreativesModalMissingParams from './CreativesModalMissingParams';
import CreativesModalStepOneListItem from './CreativesModalStepOneListItem';
import getMissingRequiredParams from './getMissingRequiredParams';
import { statuses, TEMPLATE_STATUSES } from './statuses';
import templatesByKey from './templates';

const UI_SCHEMA_DEFAULT = {
  'ui:submitButtonOptions': {
    norender: true,
  },
};

const SelectWithPlaceholder = ({
  placeholder,
  value,
  onChange,
  children,
  sx,
  ...props
}) => (
  <Select
    displayEmpty
    value={value || ''}
    onChange={onChange}
    sx={sx}
    {...props}
  >
    <MenuItem value="" disabled>
      {placeholder}
    </MenuItem>
    {children}
  </Select>
);

const CreativesModalStepOne = ({
  availableCreatives,
  baseParams,
  formRef,
  prefetchInsights,
  selectedTemplateKeyState,
  userDataState,
}) => {
  const [userData, setUserData] = userDataState;
  const [selectedTemplateKey, setSelectedTemplateKey] = selectedTemplateKeyState;

  const template = templatesByKey[selectedTemplateKey];

  const handleChange = ({ formData }) => {
    setUserData(formData);
  };

  // TODO this is a hack to make sure the form data is merged with the default form data
  useEffect(() => {
    if (selectedTemplateKey && template?.defaultFormData) {
      setUserData({
        ...userData,
        ...template.defaultFormData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateKey]);

  const missingRequiredParams = useMemo(
    () => getMissingRequiredParams(baseParams, template?.required || []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseParams, selectedTemplateKey],
  );

  const { identity } = useAppSelector((state) => state.app);

  const isQlooUser = useMemo(
    () => Boolean(getIsQlooUser(identity)),
    [identity],
  );

  const genderFromBaseParams = baseParams[allParamNames.signalDemographicsGender];

  const handleChangeSelectedTemplateKey = (e) => {
    const nextSelectedTemplateKey = e.target.value;

    prefetchInsights(nextSelectedTemplateKey);
    setSelectedTemplateKey(nextSelectedTemplateKey);
    localStorage.setItem('lastSelectedCreative', nextSelectedTemplateKey);
  };

  return (
    <div className={styles.contentContainer}>
      <div className={styles.stepOneSection}>
        <Typography variant="h2" className={styles.baseParamsHeader} />
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <SelectWithPlaceholder
            placeholder="Select an action"
            onChange={handleChangeSelectedTemplateKey}
            value={selectedTemplateKey}
            sx={{
              fontSize: '1.5rem',
            }}
          >
            {availableCreatives.map((key) => {
              const status = statuses[key] || TEMPLATE_STATUSES.EXPERIMENTAL;
              return (
                <MenuItem
                  className={styles[`selectItem${status}`]}
                  key={key}
                  value={key}
                >
                  <CreativesModalStepOneListItem
                    templateKey={key}
                    isQlooUser={isQlooUser}
                  />
                </MenuItem>
              );
            })}
          </SelectWithPlaceholder>
        </FormControl>
      </div>
      <Divider />

      {!selectedTemplateKey || template?.hideBaseQuerySummary ? null : (
        <>
          {missingRequiredParams.length ? (
            <CreativesModalMissingParams
              missingRequiredParams={missingRequiredParams}
            />
          ) : null}
          <div className={clsx(styles.baseParams, styles.stepOneSection)}>
            <Typography variant="h2" className={styles.baseParamsHeader}>
              Using this audience
            </Typography>
            <QueryBuilder params={baseParams} onOpen={() => {}} veltLocation="">
              {({
                DemographicsComponent,
                InterestsComponent,
                LocationComponent,
              }) => (
                <div className={styles.baseParamsInnerContainer}>
                  <InfoItem text="BASE QUERY" />
                  <div className={styles.baseParamsInner}>
                    <DemographicsComponent
                      color="orange"
                      className={clsx([
                        styles.baseParamsPicker,
                        styles.baseParamsPickerLightOrange,
                      ])}
                    />
                    &nbsp;
                    <div>from</div>
                    &nbsp;
                    <LocationComponent
                      color="purple"
                      className={clsx([
                        styles.baseParamsPicker,
                        styles.baseParamsPickerLightPurple,
                      ])}
                    />
                    &nbsp;
                    <div>that like{genderFromBaseParams ? '' : 's'}</div>
                    &nbsp;
                    <InterestsComponent
                      color="blue"
                      className={clsx([
                        styles.baseParamsPicker,
                        styles.baseParamsPickerLightBlue,
                      ])}
                    />
                  </div>
                </div>
              )}
            </QueryBuilder>
          </div>
          <Divider />
        </>
      )}
      {template?.form ? (
        <div className={styles.formContainer}>
          <Form
            formData={userData}
            uiSchema={{
              ...UI_SCHEMA_DEFAULT,
              ...(template?.formUiSchema || {}),
            }}
            onChange={handleChange}
            ref={formRef}
            schema={template.form}
            validator={validator}
            disabled={!!missingRequiredParams.length}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CreativesModalStepOne;
