import { Checkbox, ListItem, ListItemAvatar } from '@mui/material';

import DashboardUserChipAvatar from '../DashboardChipAvatar/DashboardUserChipAvatar';
import IdentityRoleSelector from './IdentityRoleSelector';

const ShareWithListItem = ({
  identity, dashboardId, viewOnly, onCheck,
}) => {
  return (
    <ListItem
      secondaryAction={identity.identityLink ? (
        <IdentityRoleSelector
          identityLink={identity.identityLink}
          dashboardId={dashboardId}
          viewOnly={viewOnly}
        />
      ) : (!!onCheck && <Checkbox checked={identity.isSelected || false} onChange={onCheck(identity)} />)}
    >
      <ListItemAvatar>
        <DashboardUserChipAvatar
          userId={identity.identityId}
          label={identity.fullName || identity.identityId}
          email={identity.loginEmailAddress}
          isShowLabel
        />
      </ListItemAvatar>
    </ListItem>
  );
};

export default ShareWithListItem;
