export interface ColumnConfig {
  header: string;
  fnAcc?: string;
  acc?: string;
  toolTipAcc?: string;
  size?: number;
}

export const ColumnSizes = {
  DATE: 100,
  OFFICIAL_SITE: 200,
  DISAMBIGUATION: 150,
};
