import useItemColor from '@/hooks/useItemColor';

import Chip from '../Chip';
import styles from './OmnisearchItemChip.module.scss';

const OmnisearchItemChip = ({ item, onDelete, ...props }) => {
  const { main, contrastText } = useItemColor(item);

  return (
    <Chip
      {...props}
      key={item.id}
      onDelete={onDelete}
      variant="contained"
      color={main ? undefined : 'muted'}
      className={styles.chip}
      deleteIconClassName={styles.chipDeleteIcon}
      sx={{
        backgroundColor: main,
        color: contrastText,
      }}
    >
      {item.name}
    </Chip>
  );
};

export default OmnisearchItemChip;
