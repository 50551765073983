import { useAppSelector } from '@/store';

const useUserDashboardIdentity = (dashboardId: string) => {
  const { identity } = useAppSelector((state) => state.app);

  const dashboard = useAppSelector((state) => state.dashboards.items[dashboardId]);

  return dashboard?.identityLinks?.find((identityLink) => identityLink.identityId === identity.identityId);
};

export default useUserDashboardIdentity;
