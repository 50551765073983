import { useEffect } from 'react';

import { useIdentityBatch } from '@/contexts/IdentityBatchContext';
import { useAppSelector } from '@/store';

const useIdentity = (id: string) => {
  const identityBatch = useIdentityBatch();

  const identity = useAppSelector((state) => state.identities.byId[id]);

  useEffect(() => {
    if (!identity) {
      identityBatch.queueIdentityId(id);
    }
  }, [identity, id, identityBatch]);

  return identity;
};

export default useIdentity;
