import LinkIcon from '@mui/icons-material/Link';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { useCopyAndNotify } from '@v1/components/CopyAndNotify';
import useHasDashboardPermissions from '@v1/hooks/useHasDashboardPermissions';
import { bindDialog } from 'material-ui-popup-state';
import {
  useCallback, useMemo, useRef, useState,
} from 'react';

import permissionTags, { inheritedPermissionTags } from '@/constants/permissionTags';
import getTitle from '@/screens/Dashboard/helpers/getTitle';
import { useAppSelector } from '@/store';
import {
  useDashboardIdentitySoftDeleteMutation, useShareDashboardMutation,
} from '@/store/apis/dashboardsApi';
import { useIdentitySubordinatesGetQuery } from '@/store/apis/identityApi';
import featureFlagUtils, { featureFlags } from '@/utils/featureFlagUtils';
import getIsQlooUser from '@/utils/getIsQlooUser';
import keyBy from '@/utils/keyBy';

import { removeDuplicates } from '../helpers/dashboardHelpers';
import CustomInputWithChips from './CustomInputWithChips';
import OrganizationSelector from './OrganizationSelector';
import styles from './ShareWithDialog.module.scss';
import ShareWithList from './ShareWithList';
import typeTagIds from './typeTagIds';

const useIdentityList = ({
  dashboard, organizationId, searchInput, selected,
}) => {
  const identitiesById = useAppSelector((state) => state.identities.byId);
  const selectedByIdentityId = useMemo(() => keyBy(selected, 'identityId'), [selected]);
  const { data: subordinates } = useIdentitySubordinatesGetQuery({ identityId: organizationId }, { skip: !organizationId });

  const orgIdentity = useMemo(() => identitiesById[organizationId], [identitiesById, organizationId]);
  const loggedIdentityIds = useRef(new Set());

  const identitiesWithLinks = useMemo(() => {
    return (dashboard?.identityLinks || [])
      .map((identityLink) => {
        const identity = identitiesById[identityLink.identityId];

        if (!identity) {
          if (!loggedIdentityIds.current.has(identityLink.identityId)) {
            Sentry.captureMessage(
                  `Identity with ID ${identityLink.identityId} is missing in identitiesById for dashboard ID ${dashboard.dashboardId}`,
                  { level: 'warning' },
            );
            loggedIdentityIds.current.add(identityLink.identityId);
          }
        }

        return identity ? { ...identity, identityLink } : null;
      })
      .filter(Boolean);
  }, [dashboard, identitiesById]);

  const uniqueIdentities = useMemo(
    () => removeDuplicates([...identitiesWithLinks, orgIdentity, ...(subordinates || [])], 'identityId'),
    [identitiesWithLinks, orgIdentity, subordinates],
  );

  const { withAccess, withoutAccess } = useMemo(() => {
    return uniqueIdentities.filter((item) => item.identityId).reduce((acc, item) => {
      if (item.fullName.toLowerCase().includes(searchInput.toLowerCase())) {
        if (item.identityLink) {
          acc.withAccess.push(item);
        } else if (searchInput.length && acc.withoutAccess.length < 20) {
          const isSelected = !!selectedByIdentityId[item.identityId];
          acc.withoutAccess.push({
            ...item,
            isSelected,
          });
        }
      }
      return acc;
    }, { withAccess: [], withoutAccess: [] });
  }, [uniqueIdentities, searchInput, selectedByIdentityId]);

  const { orgsWithAccess, usersWithAccess } = useMemo(() => {
    return [...withAccess].reduce((acc, item) => {
      if (item.identityLink.typeTagId === typeTagIds.person) {
        acc.usersWithAccess.push(item);
      } else {
        acc.orgsWithAccess.push(item);
      }
      return acc;
    }, { orgsWithAccess: [], usersWithAccess: [] });
  }, [withAccess]);

  return {
    orgsWithAccess,
    usersWithAccess,
    withoutAccess,
  };
};

const ShareWithDialog = ({ dashboard, popupState }) => {
  const { identity } = useAppSelector((state) => state.app);
  const isOwner = useHasDashboardPermissions(dashboard.id, [permissionTags.roleOwner]);

  const [copyPublicDashboardUrl] = useCopyAndNotify({ successMessage: 'Copied URL to clipboard' });

  const [selectedTab, setSelectedTab] = useState('share');
  const [searchInput, setSearchInput] = useState('');
  const [selectedIdentities, setSelectedIdentities] = useState([]);
  const [role, setRole] = useState(permissionTags.roleViewer);

  const [selectedOrgId, setSelectedOrgId] = useState(
    identity?.hierarchy?.find((h) => h.childTypeTagId === typeTagIds.companyUnit)?.childIdentityId,
  );

  const [shareDashboard, { isLoading: isLoadingShareDashboard }] = useShareDashboardMutation();
  const [dashboardIdentitySoftDelete] = useDashboardIdentitySoftDeleteMutation();

  const isQlooUser = useMemo(() => Boolean(getIsQlooUser(identity)), [identity]);
  const isPublishDashboardEnabled = useMemo(
    () => featureFlagUtils.isEnabled(identity, featureFlags.public_dashboard),
    [identity],
  );

  const { orgsWithAccess, usersWithAccess, withoutAccess } = useIdentityList({
    dashboard,
    organizationId: selectedOrgId,
    searchInput,
    selected: selectedIdentities,
  });

  const [wasDashboardPublic] = useState(
    usersWithAccess.some((p) => p.identityId === import.meta.env.VITE_PUBLIC_DASHBOARD_IDENTITY),
  );
  const [isPublicDashboard, setIsPublicDashboard] = useState(wasDashboardPublic);
  const publicDashboardURL = `https://${window.location.host}/#/dashboards/public/${dashboard.id}`;

  const handleIdentityCheck = useCallback(
    (identityItem) => (e) =>
      setSelectedIdentities((prev) =>
        (e.target.checked
          ? [...prev, identityItem]
          : prev.filter((user) => user.identityId !== identityItem.identityId))),
    [],
  );

  const handleConfirm = useCallback((isPublic) => {
    if (!wasDashboardPublic && isPublic === true) {
      shareDashboard({
        dashboardId: dashboard.dashboardId,
        identityIds: `{${import.meta.env.VITE_PUBLIC_DASHBOARD_IDENTITY}}`,
        grantedPermissionTagIds: `{${permissionTags.roleViewer}}`,
      });
    } else if (wasDashboardPublic && isPublic === false) {
      dashboardIdentitySoftDelete({
        dashboardId: dashboard.dashboardId,
        identityId: import.meta.env.VITE_PUBLIC_DASHBOARD_IDENTITY,
      });
    }

    const identityIds = selectedIdentities.map((user) => user.identityId);

    if (identityIds.length) {
      shareDashboard({
        dashboardId: dashboard.dashboardId,
        identityIds: `{${identityIds.join(', ')}}`,
        grantedPermissionTagIds: `{${inheritedPermissionTags[role].join(', ')}}`,
      });
    }

    setSearchInput('');
    setSelectedIdentities([]);
  }, [
    wasDashboardPublic,
    shareDashboard,
    dashboard,
    selectedIdentities,
    role,
    dashboardIdentitySoftDelete,
  ]);

  const handleClose = () => {
    setSearchInput('');
    setSelectedIdentities([]);
    popupState.close();
  };

  return (
    <Dialog {...bindDialog(popupState)} fullWidth maxWidth="sm">
      <TabContext value={selectedTab}>
        <DialogTitle padding="0px !important">
          <Tabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
            <Tab label="Share" value="share" />
            {isPublishDashboardEnabled && <Tab label="Publish" value="publish" />}
          </Tabs>
        </DialogTitle>
        <DialogContent dividers sx={{ overflowY: selectedTab === 'publish' ? 'hidden' : 'scroll' }}>
          <TabPanel value="share" sx={{ width: '100%', padding: 0 }}>
            <Box mb={2} display="flex" gap={1}>
              <CustomInputWithChips
                isQlooUser={isQlooUser}
                searchInput={searchInput}
                selectedIdentities={selectedIdentities}
                setSelectedIdentities={setSelectedIdentities}
                setSearchInput={setSearchInput}
                setRole={setRole}
              />
              {isQlooUser && (
                <OrganizationSelector selectedOrgId={selectedOrgId} setSelectedOrgId={setSelectedOrgId} />
              )}
            </Box>
            {!!orgsWithAccess.length && (
              <ShareWithList
                title="Organizations with access"
                identities={orgsWithAccess}
                dashboardId={dashboard.dashboardId}
                viewOnly={!isOwner}
              />
            )}
            {!!usersWithAccess.length && (
              <ShareWithList
                title="Users with access"
                identities={usersWithAccess}
                dashboardId={dashboard.dashboardId}
                viewOnly={!isOwner}
              />
            )}
            {!!withoutAccess.length && (
              <ShareWithList
                title="Not in dashboard"
                identities={withoutAccess}
                dashboardId={dashboard.dashboardId}
                viewOnly={!isOwner}
                onIdentityCheck={handleIdentityCheck}
              />
            )}
          </TabPanel>
          <TabPanel value="publish" className={styles.publishPanel}>

            {!isPublicDashboard && (
              <Box textAlign="center">
                <div className={styles.shareTitle}>
                  Publish <span className={styles.shareTitleBold}>{getTitle(dashboard)}</span> to web
                </div>
                <img src="/SharedDashboardPreview.png" alt="Shared Dashboard Preview" className={styles.previewImage} />
              </Box>
            )}

            {isPublicDashboard && (
              <TextField
                value={publicDashboardURL.split('//')[1]}
                slotProps={{
                  input: {
                    readOnly: true,
                    endAdornment: <LinkIcon className={styles.linkIcon} />,
                  },
                }}
                className={styles.urlInput}
                onClick={() => copyPublicDashboardUrl(publicDashboardURL)}
              />
            )}
          </TabPanel>
        </DialogContent>
        <DialogActions>
          {selectedTab === 'share' && <Button color="secondary" variant="outlined" onClick={handleClose}>Close</Button>}
          {selectedTab === 'share' && !!selectedIdentities.length && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleConfirm}
              loading={isLoadingShareDashboard}
            >
              Apply Changes
            </Button>
          )}
          {selectedTab === 'publish' && !isPublicDashboard && (
            <Button
              color="primary"
              variant="contained"
              sx={{ width: '100%' }}
              onClick={() => { setIsPublicDashboard(true); handleConfirm(true); }}
              loading={isLoadingShareDashboard}
            >
              Publish
            </Button>
          )}
          {selectedTab === 'publish' && isPublicDashboard && (
            <Box display="flex" width="100%" sx={{ justifyContent: 'space-between' }}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => { setIsPublicDashboard(false); handleConfirm(false); }}
                loading={isLoadingShareDashboard}
              >
                Unpublish
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => { window.open(publicDashboardURL, '_blank'); }}
                loading={isLoadingShareDashboard}
              >
                View now
              </Button>
            </Box>
          )}
        </DialogActions>
      </TabContext>
    </Dialog>
  );
};

export default ShareWithDialog;
