import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IconButton } from '@mui/material';
import { urnsToDisplayName } from '@qloo/categories';
import clsx from 'clsx';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import Breadcrumb from '@/components/Breadcrumb';
import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import { paramNames } from '@/constants';
import useActiveDashboard from '@/hooks/useActiveDashboard';
import { useDashboardActionsContext } from '@/screens/Dashboard/DashboardActionsProvider';
import { affinityColumn, nameColumn, rankColumn } from '@/screens/Dashboard/DashboardPanel/helpers/getColumns';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import serializeInsightsParams from '@/utils/serializeInsightsParams';
import tooltips from '@/utils/tooltips';

import { defaultPageOffset, defaultPageSize } from '../../helpers/DashboardPanelConstants';
import styles from '../PanelBodyExplore.module.scss';
import TableWithTitle from '../TableWithTitle';
import SelectedCategoryContentTags from './SelectedCategoryContentTags';
import SelectedCategorySubcategoryDialog from './SelectedCategorySubCategoryDialog';

const TableTitles = {
  ENTITIES: 'Entities',
  TAGS: 'Tags',
};

const SelectedCategoryContent = ({
  selectedCategory,
  setHasMoreExplorePages,
  panel,
  selectedSubCategories,
  categorySubCategories,
  fixedSubcategory,
  onCategorySelected,
  onSubCategoryChange,
  onSubcategoriesReset,
}) => {
  const [selectedData, setSelectedData] = useState();
  const dashboardActions = useDashboardActionsContext();
  const { baseParams } = useActiveDashboard();

  const {
    panelId,
    settings: panelSettings,
    params: panelParams,
  } = panel;

  const highlightedSelectedCategory = useMemo(() => {
    if (!selectedSubCategories) {
      return undefined;
    }
    const keysWithIncludeValues = Object.keys(selectedSubCategories)
      .filter((key) => selectedSubCategories[key] === 'include');

    return keysWithIncludeValues.length > 0 ? keysWithIncludeValues.join(';') : undefined;
  }, [selectedSubCategories]);

  useEffect(() => {
    setSelectedData(panelSettings?.specificData || null);
  }, [panelSettings?.specificData]);

  const serializedInsightsParams = useMemo(() => {
    const {
      [paramNames.filterLocation]: filterLocation,
      ...restPanelParams
    } = panelParams;

    const tempParams = {
      ...restPanelParams,
      [paramNames.signalLocation]: filterLocation || restPanelParams[paramNames.signalLocation],
      [paramNames.filterType]: selectedCategory,
      offset: panelParams?.offset || defaultPageOffset,
      take: (panelParams?.take || defaultPageSize) + 1,
    };

    if (highlightedSelectedCategory) {
      tempParams[paramNames.filterTags] = { [highlightedSelectedCategory]: 'include' };
    }

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, panelParams, selectedCategory, highlightedSelectedCategory]);

  const {
    data: entitiesResult,
    isLoading: isEntitiesLoading,
    error: insightsError,
  } = useGetInsightQuery(serializedInsightsParams, {
    skip: selectedData === 'TAGS',
  });

  const entities = useMemo(
    () => (insightsError ? [] : entitiesResult?.results?.entities.slice(0, panelParams?.take)),
    [entitiesResult?.results?.entities, insightsError, panelParams],
  );

  const entitiesCount = entitiesResult?.results?.entities?.length || 0;

  useEffect(() => {
    setHasMoreExplorePages(
      entitiesCount > (panelParams?.take || defaultPageSize), // || tagsCount > pagination[paramNames.take],
    );
  }, [
    entitiesCount,
    panelParams,
    setHasMoreExplorePages,
  ]);

  const handleChangePanelSettings = useCallback((nextExploreControls) => {
    const nextSettings = {
      ...panelSettings,
      ...nextExploreControls,
    };

    dashboardActions.changePanelSettings({
      panelId,
      nextSettings,
    });
  }, [dashboardActions, panelId, panelSettings]);

  const handleSpecificDataSelect = (type) => () => {
    setSelectedData(type);
    handleChangePanelSettings({ specificData: type });
  };

  const currentTags = useMemo(() => {
    if (!categorySubCategories || categorySubCategories.length === 0 || !highlightedSelectedCategory) return [];

    const currentSubCategories = highlightedSelectedCategory.split(';');

    return categorySubCategories.filter((item) => currentSubCategories.includes(item.id))
      .map((option) => ({
        id: option.id,
        name: option.name,
      }));
  }, [categorySubCategories, highlightedSelectedCategory]);

  const subCategoryName = useMemo(() => {
    if (!categorySubCategories || categorySubCategories.length === 0 || !fixedSubcategory) return '';

    const { name } = categorySubCategories.find((item) => fixedSubcategory === item.id) || {};

    return ` / ${name}`;
  }, [categorySubCategories, fixedSubcategory]);

  const handleBack = useCallback(() => {
    onCategorySelected(null)();
    setHasMoreExplorePages(false);
    handleChangePanelSettings({ selectedCategory: null });
    onSubcategoriesReset();
  }, [onCategorySelected, setHasMoreExplorePages, handleChangePanelSettings, onSubcategoriesReset]);

  const breadcrumbItems = useMemo(() => {
    return [
      {
        label: <TooltipInfo title={tooltips.backToMainCategories}>Categories</TooltipInfo>,
        onClick: handleBack,
      },
      !highlightedSelectedCategory && {
        label: (
          <SelectedCategorySubcategoryDialog
            selectedCategory={selectedCategory}
            selectedSubCategories={selectedSubCategories}
            onSubcategoriesReset={onSubcategoriesReset}
            onSubCategoryChange={onSubCategoryChange}
            buttonLabel={urnsToDisplayName(selectedCategory)}
          />
        ),
      },
      !!highlightedSelectedCategory && {
        label: urnsToDisplayName(selectedCategory),
        ...((!!selectedData || !!highlightedSelectedCategory) && {
          onClick: () => {
            if (highlightedSelectedCategory) {
              onCategorySelected(selectedCategory)();
              handleChangePanelSettings({ selectedCategory });
              onSubcategoriesReset();
            }
            return handleChangePanelSettings({ specificData: null });
          },
        }),
      },
      !selectedData && !!highlightedSelectedCategory && {
        label: (
          <SelectedCategorySubcategoryDialog
            selectedCategory={selectedCategory}
            selectedSubCategories={selectedSubCategories}
            onSubcategoriesReset={onSubcategoriesReset}
            onSubCategoryChange={onSubCategoryChange}
            buttonLabel={highlightedSelectedCategory
              ? currentTags.map((item) => item.name).join(', ')
              : 'filter for sub categories'}
          />
        ),
      },
      !!selectedData && {
        label: highlightedSelectedCategory
          ? currentTags.map((item) => item.name).join(', ')
          : '',
        onClick: () => {
          handleChangePanelSettings({ specificData: null });
        },
      },
      !!selectedData && { label: TableTitles[selectedData] },
    ].filter(Boolean);
  }, [
    currentTags,
    handleBack,
    handleChangePanelSettings,
    highlightedSelectedCategory,
    onCategorySelected,
    onSubCategoryChange,
    onSubcategoriesReset,
    selectedCategory,
    selectedData,
    selectedSubCategories,
  ]);

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <span className={styles.name}>
          <IconButton onClick={handleBack} color="inherit">
            <ChevronLeftIcon />
          </IconButton>
          {urnsToDisplayName(selectedCategory)}
          {fixedSubcategory && fixedSubcategory !== selectedCategory && subCategoryName}
        </span>
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <div className={clsx({ [styles.data]: true, [styles.singleData]: !!selectedData })}>
        {(!selectedData || selectedData === 'TAGS') && (
          <SelectedCategoryContentTags
            onSpecificDataSelect={handleSpecificDataSelect}
            panelParams={panelParams}
            selectedCategory={selectedCategory}
            selectedData={selectedData}
            highlightedSelectedCategory={highlightedSelectedCategory}
          />
        )}
        {(!selectedData || selectedData === 'ENTITIES') && (
          <TableWithTitle
            isLoading={isEntitiesLoading}
            title="Entities"
            columns={[
              rankColumn,
              nameColumn,
              affinityColumn,
            ]}
            data={entities || []}
            onSelect={handleSpecificDataSelect(selectedData === 'ENTITIES' ? null : 'ENTITIES')}
          />
        )}
      </div>
    </div>
  );
};

export default SelectedCategoryContent;
