export const featureFlags = {
  public_dashboard: 'public_dashboard', // Enables users to make their dashboards accessible by anyone with the URL
  text_insight: 'text_insight', // Enables users to create insights panels that only contain text
};

const isEnabled = (identity: any, featureFlag: keyof typeof featureFlags) => {
  const foundFeatureFlag = featureFlags[featureFlag];
  return identity.calculatedPermissionTagIds.includes(`feature.flag.${foundFeatureFlag}`);
};

const featureFlagUtils = {
  isEnabled,
};

export default featureFlagUtils;
