import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  IconButton, ListItemIcon, ListItemText, Menu, MenuItem,
} from '@mui/material';
import useHasDashboardPermissions from '@v1/hooks/useHasDashboardPermissions';
import useHasPermission from '@v1/hooks/useHasPermission';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useState } from 'react';

import TooltipInfo from '@/components/Tooltip/TooltipInfo';
import { api } from '@/constants';
import muiVeltIds from '@/constants/muiVeltIds';
import permissionTags from '@/constants/permissionTags';
import useActiveDashboard from '@/hooks/useActiveDashboard';
import tooltips from '@/utils/tooltips';

import { useDashboardActionsContext } from '../../DashboardActionsProvider';
import getIsInsightPanel from '../helpers/getIsInsightPanel';
import MenuItemColor from './MenuItemColor';
import MenuItemExportData from './MenuItemExportData';
import MenuItemRename from './MenuItemRename';

const PanelContextMenu = ({
  panelId, panelTitle, panelType, serializedInsightsParams,
}) => {
  const [isEnableKeyDown, setIsEnableKeyDown] = useState(false);

  const dashboard = useActiveDashboard();
  const isEditor = useHasDashboardPermissions(dashboard.id, [permissionTags.roleEditor]);

  const dashboardActions = useDashboardActionsContext();
  const hasExportPermission = useHasPermission('feature.dashboard.panel.export');

  const menuPopupState = usePopupState({
    variant: 'popper',
    popupId: 'menuPopupState',
  });

  const handlePanelDelete = () => {
    dashboardActions.deletePanel(panelId);
  };

  const handlePanelClone = () => {
    dashboardActions.clonePanel(panelId);
  };

  const handleOpenInRequestBrowser = () => {
    const requestUrl = `${api.server}/v2/insights?${serializedInsightsParams}`;
    window.open(
      `/#/requests?requests=${encodeURIComponent(requestUrl)}`,
      '_blank',
    );
  };

  const isInsightPanel = getIsInsightPanel(panelType);

  return (
    <>
      <TooltipInfo title={tooltips.dashboard.moreActions} followCursor>
        <IconButton aria-label="panel-menu" {...bindTrigger(menuPopupState)} id={`${panelId}-${muiVeltIds.buttons.panelMenu}`}>
          <MoreHorizIcon color="secondary" />
        </IconButton>
      </TooltipInfo>
      <Menu
        {...bindMenu(menuPopupState)}
        onKeyDownCapture={(e) => {
          if (isEnableKeyDown) e.stopPropagation();
        }}
      >
        <MenuItemRename
          panelId={panelId}
          panelTitle={panelTitle}
          disabled={!isEditor}
          onToggleKeyDown={setIsEnableKeyDown}
        />
        <TooltipInfo title={tooltips.dashboard.clonePanel} followCursor>
          <MenuItem onClick={handlePanelClone}>
            <ListItemIcon><FileCopyOutlinedIcon /></ListItemIcon>
            <ListItemText>Clone Panel</ListItemText>
          </MenuItem>
        </TooltipInfo>
        {isInsightPanel && (
          <TooltipInfo title={tooltips.dashboard.openInRequestBrowser} followCursor>
            <MenuItem onClick={handleOpenInRequestBrowser}>
              <ListItemIcon><OpenInNewIcon /></ListItemIcon>
              <ListItemText>Open in request browser</ListItemText>
            </MenuItem>
          </TooltipInfo>
        )}
        <TooltipInfo title={tooltips.dashboard.delete} followCursor>
          <MenuItem onClick={handlePanelDelete}>
            <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
            <ListItemText>Delete Panel</ListItemText>
          </MenuItem>
        </TooltipInfo>
        {(isInsightPanel && hasExportPermission) && (
          <TooltipInfo title={tooltips.dashboard.export} followCursor>
            <MenuItemExportData
              panelId={panelId}
              serializedInsightsParams={serializedInsightsParams}
              onDownloaded={menuPopupState.close}
            />
          </TooltipInfo>
        )}
        <MenuItemColor panelId={panelId} parentMenuState={menuPopupState} />
      </Menu>
    </>
  );
};

export default PanelContextMenu;
